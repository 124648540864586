import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import { I18n } from 'aws-amplify';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Subtitles, Dates } from './styles';

class AddToCampaign extends Component {

    state = {
        campaignSelected: null
    }

    handleSelect = id => {
        this.setState({
            campaignSelected: id
        })
    }

    handleCreateCampaign = () => {
        this.props.history.push('campaigns/create')
    }

    UNSAFE_componentWillReceiveProps() {
        this.setState({
            campaignSelected: null
        })
    }

    render() {

        return (
            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{I18n.get("_select_the_campaign", "Select the campaign")}</DialogTitle>
                <DialogContent style={{ maxHeight: '500px', padding: 0 }}>
                    <Divider />
                    <List>
                        {this.props.CampaignsModel.items.length > 0 ?
                            this.props.CampaignsModel.items.map(campaign => {
                                return (
                                    <ListItem
                                        key={campaign.Campaign_ID}
                                        button
                                        onClick={() => this.handleSelect(campaign.Campaign_ID)}
                                        selected={this.state.campaignSelected === campaign.Campaign_ID}>

                                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                            <div>
                                                <Typography variant="h6">{campaign.Name}</Typography>
                                            </div>
                                            <Subtitles>
                                                <Typography variant="caption">{I18n.get("_brand", "Brand")}: {campaign.Brand}</Typography>
                                                <Typography variant="caption">{I18n.get("_advertiser", "Advertiser")}: {campaign.Advertiser}</Typography>
                                            </Subtitles>
                                        </div>

                                        <Dates>
                                            <Typography variant="subtitle1">{new Date(campaign.StartDate).toLocaleDateString()} - </Typography>
                                            <Typography variant="subtitle1">{new Date(campaign.EndDate).toLocaleDateString()}</Typography>
                                        </Dates>
                                        <Divider />
                                    </ListItem>
                                )
                            }) :
                            <div>
                                <Typography align="center" style={{ margin: '16px 0' }} variant="subtitle1" >No campaigns created yet</Typography>

                            </div>
                        }
                    </List>
                </DialogContent>

                <DialogActions>
                    {this.props.CampaignsModel.items.length > 0 ?
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!(this.state.campaignSelected !== null)}
                            style={{ color: 'white' }}
                            onClick={() => this.props.handleConfirm(this.state.campaignSelected)}>Confirm
                        </Button> :
                        <Button
                            onClick={this.handleCreateCampaign}
                            variant="contained"
                            style={{ color: 'white' }}
                            color="primary">Create one
                        </Button>}
                    <Button color="primary" variant="outlined" onClick={this.props.handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withRouter(inject("CampaignsModel")(observer(AddToCampaign)));