import React, { useMemo, useState, useEffect, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { autorun } from "mobx";

const autocompleteService = { current: null };

const placeService = { current: null };

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

function GoogleMapPlacesInput(props) {
    const classes = useStyles();
    const { onHandleSelect, store, field, ref } = props
    const errors = store.errors && store.errors[field]
    const hasError = !!errors
    const dirty = store.validating || store.touchedMap.has(field);
    const inputRef = useRef()

    useEffect(() => {
        return autorun(() => {
            if (store.isFirstInvalid && !!(store.errors && store.errors[field])) {
                if (ref) {
                    ref.current.focus()
                } else {
                    inputRef.current.focus()
                }
            }
        })
    }, [])

    const inputProps = _.pick(props, [
        'margin',
        'label',
        'variant',
        'fullWith',
        'input',
        'autoComplete'])

    const autocompleteProps = _.pick(props, [
        'noOptionsText',
        'disabled',
        'id',
        'defaultValue'])


    const [value, setValue] = useState(null)
    const [inputValue, setInputValue] = useState('')
    const [options, setOptions] = useState([])

    const fetch = useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback)
            }, 200),
        [],
    )

    const fetchPlace = useMemo(
        () => (request, callback) => {
            placeService.current.getDetails(request, callback)
        },
        [],
    )

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService()
        }
        if (!placeService.current && window.google) {
            placeService.current = new window.google.maps.places.PlacesService(document.getElementById(`cnt_map_${autocompleteProps.id}`))
        }
        if (!autocompleteService.current || !placeService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : [])
            return undefined
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = []

                if (value) {
                    newOptions = [value]
                }

                if (results) {
                    newOptions = [...newOptions, ...results]
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false
        }
    }, [value, inputValue, fetch])

    function findPlace(newValue) {
        if (newValue && newValue.place_id) {
            fetchPlace({ placeId: newValue.place_id }, (result) => {
                onHandleSelect({
                    lat: result.geometry.location.lat(),
                    lng: result.geometry.location.lng(),
                    name: result.name,
                    types: result.types,
                    formatted_address: result.formatted_address,
                    description: result.description,
                    result: result
                })
            })
        }
    }

    return (
        <>
            <div id={`cnt_map_${autocompleteProps.id}`}></div>
            <FormControl error={dirty && hasError} fullWidth>
                <Autocomplete
                    data-testid={'map-input-testid'}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                    filterOptions={(x) => x}
                    options={options}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={store["address"]}
                    onChange={(event, newValue) => {
                        setOptions(newValue ? [newValue, ...options] : options)
                        setValue(newValue)
                        findPlace(newValue)
                        store.setField("address", newValue)

                    }}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            {...inputProps}
                            error={dirty && hasError}
                            autoComplete='new-password'
                            inputRef={innerRef => {
                                if (ref) {
                                    ref.current = innerRef
                                } else {
                                    inputRef.current = innerRef
                                }
                            }}
                        />
                    )}
                    renderOption={(option) => {
                        const matches = option.structured_formatting.main_text_matched_substrings;
                        const parts = parse(
                            option.structured_formatting.main_text,
                            matches.map((match) => [match.offset, match.offset + match.length]),
                        );

                        return (
                            <Grid container alignItems="center">
                                <Grid item>
                                    <LocationOnIcon className={classes.icon} />
                                </Grid>
                                <Grid item xs>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>
                                    ))}

                                    <Typography variant="body2" color="textSecondary">
                                        {option.structured_formatting.secondary_text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    }}

                    {...autocompleteProps}
                />
                {dirty && hasError && <FormHelperText>{errors[0]}</FormHelperText>}
            </FormControl>
        </>
    )
}

export default (observer(GoogleMapPlacesInput))
