import styled from 'styled-components';
import { palette } from '../../../../../../constants/theme';

export const ChangeLabel = styled.h6`
    color: ${palette.blue};
    padding-top: 5px; 
    font-size: 10px; 
    cursor: pointer;
`;

export const ActionIcon = styled.img`
    height: 34px;
    width: 34px;
    margin-right: 8px;
    border-radius: 6px;
    border: none;
    flex-grow: 0;
    cursor: pointer;
`;