import React, {Component} from 'react';
import Snackbar from "@material-ui/core/Snackbar";
import {Hub} from "aws-amplify";
import {Alert} from "@material-ui/lab";

class GlobalSnackBar extends Component {

    constructor(props){
        super(props);
        this.state = {
            msg:'',
            open:false,
            duration: 2000,
            severity:'message'
        }

        Hub.listen('fcSnackBar', this.fcSnackListener, `fcErrorSnackBar_listener`);

    }

    fcSnackListener = ({ payload: { msg, severity, duration} }) => {
        if(!msg){return;}

        this.setState({msg})

        if(severity){ this.setState({severity})}
        if(duration){ this.setState({duration})}

        this.handleOpen();
    }

    handleOpen (){
        this.setState({open:true})
    }
    handleClose(){
        this.setState({open:false})
    }
    render() {
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.open}
                    autoHideDuration={this.state.duration}
                    onClose={()=> { this.handleClose(); }}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}>

                    <Alert
                        severity={this.state.severity}
                        sx={{ width: '100%' }}>
                        {this.state.msg}
                    </Alert>

                </Snackbar>
            </div>
        );
    }
}

export default GlobalSnackBar;
