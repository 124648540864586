import _ from "lodash";

import Default from "./ActionTemplates/default";
import SMS from "./ActionTemplates/sms";
import Call from "./ActionTemplates/call";
import Uber from "./ActionTemplates/uber";
import Lyft from "./ActionTemplates/lyft";
import Spotify from "./ActionTemplates/spotify";
import iTunes from "./ActionTemplates/itunes";
import AmazonMusic from "./ActionTemplates/amazonmusic";
import Email from "./ActionTemplates/email";
import GoogleMap from './ActionTemplates/googlemaps'
import Waze from './ActionTemplates/waze'
import appleMaps from './ActionTemplates/applemaps'
import GooplePlayStore from './ActionTemplates/googleplaystore'
import ItunesAppStore from './ActionTemplates/itunesappstore';
import Amazon from './ActionTemplates/amazon';
import Telegram from './ActionTemplates/telegram';

import YouTube from "./ActionTemplates/youtube";
import Messenger from "./ActionTemplates/messenger";
import WhatsApp from "./ActionTemplates/whatsapp";
import Facebook from './ActionTemplates/facebook';
import Linkedin from "./ActionTemplates/linkedin";
import Twitter from "./ActionTemplates/twitter";
import Snapchat from "./ActionTemplates/snapchat";
import Instagram from './ActionTemplates/instagram';
import SocialSearch from './ActionTemplates/socialSearch';
import Tiktok from './ActionTemplates/tiktok';

import Venmo from "./ActionTemplates/venmo";
import Paypal from "./ActionTemplates/paypal";
import Cash from "./ActionTemplates/cash";

import Postmates from "./ActionTemplates/postmates";
import Opentable from "./ActionTemplates/opentable";

import Poll from "./ActionTemplates/poll";


import FastForm from "./ActionTemplates/FastForm";
import FastFormZapier from "./ActionTemplates/FastFormZapier";
import FastFormIntegromat from "./ActionTemplates/FastFromIntegromat";
import Grubhub from "./ActionTemplates/grubhub";
import UberEats from "./ActionTemplates/ubereats";
import Seamless from "./ActionTemplates/seamless";
import DoorDash from "./ActionTemplates/doordash";
import CalendarAction from "./ActionTemplates/event";
import GroupAction from "./ActionTemplates/groupAction";

import VCardAction from "./ActionTemplates/vcard";
import YoutubeSubscribeAction from "./ActionTemplates/youtubeSubscribe";
import MobilityAction from "./ActionTemplates/mobility";
import FileAction from "./ActionTemplates/file";

// import Ticketmaster from "./actions/ticketmaster";
// import Eventbrite from "./actions/eventbrite";

import createOwnFastCard from "./ActionTemplates/createOwnFastCard"
import calendly from "./ActionTemplates/calendly"
import FastProduct from "./ActionTemplates/FastProduct";

// This is also the order actions appear in the dialog in
export const actionsList = [
    Default,
    Opentable,
    Linkedin,
    Twitter,
    Facebook,
    Instagram,
    WhatsApp,
    Messenger,
    YouTube,
    Snapchat,
    CalendarAction,
    SMS,

    ///Food
    Postmates,
    Grubhub,
    UberEats,
    Seamless,
    DoorDash,
    // Eventbrite,
    Venmo,
    Cash,
    Paypal,
    // Ticketmaster,
    Call,
    Email,
    Uber,
    Lyft,
    Spotify,
    Amazon,
    iTunes,
    AmazonMusic,
    GoogleMap,
    Waze,
    appleMaps,
    ItunesAppStore,
    GooplePlayStore,
    Tiktok,
    Poll,
    Telegram,
    YoutubeSubscribeAction,
    SocialSearch,
    // Fast Forms
    FastForm,
    FastFormZapier,
    FastFormIntegromat,

    GroupAction,
    VCardAction,
    MobilityAction,
    FileAction,
    createOwnFastCard,

    //calendly
    calendly,
    // Fast Product
    FastProduct
];

export const actionsMap = _.keyBy(actionsList, e => e.metadata.id);
