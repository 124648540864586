import React, { useState } from 'react';
import { action, computed, decorate, observable } from "mobx";
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import {
    ListItem,
    ServiceActionButton,
    ServiceActionButtonText,
    ServiceSubtitle,
    ServiceThumbnail,
    ServiceTitle,
    TextContainer
} from "../../../../../models/ActionModel/styles";
import { randomString } from "../../../../../utils/functions";
import { observer } from "mobx-react";
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import { actionsMap } from "../index";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { I18n } from "aws-amplify";

const useStyles = makeStyles(theme => ({
    selectItem: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        paddingLeft: '30px'
    },
}));

const RenderGroupAction = observer((props) => {

    const [open, setOpen] = useState(false);
    const { cardModel, index, theme, actionModel } = props
    const classes = useStyles()

    const toggleOpen = () => {
        setOpen(!open);
    }

    let isLast = cardModel.LinksList.length === 0 ? true : (cardModel.LinksList.length - 1 === index);
    let image = actionModel.Image || actionModel.constructor.metadata.icon;
    return (
        <>
            <ListItem
                failed={false}
                last={isLast}
                theme={theme}
                key={index}
                onClick={toggleOpen}>
                {
                    actionModel._ImageIsFromUs ? (
                        <ServiceThumbnail
                            theme={theme}
                            loading="lazy"
                            src={`${image}?width=80&height=80`}
                            srcSet={`${image}?width=50&height=50 1x,
										 ${image}?width=80&height=80 2x,
										 ${image}?width=100&height=100 3x`
                            }
                            alt={actionModel.Title || actionModel.constructor.metadata.name} />
                    ) : (
                            <ServiceThumbnail
                                theme={theme}
                                loading="lazy"
                                src={image}
                                alt={actionModel.Title || actionModel.constructor.metadata.name} />
                        )
                }

                <TextContainer>
                    <ServiceTitle theme={theme}>{actionModel.Title || actionModel.constructor.metadata.name}</ServiceTitle>
                    <ServiceSubtitle theme={theme}>{actionModel.Subtitle || actionModel.constructor.metadata.subtitle}</ServiceSubtitle>
                </TextContainer>

                <ServiceActionButton theme={theme} failed={false}>
                    {open ?
                        <UpIcon style={{ color: theme.cardActions }} />
                        :
                        <ServiceActionButtonText theme={theme}>
                            <div>{(actionModel.ButtonText).toUpperCase()}</div>
                        </ServiceActionButtonText>}
                </ServiceActionButton>
            </ListItem>
            {open && actionModel.childActions.map((childAction, index2) => {
                return <React.Fragment key={index2}>
                    {childAction.RenderItem(cardModel, index2, theme, classes.selectItem)}
                </React.Fragment>
            })}
        </>
    );

})


class GroupAction extends ActionModel {

    static metadata = {
        id: "group",
        name: "Group",
        icon: "https://l.fast.cm/content/icons/group.png",
        categories: {
            general: true
        },
        help:{
            id:"articles/how-to-group-similar-features"
        }
    };

    isValid = false
    childActions = []

    get isGroup() {
        return true
    }

    get groupable() {
        return false
    }

    onValidateUrl = () => {
        this.isValid = true
    }

    addChildAction(action) {
        this.childActions.push(action);
    }

    addAllChildActions(actions) {
        actions.forEach(action => {
            let dat = action.asObject;
            this.childActions.push((dat.action && actionsMap.hasOwnProperty(dat.action) ? new actionsMap[dat.action](dat) : new actionsMap["default"](dat)));
        });
    }

    removeChildAction(index) {
        this.childActions.splice(index, 1);
    }

    get isInvalidEmptyChildList() {
        return this.highlightInvalid && this.childActions.length === 0;
    }

    get asObject() {
        let ret = {
            Title: "",
            Image: "",
            Link: "",
            ButtonText: ""
        };

        this.propNames.forEach(e => {

            ret[e] = this[e];
        });

        if (this.constructor.metadata.id && this.constructor.metadata.id !== "default") {
            ret.action = this.constructor.metadata.id;
        }

        if (this.Subtitle) {
            ret.Subtitle = this.Subtitle
        }

        ret.actionID = this.actionID || `${this.constructor.metadata.id}_${randomString(9, "#aA")}`

        ret["childActions"] = this.childActions.map(childAction => childAction.asObject)

        return ret;
    }

    get isChildActionsEmpty() {
        return this.childActions.length === 0
    }

    validate() {
        let isValid = super.validate();

        if (this.childActions.length === 0) {
            return false
        }

        this.childActions.forEach(childAction => {
            if (!childAction.validate()) {
                isValid = false;
            }
        })

        return isValid
    }

    RenderItem = (cardModel, index, theme) => {
        return (<RenderGroupAction cardModel={cardModel} index={index} theme={theme} actionModel={this} />)
    };
}

decorate(GroupAction, {
    Title: observable,
    Link: observable,
    ButtonText: observable,
    isValid: observable,
    childActions: observable,
    addChildAction: action,
    addAllChildActions: action,
    removeChildAction: action,
    asObject: computed,
    isInvalidEmptyChildList: computed,
    isGroup: computed,
    isChildActionsEmpty: computed,
    groupable: computed

})

export default GroupAction;
