import React, { useState} from 'react';
import MUIDataTable from "mui-datatables";
import { inject, observer } from 'mobx-react';
import {API, I18n} from 'aws-amplify';
import Button from "@material-ui/core/Button";
import toast from "react-hot-toast";
import lodashGet from "lodash/get";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typograghy from "@material-ui/core/Typography";
import PropTypes from "prop-types";

const apiNamePayment = 'fastCardPayment';

const BillingPanel = props => {

    const [fetchingPortal, setFetchingPortal] = useState(false);

    const columns = [
        {
            name: "name",
            label: I18n.get("_name",),
            options: {
                filter: false,
                sort: false,
                customHeadRender: ()=>null,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <Typograghy
                        style={{fontWeight: 500 }}
                        variant={'subtitle1'}>{I18n.get(`_${value}`)}</Typograghy>
                }
            }
        }, {
            name: "details",
            label: I18n.get("_details", ),
            options: {
                filter: false,
                sort: false,
                customHeadRender: ()=>null,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if(!value) return <Typograghy variant={'body1'}>---</Typograghy>

                    if(tableMeta.rowData[0] === 'plan' || tableMeta.rowData[0] === 'payment_type' ){ // if it's the plan row
                        return <Typograghy style={{textTransform: 'capitalize'}} variant={'body1'}>{I18n.get(`_${value}`)}</Typograghy>
                    }

                    if(value) return <Typograghy style={{textTransform: 'capitalize'}} variant={'body1'}>{value}</Typograghy>
                }
            }
        },
    ];

    let subscriptionData = lodashGet(props, 'UserModel.stripeDataSummary.subscriptionsData[0]');
    let paymentType = lodashGet(subscriptionData, 'type');
    let last4 = lodashGet(subscriptionData, 'last4');

    let data = [
        {
            name: 'plan',
            details: `plan_${lodashGet(subscriptionData, 'tier')}`,
        },
        {
            name: 'payment_type',
            details: paymentType?`payment_type_${lodashGet(subscriptionData, 'type')}`: undefined,
        },
        {
            name: 'payment_method',
            details: last4? `${lodashGet(subscriptionData, 'brand', '')} (${lodashGet(subscriptionData, 'last4')})`:undefined,
        },
    ]


    const options = {
        textLabels: {
            body: {
                noMatch: props.UserModel.loading ? I18n.get("_fetching_billing_info") : I18n.get("_no_billing_info")
            }
        },
        filterType: 'checkbox',
        selectableRows: 'none',
        rowsPerPageOptions:[], // disable pagination
        customFooter: () => null,
        customToolbar: () => <><Button disabled={fetchingPortal} onClick={()=>fetchCustomerPortalLink()} color={'primary'} variant={'outlined'}>{I18n.get('_manage_subscription')}</Button></>,
        download: false,
        search: false,
        print: false,
        viewColumns: false,
        filter: false,
    };
    const fetchCustomerPortalLink = ()=>{
        setFetchingPortal(true);
        API.post(apiNamePayment, '/getCustomerPortal', {body:{return_url: window.location.href}})
            .then(result => {
                window.open(lodashGet(result, 'data.session.url'), props.openNewTab?'_blank':'_self');
            })
            .catch(error => toast.error(I18n.get('_request_failed')))
            .finally(()=>setFetchingPortal(false));
    }



    return (
        <div>
        {(fetchingPortal || !subscriptionData) &&
            <LinearProgress color="primary" style={{flexGrow: 1}}/>
        }
        {subscriptionData &&
            <MUIDataTable
                title={I18n.get("_my_plan")}
                data={data}
                columns={columns}
                options={options}
            />
        }
        </div>
    );
};

BillingPanel.propTypes = {
    openNewTab: PropTypes.bool
};

export default inject("UserModel")(observer(BillingPanel));