import React from 'react';
import { observable, decorate } from "mobx";
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import NewActionHeader from '../../NewAction/NewActionHeader/NewActionHeader';

class UberEats extends ActionModel {
	static metadata = {
		id: "ubereats",
		name: "Uber Eats",
		icon: "https://l.fast.cm/content/icons/ubereats.png",
		categories: {
			// food: true
		}
	};

	Title = "Ubereats";
	Link = "https://www.ubereats.com/"
	ButtonText = "GO";
	isValid = false

	onValidateUrl = () => {
		this.isValid = true
	}

	getConfigCardBody(cardModel, handleOnChangeIcon) {
		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />
				<TextField
					variant="outlined"
					margin='dense'
					fullWidth
					type="text"
					autoComplete="off"
					id="destId"
					placeholder="type here the url"
					value={this.Link}
					onChange={e => this.Link = e.target.value}
					disabled={cardModel.loading} />
			</CardContent>
		)
	}
}

decorate(UberEats, {
	Link: observable,
	isValid: observable,
	Title: observable,
	ButtonText: observable
})

export default UberEats