import React from "react";
import { observable, decorate } from "mobx";
import CardContent from '@material-ui/core/CardContent';
import { I18n } from 'aws-amplify';
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import NewActionHeader from "../../NewAction/NewActionHeader/NewActionHeader";
import PhoneInput from "../../NewAction/inputs/PhoneInput/PhoneInput";
import TextInput from "../../NewAction/inputs/TextInput/TextInput";
// import TextInput from "../../MainContent/MainContent";

class SMSAction extends ActionModel {
	static metadata = {
		id: "sms",
		name: "Text Message",
		icon: "https://l.fast.cm/content/icons/sms.png",
		categories: {
			general: true
		},
		help: {
			id: 'articles/about-general-links-text-message',
			helperText: I18n.get('_sms_help_text', "Click to learn more about the Text action")
		}
	};

	phoneNumber = "";
	isValid = false;
	message = "";

	constructor(dat) {
		super();

		this.propNames.push("phoneNumber", "message");
		this.setFromObject(dat);
	}

	getConfigCardBody(cardModel, handleOnChangeIcon) {
		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />
				<PhoneInput
					cardModel={cardModel}
					actionModel={this} />

				<TextInput
					fullWidth
					type="textarea"
					field={'message'}
					label={I18n.get("_action_email_placeHolder_message")}
					store={this}
					autoComplete="off"
					disabled={cardModel.loading}
					style={{ marginTop: '16px' }}
					inputProps={{
						'data-testid': 'message-testid'
					}}
				/>
			</CardContent>
		)
	}

	getRules() {
		return {
			phoneNumber: {
				presence: { allowEmpty: false }
			},
			...super.getRules()
		}
	}

};

decorate(SMSAction, {
	phoneNumber: observable,
	isValid: observable,
	message: observable
})

export default SMSAction
