import React from 'react';
import { action, computed, decorate, flow, observable } from "mobx";
import { Storage } from 'aws-amplify';
import CardContent from "@material-ui/core/CardContent";
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import NewActionHeader from '../../NewAction/NewActionHeader/NewActionHeader';
import EmailContact from "../Common/EmailContact";
import { randomString } from "../../../../../utils/functions";
import PhoneContact from "../Common/PhoneContact";
import vcard from 'vcard-generator';
import * as Sentry from "@sentry/react";

import {
    ListItem,
    ServiceActionButton,
    ServiceActionButtonText,
    ServiceSubtitle,
    ServiceThumbnail,
    ServiceTitle,
    TextContainer
} from "../../../../../models/ActionModel/styles";
import VCardInput from "../../NewAction/inputs/VCardInput/VCardInput";
import WebsiteContact from "../Common/WebsiteContact";
import { I18n } from 'aws-amplify';


class VCardAction extends ActionModel {

    static metadata = {
        id: "vcard",
        name: "vCard",
        icon: "https://l.fast.cm/content/icons/contact.png",
        categories: {
            general: true
        },
        help: {
            id: 'articles/address-book-or-contacts-action',
            helperText: I18n.get('_vcard_help_text', "Click to learn more about the Contact action")
        }
    };

    isValid = false
    photo = ''
    name = ''
    lastName = ''
    phones = []
    emails = []
    imageData = ''
    lat_lng = []
    lat = 0;
    lng = 0;
    company = ''
    address = ''
    zipCode = ''
    city = ''
    state = ''
    websites = []


    constructor(dat) {
        super(dat);
        // this.propNames = ["Title", "Image", "Link", "Subtitle", "ButtonText", "name", "lastName", "address", "photo", "lat_lng", "company", "state", "zipCode", "city"]
        this.propNames.push("name", "lastName", "address", "photo", "lat_lng", "company", "state", "zipCode", "city");
        this.setFromObject(dat)
        this.lat = this.lat_lng[0]
        this.lng = this.lat_lng[1]
    }

    validate() {
        let valid = super.validate();

        this.emails.forEach(value => {
            if (!value.validate()) {
                valid = false
            }
        })

        this.phones.forEach(value => {
            if (!value.validate()) {
                valid = false
            }
        })

        this.websites.forEach(value => {
            if (!value.validate()) {
                valid = false
            }
        })

        return valid
    }

    addEmail(index) {
        this.emails.splice(index + 1, 0, new EmailContact())
    }

    removeEmail(index) {
        this.emails.splice(index, 1)
    }

    addPhone(index) {
        this.phones.splice(index + 1, 0, new PhoneContact())
    }

    removePhone(index) {
        this.phones.splice(index, 1)
    }

    addWebsite(index) {
        this.websites.splice(index + 1, 0, new WebsiteContact())
    }

    removeWebsite(index) {
        this.websites.splice(index, 1)
    }

    removePhoto() {
        this.photo = undefined;
    }

    downloadCvf(model) {

        let cvfData = {
            name: {
                familyName: model.lastName,
                givenName: model.name,
            },
            formattedNames: [{
                text: `${model.name} ${model.lastName}`,
            }],
            nicknames: [],
            extraName: {
            },

            works: [{
                organization: model.company,
            }],
            emails: model.emails.map((email) => {
                return {
                    type: email.type,
                    text: email.value
                }
            }),
            phones: model.phones.map((phone) => {
                return {
                    type: phone.type,
                    text: phone.value
                }
            }),
            addresses: [
                {
                    type: 'work',
                    street: model.address,
                    locality: model.state,
                    code: model.zipCode
                }],
            urls: model.websites.map((web) => {
                return {
                    type: web.type,
                    uri: web.value
                }
            }),
            socialProfiles: [],
            photos: [],
        };

        if (model.photo) {
            this.convertImgToBase64(model.photo, (image) => {
                cvfData.photos.push({
                    type: 'home',
                    dataUri: image
                })
                this.downloadFile(cvfData)
            })
        } else {
            this.downloadFile(cvfData)
        }
    }

    downloadFile(cvfData) {

        let data = new Blob([vcard.generate(cvfData)], { type: 'text/vcf' });
        var csvURL = window.URL.createObjectURL(data);
        let tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'vcard.vcf');
        tempLink.click();
    }

    convertImgToBase64(url, callback, outputFormat) {
        var canvas = document.createElement('CANVAS');
        var ctx = canvas.getContext('2d');
        var img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = function () {
            canvas.height = img.height;
            canvas.width = img.width;
            ctx.drawImage(img, 0, 0);
            var dataURL = canvas.toDataURL(outputFormat || 'image/png');
            callback.call(this, dataURL);
            // Clean up
            canvas = null;
        };
        img.src = url;
    }

    saveImage = flow(function* (card, imageData, cb) {
        try {
            const fileName = randomString(9, '0123456789abcdefghijklmnopqrstuvwxyz');
            yield Storage.put("cardTemp/" + card.Card_ID + "/" + fileName, new File([imageData], "test.png"), {
                level: 'public',
            })

            this.photo = "https://s3.amazonaws.com/fastlinkuploads/public/cardTemp/" + card.Card_ID + "/" + fileName

        } catch (err) {
            Sentry.captureException(err);
        } finally {
            cb()
        }
    });

    setFromObject(dat) {
        if (!dat) {
            return;
        }

        for (let i in dat) {
            if (this.propNames.indexOf(i) !== -1) {
                this[i] = dat[i];
            }
        }

        if (this.Image) {
            this.Image = this._checkImageUrl(this.Image)
        }

        this.actionID = dat.actionID || `${this.constructor.metadata.id}_${randomString(9, "#aA")}`;
        this.crawledLink = dat.crawledLink;

        if (dat.emails && dat.emails.length > 0) {
            this.emails = dat.emails.map(email => new EmailContact(email))
        } else {
            this.emails = [new EmailContact()]
        }

        if (dat.phones && dat.phones.length > 0) {
            this.phones = dat.phones.map(phone => new PhoneContact(phone))
        } else {
            this.phones = [new PhoneContact()]
        }

        if (dat.websites && dat.websites.length > 0) {
            this.websites = dat.websites.map(data => new WebsiteContact(data))
        } else {
            this.websites = [new WebsiteContact()]
        }
    }

    get asObject() {

        let ret = {
            Title: "",
            Image: "",
            Link: "",
            ButtonText: ""
        };

        if (this.emails) {
            ret['emails'] = this.emails.filter(email => email.value).map(email => {
                const { value, type } = email;
                return { value, type }
            })
        }

        if (this.phones) {
            ret['phones'] = this.phones.filter(phone => phone.value).map(phone => {
                const { value, type } = phone;
                return { value, type }
            })
        }

        if (this.websites) {
            ret['websites'] = this.websites.filter(data => data.value).map(data => {
                const { value, type } = data;
                return { value, type }
            })
        }

        this.propNames.forEach(e => {
            ret[e] = this[e];
        });

        if (this.constructor.metadata.id && this.constructor.metadata.id !== "default") {
            ret.action = this.constructor.metadata.id;
        }

        if (this.Subtitle) {
            ret.Subtitle = this.Subtitle
        }

        ret.actionID = this.actionID || `${this.constructor.metadata.id}_${randomString(9, "#aA")}`

        return ret;
    }

    getConfigCardBody(cardModel, handleOnChangeIcon) {
        return (
            <CardContent>
                <NewActionHeader
                    onChangeIcon={handleOnChangeIcon}
                    actionModel={this} />

                <VCardInput cardModel={cardModel} actionModel={this} />
            </CardContent>
        )
    }

    RenderItem = (cardModel, index, theme, containerClassName) => {
        let isLast = cardModel.LinksList.length === 0 ? true : (cardModel.LinksList.length - 1 === index);
        let image = this.Image || this.constructor.metadata.icon;
        return (
            <ListItem
                failed={false}
                last={isLast}
                theme={theme}
                key={index}
                className={containerClassName}>
                {
                    this._ImageIsFromUs ? (
                        <ServiceThumbnail
                            theme={theme}
                            loading="lazy"
                            src={`${image}?width=80&height=80`}
                            srcSet={`${image}?width=50&height=50 1x,
							 		 ${image}?width=80&height=80 2x,
							 		 ${image}?width=100&height=100 3x`
                            }
                            alt={this.Title || this.constructor.metadata.name} />
                    ) : (
                        <ServiceThumbnail
                            theme={theme}
                            loading="lazy"
                            src={image}
                            alt={this.Title || this.constructor.metadata.name} />
                    )
                }

                <TextContainer>
                    <ServiceTitle theme={theme}>{this.Title || this.constructor.metadata.name}</ServiceTitle>
                    <ServiceSubtitle theme={theme}>{this.Subtitle || this.constructor.metadata.subtitle}</ServiceSubtitle>
                </TextContainer>

                <ServiceActionButton theme={theme} failed={false} onClick={() => this.downloadCvf(this)}>
                    <ServiceActionButtonText theme={theme}>
                        <div>{(this.ButtonText).toUpperCase()}</div>
                    </ServiceActionButtonText>
                </ServiceActionButton>
            </ListItem>
        );
    };

    getRules() {
        return {
            name: {
                presence: { allowEmpty: false },
            },
            lat_lng: {
                type: "array"
            },
            lat: {
                numericality: {
                    greaterThanOrEqualTo: -90,
                    lessThanOrEqualTo: 90,
                }
            },
            lng: {
                numericality: {
                    greaterThanOrEqualTo: -180,
                    lessThanOrEqualTo: 180,
                }
            },
            ...super.getRules()
        }
    }
}

decorate(VCardAction, {
    ButtonText: observable,
    isValid: observable,
    photo: observable,
    name: observable,
    lastName: observable,
    address: observable,
    phones: observable,
    emails: observable,
    addEmail: action,
    removeEmail: action,
    addPhone: action,
    removePhone: action,
    websites: observable,
    addWebsite: action,
    removeWebsite: action,
    setFromObject: action,
    asObject: computed,
    removePhoto: action,
    lat_lng: observable,
    lng: observable,
    lat: observable,
    company: observable,
    mailingAddress: observable,
    zipCode: observable,
    city: observable,
    state: observable
})

export default VCardAction;
