import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    root: {
        display: 'flex'
    },
    autoComplete: {
        marginRight: 8,
        width: 80
    },
    clearIndicator: {
        display: 'none'
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    paper: {
        width: 300
    }
});