import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Typography from "@material-ui/core/Typography";
import { Hidden, withStyles } from "@material-ui/core";
import {Auth, I18n} from "aws-amplify";
import { styles } from "./styles";
import { sendEvent } from "../../../utils/eventLog";
import lodashGet from 'lodash/get'
import WorkSpacePopover from './WorkSpacePopover/WorkSpacePopover';
import CustomAvatar from "../../CustomAvatar/CustomAvatar";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { adminProtocolStorage } from "../../../constants/data-fetching";

class WorkSpaceButton extends Component {

    childSelectorRef = React.createRef()
    state = {
        open: false
    }

    closePopOver = () => {
        this.setState({ open: false })
    }
    openPopOver = () => {
        this.setState({ open: true })
    }

    goToWorkSpaces = () => {
        this.closePopOver();
        this.props.history.push('/workspaces');
    }

    selectWorkSpace = (id) => {
        let { WorkSpacesModel } = this.props;
        WorkSpacesModel.switchWorkSpace(id)
        this.closePopOver();
        this.props.history.push('/');
        window.location.reload();
    }

    goToSettings = () => {
        this.closePopOver();
        this.props.history.push('/settings/account');
    }

    signOut = () => {
        let { UserModel } = this.props;
        this.closePopOver();
        UserModel.signOut();
    }


    overWatchSignOut =()=> {
        if (window.sessionStorage) {
            window.sessionStorage.removeItem(adminProtocolStorage)
        }
        this.props.history.push('/');
        window.location.reload();
    }

    render() {
        let { WorkSpacesModel, UserModel, classes } = this.props;

        return (
            <div >
                <div ref={this.childSelectorRef} className={classes.buttonContainer} onClick={this.openPopOver}>
                    <CustomAvatar
                        width={30}
                        height={30}
                        textColor={WorkSpacesModel.selectedWorkSpace.color}
                        backgroundColor={WorkSpacesModel.selectedWorkSpace.colorHex}
                        picture={WorkSpacesModel.selectedWorkSpace.picture}
                        initials={WorkSpacesModel.selectedWorkSpace.initials}
                        name={WorkSpacesModel.selectedWorkSpace.name} />
                    <Hidden mdDown>
                        <Typography>{I18n.get("_account", "Account")}</Typography>
                        <ExpandMoreIcon style={{ marginLeft: 4 }} />
                    </Hidden>
                </div>
                <WorkSpacePopover
                    ref={this.childSelectorRef.current}
                    open={this.state.open}
                    UserModel={UserModel}
                    WorkSpacesModel={WorkSpacesModel}
                    goToSettings={this.goToSettings}
                    handleSelect={this.selectWorkSpace}
                    goToWorkSpaces={this.goToWorkSpaces}
                    handleClose={this.closePopOver}
                    signOut={this.signOut}
                    adminSignOut={this.overWatchSignOut}
                />
            </div>
        );
    }
}

WorkSpaceButton.propTypes = {};

export default withRouter(inject("WorkSpacesModel", "UserModel")(withStyles(styles, { withTheme: true })(observer(WorkSpaceButton))));
