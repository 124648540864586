import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => createStyles({
    active: {
        color: 'blue'
    },
    navLink: {
        textDecoration: 'none',
        color: 'black'
    },
    drawerPaper: {
        width: '70%',
        paddingTop: 20
    }
}));
