import React from 'react';
import { observable, decorate } from "mobx";
import { I18n } from 'aws-amplify';
import CardContent from "@material-ui/core/CardContent";
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import NewActionHeader from '../../NewAction/NewActionHeader/NewActionHeader';
import FastProductInput from "../../NewAction/inputs/FastProductInput/FastProductInput";

class FastForm extends ActionModel {

    static metadata = {
        id: "FastProduct",
        name: "Fast Product",
        icon: "https://l.fast.cm/content/icons/fastproduct.png",
        categories: {
            general: true,
            integration: true
        },
        comingSoon:true,
    };

    constructor(dat) {
        super();
        this.propNames.push("productData",);
        this.setFromObject(dat);
    }

    Title = "Fast Product";
    Link = "https://www.thefastmind.com";
    ButtonText = "BUY";
    isValid = false;
    productData = {
        "variants": [
        //     {
        //     "name": "Strawberry Banana",
        //     "description":"the red solo cup description",
        //     "image": "https://i5.walmartimages.com/asr/25207bbb-d8b9-4625-b2f6-5d2d3a345e77.30a33dd3612b7356977d7317a97ba0fe.jpeg?odnHeight=612&odnWidth=612&odnBg=FFFFFF",
        //     "default":1,
        //     "retailers": [
        //         {
        //         "name": "Name as displayed in Walmart",
        //         "source": "walmart",
        //         "description": "This is the descprion of the product",
        //         "image": "https://i5.walmartimages.com/asr/2b4f338f-2301-4ea1-9226-80dc3fa332b6.5fea691e4cd329dc67a3e683ed09a1f4.jpeg?odnHeight=612&odnWidth=612&odnBg=FFFFFF",
        //          "source_type": "online",
        //         "sku": "hjklndsd32klslkdm"
        //         },
        //         {
        //             "name": "Name as displayed in Walmart",
        //             "source": "walmart",
        //             "description": "This is the descprion of the product",
        //             "image": "https://i5.walmartimages.com/asr/2b4f338f-2301-4ea1-9226-80dc3fa332b6.5fea691e4cd329dc67a3e683ed09a1f4.jpeg?odnHeight=612&odnWidth=612&odnBg=FFFFFF",
        //             "source_type": "in_store",
        //             "sku": "hjkln3sfsv2kldfghjkslkdm"
        //         },
        //         {
        //             "name": "Name as displayed in Target",
        //             "image": "https://target.scene7.com/is/image/Target/GUEST_622586d7-73ba-4836-9af8-0d5fb7c7bf8f?fmt=webp&wid=1400&qlt=80",
        //             "source": "target",
        //             "source_type": "in_store",
        //             "sku": "hjkln32cjkskldfghjkslkdm"
        //         },
        //         {
        //             "name": "Name as displayed in Amazon",
        //             "image": "https://m.media-amazon.com/images/I/81gIINzkz+L._SX679_PIbundle-4,TopRight,0,0_AA679SH20_.jpg",
        //             "source": "amazon",
        //             "source_type": "online",
        //             "sku": "hjkln32djnskkldfghjkslkdm"
        //         },
        //         {
        //             "name": "Name as displayed in Macy's",
        //             "source": "macys",
        //             "source_type": "online",
        //             "sku": "hjkln32kjskaldfghjkslkdm"
        //         },
        //         {
        //             "name": "Name as displayed in Ebay",
        //             "source": "ebay",
        //             "source_type": "online",
        //             "sku": "hjkdhjskln32kldfghjkslkdm"
        //         },
        //         {
        //             "name": "Name as displayed in Best Buy",
        //             "source": "bestbuy",
        //             "source_type": "online",
        //             "sku": "hjkln32kldfksdl,ghjkslkdm"
        //         },
        //
        //     ]
        //
        // },
        //     {
        //     "name": "BlueBerry",
        //     "image": 'https://i5.walmartimages.com/asr/ea77a92a-3414-4b33-ba02-4e7505943929_2.bcddc0ecc46e8374709c3335be7d511c.jpeg?odnHeight=612&odnWidth=612&odnBg=FFFFFF',
        //     "retailers": [{
        //         "name": "Name as displayed in Walmart",
        //         "source": "walmart",
        //         "source_type": "online",
        //         "sku": "hjycuvyiukln32klslkdm"
        //     },
        //         {
        //             "name": "Name as displayed in Walmart",
        //             "source": "walmart",
        //             "source_type": "in_store",
        //             "sku": "hjkln32cvuibokldfghjkslkdm"
        //         },
        //         {
        //             "name": "Name as displayed in Ebay",
        //             "source": "ebay",
        //             "source_type": "online",
        //             "sku": "hjklncvd68fb798n32kldfghjkslkdm"
        //         }
        //     ]
        //
        // },
        //     {
        //     "name": "Peaches & Cream",
        //     "image": "https://i5.walmartimages.com/asr/d4f9ab93-41d8-4cf3-9a08-9f0b4805d1d0.aa1e28d5245c075e3586a04c9c6c6c0b.jpeg?odnHeight=612&odnWidth=612&odnBg=FFFFFF",
        //     "retailers": [
        //         {
        //         "name": "Name as displayed in Walmart",
        //         "source": "walmart",
        //         "source_type": "online",
        //         "sku": "hjkluin32klslkdm"
        //     },
        //         {
        //             "name": "Name as displayed in Macy's",
        //             "source": "macys",
        //             "source_type": "online",
        //             "sku": "hjklnctvybn32kldfghjkslkdm"
        //         },
        //         {
        //             "name": "Name as displayed in Walmart",
        //             "source": "walmart",
        //             "source_type": "in_store",
        //             "sku": "hjklyvbuinmon32kldfghjkslkdm"
        //         }
        //     ]
        //
        // },
        ]

    }

        onValidateUrl = () => {
        this.isValid = true
    };

    getConfigCardBody(cardModel, handleOnChangeIcon) {
        return (
            <CardContent>
                <NewActionHeader
                    onChangeIcon={handleOnChangeIcon}
                    actionModel={this} />
                <FastProductInput
                    cardModel={cardModel}
                    actionModel={this} />
            </CardContent>
        )
    }

    getRules() {
        return {
            //TODO check if link is mandatory
            Link: {
                presence: { allowEmpty: false },
                url: { message: I18n.get("_action_wrong_link", "Wrong link") }
            },
            ...super.getRules()
        }
    }
}

decorate(FastForm, {
    Link: observable,
    isValid: observable,
    Title: observable,
    ButtonText: observable,
    productData: observable,
});

export default FastForm;
