import React from 'react';
import { Container, Image } from './styles.js'
import { observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';

function CalendarItem(props) {

    const { item, theme } = props

    return (
        <Container onClick={() => props.clicked(item)}>
            <Image src={item.iconUrl} alt={item.id} />
            <Typography variant="caption" color="textPrimary" style={{ color: theme.Title }}>{item.id}</Typography>
        </Container>
    )
}

export default observer(CalendarItem);