import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import SpotifyItem from './SpotifyItem/SpotifyItem';
import { API } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import TextInput from "../TextInput/TextInput";
import { I18n } from 'aws-amplify';
import * as Sentry from "@sentry/react";

const styles = {
    paper: {
        width: '420px',
    }
};

class SpotifyInput extends Component {

    state = {
        dropdownOpen: false,
        typeSelected: "track",
        suggestions: [],
        value: '',
        open: false
    };

    inputRef = React.createRef();

    toggle = () => {
        this.setState(prevState => {
            return {
                dropdownOpen: !prevState.dropdownOpen
            }
        });
    };

    handleTypeChanged = event => {
        this.setState({
            typeSelected: event.target.value
        })
    };

    handleSearch = value => {

        // let value = event.target.value;

        this.setState({
            value: value
        });

        clearTimeout(this.typingTimer);

        this.typingTimer = setTimeout(() => {
            if (value) {
                this.getSuggestions(value, this.state.typeSelected)
            }
        }, 400);
    };

    getSuggestions = (value, type) => {
        let path = '/actions/getSpotifySuggestions';
        let myInit = {
            body: {
                q: value,
                type: type
            }
        };
        const apiName = 'fastShare';

        API.post(apiName, path, myInit)
            .then(result => {
                this.handleSuggestions(result.data)
            })
            .catch(error => {
                Sentry.captureException(error);
            })
    };

    handleSuggestions = suggestions => {
        const items = suggestions.map(el => {

            let url = el.external_urls['spotify'];
            let imageUrl = "";
            let artist = null;
            let length = 0;
            let subtitle = null;

            switch (this.state.typeSelected) {
                case "track":
                    length = el.album.images.length;
                    imageUrl = el.album.images[length - 2].url;
                    artist = el.artists[0].name;
                    subtitle = artist + " - " + el.album.name;
                    break;
                case "album":
                    length = el.images.length;
                    let temp = el.images[length - 2];
                    artist = el.artists[0].name;
                    imageUrl = temp ? temp.url : null;
                    subtitle = artist + " - " + el.release_date.slice(0, 4);
                    break;
                case "artist":
                    length = el.images.length;
                    temp = el.images[length - 2];
                    imageUrl = temp ? temp.url : null;
                    break;
                case "playlist":
                    length = el.images.length;
                    temp = el.images[length - 2];
                    imageUrl = temp ? temp.url : null;
                    subtitle = el.tracks.total + " tracks";
                    break;
                default:
                    break;
            }

            return {
                url: url,
                image: imageUrl,
                title: el.name,
                subtitle: subtitle
            }
        }).filter(el => {
            return el.image !== null
        });

        this.setState({
            suggestions: items.slice(0, 4),
            open: items.length > 0
        })
    };

    handleClose = () => {
        this.props.actionModel.Link = "";

        this.setState({
            suggestions: [],
            open: false
        })
    };

    componentWillUnmount() {
        clearTimeout(this.typingTimer);
    }

    handleSelected = suggestion => {
        this.props.actionModel.Title = suggestion.title;
        this.props.actionModel.Link = suggestion.url;
        this.props.actionModel.Subtitle = suggestion.subtitle;

        this.setState({
            open: false,
            value: suggestion.url
        })
    };

    render() {

        let { actionModel, cardModel, classes } = this.props;

        return (
            <div>
                <TextInput
                    type={'text'}
                    field={'Link'}
                    label={I18n.get("_action_type_url")}
                    store={actionModel}
                    disabled={cardModel.loading}
                    showRequiredHelperText={I18n.get("_required", "Required")}
                    InputProps={{
                        endAdornment: (
                            <React.Fragment>
                                {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                            </React.Fragment>
                        ),
                    }}
                    onChange={this.handleSearch}
                />
                <span ref={this.inputRef} />

                <Popover
                    open={this.state.open}
                    classes={{
                        paper: classes.paper
                    }}
                    anchorEl={this.inputRef.current}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <List>
                        {this.state.suggestions.map(el => {
                            return <SpotifyItem
                                key={el.title}
                                suggestion={el}
                                handleSelected={this.handleSelected} />
                        })}
                    </List>
                </Popover>
                <Select
                    value={this.state.typeSelected}
                    variant="outlined"
                    margin="dense"
                    style={{ marginTop: "16px", width: '200px' }}
                    onChange={this.handleTypeChanged}
                >
                    <MenuItem value="track">Tracks</MenuItem>
                    <MenuItem value="album">Albums</MenuItem>
                    <MenuItem value="artist">Artists</MenuItem>
                    <MenuItem value="playlist">Playlists</MenuItem>
                </Select>
            </div>
        );
    }
}

SpotifyInput.propTypes = {
    actionModel: PropTypes.object.isRequired,
    cardModel: PropTypes.object.isRequired
};

export default withStyles(styles)(observer(SpotifyInput));
