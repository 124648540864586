import React from "react";
import { decorate, observable } from "mobx";
import CardContent from '@material-ui/core/CardContent';
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import NewActionHeader from "../../NewAction/NewActionHeader/NewActionHeader";
import { I18n } from 'aws-amplify';
import TextInput from "../../NewAction/inputs/TextInput/TextInput";

class WhatsAppAction extends ActionModel {

	static metadata = {
		id: "whatsapp",
		name: "WhatsApp",
		icon: "https://l.fast.cm/content/icons/whatsapp.png",
		categories: {
			social: true
		},
		help: {
			id: 'articles/about-social-actions-whatsapp-integration',
			helperText: I18n.get('_whatsapp_help_text', "Click to learn more about the WhatsApp action")
		}
	};

	countryCode = ""
	phoneNumber = "";
	isValid = false;
	message = "";

	constructor(dat) {
		super();

		this.propNames.push("phoneNumber", "message", "countryCode");
		this.setFromObject(dat);
	}

	getConfigCardBody(cardModel, handleOnChangeIcon) {

		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />

				<TextInput
					fullWidth
					type="tel"
					field={'phoneNumber'}
					label={I18n.get("_phone_number", "Phone Number")}
					placeholder={'+12025550171'}
					store={this}
					disabled={cardModel.loading}
					inputProps={{
						'data-testid': 'phoneNumber'
					}}
				/>

				<TextInput
					fullWidth
					type="textarea"
					field={'message'}
					label={I18n.get("_message_content", "Message Content")}
					placeholder={I18n.get("_message_content", "Message Content")}
					store={this}
					autoComplete="off"
					disabled={cardModel.loading}
					style={{ marginTop: '16px' }}
					inputProps={{
						'data-testid': 'message'
					}}
				/>
			</CardContent>
		)
	}

	getRules() {
		return {
			phoneNumber: {
				presence: { allowEmpty: false }
			},
			...super.getRules()
		}
	}
};

decorate(WhatsAppAction, {
	phoneNumber: observable,
	isValid: observable,
	message: observable,
	countryCode: observable
})

export default WhatsAppAction
