import { observable, action, computed, decorate } from "mobx";
import CardModel from '../CardModel/CardModel';
import { Auth, API } from 'aws-amplify';
import { parallel } from "async-es";
import { amountOfcardsFetched } from '../../constants/data-fetching'
import * as Sentry from "@sentry/react";
class CardsDataModel {
  TagsList = [];
  FilterTags = [];
  LinksList = [];
  LinksListDict = {};
  StartKey; // the start key for the next request
  isLoading = false;

  fetchData(callback) {
    this.isLoading = true;
    let theThis = this;
    parallel([
      cb => theThis.fetchFastcards(cb),
      cb => theThis._FetchUserTags(cb)
    ], (err, results) => {
      if (callback) { callback(!err) }
      theThis.isLoading = false;
    });
  }

  _FetchUserTags(callback) {
    let theThis = this;
    Auth.currentAuthenticatedUser()
      .then(user => {
        if (user) {
          let path = '/GetUserTags';
          let myInit = {
            body: { limit: 200 }
          };
          let apiName = 'fastShare';
          API.post(apiName, path, myInit)
            .then(response => {
              const { tags } = response;

              theThis.TagsList = tags.map(t => {
                return {
                  text: t.tag,
                  color: t.data
                }
              });

              if (callback) { callback(null, true) }
            }).catch((error) => {
              Sentry.captureException(error);
              if (callback) { callback(null, false) }
            });
        }
      })
      .catch((error) => {
        if (callback) { callback(null, false) }
      });
  }

  resetCardsData() {
    this.StartKey = undefined;
    this.LinksList = [];
    this.LinksListDict = {};
  }

  PullToRefresh(callback, archived = false) {
    this.resetCardsData();
    this.fetchFastcards(callback, archived);
  }

  fetchMoreUserLinks(callback, archived = false) {
    this.fetchFastcards(callback, archived)
  }

  fetchArchivedLinks(callback, archived) {
    this.fetchFastcards(callback, archived)
  }

  fetchFastcards(callback, archived = false) {
    this.isLoading = true;

    Auth.currentAuthenticatedUser()
      .then(user => {
        if (user) {
          let path = '/GetUserLinks';
          let myInit = {
            body: {
              limit: amountOfcardsFetched,
              onlyArchived: archived, // set true to only see archived cards
              filterTags: this.FilterTags
            }
          };

          if (this.StartKey) {
            myInit.body.startKey = this.StartKey
          }

          let apiName = 'fastShare';
          API.post(apiName, path, myInit)
            .then(response => {
              this.isLoading = false;

              let newStructure = response.cards || false; // we are now sending the response as an object
              let cards = newStructure ? response.cards : response;
              this.StartKey = newStructure ? response.next : '';

              let cout = [];
              cards.sort((a, b) => parseInt(b.Time_ID, 10) - parseInt(a.Time_ID, 10));
              cards.forEach(element => {
                cout.push(new CardModel(element))
              });

              this.changeLinks(cout)
              if (callback) { callback(null, true) }
            }).catch(error => {
              Sentry.captureException(error);
              this.isLoading = false;
              if (callback) { callback(null, false) }
            });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        this.isLoading = false;
        if (callback) { callback(null, false) }
      });
  }

  changeLinks(dat) {
    let tempDict = {};
    dat.forEach(card => {
      tempDict[card.Card_ID] = card;
    });

    this.LinksList.push(...dat);
    this.LinksListDict = { ...this.LinksListDict, ...tempDict };
  }

  addLink(dat) {
    let card = new CardModel(dat);
    this.LinksList.push(card);
    this.LinksListDict[card.Card_ID] = card
  }

  ChangeLink(Ind, dat) {
    this.LinksList[Ind].ChangeData(dat);
  }

  get GetList() {
    return this.LinksList.map(element => {
      return {
        Card_ID: element.Card_ID,
        Image: element.Image,
        Link: element.Link,
        Subtitle: element.Subtitle,
        Title: element.Title,
        Twitter: element.Twitter
      }
    });
  }


  get hasMore() {
    return this.StartKey
  }

  get GetDropDownList() {
    return this.LinksList
      .map(element => {
        return {
          value: element.Card_ID,
          name: element.Title,
          Subtitle: element.Subtitle,
          photo: element.Twitter
        }
      })
  }
}

decorate(CardsDataModel, {
  FilterTags: observable,
  StartKey: observable,
  TagsList: observable,
  LinksList: observable,
  LinksListDict: observable,
  isLoading: observable,
  resetCardsData: action,
  changeLinks: action,
  fetchData: action,
  fetchFastcards: action,
  fetchMoreUserLinks: action,
  addLink: action,
  ChangeLink: action,
  GetList: computed,
  GetDropDownList: computed,
  hasMore: computed
});

export default CardsDataModel;