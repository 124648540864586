import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { createStyles } from '@material-ui/core';

export const styles = (theme) => createStyles({
    scrollContainer: {
        padding: 10,
        [theme.breakpoints.up('sm')]: {
            paddingTop: 64,
        },
        [theme.breakpoints.up('md')]: {
            padding: '100px 50px 50px 50px'
        }
    }
})


export const Background = styled.div`
    flex-grow: 1;
`;

export const StyledInfiniteScroll = styled(InfiniteScroll)`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

export const Container = styled.div`
    padding-bottom: 220px;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
`;

export const SkeletonContainer = styled.div`
    padding-bottom: 220px;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    margin-top: 80px;
    margin-left: 10px;

    @media (min-width: 340px) {
        margin-left: 20px;
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    }
  
    @media (min-width: 768px) {
      margin-top: 160px;
      margin-left: 48px;
    }
`;

