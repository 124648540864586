import styled from 'styled-components'

export const ListItem = styled.li`
    display: flex;
    min-height: 7.5vh;
    width: 100%;
    padding: 4px 16px;
    cursor: pointer;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    opacity: ${props => props.failed ? 0.3 : 1} ;
    border-style: ${props => props.last ? "none" : null} ;
    border-bottom: ${props => props.last ? null : `1px solid ${props.theme.divider}`} ;
`;

export const ServiceThumbnail = styled.img`
    height: 4.5vh;
    width: 4.5vh;
    border: ${props => `1px none ${props.theme.divider}`}; 
    border-radius: 6px;
    object-fit: cover;
    margin-right: 16px;
`;

export const TextContainer = styled.div`
    flex: 1;
    overflow: hidden;
`;

export const ServiceTitle = styled.h6`
    font-family: Sanfranciscodisplay, sans-serif;
    color: ${props => `${props.theme.Title}`};
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    margin: 0;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ServiceSubtitle = styled.div`
    color: ${props => `${props.theme.Subtitle}`};
`;

export const ServiceActionButton = styled.div`
    display: flex;
    min-width: 10vh;
    height: 4vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: ${props => `${props.theme.actionButton}`};
    opacity: ${props => props.failed ? 0.6 : 1} ;
    text-decoration: none;
    margin-left: 8px;

    :active {
        transform: translateY(4px);
    }
`;

export const ServiceActionButtonText = styled.div`
    font-family: Sanfranciscodisplay, sans-serif;
    color: ${props => `${props.theme.cardActions}`};
    font-weight: 600;
    font-size: 12px;
`;

export const ServiceActionGroupedButton = styled(ServiceActionButton)`
    background-color:  ${props => `${props.theme.cardActions}`};
`;

export const ServiceActionGroupedText = styled(ServiceActionButtonText)`
    color:white;
`;

export const ExpandableCell = styled.div`
    position: relative;
    height: ${props => props.open ? "7vh" : 0};
    visibility: ${props => props.open ? "visible" : "hidden"};
    opacity: ${props => props.open ? 1 : 0};
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 100;
    margin-bottom: ${props => props.open ? 0 : "-1px"};
    border-bottom: ${props => `1px solid ${props.theme.divider}`};
    background-color: ${props => props.theme.divider};
    :last-child {
        border-bottom: 0;
        border-bottom: ${props => props.open ? `1px solid ${props.theme.divider}` : 0};
        
    };
    ${props => props.last ? 'border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;' : ''}
    
`;

export const ArrowContainer = styled.div`
    transform: ${props => props.expanded ? `rotate(-180deg)` : `rotate(0deg)`};
    transition: transform 0.2s linear;
    width: 22px;
    height: 22px;
    color: ${props => `${props.theme.cardActions}`};
`;
