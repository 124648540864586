import {observable, decorate, action, toJS} from "mobx";
import { Auth, Hub, API } from 'aws-amplify';
import lodashMapValues from 'lodash/mapValues'
import lodashEmpty from 'lodash/isEmpty'
import lodashGet from 'lodash/get'
import lodashKeyBy from 'lodash/keyBy'
import asyncParallel from 'async/parallel';
import { sendEvent, setUserProperties } from "../../utils/eventLog";
import * as Sentry from "@sentry/react";
import {parseURLParameters} from "../../utils/functions";
import {adminProtocolStorage, inviteQuery, rewardfulReferral, tierStorage} from "../../constants/data-fetching";
import mixpanel from "mixpanel-browser";
import ls from 'local-storage'

class UserModel {
    profilePicLink = {};
    username;
    currentUser;
    userProperties = {};
    fcUserData = {};
    stripeData = {};
    stripeDataSummary = {};
    creditData = {};
    subscriptionPlans = {};
    subscriptionSelected = "";
    loadingSubscriptions = false;
    visitPackages = {};
    paymentMethods = [];
    loading = false;
    defaultPayment = null;
    pendingPlan = null;
    fetchingUserInfo = false;

    constructor() {
        // this.fetchUserInfo();

        Hub.listen('auth', ({ payload: { event, data } }) => {

            switch (event) {
                case 'signIn':
                    sendEvent('sign_in');
                    this.fetchUserInfo();
                    break;
                case 'signUp':
                    sendEvent('signed_up');
                    break;
                case 'signOut':
                    sendEvent('sign_out');
                    this.resetUser();
                    break;
                case 'signIn_failure':
                    break;
                case 'tokenRefresh':
                    this.fetchUserInfo();
                    break;
                case 'configured':
                    break;
                case "customOAuthState":
                    const queryItems = parseURLParameters(data);
                    const tier = lodashGet(queryItems, tierStorage);
                    const invitation = lodashGet(queryItems, inviteQuery);

                    if (tier) {
                        setTimeout(()=>{
                            // give enough time for the paywall to be created
                            Hub.dispatch('fcPayWall', { data, event: 'checkOut'});
                        },500)
                    }

                    if (invitation) {
                        setTimeout(()=>{
                            Hub.dispatch('fcInvite', { data, event: 'WorkSpaceInvitation'});
                        },500)
                    }
                    break;
                default: break;
            }

        }, "userModel_auth_watcher");
    }

    resetUser() {
        this.profilePicLink = {};
        this.username = undefined;
        this.currentUser = undefined;
        this.stripeData = {};
        this.stripeDataSummary = {};
        this.creditData = {};
        this.subscriptionPlans = {};
        this.visitPackages = {};
        this.defaultPayment = undefined

        if(window.$zoho && window.$zoho.salesiq){
            window.$zoho.salesiq.reset();
        }
    }

    signOut() {

        if (window.sessionStorage) {
            window.sessionStorage.removeItem(adminProtocolStorage)
        }

        window.location.replace(window.location.origin);
        Auth.signOut();
        mixpanel.reset();
    }

    capitalize = (s) => {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1)
    };

    onConversationsAPIReady() {

        if (this.currentUser) {
            // let _hsq = window._hsq = window._hsq || [];

            let userLang = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
            let country = "unknown"
            let loginProvider;
            try {
                loginProvider = this.currentUser.username.split('_')[1] ? this.currentUser.username.split('_')[0] : "Fastmind"
            } catch (e) {
                loginProvider = "Unknown"
            }

            if (userLang) {
                userLang = userLang.toLowerCase()
            }

            try {
                country = userLang.split('-')[1]
                if (country) { country = country.toUpperCase(); }
            } catch (e) {
                country = "unknown"
            }


            let dataToPush = [
                { key: 'email', value: this.currentUser.attributes.email },
                { key: 'avatar_url', value: this.currentUser.attributes.picture },
                { key: 'lastname', value: this.capitalize(this.currentUser.attributes['family_name']) },
                { key: 'firstname', value: this.capitalize(this.currentUser.attributes['given_name']) },
                { key: 'fastcard_user', value: this.currentUser.attributes.sub },
                { key: 'hs_language', value: userLang },
                { key: 'country', value: country },
                { key: 'login_provider', value: loginProvider },
                { key: 'purpose', value: this.currentUser.attributes['custom:type'] },
            ].filter(k => !lodashEmpty(k.value)).filter(k => k.value.length <= 100);

            let finalData = lodashMapValues(lodashKeyBy(dataToPush, 'key'), 'value'); // only send data that we have not to send undefined or empties

            finalData['active_fastcards'] = (this.fcUserData.totalActiveCards || 0);
            finalData['inactive_fastcards'] = (this.fcUserData.totalInactiveCards || 0);
            finalData['totalFastCards'] = (this.fcUserData.totalActiveCards || 0) + (this.fcUserData.totalInactiveCards || 0);
            finalData['plan'] = toJS(lodashGet(this, 'stripeDataSummary.tiers[0]','starter'))

            this.userProperties = finalData
            setUserProperties(finalData);

            setTimeout(() => {
                // window.HubSpotConversations.widget.refresh();
            }, 1000)


        }
    }

    sendIdentify() {
        this.onConversationsAPIReady();
    }


    fetchUserInfo() {
        if(this.fetchingUserInfo){ console.log("already fetching user so let's stop"); return;} // do not fetch the user if we're already in the process
        let self = this;
        this.fetchingUserInfo = true
        console.log("Fetching the user Info")
        Auth.currentAuthenticatedUser({ bypassCache: true })
            .then(async currentUser => {

                let identities = lodashGet(currentUser, 'attributes.identities');
                let facebookUserID;
                if (typeof identities === "string") {
                    try {
                        identities = JSON.parse(identities)
                    } catch (error) {
                        Sentry.captureException(error);
                    }
                }

                if (identities) {
                    for (let i = 0; i < identities.length; i++) {
                        let identity = identities[i];
                        if (identity.providerName === "Facebook") {
                            facebookUserID = identity.userId;
                            break;
                        }
                    }
                }


                let avatarName = "";

                if (currentUser.attributes) {
                    if (currentUser.attributes.hasOwnProperty('family_name') && currentUser.attributes.hasOwnProperty('given_name')) {
                        avatarName = `${currentUser.attributes['given_name'].charAt(0)}${currentUser.attributes['family_name'].charAt(0)}`
                    } else {
                        avatarName = currentUser.attributes.email.charAt(0)
                    }
                }

                this.currentUser = currentUser;

                if (facebookUserID && !currentUser.attributes.picture) {
                    let facebookPicture = `https://graph.facebook.com/${facebookUserID}/picture`
                    await Auth.updateUserAttributes(currentUser, { 'picture': facebookPicture });
                    currentUser.attributes.picture = facebookPicture;
                }

                if (currentUser.attributes && currentUser.attributes.picture) {
                    this.profilePicLink = currentUser.attributes.picture
                } else {
                    this.profilePicLink = `https://via.placeholder.com/200.webp/136cff/FFFFFF?text=${avatarName.toUpperCase()}`;
                }
                
                this.username = currentUser.username;
                // this.pizzlyClient = new Pizzly({ host: 'https://fast-integrations.fast.cm' })

                asyncParallel([
                    cb => this.fetchBillingInfo(cb),
                    cb => this.fetchUserGeneralInfo(cb),
                ], async (err, data) => {

                    // TODO: Add a new token manager service to handle the tokens eg Nango

                    self.sendIdentify();
                });
            })
            .catch((err)=>{
                Sentry.captureException(err)
                console.log("Error fetching the user Info", err)
            })
            .finally(()=>{
                self.fetchingUserInfo = false;
            })
    }


    fetchUserGeneralInfo = (callback) => {
        let path = '/getUserInfo';
        let apiName = 'fastShare';
        let response ={};
        API.post(apiName, path, {body:{}})
            .then(result => {
                this.fcUserData = result.data
                response = result.data
            })
            .catch(error => {
                Sentry.captureException(error)
            })
            .finally(() => {
                if (callback) { callback(undefined, response) }
            });
    };

    fetchAccessToken = (data, callback) => {
        let path = '/getPizzlyToken';
        let apiName = 'fastShare';
        let { provider} = data;
        if(!provider){ callback(undefined); return;}
        API.post(apiName, path, {body: { provider }
        })
        .then(result => {
            let {accessToken} = result.data
            callback(accessToken)
        })
        .catch(error => {
            if (callback) { callback(undefined) }
        })
    };

    fetchBillingInfo = (callback) => {
        let path = '/getUserBillingInfo';
        let apiName = 'fastCardPayment';
        let d={}
        d[rewardfulReferral] = ls.get(rewardfulReferral)
        API.post(apiName, path, {body:{...d}})
            .then(result => {
                if (result.error) {
                    console.log(`error happened in ${apiName + "/" + path}`);
                    return
                }

                this.stripeDataSummary = result.data.dataSummary;
                this.stripeData = result.data.user;
                this.defaultPayment = result.data.user.invoice_settings.default_payment_method
            })
            .catch(error => {
                Sentry.captureException(error);
            })
            .finally(() => {
                if (callback) { callback(undefined, {}) }
            })
            ;
    };

    fetchCreditProgress(callback) {
        Auth.currentAuthenticatedUser({ bypassCache: true })
            .then(currentUser => {
                let path = '/consumedCredits';
                let apiName = 'fastCardPayment';
                if (currentUser) {
                    API.post(apiName, path, {body:{}})
                        .then(result => {
                            this.creditData = result.data;
                            if (callback) { callback(undefined, this.creditData); }
                        })
                        .catch(error => {
                            Sentry.captureException(error);
                            if (callback) { callback({ error }, undefined); }
                        })
                } else {
                    if (callback) { callback({}, undefined); }
                }
            })
    }

    updateUserEmail(email, callback) {
        let path = '/updateEmail';
        let apiName = 'fastShare';

        let previousEmail = this.currentUser.attributes.email
        API.post(apiName, path, {body:{previousEmail, email}})
            .then(result => {
                if (callback) { callback(undefined, this.creditData); }
            })
            .catch(error => {
                Sentry.captureException(error);
                if (callback) { callback({ error }, undefined); }
            })
    }


    fetchVisitPackages(callback) {
        Auth.currentAuthenticatedUser({ bypassCache: true })
            .then(currentUser => {
                let path = '/listCredits';
                let apiName = 'fastCardPayment';
                if (currentUser) {
                    API.post(apiName, path, {body:{}})
                        .then(result => {
                            this.visitPackages = result.data;
                            if (callback) callback(null, result.data)
                        })
                        .catch(error => {
                            Sentry.captureException(error);
                            if (callback) callback(error, null)
                        })
                }
            })
    }


    fetchConnectedAccounts = async (callback) => {
        const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });

        if (currentUser) {
            let path = '/dataSources/getConnectedAccounts';
            let apiName = 'fastCardAuth';

            try {
                let result = await API.post(apiName, path, {body:{}})
                if (callback) {
                    callback(null, result.data)
                }
            } catch (error) {
                Sentry.captureException(error);
                if (callback) {
                    callback(error, null)
                }
            }
        }
    }

    disconnectAccount = async (account, callback) => {
        if (account) {
            const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
            if (currentUser) {
                let path = '/dataSources/disconnectConnectedAccounts';
                let apiName = 'fastCardAuth';
                let data = {
                    body: {
                        dataSourceID: account.dataSourceID,
                        accountID: account.accountID
                    }
                }

                try {
                    await API.post(apiName, path, data)
                    if (callback) {
                        callback(null)
                    }
                } catch (error) {
                    Sentry.captureException(error);
                    if (callback) {
                        callback(error)
                    }
                }
            }
        }
    }

    fetchAds = async (accountId, dataSourceId, campaignId, adSetId, callback) => {
        const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });

        if (currentUser) {
            let path = '/dataSources/getAllAds';
            let apiName = 'fastCardAuth';
            let data = {
                body: {
                    dataSourceID: dataSourceId,
                    campaignID: campaignId,
                    accountID: accountId,
                    adSetID: adSetId
                }
            }

            try {
                let result = await API.post(apiName, path, data)
                if (callback) {
                    callback(null, result.data)
                }

            } catch (error) {
                Sentry.captureException(error);
                if (callback) {
                    callback(error, null)
                }
            }
        }
    }

    fetchAdSets = async (accountId, dataSourceId, campaignId, callback) => {
        const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });

        if (currentUser) {
            let path = '/dataSources/getAllAdSets';
            let apiName = 'fastCardAuth';
            let data = {
                body: {
                    dataSourceID: dataSourceId,
                    campaignID: campaignId,
                    accountID: accountId
                }
            }

            try {
                let result = await API.post(apiName, path, data)
                if (callback) {
                    callback(null, result.data)
                }
            } catch (error) {
                Sentry.captureException(error);
                if (callback) {
                    callback(error, null)
                }
            }
        }
    }

    fetchAdCampaigns = async (accountId, dataSourceId, callback) => {
        const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });

        if (currentUser) {
            let path = '/dataSources/getAllCampaigns';
            let apiName = 'fastCardAuth';
            let data = {
                body: {
                    accountID: accountId,
                    dataSourceID: dataSourceId
                }
            }

            try {
                let result = await API.post(apiName, path, data)
                if (callback) {
                    callback(null, result.data)
                }
            } catch (error) {
                Sentry.captureException(error);
                if (callback) {
                    callback(error, null)
                }
            }
        }
    }

    fetchAvailableIntegrations = async (callback) => {
        const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });

        if (currentUser) {
            let path = '/dataSources/getAllPossibleIntegrations';
            let apiName = 'fastCardAuth';
            let data = { body: {} }

            try {
                let result = await API.post(apiName, path, data)

                if (callback) {
                    callback(null, result.integrations)
                }
            } catch (error) {
                Sentry.captureException(error);
                if (callback) {
                    callback(error, null)
                }
            }
        }
    }

    saveCognitoAttributes = (data, callback)=>{
        Auth.currentAuthenticatedUser()
            .then(currentUser => {
                Auth.updateUserAttributes(currentUser, data).then(r =>{
                    callback(undefined, r)
                }).catch((e)=>{
                    callback(e, null)
                });
            })
            .catch(error => {
                Sentry.captureException(error);
                callback(error,null)
            })
    }
    saveUserAttributes = async (data, callback)=>{
        let path = '/updateUserInfo';
        let apiName = 'fastShare';
        let init = {
            body: {
                ...data
            }
        }

        try {
            let result = await API.post(apiName, path, init)
            if (callback) {
                callback(null, result.data)
            }
        } catch (error) {
            Sentry.captureException(error);
            if (callback) {
                callback(error, null)
            }
        }
    }
}

decorate(UserModel, {
    profilePicLink: observable,
    username: observable,
    currentUser: observable,
    creditData: observable,
    fcUserData: observable,
    userProperties: observable,
    stripeData: observable,
    stripeDataSummary: observable,
    defaultPayment: observable,
    loading: observable,
    loadingSubscriptions: observable,
    subscriptionPlans: observable,
    visitPackages: observable,
    paymentMethods: observable,
    pendingPlan: observable,
    subscriptionSelected: observable,
    fetchCreditProgress: action,
    fetchAccessToken: action,
    saveCognitoAttributes: action,
    saveUserAttributes: action,
    signOut: action,
    fetchVisitPackages: action,
    buyCreditsIntent: action,
});

export default new UserModel();