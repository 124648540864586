import {countries} from "../../../../../../../utils/countries";
import ISO6391 from "iso-639-1";

export const formSchema = {
    "type": "object",
    "title": "Mailing List",
    "description": "Fill out the form to received a text and join the newsletter.",
    "properties": {
        "email": {
            "title": "Email",
            "type": "string"
        },
        "first_name": {
            "title": "First name",
            "type": "string"
        },
        "last_name": {
            "title": "Last Name",
            "type": "string"
        },
        "phone": {
            "title": "Phone",
            "type": "integer"
        },

        "age": {
            "title": "Age",
            "type": "integer"
        },
        "postal_code": {
            "title": "Zip code / Postal",
            "type": "string"
        },
        "note": {
            "title": "Note",
            "type": "string"
        },
        "country": {
            "title": "Country",
            "type": "string",
            "enum": countries.map(c=>c.code),
            "enumNames": countries.map(c=>c.label),
        },
        "language": {
            "title": "Language",
            "type": "string",
            "enum": ISO6391.getAllCodes(),
            "enumNames": ISO6391.getAllCodes().map(c=>`${ISO6391.getName(c)} (${ISO6391.getNativeName(c)})`),
        }
    },
    "required": [
        "email",
        "note"
    ]
};
