import { decorate, observable } from "mobx";
import ValidationModel from "../../../../../models/ValidationModel/ValidationModel";
import { I18n } from 'aws-amplify';

class EventAlarm extends ValidationModel {
    type = 'display'
    description = ''
    trigger = '10m'

    constructor(data) {
        super();
        if (data) {
            this.type = data.type;
            this.description = data.description
            this.trigger = data.trigger
        }
    }

    getRules() {
        return {
            description: {
                presence: { allowEmpty: false, message: I18n.get("_required") }
            },
            trigger: {
                presence: { allowEmpty: false, message: I18n.get("_required") }
            },
        };
    }
}

decorate(EventAlarm, {
    type: observable,
    description: observable,
    trigger: observable
})

export default EventAlarm
