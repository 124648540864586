import {Flex, View, useAuthenticator, useTheme} from "@aws-amplify/ui-react";
import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import {withRouter} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {I18n} from "aws-amplify";
import Button from "@material-ui/core/Button";
import {sendEvent} from "../../../utils/eventLog";
import Link from "@material-ui/core/Link";

const CustomSignUpFooter = (props)=> {
    const { tokens } = useTheme();
    const { toSignIn } = useAuthenticator();
    const { classes } = props;

    return (
        <View padding={`${tokens.space.xl} ${tokens.space.xl}`}>

            <Typography variant="caption">{I18n.get("_policy1", "By creating a free account, you acknowledge that you have read and understand our")} <Link href="https://fastmind.io/cookie-policy"> {I18n.get("_policy2", "Cookie Policy")}</Link>, <Link href="https://fastmind.io/privacy">{I18n.get("_privacy_policy", "Privacy Policy")}</Link>, {I18n.get("_policy3", "and our")} <Link href="https://fastmind.io/terms">{I18n.get("_term_of_services", "Terms of Service")}</Link>.</Typography>

            <div>
                <Flex justifyContent="center"  padding={`${tokens.space.medium} ${tokens.space.medium}`}>
                    <Typography variant="h6">{I18n.get("_ask_login", "Already have a Login?")}</Typography>
                </Flex>
                <Button
                    data-testid='signinSubmit'
                    // disabled={this.state.loading}
                    className={classes.outlinedGradientButton}
                    fullWidth
                    onClick={() => {
                        sendEvent('signIn_button_click');
                        toSignIn();
                    }}>
                    {I18n.get("_sign_in", "Sign in")}
                </Button>
            </div>
        </View>
    );
}

export default  withStyles(styles)(withRouter(CustomSignUpFooter));
