import styled from 'styled-components';


export const ButtonContainer = styled.div`
    margin: 0;
    cursor: pointer;
    @media screen and (min-width: 600px) {
        display: none;
    }

    @media screen and (min-width: 600px) and (max-width: 960px) and (orientation: landscape) {
        display: block;
    }
`
