import React from 'react';
import { NavItems, useStyles } from './styles';
import PropTypes from 'prop-types';
import { Auth, I18n } from 'aws-amplify';
import { Drawer, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import LinkIcon from '@material-ui/icons/Link';
import AssignmentIcon from "@material-ui/icons/Assignment";
import HelpIcon from "@material-ui/icons/Help";
import Divider from "@material-ui/core/Divider";
import { NavLink, useLocation } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import InsertCommentIcon from "@material-ui/icons/InsertComment";
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import {baseUrl} from "../../../constants/help-endpoint";
import {useFlags} from "launchdarkly-react-client-sdk";

const SideDrawer = props => {

    const location = useLocation();
    const flags = useFlags();
    const classes = useStyles();

    let locationItems = location.pathname.split("/")

    return (
        // <NavItems show={props.isOpen} aria-label='aside' tabIndex='aside'>
        <Drawer anchor={'left'} open={props.isOpen} onClose={props.toggleButtonClicked} classes={{ paper: classes.drawerPaper }}>

            <ListItem onClick={props.toggleButtonClicked} style={{ marginBottom: 20 }}>
                <img src={'/img/content/logo-white.jpg'} style={{ width: 150 }} />
            </ListItem>
            <Divider />

            <NavLink
                className={classes.navLink}
                aria-label={I18n.get("_dashboard")} tabIndex='0' to="/"
                onClick={props.toggleButtonClicked}
            >
                <ListItem component="div" selected={locationItems[1] === ""}>
                    <ListItemIcon>
                        <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText primary={I18n.get("_dashboard")} />
                </ListItem>
            </NavLink>
            <Divider />

            <NavLink
                className={classes.navLink}
                aria-label={I18n.get("_fastlinks")} tabIndex='0' to="/fastlinks"
                onClick={props.toggleButtonClicked}
            >
                <ListItem component="div" selected={locationItems[1] === "fastlinks"}>
                    <ListItemIcon>
                        <LinkIcon />
                    </ListItemIcon>
                    <ListItemText primary={I18n.get("_fastlinks")} />
                </ListItem>
            </NavLink>
            <Divider />

            {flags['workspaceAccess'] && <>

                <NavLink
                    className={classes.navLink}
                    aria-label={I18n.get("_workspaces")} tabIndex='0' to="/workspaces"
                    onClick={props.toggleButtonClicked}
                >
                    <ListItem component="div" selected={locationItems[1] === "workspaces"}>
                        <ListItemIcon>
                            <FolderSharedIcon/>
                        </ListItemIcon>
                        <ListItemText primary={I18n.get("_workspaces")} />
                    </ListItem>
                </NavLink>
                <Divider />
            </>}
            <NavLink
                className={classes.navLink}
                aria-label={I18n.get("_settings")} tabIndex='0' to="/settings/account"
                onClick={props.toggleButtonClicked}
            >
                <ListItem selected={locationItems[1] === "settings"}>
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary={I18n.get("_settings")} />
                </ListItem>
            </NavLink>

            <Divider />

            <NavLink
                className={classes.navLink}
                aria-label={I18n.get("_feedback")} tabIndex='0'
                to={{pathname:"https://feedback.fastmind.io/feature-requests"}}
                target="_blank"
                onClick={props.toggleButtonClicked}>
                <ListItem>
                    <ListItemIcon>
                        <InsertCommentIcon />
                    </ListItemIcon>
                    <ListItemText primary={I18n.get("_feedback")} />
                </ListItem>
            </NavLink>

            <Divider />

            <NavLink
                className={classes.navLink}
                aria-label={I18n.get("_help")} tabIndex='0' to={{pathname:baseUrl}} target="_blank"
                onClick={props.toggleButtonClicked}>
                <ListItem>
                    <ListItemIcon>
                        <HelpIcon />
                    </ListItemIcon>
                    <ListItemText primary={I18n.get("_help")} />
                </ListItem>
            </NavLink>

            <Divider />
            <NavLink
                className={classes.navLink}
                aria-label={I18n.get("_affiliate")} tabIndex='0' to={{pathname:"https://affiliate.fast.cm"}} target="_blank"
                onClick={props.toggleButtonClicked}>
                <ListItem>
                    <ListItemIcon>
                        <MoneyIcon />
                    </ListItemIcon>
                    <ListItemText primary={I18n.get("_affiliate")} />
                </ListItem>
            </NavLink>

            <Divider />

            <NavLink
                className={classes.navLink}
                aria-label={I18n.get("_logout")} tabIndex='0' to="/"
                onClick={() => {
                    Auth.signOut();
                    props.toggleButtonClicked();
                }}
            >
                <ListItem>
                    <ListItemIcon>
                        <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary={I18n.get("_logout")} />
                </ListItem>
            </NavLink>
        </Drawer>
    );
}

SideDrawer.propTypes = {
    isOpen: PropTypes.bool.isRequired
}

export default SideDrawer
