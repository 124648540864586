import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './FastCardserviceWorker';
import { BrowserRouter } from 'react-router-dom';
import App from './containers/App';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { useTheme } from './hooks';
import { toJS } from 'mobx';
import { initializeFirebase } from "./utils/firebase";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import CookieBot from 'react-cookiebot'
import { StylesProvider } from '@material-ui/core/styles';
import mixpanel from "mixpanel-browser";
import {Toaster} from "react-hot-toast";
import  {Amplify, Auth } from "aws-amplify";
import {amplifyConfig} from "./API";

Sentry.init({
    dsn: "https://0b3d04995f8e4b28bb2fa7d8f13d2af9@o461190.ingest.sentry.io/5462651",
    integrations: [ new BrowserTracing(),],
    tracesSampleRate: 1.0, //value from 0 to 1 to manage the volume of captured data
    release: "fastmind-admin@" + process.env.npm_package_version,
    beforeSend(event, hint) {

        const error = hint.originalException;
        /* tslint:disable:no-string-literal only-arrow-functions */
        const isNonErrorException =
            event.exception.values[0].value.startsWith('Non-Error exception captured') ||
            (error.message && error.message.startsWith('Non-Error exception captured'));
        /* tslint:enable:no-string-literal only-arrow-functions */

        if (isNonErrorException) {
            // We want to ignore those kind of errors
            return null;
        }

        if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
            console.log(error)
            return null
        }

        return event;
    }
});

function AppContainer() {

    const theme = useTheme()
    const themeConfig = createMuiTheme(toJS(theme.current))

    useEffect(() => {
        initializeFirebase();
        Amplify.configure({...amplifyConfig});
        mixpanel.init(process.env.REACT_APP_MIX_PANEL);
    }, [])


    return (
        <BrowserRouter>
            <StylesProvider injectFirst>
                <ThemeProvider theme={themeConfig}>
                    <CookieBot domainGroupId={"97f0e948-f021-423b-bfe6-35f94b2d093d"} />
                    <Toaster/>
                    <App />
                </ThemeProvider>
            </StylesProvider>
        </BrowserRouter>
    );
}

ReactDOM.render(<AppContainer />, document.getElementById('root'));

serviceWorker.unregister();
