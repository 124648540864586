import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
`;

export const Image = styled.img`
    width: 2.7vh;
    height: 2.7vh;
`;