import React, {Component} from 'react';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {I18n} from "aws-amplify";
import {Switch} from "@material-ui/core";
import {inject, observer} from "mobx-react";

class PriorityLinkConfigInput extends Component {
    render() {
        return (
            <div>
                <ListItem>
                    <ListItemText
                        id="switch-list-label-shake"
                        primary={I18n.get('_priority_link')} />
                    <Switch
                        edge="end"
                        color="primary"
                        onChange={(event)=>{
                            if(!this.props.hasFeature(this.props.LDKey)){return;}
                            this.props.actionModel.Animate = !this.props.actionModel.Animate
                        }
                        }
                        checked={this.props.actionModel.Animate}
                        inputProps={{
                            'aria-labelledby': 'switch-list-label-shake',
                        }}
                    />
                </ListItem>
            </div>
        );
    }
}

PriorityLinkConfigInput.propTypes = {};

export default  inject("model")(observer(PriorityLinkConfigInput));

