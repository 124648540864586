import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListItemText from "@material-ui/core/ListItemText";
import {I18n} from "aws-amplify";
import {Chip, Divider, IconButton, ListItemSecondaryAction, Switch, Typography} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ListItem from "@material-ui/core/ListItem";
import {inject, observer} from "mobx-react";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {withStyles} from "@material-ui/core/styles";
import {styles} from './styles'

class PayWallConfigInput extends Component {

    constructor(props){
        super(props);

        this.state = {
            openDialog:false,
            error:false,
            ttlError:false,
            textField: undefined
        }
    }

    handleOpen= ()=>{
        if(!this.props.hasFeature(this.props.LDKey)){return;}
        this.setState({openDialog:true})
    }
    handleClose= ()=>{
        this.setState({openDialog:false})
    }
    handleSave = ()=>{
        // check if it's a number
        if( this.isValid()){
            this.handleClose();
        }
    }

    addVariableInput =(input)=>{
        let { textField } = this.state;
        if(textField){
            this.props.actionModel.paywallData[textField]= (this.props.actionModel.paywallData[textField]||"") + input
        }
    }

    isValid = ()=>{
        let paymentAmount = this.props.actionModel.paywallData.amount;
        let ttlAmount = this.props.actionModel.paywallData.purchaseTtl;
        try{
            paymentAmount = parseFloat(paymentAmount);
        }catch(e){
            paymentAmount = undefined;
        }

        try{
            ttlAmount = parseFloat(ttlAmount);
        }catch(e){
            ttlAmount = undefined;
        }

        let validAmount = paymentAmount && paymentAmount >=1;
        let validTtl = ttlAmount && ttlAmount >=1;

        this.setState({error: !validAmount, ttlError:!validTtl})
        return validAmount && validTtl
    }


    render() {
        let { paywallData } = this.props.actionModel
        let { amount, active } = paywallData;
        let { theme, classes }= this.props;
        let { textField} = this.state;

        return (
            <>
            <ListItem
            disabled={true}
            >
                {/*<ListItemIcon>*/}
                {/*    <WifiIcon />*/}
                {/*</ListItemIcon>*/}

                <ListItemText
                    id="switch-list-label-paywall"
                    primary={I18n.get('_paywall')}
                    secondary={I18n.get('_coming_soon')}
                    // secondary={active?`$${amount||0} USD`:"---"}
                />

                <ListItemSecondaryAction>
                    <IconButton
                        // disabled={!active}
                        edge="end"
                        onClick={this.handleOpen}
                        disabled={true}
                        aria-label={I18n.get("_edit")}>
                        <EditIcon/>
                    </IconButton>
                    <Switch
                        color="primary"
                        edge="end"
                        inputProps={{
                            'aria-labelledby': 'switch-list-label-paywall',
                        }}
                        disabled={true}
                        checked={active}
                        onChange={()=>{
                            if(!this.props.hasFeature(this.props.LDKey)){return;}
                            this.props.actionModel.paywallData.active = !active
                            if( amount===0 && !active){  this.setState({openDialog:true}); }
                        }}

                    />
                </ListItemSecondaryAction>

            </ListItem>


                <Dialog open={this.state.openDialog} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{I18n.get('_paywall')}</DialogTitle>
                    <DialogContent>
                        {/*<DialogContentText>*/}
                        {/*    To subscribe to this website, please enter your email address here. We will send updates*/}
                        {/*    occasionally.*/}
                        {/*</DialogContentText>*/}


                        <FormControl component="fieldset">
                            {/*<FormLabel component="legend">Label Placement</FormLabel>*/}
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    value="end"
                                    control={
                                        <Checkbox
                                            checked = {this.props.actionModel.paywallData.everyTime}
                                            disabled={this.props.model.loading}
                                            onChange={e => {
                                                this.props.actionModel.paywallData.everyTime = !this.props.actionModel.paywallData.everyTime
                                            }}
                                            color="primary" />
                                    }
                                    label="Require Payment Everytime"
                                    labelPlacement="end"
                                />
                            </FormGroup>
                            <FormGroup aria-label="position" row>
                                <TextField
                                    style={{marginTop: '16px', marginBottom: '10px'}}
                                    type={'number'}
                                    fullWidth={true}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        endAdornment: <InputAdornment position="start">USD</InputAdornment>
                                    }}

                                    autoComplete="off"
                                    margin={'dense'}
                                    onFocus={()=>this.setState({textField:undefined})}
                                    variant={'outlined'}
                                    id="paywallAmount"
                                    error={this.state.error}
                                    helperText={'Amount must be more then 1 Dollar'}
                                    value={this.props.actionModel.paywallData.amount}
                                    onChange={e => this.props.actionModel.paywallData.amount = parseFloat(e.target.value)}
                                    label={I18n.get('_amount','Amount')}
                                    disabled={this.props.model.loading || !this.props.actionModel.paywallData.active}

                                />

                                <TextField
                                    style={{marginTop: '16px', marginBottom: '10px'}}
                                    type={'number'}
                                    fullWidth={true}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{I18n.get('_expires')}</InputAdornment>,
                                        endAdornment: <InputAdornment position="start">{I18n.get('_expires_days')}</InputAdornment>
                                    }}

                                    autoComplete="off"
                                    margin={'dense'}
                                    onFocus={()=>this.setState({textField:undefined})}
                                    variant={'outlined'}
                                    id="paywallExpires"
                                    error={this.state.ttlError}
                                    helperText={'Must be more then 1 Day'}
                                    value={this.props.actionModel.paywallData.purchaseTtl}
                                    onChange={e => this.props.actionModel.paywallData.purchaseTtl = parseFloat(e.target.value)}
                                    label={I18n.get('_expires')}
                                    disabled={this.props.model.loading || !this.props.actionModel.paywallData.active}

                                />

                                <TextField
                                    fullWidth={true}
                                    maxLength="200"

                                    autoComplete="off"
                                    margin={'dense'}
                                    onFocus={()=>this.setState({textField:undefined})}
                                    variant={'outlined'}
                                    id="paywallDescription"
                                    value={this.props.actionModel.paywallData.description}
                                    onChange={e => this.props.actionModel.paywallData.description = e.target.value}
                                    label={I18n.get('_item_description')}
                                    placeholder={I18n.get('_item_description')}
                                    disabled={this.props.model.loading || !this.props.actionModel.paywallData.active}
                                />
                                <div className={classes.section2}>
                                    <Typography gutterBottom variant="body1">
                                        Variable Input
                                    </Typography>
                                    <div>
                                        <Chip disabled={!textField} onClick={()=>this.addVariableInput('{firstName}')} className={classes.chip} label={I18n.get('_first_name')} />
                                        <Chip disabled={!textField} onClick={()=>this.addVariableInput('{lastName}')} className={classes.chip} label={I18n.get('_last_name')}/>
                                        <Chip disabled={!textField} onClick={()=>this.addVariableInput('{fullName}')} className={classes.chip} label={I18n.get('_full_name')} />
                                        <Chip disabled={!textField} onClick={()=>this.addVariableInput('{email}')} className={classes.chip} label={I18n.get('_email')} />
                                    </div>
                                </div>
                                <TextField
                                    fullWidth={true}
                                    maxLength="50"
                                    autoComplete="off"
                                    margin={'dense'}
                                    variant={'outlined'}
                                    id="paywallSuccessTitle"
                                    onFocus={()=>this.setState({textField:'successTitle'})}
                                    value={this.props.actionModel.paywallData.successTitle}
                                    onChange={e => this.props.actionModel.paywallData.successTitle = e.target.value}
                                    label={I18n.get('_success_title')}
                                    placeholder={I18n.get('_success_title')}
                                    disabled={this.props.model.loading || !this.props.actionModel.paywallData.active}
                                />
                                <TextField
                                    fullWidth={true}
                                    maxLength="300"
                                    autoComplete="off"
                                    margin={'dense'}
                                    variant={'outlined'}
                                    multiline={true}
                                    maxRows={4}
                                    minRows={2}
                                    id="paywallSuccessMessage"
                                    onFocus={()=>this.setState({textField:'successMessage'})}
                                    value={this.props.actionModel.paywallData.successMessage}
                                    onChange={e => this.props.actionModel.paywallData.successMessage = e.target.value}
                                    label={I18n.get('_success_message')}
                                    placeholder={I18n.get('_success_message')}
                                    disabled={this.props.model.loading || !this.props.actionModel.paywallData.active}
                                />
                            </FormGroup>
                        </FormControl>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleSave} color="primary">
                            {I18n.get('_cancel')}
                        </Button>
                        <Button onClick={this.handleSave} color="primary">
                            {I18n.get('_save')}
                        </Button>
                    </DialogActions>
                </Dialog>

          </>
        );
    }
}

PayWallConfigInput.propTypes = {};

export default  withStyles(styles,{withTheme:true})(inject("model")(observer(PayWallConfigInput)));
