import ActionModel from "../../../../../models/ActionModel/ActionModel";
import { observable, decorate } from "mobx";

class YoutubeClickAction extends ActionModel {
    static metadata = {
        id: "share",
        name: "Share",
        icon: "https://l.fast.cm/content/icons/youtube.png",
        visibleWhenZero: false,
        categories: {
            events: true
        }
    };

    Title = "Youtube (Clicks)";

}

decorate(YoutubeClickAction, {
    Title: observable
});

export default YoutubeClickAction;

