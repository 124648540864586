import {Heading, useTheme, View} from "@aws-amplify/ui-react";
import {I18n} from "aws-amplify";
import Typography from "@material-ui/core/Typography";
import React from "react";

export function CustomSignInHeader() {
    const { tokens } = useTheme();

    return (
        <View padding={`${tokens.space.xl} ${tokens.space.xl} 0`}>
            <Typography variant="h5">{I18n.get("_sign_in", "Sign in")}</Typography>
        </View>
    );
}
