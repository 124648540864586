import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import { observable, decorate } from "mobx";
import NewActionHeader from '../../NewAction/NewActionHeader/NewActionHeader';
import ActionModel from '../../../../../models/ActionModel/ActionModel';
import ItunesInput from '../../NewAction/inputs/ItunesInput/ItunesInput';
import { I18n } from 'aws-amplify';

class ItunesAction extends ActionModel {
	static metadata = {
		id: "itunes",
		name: "iTunes",
		icon: "https://l.fast.cm/content/icons/itunes.jpeg",
		categories: {
			// media: true
		}
	};

	constructor(dat) {
		super();
		// this.propNames = ["Title", "Image", "Subtitle", "ButtonText", "Link"];
		this.setFromObject(dat);
	}

	Title = "iTunes";
	Image = this.Image || this.constructor.metadata.icon;
	ButtonText = "BUY";

	getConfigCardBody(cardModel, handleOnChangeIcon) {
		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />
				<ItunesInput
					cardModel={cardModel}
					actionModel={this} />
			</CardContent>
		)
	};

	getRules() {
		return {
			Link: {
				presence: { allowEmpty: false },
				url: { message: I18n.get("_action_wrong_link", "Wrong link") }
			},
			...super.getRules()
		}
	}
}

decorate(ItunesAction, {
	Title: observable,
	Link: observable,
	Image: observable,
	ButtonText: observable
})

export default ItunesAction
