import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import { I18n } from 'aws-amplify'
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

class RightMenu extends Component {

    state = {
        campaign: null,
        label: "See campaign"
    }


    gotToDuplicate = () => {
        this.props.model._setDuplicate(this.props.card.AllData)
        this.props.history.push("/create/card");
    }

    goToCampaign = () => {
        const campaign = this.props.CampaignsModel.items.find(item => {
            return item.Cards_ID ? item.Cards_ID.includes(this.props.cardId) : undefined
        })

        if (campaign) {
            this.props.history.push({
                pathname: `/campaigns/${campaign.Campaign_ID}`,
                search: `?cardId=${this.props.cardId}`
            })
        } else {
            this.props.onAddToCamapaign(this.props.cardId)
        }
    }

    render() {

        let campaign = this.props.CampaignsModel.items.find(item => {
            return item.Cards_ID ? item.Cards_ID.includes(this.props.cardId) : undefined
        })

        let label = campaign ? I18n.get("_see_campaign", "See campaign") : I18n.get("_add_to_campaign", "Add to campaign")

        return (
            <Menu
                id="simple-menu"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorEl={this.props.anchorEl}
                keepMounted
                open={Boolean(this.props.anchorEl)}
                onClose={this.props.handleClose}
                aria-label='simple-menu'
            >
                <MenuItem disabled={this.props.isDisabled} aria-label={I18n.get('add_to_campaign', "Add to campaign")} aria-disabled='false' onClick={this.goToCampaign} >
                    <ListItemIcon style={{ minWidth: 0, marginRight: '8px' }}>
                        <TodayOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={label} />
                </MenuItem>
                <MenuItem disabled={this.props.isDisabled} aria-label='Duplicate Card' aria-disabled='false' onClick={this.props.gotToDuplicate} >
                    <ListItemIcon style={{ minWidth: 0, marginRight: '8px' }}>
                        <FileCopyOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={I18n.get("_duplicate")} />
                </MenuItem>
                <MenuItem aria-label='Archive Card' aria-disabled='false' onClick={this.props.handleArchive}>
                    <ListItemIcon style={{ minWidth: 0, marginRight: '8px' }}>
                        <ArchiveOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={I18n.get('_archive', 'Archive')} />
                </MenuItem>
            </Menu>
        )
    }
}

RightMenu.propTypes = {
    anchorEl: PropTypes.object,
    handleArchive: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default withRouter(inject("model", "CardsDataModel", "CampaignsModel")(observer(RightMenu)));