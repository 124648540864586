import React from 'react';
import { decorate, observable } from "mobx";
import CardContent from '@material-ui/core/CardContent';
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import NewActionHeader from '../../NewAction/NewActionHeader/NewActionHeader';
import { I18n } from 'aws-amplify';
import TextInput from "../../NewAction/inputs/TextInput/TextInput";

class AmazonMusic extends ActionModel {
	static metadata = {
		id: "amazonmusic",
		name: "Amazon Music",
		icon: "https://l.fast.cm/content/icons/amazonmusic.png",
		categories: {
			// media: true
		}
	};

	isValid = false;

	getConfigCardBody(cardModel, handleOnChangeIcon) {
		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />
				<TextInput
					fullWidth
					type="text"
					field={'Link'}
					label={I18n.get("_action_type_url", "type here the url")}
					placeholder={I18n.get("_action_type_url", "type here the url")}
					store={this}
					disabled={cardModel.loading}
					showRequiredHelperText={I18n.get("_required", "Required")}
				/>
			</CardContent>
		)
	}

	getRules() {
		return {
			Link: {
				presence: { allowEmpty: false },
				url: { message: I18n.get("_action_wrong_link", "Wrong link") }
			},
			...super.getRules()
		}
	}
}

decorate(AmazonMusic, {
	Title: observable,
	Link: observable,
	ButtonText: observable,
	isValid: observable
})

export default AmazonMusic
