import React, { Component } from 'react'
import { I18n } from 'aws-amplify';
import { observer } from 'mobx-react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import GoogleMapPlacesInput from "../GoogleMapPlacesInput/GoogleMapPlacesInput";
import TextInput from "../TextInput/TextInput";

class AddressInput extends Component {

    constructor(props) {
        super(props);

        const { actionModel } = props;

        // determine which one is the original state in
        let option = 'address';

        if (actionModel.address === 'none' && actionModel.lat_lng[0] !== 0 && actionModel.lat_lng[1] !== 0) {
            option = 'lat_long'
        }

        if (actionModel.address !== 'none' && actionModel.lat_lng[0] === 0 && actionModel.lat_lng[1] === 0) {
            option = 'multiple'
        }

        this.state = {
            dropdownOpen: false,
            optionSelected: option,
        };
    }

    reset = () => {
        this.props.actionModel.dynamicLocationData = undefined;
        this.props.actionModel.address = undefined;
        this.props.actionModel.lat_lng = [0, 0];
        this.props.actionModel.lat = 0;
        this.props.actionModel.lng = 0;
    };

    toggle = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    };

    handleItemChanged = event => {
        this.reset();
        this.setState({
            optionSelected: event.target.value
        });
    };

    onHandleSelect = (loc) => {
        if (this.state.optionSelected === "address") {
            this.props.actionModel.address = (loc.name);
            this.props.actionModel.lat_lng = [loc.lat, loc.lng];
            this.props.actionModel.lat = loc.lat;
            this.props.actionModel.lng = loc.lng;

            this.props.actionModel.dynamicLocationData = {
                query: this.props.actionModel.address,
                category: (loc.types) ? loc.types.join(", ") : []
            };
            return
        }

        this.props.actionModel.address = (loc.formatted_address) || loc.description;
        this.props.actionModel.lat_lng = [loc.lat, loc.lng];
        this.props.actionModel.lat = loc.lat;
        this.props.actionModel.lng = loc.lng;
    }


    handleOnChange = event => {

        //this.props.actionModel.address = event.target.value;
        this.props.actionModel.lat_lng = [0, 0];
        this.props.actionModel.dynamicLocationData = {
            //query: event.target.value,
            query: event,
            category: []
        }
    };

    renderNearbyAddressesBar = () => {
        let { cardModel, actionModel } = this.props;

        return <div style={{ marginTop: '16px' }}>
            <TextInput
                type={'text'}
                field={'address'}
                label={I18n.get("_multi_address_placeholder")}
                placeholder={I18n.get("_multi_address_placeholder")}
                store={actionModel}
                disabled={cardModel.loading}
                onChange={this.handleOnChange}
                showRequiredHelperText={I18n.get("_required", "Required")}
                inputProps={{
                    'data-testid': 'nearby_address'
                }}
            />
        </div>
    };

    renderLatLonBars = () => {
        let { cardModel, actionModel } = this.props;

        return (
            <div style={{ display: 'flex', flex: 1, marginTop: '16px' }}>
                <TextInput
                    type={'text'}
                    field={'lat'}
                    label={I18n.get("_lat_placeholder")}
                    placeholder={I18n.get("_lat_placeholder")}
                    store={actionModel}
                    disabled={cardModel.loading}
                    onChange={(value) => {
                        actionModel.lat_lng[0] = value
                        actionModel.address = "none"
                    }}
                    style={{ marginRight: '16px' }}
                    showRequiredHelperText={I18n.get("_required", "Required")}
                    inputProps={{
                        'data-testid': 'lat'
                    }}
                />

                <TextInput
                    type={'text'}
                    field={'lng'}
                    label={I18n.get("_lon_placeholder")}
                    placeholder={I18n.get("_lon_placeholder")}
                    store={actionModel}
                    disabled={cardModel.loading}
                    onChange={(value) => {
                        actionModel.lat_lng[1] = value
                        actionModel.address = "none"
                    }}
                    showRequiredHelperText={I18n.get("_required", "Required")}
                    inputProps={{
                        'data-testid': 'lng'
                    }}
                />
            </div>
        )
    };

    renderSearchAddressBar = () => {
        return <div style={{ marginTop: '16px' }}>
            <GoogleMapPlacesInput
                id={this.props.actionModel.actionID}
                onHandleSelect={this.onHandleSelect}
                defaultValue={this.props.actionModel.address ? this.props.actionModel.address : ''}
                margin="dense"
                variant="outlined"
                label={I18n.get("_address_placeholder")}
                disabled={this.props.cardModel.loading}
                noOptionsText={I18n.get("_no_options_text", "No options")}
                store={this.props.actionModel}
                field={'address'}
            />
        </div>
    };

    render() {
        let content = null;

        switch (this.state.optionSelected) {
            case "address":
                content = this.renderSearchAddressBar();
                break;
            case "lat_long":
                content = this.renderLatLonBars();
                break;
            case "multiple":
                content = this.renderNearbyAddressesBar();
                break;
            default:
                break;
        }

        return (
            <div>
                <Select
                    value={this.state.optionSelected}
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    onChange={this.handleItemChanged}
                    inputProps={{
                        'data-testid': 'type_address'
                    }}
                    native={true}
                >
                    <option value="address">{I18n.get("_address", "Address")}</option>
                    <option value="lat_long">{I18n.get('_lat_lon')}</option>
                    <option value="multiple">{I18n.get('_multi_location')}</option>
                    {/*<MenuItem value="address">{I18n.get("_address", "Address")}</MenuItem>*/}
                    {/*<MenuItem value="lat_long">{I18n.get('_lat_lon')}</MenuItem>*/}
                    {/*<MenuItem value="multiple">{I18n.get('_multi_location')}</MenuItem>*/}
                </Select>
                {content}
            </div>
        );
    }
}

export default observer(AddressInput);
