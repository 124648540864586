import React from 'react';
import { observable, decorate } from "mobx";
import CardContent from '@material-ui/core/CardContent';
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import NewActionHeader from '../../NewAction/NewActionHeader/NewActionHeader';
import { I18n } from 'aws-amplify';
import TextInput from "../../NewAction/inputs/TextInput/TextInput";

class Seamless extends ActionModel {
	static metadata = {
		id: "seamless",
		name: "Seamless",
		icon: "https://l.fast.cm/content/icons/seamless.png",
		categories: {
			// food: true
		}
	};

	Title = "Seamless";
	Link = "https://www.seamless.com/"
	ButtonText = "GO";
	isValid = false

	getConfigCardBody(cardModel, handleOnChangeIcon) {
		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />

				<TextInput
					type={'text'}
					field={'Link'}
					label={I18n.get("_action_type_url")}
					store={this}
					disabled={cardModel.loading}
					showRequiredHelperText={I18n.get("_required", "Required")}
					inputProps={{
						'data-testid': 'link'
					}}
				/>
			</CardContent>
		)
	}

	getRules() {
		return {
			Link: {
				presence: { allowEmpty: false },
				url: { message: I18n.get("_action_wrong_link", "Wrong link") }
			},
			...super.getRules()
		}
	}
}

decorate(Seamless, {
	Link: observable,
	isValid: observable,
	Title: observable,
	ButtonText: observable
})

export default Seamless
