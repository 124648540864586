export const styles = theme => ({
    container: {
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            height: '100vh'
        }
    },
    containedGradientButton: {
        color: "white",
        marginTop: 16,
        height: 36,
        backgroundImage: "linear-gradient(to right, #097AD1, #00DFA4)",
        '&:hover': {
            backgroundImage: "linear-gradient(to right, #0040C1 , #00B4AC)",
        },
        '&:disabled': {
            color: "#9B9B9B",
            backgroundImage: "linear-gradient(to right, #D2D2D2 , #D2D2D2)",
        }
    },
    outlinedGradientButton: {
        color: theme.palette.primary,
        border: "1px solid",
        marginTop: 16,
        borderImageSlice: 4,
        borderImageSource: "linear-gradient(to right, #097AD1, #00DFA4)",
        '&:disabled': {
            color: "#9B9B9B",
            borderImageSource: "linear-gradient(to right, #D2D2D2 , #D2D2D2)",
        }
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '25rem',
        maxWidth: 440,
        margin: '32px auto 16px auto',
        height: 'fitContent',
        padding: 32,
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        }
    },
    logo: {
        marginTop: 48,
        height: 26
    },
    middleContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        marginBottom: 16
    }
})
