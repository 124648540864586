import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";



const firebaseConfig = {
    apiKey: "AIzaSyBuwQ6YBrvAq6fmzlJJe-qziYgEhuaMSww",
    authDomain: "fast-cards-1544633075652.firebaseapp.com",
    databaseURL: "https://fast-cards-1544633075652.firebaseio.com",
    projectId: "fast-cards-1544633075652",
    storageBucket: "fast-cards-1544633075652.appspot.com",
    messagingSenderId: "355962619087",
    appId: "1:355962619087:web:70e4b5acac0301d75a63ba",
    measurementId: "G-2ETTFRHEZM"
};

export const initializeFirebase = () => {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const perf = getPerformance(app);
};