import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import YoutubeInput from './../../NewAction/inputs/YoutubeInput/YoutubeInput';
import { observable, decorate } from "mobx";
import ActionModel from '../../../../../models/ActionModel/ActionModel';
import NewActionHeader from '../../NewAction/NewActionHeader/NewActionHeader';

class YoutubeAction extends ActionModel {
	static metadata = {
		id: "youtube",
		name: "YouTube",
		icon: "https://l.fast.cm/content/icons/youtube.png",
		categories: {
			// social: true
		}
	};


	constructor(dat) {
		super();
		// this.propNames = ["Title", "Image", "Subtitle", "ButtonText", "Link"];
		this.setFromObject(dat);
	}
	// Title = "YouTube";
	Image = this.Image || this.constructor.metadata.icon;
	isValid = false;

	getConfigCardBody(cardModel, handleOnChangeIcon) {
		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />
				<YoutubeInput
					actionModel={this}
					cardModel={cardModel} />
			</CardContent>
		)
	}

	getRules() {
		return {
			Subtitle: {
				presence: { allowEmpty: true }
			},
			...super.getRules()
		}
	}
}

decorate(YoutubeAction, {
	Link: observable,
	isValid: observable,
	Title: observable,
	ButtonText: observable
})

export default YoutubeAction
