import Validate from "validate.js";
import {rules} from "../ActionModel/rules";
import {action, computed, decorate, flow, observable, reaction} from "mobx";
import * as Sentry from "@sentry/react";


class ValidationModel {

    errors = null;
    validating = false;
    disposeValidation = null;
    touchedMap = new Map();
    isFirstInvalid = false;

    constructor() {
        this.setupValidation();
        this.touchedMap = new Map()
    }

    validate() {
        this.validating = true
        this.errors = Validate.validate(this, { ...this.getRules() }, { fullMessages: false })
        return !this.errors
    }

    setField(field, value) {
        this[field] = value;
        this.touchedMap.set(field, true)
        this.isFirstInvalid = false
    }

    setupValidation() {
        this.disposeValidation = reaction(
            () => {
                const values = {};
                Object.keys(this.getRules()).forEach(value => {
                    values[value] = this[value]
                })
                return values;
            },
            fields => {
                this.validateFields(fields);
            },
        );
    }

    validateFields = flow(function* (fields) {
        this.validating = true;
        this.errors = null;

        try {
            yield Validate.async(fields, { ...rules, ...this.getRules() }, { fullMessages: false });

            this.errors = null;
        } catch (err) {
            Sentry.captureException(err);
            this.errors = err;
        } finally {
            this.validating = false;
        }
    });

    getRules() {
        return {};
    }

    get isInvalid() {
        return !!this.errors
    }

    setIsFirstInvalid(value) {
        return this.isFirstInvalid = value
    }

    cleanupValidation() {
        this.disposeValidation()
    }
}

decorate(ValidationModel, {
    validate: action,
    errors: observable.ref,
    setField: action,
    validating: observable,
    isInvalid: computed,
    touchedMap: observable,
    isFirstInvalid: observable,
    setIsFirstInvalid: action,
});

export default ValidationModel
