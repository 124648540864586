import React from 'react';
import PropTypes from 'prop-types';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import { ButtonContainer } from './styles';

const BackButton = props => {

    return (
        <ButtonContainer >
            <IconButton onClick={props.goBack}>
                <ArrowBackIosIcon style={{ color: 'white' }} />
            </IconButton>
        </ButtonContainer>
    );
}

BackButton.propTypes = {
    goBack: PropTypes.func.isRequired
}

export default BackButton
