export const phoneContactTypes = [
    '_home',
    '_work',
    '_cell',
    '_other'
]

export const emailContactTypes = [
    '_home',
    '_work',
    '_other'
]

export const websiteContactTypes = [
    '_home',
    '_work'
]

