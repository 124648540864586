import React from 'react';
import { observable, decorate } from "mobx";
import CardContent from '@material-ui/core/CardContent';
import { I18n } from 'aws-amplify';
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import UserName from '../../NewAction/inputs/UsernameInput/UsernameInput'
import NewActionHeader from '../../NewAction/NewActionHeader/NewActionHeader';

class TiktokAction extends ActionModel {

	static metadata = {
		id: "tiktok",
		name: "Tiktok",
		icon: "https://l.fast.cm/content/icons/tiktok.jpg",
		categories: {
			// social: true
		}
	};

	constructor(dat) {
		super();

		// this.propNames = ["Title", "Image", "Subtitle", "ButtonText", "destId", "Link"];
		this.propNames.push("destId");
		// This completely overwrites the existing validatorMap making these the only required properties
		this.validatorMap = {
			Title: "string",
			Subtitle: "notRequired",
			ButtonText: "string",
			destId: "string"
		};

		this.setFromObject(dat);
	}

	Title = "Tiktok";
	Link = "https://www.tiktok.com/@"
	ButtonText = "GO";
	isValid = false
	destId = "";


	onValidateUrl = () => {
		this.isValid = true
	}

	getConfigCardBody(cardModel, handleOnChangeIcon) {
		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />
				<UserName
					placeHolderText={I18n.get("_username")}
					cardModel={cardModel}
					actionModel={this} />
			</CardContent >
		)
	}
}

decorate(TiktokAction, {
	Title: observable,
	Link: observable,
	ButtonText: observable,
	isValid: observable,
	destId: observable
})

export default TiktokAction;