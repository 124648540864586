import {useEffect, useState} from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import {useUserModel} from "../hooks";
import { toJS } from 'mobx'
import lodashGet from 'lodash/get';

const LaunchDarkly = () => {

    const [ldUser, setLdUser] = useState({});
    const userModel = useUserModel();
    const ldClient = useLDClient();

    const identifyLaunchDarkly = ()=>{
        if(ldClient && userModel.userProperties.email){
            let data = {
                key: userModel.userProperties.fastcard_user,
                avatar: userModel.userProperties.avatar_url,
                email: userModel.userProperties.email,
                firstName : userModel.userProperties.firstname,
                lastName: userModel.userProperties.lastname,
                country: userModel.userProperties.country,
                custom:{
                    purpose: userModel.userProperties.purpose,
                    totalFastCards: userModel.userProperties.totalFastCards,
                    products: toJS(lodashGet(userModel, 'stripeDataSummary.products',[])),
                    prices:toJS(lodashGet(userModel, 'stripeDataSummary.prices',[])),
                    tiers: toJS(lodashGet(userModel, 'stripeDataSummary.tiers',[])),
                }
            }

            let isSelf = userModel.fcUserData.isSelf;
            // we're in overWatch protocol
            if(!isSelf){
                // this is an overWatch user, we're only are going to send the key as to not alter the real user's info.
                // that will allow for us to see exactly what the user see's
                data = {
                    key: userModel.fcUserData.userID
                }
            }
            setLdUser(data)

            ldClient.identify(data, undefined, (err, flags)=> {
                console.log("New user's flags available", flags);
            });
        }
    }

    useEffect(()=>{
        identifyLaunchDarkly();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userModel.userProperties.email, (typeof ldClient ==='object')])
    
    return null;
};
export default LaunchDarkly;