import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
    root: {
        display: 'flex',
        justifyContent: 'space-around'
    },
    innerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '86px auto',
        textAlign: 'center'
    },
    title: {
        color: theme.palette.primary.main
    },
    subtitle: {
        fontSize: 19,
        fontStyle: 'italic',
        color: theme.palette.text.secondary,
        marginTop: 32
    },
    image: {
        width: '100%',
        maxWidth: 750,
        margin: '16px 32px'
    },
    button: {
        width: 120,
        color: 'white'
    }
}))
