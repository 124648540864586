import React, { useState } from 'react';
import cn from 'classnames';
import { I18n } from 'aws-amplify';
import TextInput from "../TextInput/TextInput";
import { useStyles } from "./styles";
import PhotoContactInput from "../PhotoContactInput/PhotoContactInput";

import GoogleMapPlacesInput from '../GoogleMapPlacesInput/GoogleMapPlacesInput';
import ContactKeyValueInput from "../ContactKeyValueInput/ContactKeyValueInput";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { emailContactTypes, phoneContactTypes, websiteContactTypes } from "../../../../../../constants/contact-types";
import SelectInput from "../SelectInput/SelectInput";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddBox';
import { observer } from "mobx-react";

const VCardInput = (props) => {

    const { cardModel, actionModel } = props
    const [collapsed, setCollapsed] = useState(false)
    const classes = useStyles()

    function handleSearchAddress(loc) {
        actionModel.address = (loc.name);
        actionModel.lat_lng = [loc.lat, loc.lng];
        actionModel.lat = loc.lat;
        actionModel.lng = loc.lng;

        actionModel.dynamicLocationData = {
            query: actionModel.address,
            category: (loc.types) ? loc.types.join(", ") : []
        };


        let { result: { address_components } } = loc
        address_components.forEach(address => {
            switch (address.types[0]) {
                case "postal_code":
                    actionModel.zipCode = address.long_name
                    break
                case "administrative_area_level_1":
                    actionModel.state = address.long_name
                    break
                case "locality":
                    actionModel.city = address.long_name
                    break
            }
        })

    }

    return <>
        <div className={classes.headerForm}>
            <Typography className={classes.cardTitle}
                variant="h6">{I18n.get("_action_vcard_create_contact", "Create Contact")}</Typography>
            <div className={classes.btnExpandCollapseContainer}>
                <Typography color="textPrimary"
                    variant="caption">{collapsed ? I18n.get("_expand", "Expand") : I18n.get("_collapse", "Collapse")}</Typography>
                <Button variant="outlined" onClick={() => setCollapsed(!collapsed)} data-testid="expandCollapsedBtn"
                    classes={{ root: classes.btnExpandCollapse }}>
                    {collapsed ? <KeyboardArrowDownIcon size="small" /> : <KeyboardArrowUpIcon size="small" />}
                </Button>
            </div>
        </div>
        {!collapsed && <>
            <div className={classes.photoNameContainer}>
                <PhotoContactInput cardModel={cardModel} actionModel={actionModel} />
                <div className={classes.fieldsContainer}>
                    <TextInput
                        type={'text'}
                        field={'name'}
                        label={I18n.get("_first_name", "First Name")}
                        store={actionModel}
                        disabled={cardModel.loading}
                        autoComplete="off"
                        showRequiredHelperText={I18n.get("_required", "Required")}
                        classes={{ root: classes.nameMarginRight }}
                        inputProps={{
                            'data-testid': 'name'
                        }}
                    />
                    <TextInput
                        type={'text'}
                        field={'lastName'}
                        label={I18n.get("_last_name", "Last Name")}
                        store={actionModel}
                        disabled={cardModel.loading}
                        autoComplete="off"
                        inputProps={{
                            'data-testid': 'last_name'
                        }}
                    />
                </div>
            </div>
            <TextInput
                type={'text'}
                field={'company'}
                label={I18n.get("_company", "Company")}
                store={actionModel}
                disabled={cardModel.loading}
                autoComplete="off"
                inputProps={{
                    'data-testid': 'company'
                }}

            />

            <ContactKeyValueInput cardModel={cardModel}
                actionModel={actionModel}
                onAdd={(index) => actionModel.addEmail(index)}
                onRemove={(index) => actionModel.removeEmail(index)}
                values={actionModel.emails}
                valueLabel={I18n.get("_email", "Email")}
                labelOptions={emailContactTypes.map(value => {
                    return { value: value, label: I18n.get(value) }
                })}
            />

            <ContactKeyValueInput cardModel={cardModel}
                actionModel={actionModel}
                onAdd={(index) => actionModel.addPhone(index)}
                onRemove={(index) => actionModel.removePhone(index)}
                values={actionModel.phones}
                valueLabel={I18n.get("_phone", "Phone")}
                labelOptions={phoneContactTypes.map(value => {
                    return { value: value, label: I18n.get(value) }
                })}
            />
            <div className={classes.componentContainer}>
                <div className={classes.rowContainer}>
                    <TextInput
                        type={'text'}
                        field={'value'}
                        label={I18n.get("_website", "Website")}
                        store={actionModel.websites[0]}
                        disabled={cardModel.loading}
                        autoComplete="off"
                        classes={{ root: classes.valueField }}
                    />
                    <SelectInput
                        label={I18n.get("_label", "Label")}
                        store={actionModel.websites[0]}
                        field={'type'}
                        options={websiteContactTypes.map(value => {
                            return { value: value, label: I18n.get(value) }
                        })}
                        classes={{ root: classes.selectType }}
                        disabled={cardModel.loading || actionModel.websites.length > 1}
                    />
                    <div className={classes.btnRowGroupContainer}>
                        <div className={classes.btn}>
                            <IconButton
                                classes={{ root: classes.iconDelete }}
                                size="small"
                                disabled={true}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                        <div className={classes.btn}>
                            <IconButton
                                classes={{ root: classes.iconDelete }}
                                size="small"
                                onClick={() => actionModel.addWebsite(1)}
                                disabled={cardModel.loading || !actionModel.websites[0].type}
                            >
                                <AddIcon />
                            </IconButton>
                        </div>
                    </div>
                </div>
                {actionModel.websites.length > 1 && <div className={classes.rowContainer}>
                    <TextInput
                        type={'text'}
                        field={'value'}
                        label={I18n.get("_website")}
                        store={actionModel.websites[1]}
                        disabled={cardModel.loading}
                        autoComplete="off"
                        classes={{ root: classes.valueField }}
                    />
                    <SelectInput
                        label={I18n.get("_label", "Label")}
                        store={actionModel.websites[1]}
                        field={'type'}
                        options={websiteContactTypes.filter((value) => value !== actionModel.websites[0].type).map(value => {
                            return { value: value, label: I18n.get(`_${value}`) }
                        })}
                        classes={{ root: classes.selectType }}
                        disabled={cardModel.loading}
                    />
                    <div className={classes.btnRowGroupContainer}>
                        <div className={classes.btn}>
                            <IconButton
                                classes={{ root: classes.iconDelete }}
                                size="small"
                                disabled={cardModel.loading}
                                onClick={() => {
                                    actionModel.removeWebsite(1)
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                        <div className={classes.btn}>
                            <IconButton
                                classes={{ root: classes.iconDelete }}
                                size="small"
                                disabled={true}
                            >
                                <AddIcon />
                            </IconButton>
                        </div>
                    </div>
                </div>}
            </div>

            <div className={classes.mtop15}>
                <GoogleMapPlacesInput
                    id={actionModel.actionID}
                    onHandleSelect={handleSearchAddress}
                    defaultValue={actionModel.address ? actionModel.address : ''}
                    margin="dense"
                    variant="outlined"
                    label={I18n.get("_address_placeholder")}
                    disabled={cardModel.loading}
                    noOptionsText={I18n.get("_no_options_text", "No options")}
                    style={{ marginTop: '15px' }}
                    autoComplete={'off'}
                    store={actionModel}
                />
            </div>

            <div className={cn(classes.mtop15, classes.fieldsContainer)}>
                <TextInput
                    type={'text'}
                    field={'zipCode'}
                    label={I18n.get("_zip_code", "Zip Code")}
                    store={actionModel}
                    disabled={cardModel.loading}
                    autoComplete="off"
                    classes={{ root: classes.nameMarginRight }}
                />
                <TextInput
                    type={'text'}
                    field={'city'}
                    label={I18n.get("_city", "City")}
                    store={actionModel}
                    disabled={cardModel.loading}
                    autoComplete="off"
                />
            </div>

            <TextInput
                type={'text'}
                field={'state'}
                label={I18n.get("_state", "State")}
                store={actionModel}
                disabled={cardModel.loading}
                autoComplete="off"
                className={classes.mtop15}
            />
        </>}
    </>
}

export default observer(VCardInput);
