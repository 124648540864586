import React, { Component } from 'react';
import { API, Hub, I18n } from "aws-amplify";
import Dialog from '@material-ui/core/Dialog';
import lodashGet from 'lodash/get';
import DialogContent from "@material-ui/core/DialogContent";
import lodashSnakeCase from 'lodash/snakeCase';
import {withRouter} from "react-router";
import {parseURLParameters} from "../utils/functions";
import BillingPanel from "../Pages/Settings/BillingPanel/BillingPanel";

class PayWall extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checkOutOptions: {},
            open: false,
            feature: 'default',
            featureDetails: ''
        }

        Hub.listen('fcPayWall', this.fcPyaWallListener, `fcPayWall_listener`);
    }



    fcPyaWallListener = ({ payload: { event, data } }) => {


        switch (event) {
            case "clicked":
                    let flag = lodashSnakeCase(lodashGet(data, 'flag', 'default'))
                    let autoOpen = lodashSnakeCase(lodashGet(data, 'autoOpen', false)) === 'true'
                    this.setState({ feature: flag })
                    this.openDialog();
                    if (autoOpen) { this.openCustomerPortal(); }
                break;
            case "checkOut":

                const queryItems = parseURLParameters(data)

                 let interval = queryItems.interval
                 let interval_count = queryItems.intervalCount
                 let tier = queryItems.tier

                // this.props.history.push(`/settings/checkOut/?interval=${interval}&intervalCount=${interval_count}&tier=${tier}`);
                this.setState({ checkOutOptions:{interval_count, interval,tier}})
                this.openDialog();
                break;
            default: break;
        }

    }

    openCustomerPortal = () => {
        this.openDialog();
    }

    componentWillUnmount() {
        Hub.remove('fcPayWall', this.fcPyaWallListener)
    }

    openDialog = () => {
        this.setState({ open: true })
    }
    closeDialog = () => {
        this.setState({ open: false })
    }

    render() {
        return (
            <div>
                <Dialog
                    fullWidth={true}
                    maxWidth={'lg'}
                    onClose={() => { this.closeDialog() }}
                    open={this.state.open}>
                    <DialogContent>
                        <div>

                            <BillingPanel />
                            {/*<BillingPanel openNewTab={true} />*/}
                            {/*<ServiceBot checkOutOptions={this.state.checkOutOptions} billingPageID={process.env.REACT_APP_SERVICE_BOT_CUSTOMER_POPUP} />*/}
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}


// const PayWall = props => {
//     return (
//         <div>
//
//         </div>
//     );
// };
//
// PayWall.propTypes = {
//
// };

// export default PayWall;

export default withRouter(PayWall);
