import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { I18n } from 'aws-amplify';

function AlertDialog(props) {
    return (
        <Dialog
            maxWidth="sm"
            fullWidth
            open={props.open}
            onClose={props.handleClose}
        >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent >
                {props.message ? <DialogContentText>{props.message}</DialogContentText> : props.children}
            </DialogContent>
            <DialogActions>
                {props.cancelLabel ? <Button
                    color="primary"
                    variant="outlined"
                    disabled={props.loading}
                    onClick={props.handleClose}>{props.cancelLabel}</Button> :
                    null}
                <Button
                    data-testid="dialog-handle-ok-btn"
                    style={{ marginRigth: '8px', color: 'white', minWidth: 96 }}
                    variant="contained"
                    color="primary"
                    disabled={props.loading}
                    onClick={props.handleOk}>{props.loading ?
                        <CircularProgress style={{ color: 'white' }} size="1.2rem" /> : props.okLabel || I18n.get("_accept", "Accept")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

AlertDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleOk: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    okLabel: PropTypes.string,
    cancelLabel: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.object,
    errorIcon: PropTypes.element
}

export default AlertDialog;

