import { observable, action, computed, decorate, reaction, flow } from "mobx";
import { actionsMap } from "../../Pages/CardBuilder/CardBuilderSection/Actions/index";
import ActionModel from "../ActionModel/ActionModel";
import { Auth, API, Storage } from "aws-amplify";
import ShareAction from "../../Pages/CardBuilder/CardBuilderSection/Actions/PrivateActions/share";
import YoutubeClickAction from "../../Pages/CardBuilder/CardBuilderSection/Actions/PrivateActions/youtubeclick";
import days from "../../constants/data-fetching";
import { theme } from "../../constants/theme";
import moment from "moment";
import { parallel } from "async";
import CardAnalytics from "../CardAnalytics/CardAnalytics";
import defaultFetchingDays from "../../constants/data-fetching";
import {getImageCompleteURL, randomString} from "../../utils/functions";
import flat from "flat";
import { countFilters } from "../../utils/functions";
import lodashGet from "lodash/get";
import { I18n } from "aws-amplify";
import Validate from "validate.js";
import keyBy from "lodash/keyBy";
import * as Sentry from "@sentry/react";
import isEmpty from "lodash/isEmpty";
// import EventGoogle from "../../Pages/CardBuilder/CardBuilderSection/Actions/PrivateActions/eventGoogle";

const rules = {
  Title: {
    presence: { allowEmpty: false },
  },
  Subtitle: {
    presence: { allowEmpty: false },
  },
  ShareText: {
    presence: { allowEmpty: false },
  },
};

class CardModel {
  Title = "";
  Subtitle = "";
  ShareText = I18n.get("_share", "SHARE");
  showShareText = true;
  showBranding = true;
  autoTranslate = false;
  autoOptimized = false;
  autoPlayYoutube = false;
  openNewTab = false;
  autoRedirect = false;
  country = "default";
  facebookPixel = "";
  tagManagerPixel = "";
  fallbackUrl = "";
  Image = "";
  YoutubeID = "";
  CardUrl = "";
  LinksList = [new actionsMap["default"]({})];
  InactiveLinksList = [];
  InvalidActions = [];
  Card_ID = "";
  Twitter = "https://via.placeholder.com/80x80/F0F1F6/FFFFFF?text=Fastmind";
  loading = false;
  TriedBuildingCard = false;
  ActiveCard = false;
  AllData = false;
  isArchived = false;
  isAnEdit = false;
  totalClicks = 0;
  totalViews = 0;
  tags = {};
  customId = "";
  customDomain = "";
  theme = {};
  // firstDate = moment('12/01/2019').subtract(defaultFetchingDays, 'days');
  // endDate = moment('12/31/2019');
  uniqueSelected = "location";
  options = [];
  optionsSelected = {
    location: {},
    referer: {},
    utm: {},
    fcFLRefSeg: {},
    fcFLRef: {},
  };
  firstDate = moment().subtract(defaultFetchingDays, "days");
  endDate = moment();
  query = undefined;
  presetRange = "custom";
  timeSeriesData = new CardAnalytics();
  integrations = [];
  warningRequirement = -1;
  warningRequirementByCard = false;
  errors = null;
  validating = false;
  disposeValidation = null;
  touchedMap = new Map();
  invalidLinkIndex = null;
  headerCustomHtml = "";
  footerCustomHtml = "";
  languageCode="";

  // disclaimer properties
  disclaimerPosition = 'none'
  disclaimerText = ''
  disclaimerUrl = ''

  constructor(dat) {
    this.setFromObject(dat);
    // this.fetchTimeSeriesAnalytics()
    // this.publishCounter = 0;
    this.setupValidation();
  }

  getCompleteURL(link) {
    return getImageCompleteURL(link)
  }

  _setEdit(dat) {
    this.reset();
    this.isAnEdit = true;
    this.setFromObject(dat);
  }

  _setDuplicate(dat) {
    this.reset();
    this.isAnEdit = false;
    dat.customId = "";
    dat.customDomain = "";
    delete dat.Card_ID;
    delete dat.InactiveLinksList;
    this.setFromObject(dat);
  }

  setFromObject(dat) {
    dat = dat || {};
    this.InvalidActions = [];
    this.AllData = dat;
    // let's set some data
    this.Card_ID =
      dat.Card_ID || randomString(8, "0123456789abcdefghijklmnopqrstuvwxyz");
    this.facebookPixel = dat.facebookPixel;
    this.tagManagerPixel = dat.tagManagerPixel;
    this.fallbackUrl = dat.fallbackUrl;
    this.Title = dat.Title || "";
    this.ShareText = dat.shareText || I18n.get("_share", "SHARE");
    this.ActiveCard = !!dat.Card_ID;
    this.isArchived = dat.isArchived || false;
    this.Subtitle = dat.Subtitle || "";
    this.CardUrl = `${process.env.REACT_APP_FASTCARD_BASE}/${dat.Card_ID}`;
    this.Twitter = this.getCompleteURL(dat.Twitter);
    if (dat.Image) {
      this.Image = this.getCompleteURL(dat.Image);
    }

    if (dat.YoutubeID) {
      this.YoutubeID = dat.YoutubeID;
    }

    this.LinksList = [];

    if (Array.isArray(dat.LinksList)) {
      dat.LinksList.forEach((element) => {
        this.addLink(element);
      });
    }

    // creating the inactive list
    this.InactiveLinksList = [];
    if (Array.isArray(dat.InactiveLinksList)) {
      dat.InactiveLinksList.forEach((element) => {
        this.addLink(element, true);
      });
    }

    this.showBranding = dat.hasOwnProperty("showBranding")
      ? dat.showBranding
      : true;
    this.showShareText = dat.hasOwnProperty("showShareText")
      ? dat.showShareText
      : true;
    this.autoOptimized = dat.hasOwnProperty("autoOptimized")
      ? dat.autoOptimized
      : false;
    this.autoPlayYoutube = dat.hasOwnProperty("autoPlayYoutube")
      ? dat.autoPlayYoutube
      : false;
    this.autoTranslate = dat.hasOwnProperty("autoTranslate")
      ? dat.autoTranslate
      : false;
    this.autoRedirect = dat.hasOwnProperty("autoRedirect")
      ? dat.autoRedirect
      : false;
    this.openNewTab = dat.hasOwnProperty("openNewTab") ? dat.openNewTab : false;

    this.tags = dat.tags || {};
    this.country = dat.country || "default";
    this.customId = dat.customId || "";
    this.customDomain = dat.customDomain || "";
    this.warningRequirement = dat.warningRequirement || -1;
    this.warningRequirementByCard = dat.hasOwnProperty("warningRequirementByCard")? dat.warningRequirementByCard : false;

    this.disclaimerPosition = dat.disclaimerPosition || 'none';
    this.disclaimerText =  dat.disclaimerText || '';
    this.disclaimerUrl = dat.disclaimerUrl || '';


    this.theme = { ...theme, ...(dat.theme || {}) };
    if (dat.hasOwnProperty("lifetime")) {
      this.totalClicks = dat.lifetime.PageClick;
      this.totalViews = dat.lifetime.PageView;
    }

    if (dat.theme && dat.theme["backgroundImage"]) {
      this.theme["backgroundImage"] = this.getCompleteURL(
        dat.theme.backgroundImage
      );
    }

    // Time Series Data
    if (dat.timeSeriesData) {
      this.timeSeriesData = new CardAnalytics(this, dat.timeSeriesData);
    }

    this.publishCounter = 0;
    this.headerCustomHtml = dat.headerCustomHtml || "";
    this.footerCustomHtml = dat.footerCustomHtml || "";
    this.languageCode = dat.languageCode || "";
  }

  reset() {
    this.YoutubeID = "";
    this.Image = "";
    this.Twitter = "";
    this.isAnEdit = false;
    this.Title_error = undefined;
    this.Subtitle_error = undefined;
    this.Image_error = undefined;
    this.query = undefined;
    this.timeSeriesData = new CardAnalytics();
    this.customId = "";
    this.customDomain = "";
    this.publishCounter = 0;

    this.setFromObject({});
  }

  get asObject() {
    return {
      Title: this.Title,
      Subtitle: this.Subtitle,
      shareText: this.ShareText,
      isArchived: this.isArchived,
      facebookPixel: this.facebookPixel,
      tagManagerPixel: this.tagManagerPixel,
      fallbackUrl: this.fallbackUrl,
      showShareText: this.showShareText,
      showBranding: this.showBranding,
      autoOptimized: this.autoOptimized,
      autoPlayYoutube: this.autoPlayYoutube,
      openNewTab: this.openNewTab,
      autoTranslate: this.autoTranslate,
      autoRedirect: this.autoRedirect,
      LinksList: this.GetList,
      InactiveLinksList: this.GetInactiveList,
      tags: this.tags,
      country: this.country,
      customId: this.customId,
      customDomain: this.customDomain,
      theme: this.theme,
      warningRequirement: this.warningRequirement,
      warningRequirementByCard: this.warningRequirementByCard,
      headerCustomHtml: this.headerCustomHtml,
      footerCustomHtml: this.footerCustomHtml,
      languageCode: this.languageCode,
      disclaimerPosition: this.disclaimerPosition,
      disclaimerText: this.disclaimerText,
      disclaimerUrl : this.disclaimerUrl,
    };
  }

  get servicesLinkList() {
    // used for the clicks on

    let list = [...this.LinksList];
    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      if (item.childActions) {
        for (let j = 0; j < item.childActions.length; j++) {
          let subAction = item.childActions[j];
          list.push(subAction);
        }
      }
    }

    list.push(new ShareAction({ actionID: `share_${this.Card_ID}` })); // we want to see the share action
    list.push(
      new YoutubeClickAction({ actionID: `youtube_click_${this.Card_ID}` })
    ); // we want to see the share action
    return list;
  }

  get servicesInactiveLinkList() {
    // used for the clicks on
    let list = [...this.InactiveLinksList];
    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      if (item.childActions) {
        for (let j = 0; j < item.childActions.length; j++) {
          let subAction = item.childActions[j];
          list.push(subAction);
        }
      }
    }
    return list;
  }

  get allActionsDict() {
    let list = [...this.InactiveLinksList, ...this.LinksList];
    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      if (item.childActions) {
        for (let j = 0; j < item.childActions.length; j++) {
          let subAction = item.childActions[j];
          list.push(subAction);
        }
      }
    }

    return keyBy(list, "actionID");
  }

  get _ImageIsFromUs() {
    // if it's from us then don't save it again
    return this.Image.indexOf("fast.cm/content") !== -1;
  }

  _uploadImage(data, callback) {
    let model = this;
    const { imageData, imageName } = data;

    if (
      imageName === "headerImage" &&
      model.Image &&
      model.Image.indexOf("fast.cm/content") !== -1
    ) {
      callback(undefined, {});
      return;
    }

    if (imageName === "backgroundImage" && model.theme["backgroundImage"]) {
      if (model.theme["backgroundImage"].indexOf("fast.cm/content") !== -1) {
        callback(undefined, {});
        return;
      }
    }

    if (imageData) {
      let fileName = randomString(9, "0123456789abcdefghijklmnopqrstuvwxyz");

      Storage.put(
        "cardTemp/" + model.Card_ID + "/" + fileName,
        new File([imageData], "test.png"),
        {
          level: "public",
        }
      )
        .then((result) => {
          if (imageName === "headerImage") {
            if ((model.Image || "").indexOf("blob:") === 0) {
              URL.revokeObjectURL(model.Image);
            }
            model.Image =
              "https://s3.amazonaws.com/fastlinkuploads/public/cardTemp/" +
              model.Card_ID +
              "/" +
              fileName;
          }

          if (imageName === "backgroundImage") {
            if ((model.theme["backgroundImage"] || "").indexOf("blob:") === 0) {
              URL.revokeObjectURL(model.theme["backgroundImage"]);
            }
            model.theme["backgroundImage"] =
              "https://s3.amazonaws.com/fastlinkuploads/public/cardTemp/" +
              model.Card_ID +
              "/" +
              fileName;
          }

          callback(undefined, {});
        })
        .catch((error) => {
          Sentry.captureException(error);
          callback(error, {});
        });
    } else {
      callback(undefined, {});
    }
  }

  getCardInfo(cardID) {
    const path = `/GetUserCardInfo/${cardID}`;
    const apiName = "fastShare";
    this.loading = true;

    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          API.get(apiName, path)
            .then((result) => {
              this.setFromObject(result.cards[0]);
              this.fetchTimeSeriesAnalytics();
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      })
      .catch((error) => {
        this.loading = false;
      });
  }

  _saveCardData(callback) {
    this.loading = true;

    this.IsCardValid((valid) => {
      if (!valid) {
        callback({ type: "" }, undefined);
        return;
      }

      Auth.currentAuthenticatedUser()
        .then((_) => {
          const apiName = "fastShare";
          let path = "/saveCard";
          let myInit = {
            body: this.asObject,
          };

          if (this.YoutubeID) {
            myInit.body.YoutubeID = this.YoutubeID;
          } else {
            myInit.body.Image = this.Image;
          }

          if (this.isAnEdit) {
            myInit.body.Card_ID = this.Card_ID;
          }

          API.post(apiName, path, myInit)
            .then((response) => {
              let { cardData } = response;

              this.loading = false;
              callback(undefined, cardData);
            })
            .catch((error) => {
              this.loading = false;
              callback(error, undefined);
            });
        })
        .catch((error) => {
          this.loading = false;
          Sentry.captureException(error);
          callback(error, undefined);
        });
    });
  }

  _saveCard(imageData, callback) {
    this._saveCardData(callback);
  }

  addLink(data, isInactive) {
    let list = isInactive ? this.InactiveLinksList : this.LinksList;

    let action = this.buildActionModel(data);

    if (action.isGroup && data.childActions) {
      data.childActions.forEach((childAction) =>
        action.addChildAction(this.buildActionModel(childAction))
      );
    }
    list.push(action);
  }

  buildActionModel(data) {
    let action = null;
    if (data instanceof ActionModel) {
      action = data;
    } else if (data.action && actionsMap.hasOwnProperty(data.action)) {
      action = new actionsMap[data.action](data);
    } else {
      action = new actionsMap["default"](data);
    }
    return action;
  }

  deleteLink(Ind) {
    this.InactiveLinksList.push(this.LinksList[Ind]);
    this.LinksList.splice(Ind, 1);
  }

  ChangeLink(Ind, dat) {
    this.LinksList[Ind].setFromObject(dat);
  }

  get GetList() {
    return this.LinksList.map((e) => {
      return e.asObject;
    });
  }

  get GetInactiveList() {
    return this.InactiveLinksList.map((e) => e.asObject);
  }

  isBlank(str) {
    return !str || /^\s*$/.test(str);
  }

  IsCardValid(callback) {
    let valid = (this.loading = this.TriedBuildingCard = true);

    // if (this.isBlank(this.Title)) {
    //   this.Title_error = true;
    //   valid = false;
    // } else {
    //   delete (this.Title_error);
    // }
    //
    // if (this.isBlank(this.Subtitle)) {
    //   this.Subtitle_error = true;
    //   valid = false;
    // } else {
    //   delete (this.Subtitle_error);
    // }
    //
    // if (this.showShareText) {
    //   if (this.isBlank(this.ShareText)) {
    //     this.ShareText_error = true;
    //     valid = false;
    //   } else {
    //     delete (this.ShareText_error);
    //   }
    // }
    this.invalidLinkIndex = null;
    this.errors = Validate.validate(this, rules);
    valid = !this.errors;

    // should never happen
    if (!this.Image && !this.YoutubeID) {
      valid = false;
      // suggest an image
      this.Image_error = true;
    } else {
      delete this.Image_error;
    }

    this.InvalidActions = []; //reset the invalid action
    let tempInvalidActions = [];

    this.LinksList.forEach((element, index) => {
      let validAction = element.validate();
      if (!validAction) {
        if (!this.invalidLinkIndex) {
          this.invalidLinkIndex = index;
          element.setIsFirstInvalid(true);
        }
        tempInvalidActions.push({
          action: element,
          invalid: element.invalidProps,
        });
        valid = false;
      }
    });

    this.InvalidActions.push(...tempInvalidActions);
    if (process.env.NODE_ENV !== "production")
      console.log(`Invalid Actions`, tempInvalidActions);
    this.loading = valid;
    if (callback) {
      callback(this.loading);
    }
  }

  get TimeAgo() {
    return new Date(this.AllData.Time_ID * 3600000);
  }

  _GetLink() {
    let thethis = this;
    return new Promise(async (resolve, reject) => {
      let path = "/GetLinkData";
      let d = new Date();
      d.setHours(0, 0, 0, 0);
      d.setDate(d.getDate() + 1);
      let dstart = new Date();
      dstart.setHours(0, 0, 0, 0);
      dstart.setDate(dstart.getDate() - days);

      let myInit = {
        body: {
          Start_Time: dstart.toString(),
          End_Time: d.toString(),
          Card_ID: thethis.Card_ID,
        },
      };
      let apiName = "fastShare";
      API.post(apiName, path, myInit)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          Sentry.captureException(error);
          resolve(undefined);
        });
    });
  }

  _fetchTimeSeriesEvent(params, callback) {
    const apiName = "fastShare";
    const path = `/analytics/getCardData/${this.Card_ID}`;
    params["response"] = false;
    API.get(apiName, path, params)
      .then((response) => {
        callback(null, response);
      })
      .catch((error) => {
        Sentry.captureException(error);
        callback(null, {});
      });
  }
  fetchCreativesSpending(ids, params) {
    if (!ids || ids.length === 0) {
      return;
    }
    let _this = this;

    _this.loading = true;
    params.queryStringParameters.fcaids = ids.sort(); // it is a get request sorting it will allow later for us to cache responses
    params.queryStringParameters.event = "Spending";

    const apiName = "fastShare";
    const path = `/analytics/getCardData/${this.Card_ID}`;
    API.get(apiName, path, params)
      .then((response) => {
        let d = _this.timeSeriesData.data;
        d.Spending = response;
        _this.timeSeriesData = new CardAnalytics(this, d);
      })
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        _this.loading = false;
      });
  }
  fetchTimeSeriesAnalytics() {
    let _this = this;

    _this.loading = true;
    let start_date = _this.firstDate.utc().startOf("day").unix();

    // if it's the same day only fo the the start of the hour
    let end_date = moment().isSame(_this.endDate, "day")
      ? _this.endDate.utc().startOf("hour").unix()
      : _this.endDate.utc().endOf("day").unix();

    let cardDataParams = {
      queryStringParameters: {
        start_date,
        end_date,
        start_day: _this.firstDate.format("YYYYMMDD"),
        end_day: _this.endDate.format("YYYYMMDD"),
        event: "CardAnalytics",
      },
    };

    if(!isEmpty(this.query)){
      cardDataParams.queryStringParameters.query = this.query;
    }

    parallel(
      {
        CardAnalytics: (cb) => _this._fetchTimeSeriesEvent(cardDataParams, cb),
      },
      (err, results) => {
        _this.loading = false;

        if (err) {
          return;
        }

        let fcaids = lodashGet(results, "CardAnalytics.dataSummary.fcaids", []);

        _this.timeSeriesData = new CardAnalytics(_this, results);
        _this.fetchCreativesSpending(fcaids, cardDataParams);
      }
    );
  }

  applyFilters() {
    let separator = "_";

    let count = countFilters(this.optionsSelected);

    if (count > 0) {
      let query = Object.keys(
        flat(this.optionsSelected, { delimiter: separator })
      )
        .map((k) => {
          let s = k.split(separator);
          let value = s[s.length - 1];
          let key = k.split(`_${value}`)[0];
          return key === value ? undefined : `(${key}=='${value}')`;
        })
        .filter((f) => f)
        .join("&");

      this.query = query;
    } else {
      this.query = undefined;
    }

    this.fetchTimeSeriesAnalytics();
  }

  saveCustomId = async (cardId, customId, callback) => {
    const path = "/saveCustomName";
    const apiName = "fastShare";
    const myInit = {
      body: {
        Card_ID: cardId,
        name: customId,
      },
    };

    this.loading = true;

    try {
      const result = await API.post(apiName, path, myInit);
      this.customId = result.Slug;
      this.loading = false;
      if (callback) callback(result, null);
    } catch (error) {
      this.loading = false;
      Sentry.captureException(error);
      if (callback) callback(null, error);
    }
  };

  checkCustomIdAvailability = async (customId, callback) => {
    const path = "/checkLinkAvailability";
    const apiName = "fastShare";
    const myInit = {
      body: {
        name: customId,
      },
    };

    try {
      const result = await API.post(apiName, path, myInit);
      if (callback) callback(result, null);
    } catch (error) {
      Sentry.captureException(error);
      if (callback) callback(null, error);
    }
  };

  fetchIntegrations = async (cardId, callback) => {
    const currentUser = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });

    if (currentUser) {
      let path = "/dataSources/getDataSourceLinks";
      let apiName = "fastCardAuth";
      let data = {
        body: {
          Card_ID: cardId,
        },
      };

      try {
        let result = await API.post(apiName, path, data);
        if (callback) {
          callback(null, result.data);
        }
      } catch (error) {
        Sentry.captureException(error);
        if (callback) {
          callback(error, null);
        }
      }
    }
  };

  setIntegrations = (items) => {
    this.integrations = items;
  };

  removeIntegration = async (dataSourceId, callback) => {
    const currentUser = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });

    if (currentUser) {
      let path = "/dataSources/deleteDataSourceLink";
      let apiName = "fastCardAuth";
      let data = {
        body: {
          dataSourceLinkID: dataSourceId,
        },
      };

      try {
        let result = await API.post(apiName, path, data);
        if (callback) {
          callback(null, result.data);
        }
      } catch (error) {
        Sentry.captureException(error);
        if (callback) {
          callback(error, null);
        }
      }
    }
  };

  createIntegration = async (
    dataSourceId,
    cardId,
    accountId,
    adId,
    callback
  ) => {
    const currentUser = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });

    if (currentUser) {
      let path = "/dataSources/createDataSourceLink";
      let apiName = "fastCardAuth";
      let data = {
        body: {
          dataSourceID: dataSourceId,
          Card_ID: cardId,
          accountID: accountId,
          adID: adId,
        },
      };

      try {
        let result = await API.post(apiName, path, data);
        if (callback) {
          callback(null, result.data);
        }
      } catch (error) {
        Sentry.captureException(error);
        if (callback) {
          callback(error, null);
        }
      }
    }
  };

  validateIntegration = async (cardId, dataSourceLinkId, callback) => {
    const currentUser = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });

    if (currentUser) {
      let path = "/dataSources/getDataSourceLinks";
      let apiName = "fastCardAuth";
      let data = {
        body: {
          Card_ID: cardId,
        },
      };

      try {
        let result = await API.post(apiName, path, data);
        if (callback) {
          let index = result.data.dataSourceLinks.findIndex((value) => {
            return value.dataSourceLinkID === dataSourceLinkId;
          });

          if (index !== -1) {
            callback(null, result.data.dataSourceLinks[index]);
          } else {
            callback({ message: "Something went wrong" }, null);
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        if (callback) {
          callback(error, null);
        }
      }
    }
  };

  setKey = (key, value) => {
    this[key] = value;
  };

  setupValidation() {
    this.disposeValidation = reaction(
      () => {
        const { Title, Subtitle, ShareText } = this;
        return { Title, Subtitle, ShareText };
      },
      (fields) => {
        this.validateFields(fields);
      }
    );
  }

  validateFields = flow(function* (fields) {
    this.validating = true;
    this.errors = null;

    try {
      yield Validate.async(fields, rules);

      this.errors = null;
    } catch (error) {
      this.errors = error;
    } finally {
      this.validating = false;
    }
  });

  setField(field, value) {
    this[field] = value;
    this.touchedMap.set(field, true);
  }

  get standAloneLinks() {
    return this.LinksList.filter((link) => link.groupable);
  }

  get isStandAloneLinksEmpty() {
    return this.LinksList.filter((link) => link.groupable).length === 0;
  }

  removeLink(link) {
    this.LinksList.splice(this.LinksList.indexOf(link), 1);
  }

  removeLinks(links) {
    links.forEach((link) =>
      this.LinksList.splice(this.LinksList.indexOf(link), 1)
    );
  }

  get anyOptionSelected() {
    return (
      Object.keys(this.optionsSelected.location).length > 0 ||
      Object.keys(this.optionsSelected.referer).length > 0 ||
      Object.keys(this.optionsSelected.utm).length > 0
    );
  }

  get countOptionSelected() {
    return countFilters(this.optionsSelected);
  }

  incrementPublishCounter() {
    this.publishCounter = this.publishCounter + 1;
  }
}

decorate(CardModel, {
  Title: observable,
  Subtitle: observable,
  ShareText: observable,
  showShareText: observable,
  autoTranslate: observable,
  autoOptimized: observable,
  autoPlayYoutube: observable,
  openNewTab: observable,
  autoRedirect: observable,
  country: observable,
  facebookPixel: observable,
  tagManagerPixel: observable,
  fallbackUrl: observable,
  Image: observable,
  YoutubeID: observable,
  CardUrl: observable,
  LinksList: observable,
  InactiveLinksList: observable,
  InvalidActions: observable,
  Card_ID: observable,
  Twitter: observable,
  TriedBuildingCard: observable,
  ActiveCard: observable,
  AllData: observable,
  isArchived: observable,
  isAnEdit: observable,
  totalClicks: observable,
  totalVisits: observable,
  loading: observable,
  tags: observable,
  customId: observable,
  customDomain: observable,
  theme: observable,
  analytics: observable,
  headerCustomHtml: observable,
  footerCustomHtml: observable,
  languageCode: observable,
  timeSeriesData: observable,
  presetRange: observable,
  uniqueSelected: observable,
  options: observable,
  optionsSelected: observable,
  integrations: observable,
  _ImageIsFromUs: computed,
  servicesLinkList: computed,
  asObject: computed,
  fetchTimeSeriesAnalytics: action,
  TimeAgo: computed,
  IsCardValid: action,
  GetList: computed,
  GetInactiveList: computed,
  ChangeLink: action,
  deleteLink: action,
  reset: action,
  addLink: action,
  resetImage: action,
  getCardInfo: action,
  _saveCard: action,
  _saveCardData: action,
  _uploadImage: action,
  query: observable,
  applyFilters: action,
  saveCustomId: action,
  checkCustomIdAvailability: action,
  setIntegrations: action,
  warningRequirement: observable,
  warningRequirementByCard: observable,
  setKey: action,
  errors: observable.ref,
  setField: action,
  validating: observable,
  touchedMap: observable,
  invalidLinkIndex: observable,
  standAloneLinks: computed,
  isStandAloneLinksEmpty: computed,
  removeLink: action,
  removeLinks: action,
  allActionsDict: computed,
  servicesInactiveLinkList: computed,
  anyOptionSelected: computed,
  countOptionSelected: computed,
  disclaimerPosition : observable,
  disclaimerText : observable,
  disclaimerUrl : observable,
  showBranding : observable,
});

export default CardModel;
