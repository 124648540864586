import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ItunesItem from './iTunesItem/iTunesItem';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import * as Sentry from "@sentry/react";

const styles = {
    paper: {
        width: '420px',
    },
};
class ItunesInput extends Component {

    state = {
        dropdownOpen: false,
        mediaSelected: "all",
        search: '',
        suggestions: [],
        value: '',
        open: false
    };

    inputRef = React.createRef()

    toggle = () => {
        this.setState(prevState => {
            return {
                dropdownOpen: !prevState.dropdownOpen
            }
        });
    }

    handleMediaChanged = event => {
        this.setState({
            mediaSelected: event.target.value
        })
    }

    handleSearch = event => {

        let value = event.target.value;
        // let value = event;
        this.props.actionModel.Link = "";

        this.setState({
            value: value
        })

        clearTimeout(this.typingTimer);

        this.typingTimer = setTimeout(() => {
            if (value) {
                this.getSuggestions(value, this.state.mediaSelected)
            }
        }, 400);
    }

    getSuggestions = (value, media) => {
        const baseUrl = "https://itunes.apple.com/search?";

        axios.get(baseUrl + `term=${value}&media=${media}&limit=3`)
            .then(result => {
                this.handleSuggestions(result.data.results);
            })
            .catch(error => {
                this.onSuggestionsClearRequested()
                Sentry.captureException(error);
            })
    }

    handleSuggestions = suggestions => {
        const items = suggestions.map(el => {

            let url = el.collectionViewUrl;
            let artist = null;
            let subtitle = null;

            switch (this.state.mediaSelected) {
                case 'movie':
                    artist = el.collectionName;
                    subtitle = el.artistName;
                    break;
                case 'podcast':
                    artist = el.collectionName;
                    subtitle = el.artistName;
                    break;
                case 'music':
                    artist = el.collectionName;
                    subtitle = el.artistName;
                    break;
                case 'musicVideo':
                    artist = el.trackName;
                    subtitle = el.artistName;
                    break;
                case 'audiobook':
                    artist = el.collectionName;
                    subtitle = el.artistName;
                    break;
                case 'ebook':
                    artist = el.trackName;
                    subtitle = el.artistName;
                    break;
                case 'tvShow':
                    artist = el.collectionName;
                    subtitle = el.artistName;
                    break;
                default:
                    artist = el.collectionName;
                    subtitle = el.artistName;
                    break;
            }

            return {
                url: url,
                image: el.artworkUrl100,
                title: artist,
                subtitle: subtitle,
                collection: subtitle || ""
            }
        })

        this.setState({
            suggestions: items.slice(0, 4),
            open: items.length > 0 ? true : false
        })
    }

    componentWillUnmount() {
        clearTimeout(this.typingTimer);
    }

    handleClose = () => {
        this.props.actionModel.Link = ""

        this.setState({
            suggestions: [],
            open: false
        });
    };

    handleSelected = suggestion => {
        this.props.actionModel.Title = suggestion.title
        this.props.actionModel.Image = suggestion.image
        this.props.actionModel.Link = suggestion.url
        this.props.actionModel.Subtitle = suggestion.subtitle

        if (suggestion.collection.trim().length < 20) {
            this.props.actionModel.Subtitle = suggestion.collection
        }

        this.setState({
            open: false,
            value: suggestion.url
        })
    }

    render() {

        let { actionModel, cardModel, classes } = this.props

        return (
            <div>
                <TextField
                    label="Search in iTunes or paste your link here"
                    placeholder="Search in iTunes or paste your link here"
                    value={this.props.actionModel.Link || this.state.value}
                    disabled={cardModel.loading}
                    onChange={this.handleSearch}
                    className={" form-control " + actionModel.getInvalidClass("Link")}
                    fullWidth
                    ref={this.inputRef}
                    margin="dense"
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <React.Fragment>
                                {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                            </React.Fragment>
                        ),
                    }}
                />

                <Popover
                    open={this.state.open}
                    classes={{
                        paper: classes.paper
                    }}
                    anchorEl={this.inputRef.current}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <List>
                        {this.state.suggestions.map(el => {
                            return <ItunesItem
                                key={el.title}
                                suggestion={el}
                                handleSelected={this.handleSelected} />
                        })}
                    </List>
                </Popover>
                <Select
                    value={this.state.mediaSelected}
                    variant="outlined"
                    margin="dense"
                    style={{ marginTop: "16px", width: '200px' }}
                    onChange={this.handleMediaChanged}
                >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="movie">Movies</MenuItem>
                    <MenuItem value="podcast">Podcasts</MenuItem>
                    <MenuItem value="music">Music</MenuItem>
                    <MenuItem value="musicVideo">Music Videos</MenuItem>
                    <MenuItem value="audiobook">AudioBooks</MenuItem>
                    <MenuItem value="ebook">eBooks</MenuItem>
                    <MenuItem value="tvShow">Tv Shows</MenuItem>
                </Select>
            </div>
        );
    }
}

ItunesInput.propTypes = {
    actionModel: PropTypes.object.isRequired,
    cardModel: PropTypes.object.isRequired
}

export default withStyles(styles)(observer(ItunesInput));
