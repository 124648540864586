import {Flex, View, useAuthenticator, useTheme} from "@aws-amplify/ui-react";
import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import {withRouter} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {I18n} from "aws-amplify";
import Button from "@material-ui/core/Button";
import {sendEvent} from "../../../utils/eventLog";

const CustomSignInFooter = (props)=> {
    const { tokens } = useTheme();
    const { toSignUp, toResetPassword } = useAuthenticator();

    const { classes } = props;
    return (
        <View padding={`${tokens.space.xl} ${tokens.space.xl}`}>

            <Button style={{ marginTop: -60 }} fullWidth color="primary" variant="text" onClick={toResetPassword}>{I18n.get("_forgot_password", "Forgot password?")}</Button>

            <Typography style={{ marginTop: 16 }} align="center" variant="body1">{I18n.get("_ask_user", "Don't have an account yet?")}</Typography>
            <Button
                fullWidth
                className={classes.outlinedGradientButton}
                // disabled={this.state.loading}
                onClick={() => { sendEvent('signUp_button_click'); toSignUp(); }}>
                {I18n.get("_sign_up", "Sign Up")}
            </Button>
        </View>
    );
}

export default  withStyles(styles)(withRouter(CustomSignInFooter));
