import styled from 'styled-components';


export const ButtonContainer = styled.div`
    margin: 0;
    cursor: pointer;

    @media screen and (min-width: 830px) {
        display: none;
    }
`