import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import { I18n } from "aws-amplify";
import { useFlags } from 'launchdarkly-react-client-sdk';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import { useUserModel } from '../../../../hooks'

import { useStyles } from './styles';
import { baseUrl } from '../../../../constants/help-endpoint';
import { observer } from 'mobx-react';

const CreditProgressPopover = forwardRef(({handleClose, handleUpgrade }, ref) => {

    const classes = useStyles();
    const flags = useFlags();
    const userModel = useUserModel();

    const fastcardsProgress = Math.floor((userModel.fcUserData.totalActiveCards / flags.maxFastcard) * 100)
    const visitsProgress = Math.floor((userModel.creditData?.dataSummary?.creditsConsumed / flags.maxVisits) * 100)

    const handleInfoIconClick = (event) => {
        event.stopPropagation();
    }


    return (
        <Popover
            id="credit-progress-mouse-over-popover"
            open={Boolean(ref)}
            anchorEl={ref}
            onClose={handleClose}
            style={{ marginTop: 10, cursor: 'pointer', pointerEvents:'none' }}
            disableRestoreFocus
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Paper classes={{ root: classes.paper }}>
                <div className={classes.title}>
                    <Typography align="left">{`${I18n.get(`_${userModel.stripeDataSummary?.tiers?.length && userModel.stripeDataSummary?.tiers[0]}`)}`}</Typography>
                </div>
                <div className={classes.barCnt}>
                    <div className={classes.barLabelCnt}>
                        <Typography variant="caption">{I18n.get("_fastcards", "Fastcards")}</Typography>
                        <Typography variant="caption">{`${userModel.fcUserData.totalActiveCards}/${flags?.maxFastcard}`}</Typography>
                    </div>
                    <LinearProgress className={classes.bar} variant="determinate" value={fastcardsProgress} color="primary" />
                </div>

                {/*<div className={classes.barCnt}>*/}
                {/*    <div className={classes.barLabelCnt}>*/}
                {/*        <Typography variant="caption">{I18n.get("_visits", "Visits")}</Typography>*/}
                {/*        <Typography variant="caption">{`${userModel.creditData?.dataSummary?.creditsConsumed}/${flags?.maxVisits}`}</Typography>*/}
                {/*    </div>*/}
                {/*    <LinearProgress className={classes.bar} variant="determinate" value={visitsProgress} color="primary" />*/}
                {/*</div>*/}

                <div className={classes.footer}>
                    <Tooltip title={"Learn about our plans"}>
                        <Button style={{ color: 'white' }} variant="text" href={baseUrl + 'fastcard/faq/billing-pricing'} onClick={handleInfoIconClick} target="_blank" rel="noopener noreferrer">
                            <HelpIcon style={{ fontSize: 16, marginRight: 4 }} />
                            {I18n.get("_learn_more")}
                        </Button>
                    </Tooltip>
                    <Button classes={{ root: classes.btn }} onClick={handleUpgrade}>{I18n.get("_upgrade")}</Button>
                </div>
            </Paper>
        </Popover>
    )
})

CreditProgressPopover.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleUpgrade: PropTypes.func.isRequired
}

export default observer(CreditProgressPopover);