

export const endpointName =  {
    base: "fastShare",
    payment: "fastCardPayment",
    auth: process.env.REACT_APP_API_AUTH_ENDPOINT,
};


export const endpoint = {
    base: process.env.REACT_APP_API_ENDPOINT,
    payment: process.env.REACT_APP_API_PAYMENT_ENDPOINT,
    auth: process.env.REACT_APP_API_AUTH_ENDPOINT,
};