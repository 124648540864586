import React, {Component} from 'react';
import Typography from "@material-ui/core/Typography";
import {API, Auth, I18n} from "aws-amplify";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import {withStyles} from "@material-ui/core/styles";
import { styles } from "./styles";
import lodashGet from "lodash/get";
import * as Sentry from "@sentry/react";

class SocialSearchFilterInput extends Component {

    constructor(props){
        super(props);
        let { actionModel } = this.props;

        this.state = {
            loading:false,
            hashtags:[],
            mentions: [],
            defaultHashtags:lodashGet(actionModel, 'socialSearchData.hashtags',[]),
            defaultMentions: lodashGet(actionModel, 'socialSearchData.mentions',[])

        }
    }
    componentDidMount() {

        this.fetchChipData()
    }


   fetchChipData = () => {

        let self = this;

       let path = '/actions/socialSearch/searchMetaData';
       let apiName = 'fastShare';
       self.setState({ loading: true})
        Auth.currentAuthenticatedUser({ bypassCache: false })
            .then( currentUser => {

                let data = {
                    "userID": lodashGet(currentUser,'attributes.sub'),
                    "limit": 40
                }
                API.post(apiName, path, { body: { ...data } })
                    .then(response => {
                        let hashtags = lodashGet(response, "dataSummary.hashtags", []).map((item, index) => { return {title: item.name, key: index, label: item.name } })
                        let mentions = lodashGet(response, "dataSummary.mentions", []).map((item, index) => { return {title: item.name, key: index, label: item.name } })
                        self.setState({hashtags,mentions})
                    })
                    .catch(error => {
                        Sentry.captureException(error);
                    })
                    .finally(()=>{
                        self.setState({loading:false})
                    })
            })
            .catch((err)=>{
                self.setState({ loading: false})
            })
    }

    handleChange =(event, values, reason, location)=>{
        let { actionModel } = this.props;
        actionModel.socialSearchData[location] = values;
    }

    render() {

        let { hashtags, mentions, defaultMentions, defaultHashtags, loading } = this.state;

        return (
            <div style={{ marginTop: 10 }}>
                <Typography style={{ marginBottom: '8px' }}>{I18n.get('_social_search_filter_text')}:</Typography>
                <Autocomplete
                    multiple
                    disabled={loading}
                    id="tags-filled-hashtags"
                    options={hashtags.map((option) => option.label)}
                    onChange={(event, values, reason)=>this.handleChange(event, values, reason,"hashtags")}
                    defaultValue={defaultHashtags}
                    freeSolo={true}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip label={option} {...getTagProps({ index })} />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" label={I18n.get('_hashtags')} placeholder={I18n.get('_hashtags')} />
                    )}
                />

                <Autocomplete
                    multiple
                    disabled={loading}
                    style={{ marginTop: 10 }}
                    id="tags-filled-mentions"
                    options={mentions.map((option) => option.label)}
                    onChange={(event, values, reason)=>this.handleChange(event, values, reason,"mentions")}
                    defaultValue={defaultMentions}
                    freeSolo={true}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip label={option} {...getTagProps({ index })} />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" label={I18n.get('_mentions')} placeholder={I18n.get('_mentions')} />
                    )}
                />

            </div>
        );
    }
}

export default  withStyles(styles)(SocialSearchFilterInput);