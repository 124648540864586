import React, { forwardRef } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import { useStyles } from './styles';
import { I18n } from 'aws-amplify';
import _get from 'lodash/get'
import { useFlags } from "launchdarkly-react-client-sdk";
import { Button, CircularProgress, Divider, List, MenuList, MenuItem, ListItemIcon, Paper, Popover, Typography } from "@material-ui/core";
import WorkSpaceListItem from "../WorkSpaceListItem/WorkSpaceListItem";
import { useUserModel } from '../../../../hooks';
import CreditProgressBar from '../../CreditProgressBar/CreditProgressBar';
import {adminProtocolStorage} from "../../../../constants/data-fetching";

const WorkSpacePopover = forwardRef(({ open, handleClose, WorkSpacesModel, UserModel, handleSelect, goToWorkSpaces, goToSettings, signOut, adminSignOut }, ref) => {

    const classes = useStyles();
    const flags = useFlags();
    const userModel = useUserModel();

    function handleFeedbackClicked() {
        window.Canny('identify', {
            appID: '5d14ff21c9b59a5cea880342',
            user: {
                email: userModel.currentUser.attributes.email,
                name: userModel.currentUser.attributes['given_name'],
                id: userModel.currentUser.attributes.sub,
                avatarURL: userModel.profilePicLink
            },
        });
    }

    return (
        <Popover
            open={open}
            style={{ marginTop: 10 }}
            anchorEl={ref}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}>

            <Paper className={classes.paper}>

                {flags['workspaceAccess'] && <>

                    <Typography style={{ width: '100%', marginLeft: 10 }}>{I18n.get("_select_workspace", "Select a workspace")}</Typography>

                    <Divider />
                    <List dense component="div" role="list">
                        {WorkSpacesModel.loadingWorkSpaces && <CircularProgress size={20} />}
                        {WorkSpacesModel.items.map(item => {
                            return (
                                <WorkSpaceListItem
                                    item={item}
                                    key={item.workspace_ID}
                                    UserModel={UserModel}
                                    isPersonal={item.workspace_ID === _get(WorkSpacesModel, 'workSpaces.userID')}
                                    onWorkSpaceSelected={(id) => handleSelect(id)} />
                            )
                        })}
                    </List>
                    <Button
                        classes={{ root: classes.btn }}
                        fullWidth={true}
                        onClick={goToWorkSpaces}>{I18n.get("_manage_workspaces")}</Button>

                    <Divider className={classes.divider} />
                </>}
                <MenuList>
                    <MenuItem onClick={goToSettings}>
                        <ListItemIcon className={classes.itemIcon}>
                            <SettingsIcon style={{ color: 'white' }} />
                        </ListItemIcon>
                        <Typography variant="inherit">{I18n.get('_profile')}</Typography>
                    </MenuItem>
                    <MenuItem onClick={signOut}>
                        <ListItemIcon className={classes.itemIcon}>
                            <ExitToAppIcon style={{ color: 'white' }} />
                        </ListItemIcon>
                        <Typography variant="inherit">{I18n.get('_sign_out')}</Typography>
                    </MenuItem>

                    {window.sessionStorage && window.sessionStorage.getItem(adminProtocolStorage) &&
                        <div>
                            <Divider style={{ marginTop: 14, backgroundColor:'white'}} />
                            <MenuItem onClick={adminSignOut}>
                                <ListItemIcon className={classes.itemIcon}>
                                    <ExitToAppIcon style={{ color: 'white' }} />
                                </ListItemIcon>
                                <Typography variant="inherit">{I18n.get('_admin_sign_out')}</Typography>
                            </MenuItem>
                        </div>
                    }
                </MenuList>

                <div className={classes.progressContainer}>
                    <Divider style={{ backgroundColor: 'white', width: '100%', margin: '8px 0 16px', height: '1px' }} />
                    <CreditProgressBar />
                </div>
            </Paper>
        </Popover>
    )
})

WorkSpacePopover.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default observer(WorkSpacePopover);