import './styles.css';
import '../additions/css/3rd-parties.css';

import React, { Component, Fragment, Suspense, lazy } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { Provider } from "mobx-react"
import {Auth, Hub, I18n} from 'aws-amplify';
import Header from '../components/Header/Header';
import { adminProtocolStorage, adminProtocolName, tierStorage, inviteQuery } from '../constants/data-fetching'
import CustomSignUp from '../Pages/AuthUI/CustomSignUp/CustomSignUp';
// import CustomSignIn from '../Pages/AuthUI/CustomSignIn/CustomSignIn';
// import CustomConfirmSignUp from '../Pages/AuthUI/CustomConfirmSignUp/CustomConfirmSignup';
import { Main } from './styles';
// import CustomForgotPassword from '../Pages/AuthUI/CustomForgotPassword/CustomForgotPassword';
import Home from '../Pages/Home/Home';
import en from '../additions/languages/en.json';
// import pt from '../additions/languages/pt.json';
import es from '../additions/languages/es.json';
import CardModel from '../models/CardModel/CardModel';
import CardsDataModel from '../models/CardsDataModel/CardsDataModel';
import CssBaseline from '@material-ui/core/CssBaseline';
import UserModel from '../models/UserModel/UserModel';
import CampaignsModel from '../models/CampaignsModel/CampaignsModel';
import {Authenticator, CheckboxField, components, Heading, useAuthenticator, View} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import '../Pages/AuthUI/CustomAuthenticatorUI/global-styles.css';
import {pageView, sendEvent} from '../utils/eventLog'
import { isfUserFirstTimeAfterSignUp, parseURLParameters } from "../utils/functions";
import lodashGet from 'lodash/get'
import { initializeFirebase } from "../utils/firebase";
import mixpanel from "mixpanel-browser";
import CircularProgress from "@material-ui/core/CircularProgress";
// import '../API/index';
import LaunchDarkly from "./LaunchDarkly";
import PayWall from "./PayWall";
import {withLDConsumer, withLDProvider} from 'launchdarkly-react-client-sdk';
import WorkSpacesModel from "../models/workSpacesModel/workSpacesModel";
import InviteDialog from "./InviteDialog";
import EventEmitterProvider from "../providers/EventEmitterProvider";
import FastLinksModel from "../models/FastLinksModel/FastLinksModel";
import GlobalSnackBar from "../components/GlobalSnackBar/GlobalSnackBar";
import Button from "@material-ui/core/Button";
import CustomAuthHeader from "../Pages/AuthUI/CustomAuthenticatorUI/customAuthHeader";
import {CustomAuthFooter} from "../Pages/AuthUI/CustomAuthenticatorUI/customAuthFooter";
import Typography from "@material-ui/core/Typography";
import {CustomSignInHeader} from "../Pages/AuthUI/CustomSignIn/CustomSignInHeader";
import CustomSignInFooter from "../Pages/AuthUI/CustomSignIn/CustomSignInFooter";
import CustomSignUpFooter from "../Pages/AuthUI/CustomSignUp/CustomSignUpFooter";
import {CustomSignUpHeader} from "../Pages/AuthUI/CustomSignUp/customSignUpHeader";
import ReCAPTCHA from "react-google-recaptcha";
import CustomSignUpFields from "../Pages/AuthUI/CustomSignUp/CustomSignUpFormField";

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // window.LOG_LEVEL = 'DEBUG';
}

const asyncNewAnalytics = lazy(() => import('../Pages/Analytics/TimeSeriesAnalytics'));
const asyncBuilder = lazy(() => import('../Pages/CardBuilder/CardBuilder'));
const asyncShowcase = lazy(() => import('../Pages/Showcase/Showcase'));
const asyncCampaigns = lazy(() => import('../Pages/Campaigns/Campaigns'));
const asyncSegments = lazy(() => import('../Pages/FastLinks/Segments'));
const asyncFastLink = lazy(() => import('../Pages/FastLinks/FastLinks'));
const asyncWorkSpaces = lazy(() => import('../Pages/WorkSpaces/WorkSpaces'));
const asyncCreateWorkSpace = lazy(() => import('../Pages/WorkSpaces/CreateWorkspace/CreateWorkspace'));
const asyncCreateCampaign = lazy(() => import('../Pages/Campaigns/CreateCampaign/CreateCampaignFn'));
const asyncCreateFastLink = lazy(() => import('../Pages/FastLinks/CreateFastLink/CreateFastLink'));
const asyncUpdateFastLink = lazy(() => import('../Pages/FastLinks/EditFastLink/EditFastLink'));
const asyncCreateSegment = lazy(() => import('../Pages/FastLinks/CreateSegment/CreateSegment'));
const asyncCampaignDetails = lazy(() => import('../Pages/Campaigns/CampaignDetails/CampaignDetails'));
const asyncTemplates = lazy(() => import('../Pages/Templates/Templates'));
const asyncSettings = lazy(() => import('../Pages/Settings/Settings'));


let dict = {
  'es': es,
  'en': en,
  // 'pt': pt
};
class App extends Component {



  constructor(props) {
    super(props);

    this.cardModel = new CardModel();
    this.userModel = UserModel;
    this.cardsDataModel = new CardsDataModel();
    this.FastLinksModel = new FastLinksModel();
    this.campaignsModel = new CampaignsModel();
    this.WorkSpacesModel = new WorkSpacesModel();

    I18n.putVocabularies(dict);
  }



  componentDidMount() {
    let { history } = this.props;
    Auth.currentAuthenticatedUser({ bypassCache: true })
        .then(currentUser => {
          let localeData = this.getLocaleData()
          let lang = lodashGet(currentUser,'attributes.locale',dict.hasOwnProperty(localeData[0]) ? localeData[0] : 'en')
          I18n.setLanguage(lang);
        })
        .catch(error => {
        })
    this.overWatchProtocol();
    initializeFirebase();
    mixpanel.init("facee94ef061e693ffbcfd154694336d");
    this.checkOutRedirect();
    this.checkInvite();

    history.listen((location, action) => {
      // console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`)
      // console.log(`The last navigation action was ${action}`)
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  checkInvite() {
    const queryItems = parseURLParameters(this.props.location.search);
    const invitation = lodashGet(queryItems, inviteQuery);
    if (invitation) {
      Hub.dispatch('fcInvite', {
        data: this.props.location.search,
        event: 'WorkSpaceInvitation',
      });
    }
  }

  checkOutRedirect() {

    const queryItems = parseURLParameters(this.props.location.search);
    const tier = lodashGet(queryItems, tierStorage);
    if (tier) {
      Hub.dispatch('fcPayWall', {
        data: this.props.location.search,
        event: 'checkOut',
      });
    }
  }

  overWatchProtocol() {

    const queryItems = parseURLParameters(this.props.location.search);
    const overWatchProtocol = lodashGet(queryItems, adminProtocolName);

    if (window.sessionStorage && overWatchProtocol) {
      window.sessionStorage.setItem(adminProtocolStorage, overWatchProtocol);
    }
  }

  onRouteChanged() {
    pageView(this.props.location.pathname);
    this.overWatchProtocol();
  }

  getLocaleData = () => {
    let userLang = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
    return userLang.indexOf("_") !== -1 ? userLang.split("_") : userLang.split("-");
  };

  loader = () => {
    return <div style={{
      height: '100vh',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}><CircularProgress /> </div>;
  };

  components = {
    Header : CustomAuthHeader, // your global header component
    Footer: CustomAuthFooter, // your global footer component

    SignIn: {
      Header : CustomSignInHeader, // Sign in header component
      Footer: CustomSignInFooter, // Sign in footer component
    },
    SignUp: {
      Header: CustomSignUpHeader,
      FormFields:CustomSignUpFields,
      Footer: CustomSignUpFooter
    }
  };

  services = {
    async validateCustomSignUp(formData) {

      if (!formData['custom:type']) {
        return {
          'custom:type': 'Please select a type',
        };
      }
    },
  }

  formFields = {
    signIn: {
      username: {
        label: I18n.get("_email"),
        placeholder: I18n.get("_email_placeholder"),
      },
    },
    signUp: {
        given_name: {
          label: I18n.get("_first_name"),
          placeholder: I18n.get("_first_name"),
          order:1,
          required:true
        },
        family_name: {
            label: I18n.get("_last_name"),
            placeholder: I18n.get("_last_name"),
            order:2,
            required:true
        },
        username: {
            label: I18n.get("_email"),
            placeholder: I18n.get("_email_placeholder"),
            order:3,
            required:true
        },
        password: {
            order:4,
            required:true
        },
        confirm_password: {
            order:5,
            required:true
        },
    }
  }
  render() {

    return (
    <Authenticator
        // initialState={'signUp'}
        socialProviders={['google', 'facebook']}
        components={this.components}
        services={this.services}
        formFields={this.formFields}>
        <EventEmitterProvider>
          <Provider
            model={this.cardModel}
            CampaignsModel={this.campaignsModel}
            FastLinksModel={this.FastLinksModel}
            WorkSpacesModel={this.WorkSpacesModel}
            CardsDataModel={this.cardsDataModel}
            UserModel={this.userModel}>
            <Fragment>
              <PayWall />
              <GlobalSnackBar/>
              <InviteDialog />
              <LaunchDarkly />
              <CssBaseline />
              <Header toggleTheme={this.props.toggleTheme} />
              <Main>
                <Suspense fallback={this.loader()}>
                  <Switch>
                    <Route exact path="/" name="Home" render={() => (isfUserFirstTimeAfterSignUp() ? (<Redirect to="/create/card" />) : (<Home />))} />
                    <Route exact path="/analytics/:cardId" name="Analytics" component={asyncNewAnalytics} />
                    <Route exact path="/settings/:panel" name="Settings" component={asyncSettings} />
                    <Route exact path="/create/card/:cardId" name="Builder" component={asyncBuilder} />
                    <Route exact path="/create/card" name="Builder" component={asyncBuilder} />
                    <Route exact path="/create" name="Templates" component={asyncTemplates} />
                    <Route exact path="/showcase" name="Showcase" component={asyncShowcase} />

                    {/*<Route exact path="/calculator" name="Calculator" component={asyncCalculator} />*/}

                    <Route exact path="/campaigns/create" name="Create Campaign" component={asyncCreateCampaign} />
                    <Route exact path="/campaigns/:campaignId" name="Campaigns Details" component={asyncCampaignDetails} />
                    <Route exact path="/campaigns" name="Campaigns" component={asyncCampaigns} />

                    <Route exact path="/fastlinks" name="Fastlinks" component={asyncFastLink} />
                    <Route exact path="/fastlink/create" name="Create FastLink" component={asyncCreateFastLink} />
                    <Route exact path="/fastlink/edit/:cardId" name="Edit FastLink" component={asyncUpdateFastLink} />

                    {/*The segment Details*/}
                    <Route exact path="/fastlinks/:cardId" name="FastLinks Details" component={asyncSegments} />
                    <Route exact path="/fastlinks/:cardId/segment/create" name="Segment create" component={asyncCreateSegment} />
                    <Route exact path="/fastlinks/:cardId/segment/edit/:segmentId" name="FastLink Edit Details" component={asyncCreateSegment} />

                    { lodashGet(this.props,'flags.workspaceAccess',false) && <Route exact path="/workspaces" name="Campaigns" component={asyncWorkSpaces} />}
                    { lodashGet(this.props,'flags.workspaceAccess',false) && <Route exact path="/workspaces/create" name="Create Campaigns" component={asyncCreateWorkSpace} />}
                    <Redirect to='/' />
                  </Switch>
                </Suspense>
              </Main>
            </Fragment>
          </Provider>
        </EventEmitterProvider>
    </Authenticator>
    );
  }
}

export default withRouter(withLDProvider({ clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_KEY })(withLDConsumer()(App)));
