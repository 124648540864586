import { decorate, observable } from "mobx";
import ValidationModel from "../../../../../models/ValidationModel/ValidationModel";
import ValidationRulePattern from "../../../../../models/ValidationModel/ValidationRulePattern";
import { I18n } from 'aws-amplify';

class EmailContact extends ValidationModel {
    value = ''
    type = 'home'

    constructor(data) {
        super();
        if (data) {
            this.value = data.value;
            this.type = data.type;
        }
    }

    getRules() {
        return {
            value: {
                format: {
                    pattern: ValidationRulePattern.EMAIL_OR_EMPTY,
                    message: I18n.get("_action_wrong_email")
                }
            }
        };
    }
}

decorate(EmailContact, {
    value: observable,
    type: observable,
})

export default EmailContact
