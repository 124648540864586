import React from 'react';
import { observer } from "mobx-react";
import { I18n } from 'aws-amplify';
import TextInput from "../TextInput/TextInput";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddBox';
import IconButton from "@material-ui/core/IconButton";
import { useStyles } from './styles';
import SelectInput from "../SelectInput/SelectInput";

const ContactKeyValueInput = (props) => {

  const classes = useStyles();
  const { cardModel, values, onAdd, onRemove, valueLabel, labelOptions } = props;

  return (

    <div className={classes.componentContainer}>
      {values.map((value, index) =>
        <div key={index} className={classes.rowContainer}>
          <TextInput
            type={'text'}
            field={'value'}
            label={valueLabel}
            store={value}
            disabled={cardModel.loading}
            autoComplete="off"
            classes={{ root: classes.valueField }}
            inputProps={{
              'data-testid': `value-${index}`
            }}
          />
          <SelectInput
            label={I18n.get("_label", "Label")}
            store={value}
            field={'type'}
            options={labelOptions}
            classes={{ root: classes.selectType }}
            disabled={cardModel.loading}
          />
          <div className={classes.btnRowGroupContainer}>
            <div className={classes.btn}>
              <IconButton
                classes={{ root: classes.iconDelete }}
                size="small"
                disabled={values.length === 1 || cardModel.loading}
                onClick={() => { if (values.length > 1) onRemove(index) }}
                data-testid={`key-delete-btn-item${index}`}
              >
                <DeleteIcon />
              </IconButton>
            </div>
            <div className={classes.btn}>
              <IconButton
                classes={{ root: classes.iconDelete }}
                size="small"
                onClick={() => onAdd(index)}
                disabled={cardModel.loading}
                data-testid={`key-add-btn-item${index}`}
              >
                <AddIcon />
              </IconButton>
            </div>
          </div>
        </div>
      )}
    </div>
  )

}


export default observer(ContactKeyValueInput);
