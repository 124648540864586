import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
    paper: {
        padding: 10,
        width: 280,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.text.primary,
        color: 'white'

    },
    btn: {
        background: 'linear-gradient(45deg, rgb(139,58,248) 30%, rgb(19,189,212) 90%)',
        border: 0,
        borderRadius: 3,
        color: 'white',
        height: 36,
        marginTop: 20,
        padding: '0 30px',
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '20px',
        width: '100%'
    },
    divider: {
        marginTop: 20,
        backgroundColor: theme.palette.text.secondary
    },
    itemIcon: {
        minWidth: 44
    },
    progressContainer: {
        display: 'block',
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    }
}));