import React from 'react';
import { observable, decorate } from "mobx";
import CardContent from '@material-ui/core/CardContent';
import { I18n } from 'aws-amplify';
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import UserName from '../../NewAction/inputs/UsernameInput/UsernameInput'
import NewActionHeader from '../../NewAction/NewActionHeader/NewActionHeader';

class InstagramAction extends ActionModel {
	static metadata = {
		id: "instagram",
		name: "Instagram",
		icon: "https://l.fast.cm/content/icons/instagram.png",
		categories: {
			// social: true
		}
	};

	Title = "Instagram";
	Link = "https://www.instagram.com/"
	ButtonText = "GO";
	isValid = false;
	destId = "";

	constructor(dat) {
		super();
		this.propNames.push("destId");
		this.setFromObject(dat);
	}


	getConfigCardBody(cardModel, handleOnChangeIcon) {
		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />
				<UserName
					placeHolderText={I18n.get("_username")}
					cardModel={cardModel}
					actionModel={this} />
			</CardContent>
		)
	}

	getRules() {
		return {
			destId: {
				presence: { allowEmpty: false, message: I18n.get("_required") },
			},
			...super.getRules()
		}
	}
}

decorate(InstagramAction, {
	Link: observable,
	destId: observable,
	Title: observable,
	ButtonText: observable,
	isValid: observable
})

export default InstagramAction
