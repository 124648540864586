import React from 'react';
import { observer } from "mobx-react";
import { I18n } from 'aws-amplify';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { ActionIcon } from './styles';
import { useOnboardingModel } from '../../../../../../hooks';

const IconInput = props => {
	const { actionModel } = props;
	const onboardingModel = useOnboardingModel();

	function handleClickOnChange() {
		props.onChangeIconClicked()

		if (onboardingModel.open) {
			onboardingModel.blockStep(false)
			onboardingModel.nextStep();
		}
	}

	return (
		<div data-tut="reactour__actions-icon">
			<ActionIcon onClick={handleClickOnChange}
				src={actionModel.Image || actionModel.constructor.metadata.icon} alt='action icon' />
			<Typography
				style={{ fontSize: '10px', cursor: 'pointer' }}
				variant="body2"
				color="primary"
				onClick={handleClickOnChange}> {I18n.get('_change')}</Typography>
		</div>
	)
}

IconInput.propTypes = {
	cardModel: PropTypes.object.isRequired,
	actionModel: PropTypes.object.isRequired,
	onChangeIconClicked: PropTypes.func.isRequired
}

export default observer(IconInput);