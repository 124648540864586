import React from "react";
import { action, decorate, flow, observable } from "mobx";
import { I18n } from "aws-amplify";
import { Storage } from 'aws-amplify';
import CardContent from '@material-ui/core/CardContent';
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import NewActionHeader from '../../NewAction/NewActionHeader/NewActionHeader';
import * as Sentry from "@sentry/react";
import FileInput from "../../NewAction/inputs/FileInput/FileInput";
import {
	ListItem,
	ServiceActionButton,
	ServiceActionButtonText,
	ServiceSubtitle,
	ServiceThumbnail,
	ServiceTitle,
	TextContainer
} from "../../../../../models/ActionModel/styles";

class FileAction extends ActionModel {
	static metadata = {
		id: "file",
		name: "File Access",
		icon: "https://l.fast.cm/content/icons/file.png",
		categories: {
			general: true
		},
		help: {
			id:"articles/all-about-file-access",
		}
	};

	name = "";
	ButtonText = I18n.get("_view", "VIEW");
	s3key = "";
	size = 0;

	constructor(dat) {
		super();
		this.propNames.push("name", "s3key");
		this.setFromObject(dat);
	}

	uploadFile = flow(function* (file, path, cb) {

		try {
			let result = yield Storage.put(path, file, {
				level: 'public',
				contentDisposition: `attachment; filename="${file.name}"`, //https://docs.amplify.aws/lib/storage/upload/q/platform/js#put
				})

			this.s3key = result.key;
			this.Link = `https://s3.amazonaws.com/fastlinkuploads/public/${path}`;
			this.name = file.name;
		} catch (err) {
			Sentry.captureException(err);
		} finally {
			cb()
		}
	});

	removeFile = flow(function* (cb) {
		try {
			yield Storage.remove(this.s3key)
			this.s3key = ""
			this.Link = ""
			this.name = ""
		} catch (err) {
			Sentry.captureException(err);
		} finally {
			cb()
		}
	});


	getConfigCardBody(cardModel, handleOnChangeIcon) {
		return (<CardContent>
			<NewActionHeader
				onChangeIcon={handleOnChangeIcon}
				actionModel={this} />

			<FileInput actionModel={this} cardModel={cardModel} />

		</CardContent>)

	}

	RenderItem = (cardModel, index, theme, containerClassName) => {
		let isLast = cardModel.LinksList.length === 0 ? true : (cardModel.LinksList.length - 1 === index);
		let image = this.Image || this.constructor.metadata.icon;
		return (
			<ListItem
				failed={false}
				last={isLast}
				theme={theme}
				key={index}
				className={containerClassName}>
				{
					this._ImageIsFromUs ? (
						<ServiceThumbnail
							theme={theme}
							loading="lazy"
							src={`${image}?width=80&height=80`}
							srcSet={`${image}?width=50&height=50 1x,
							 		 ${image}?width=80&height=80 2x,
							 		 ${image}?width=100&height=100 3x`
							}
							alt={this.Title || this.constructor.metadata.name} />
					) : (
						<ServiceThumbnail
							theme={theme}
							loading="lazy"
							src={image}
							alt={this.Title || this.constructor.metadata.name} />
					)
				}

				<TextContainer>
					<ServiceTitle theme={theme}>{this.Title || this.constructor.metadata.name}</ServiceTitle>
					<ServiceSubtitle theme={theme}>{this.Subtitle || this.constructor.metadata.subtitle}</ServiceSubtitle>
				</TextContainer>

				<ServiceActionButton theme={theme} failed={false} onClick={() => this.donwload(this)}>
					<ServiceActionButtonText theme={theme}>
						<div>{(this.ButtonText).toUpperCase()}</div>
					</ServiceActionButtonText>
				</ServiceActionButton>
			</ListItem>
		);
	};

	getRules() {
		return {
			...super.getRules()
		}
	}

	async donwload(fileAction) {
		let result = await Storage.get(this.s3key, { download: true })

		const url = window.URL.createObjectURL(result.Body);
		const a = document.createElement('a');
		a.href = url;
		a.download = this.name;
		const clickHandler = () => {
			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				a.removeEventListener('click', clickHandler);
			}, 150);
		};
		a.addEventListener('click', clickHandler, false);
		a.click();
	}
};

decorate(FileAction, {
	name: observable,
	ButtonText: observable,
	s3key: observable
})

export default FileAction
