import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { CardContent } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import PropTypes from "prop-types";
import {I18n} from "aws-amplify";

// import { withStyles } from '@material-ui/core/styles';
// import { purple } from '@material-ui/core/colors';

const grid = 8;

const getItemStyle = (color, isDragging, draggableStyle) => {

    return {
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        // padding: grid,
        margin: `0 0 ${grid * 2}px 0`,
        borderRadius: 5,
        // change background colour if dragging
        // background: isDragging ? ColorLuminance(color, 0.6) : color,
        // styles we need to apply on draggables
        ...draggableStyle
    }
};


// const PurpleSwitch = withStyles({
//     switchBase: {
//         color: props => purple[300],
//         '&$checked': {
//             color: purple[500],
//         },
//         '&$checked + $track': {
//             backgroundColor: purple[500],
//         },
//     },
//     checked: {},
//     track: {},
// })(Switch);

class FieldItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            colorAssignment: this.props.colorAssignment
        };
    }
    handledSwitchChange = (event, index) => {
        this.props.handledRequired(event, index);
    };
    render() {
        let { provided, item, snapshot, index } = this.props;

        return (
            <div>
                <Card
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                        ...getItemStyle(
                            this.state.colorAssignment[item.id],
                            snapshot.isDragging,
                            provided.draggableProps.style
                        )
                    }
                    }>

                    <div style={{ padding: 4, color: 'white', backgroundColor: this.state.colorAssignment[item.id] }}>
                        <Typography variant={'subtitle2'}>{this.props.title || item.title}</Typography>
                    </div>
                    <CardContent style={{ backgroundColor: '#f5f5f5', padding: 6 }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <FormControlLabel
                                value="start"
                                style={{ marginLeft: 0 }}
                                control={
                                    <Switch
                                        color={'primary'}
                                        custom={this.state.colorAssignment[item.id]}
                                        disabled={this.props.disableControls}
                                        checked={item.required}
                                        onChange={(event) => this.handledSwitchChange(event, index)} name={item.id} />}
                                label={I18n.get("_form_field_required", "Required: ")}
                                labelPlacement="start" />
                        </div>
                    </CardContent>
                </Card>

            </div>
        );
    }
}

FieldItem.propTypes = {
    handledRequired: PropTypes.func.isRequired,
    provided: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    snapshot: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    colorAssignment: PropTypes.object.isRequired
};


export default FieldItem
