import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
    },
    textImgSize: {
        fontSize: 10
    },
    dropcontent: {
        outlineStyle: 'none'
    },
    fileContainer: {
        justifyContent: "space-between",
        alignItems: "center",
        padding: '6px 8px',
        marginBottom: 5,
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        borderRadius: theme.shape.borderRadius,
        border: `1px solid rgba(0, 0, 0, 0.23)`,
        display: 'flex'
    },
    fileName: {
        marginLeft: 20
    }
}));
