import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { inject, observer } from 'mobx-react';
import { I18n } from 'aws-amplify';

class YoutubeInput extends Component {

    state = {
        value: "",
        message: "",
        error: false,
    }

    onChange = (event) => {

        this.props.model.YoutubeID = "";
        this.props.model.Image = "";

        const _value = event.target.value;

        this.setState({
            value: _value
        })

        // let pattern = "(?:youtube(?:-nocookie)?\.com/(?:[^/]+/.+/|(?:v|e(?:mbed)?)/|.*[?&]v=)|youtu\.be/)([^&?/\s]{11})"
        let pattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        let matches = _value.match(pattern);

        if (matches && matches[1]) {
            this.props.model.YoutubeID = matches[1];
            this.setState({
                error: false,
                message: ""
            })
        } else {
            this.setState({
                error: true,
                message: "Invalid link"
            })
        }
    };

    render() {

        let youtubeValue = this.props.model.YoutubeID !== "" ? `https://youtu.be/${this.props.model.YoutubeID}` : ""

        return (

            <TextField
                fullWidth
                placeholder={"https://www.youtube.com/watch?v=8j8oDOVBWkM"}
                label={I18n.get("_youtube_link_label", "Youtube Link")}
                required
                error={this.state.error}
                helperText={this.state.message}
                value={this.state.value || youtubeValue}
                margin="dense"
                variant="outlined"
                onChange={this.onChange}
                inputProps={{
                    'data-testid': 'link'
                }}
            />
        );
    }

}

export default inject('model')(observer(YoutubeInput));
