import React from 'react';
import { decorate, observable } from "mobx";
import CardContent from '@material-ui/core/CardContent';
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import NewActionHeader from '../../NewAction/NewActionHeader/NewActionHeader';
import { I18n } from 'aws-amplify';
import TextInput from "../../NewAction/inputs/TextInput/TextInput";


class OpentableAction extends ActionModel {

	static metadata = {
		id: "opentable",
		name: "OpenTable",
		icon: "https://l.fast.cm/content/icons/opentable.png",
		categories: {
			food: true,
			general: true,
			restaurant:true
		}
	};

	Title = "OpenTable";
	Link = "https://www.opentable.com/"
	ButtonText = "GO";
	isValid = false
	restaurantId = "";

	constructor(dat) {
		super();
		this.propNames.push("restaurantId");
		this.setFromObject(dat);
	}

	onValidateUrl = () => {
		this.isValid = true
	}

	getConfigCardBody(cardModel, handleOnChangeIcon) {
		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />

				<TextInput
					type={'text'}
					field={'restaurantId'}
					label={I18n.get("_action_restaurantId")}
					store={this}
					disabled={cardModel.loading}
					showRequiredHelperText={I18n.get("_required", "Required")}
					inputProps={{
						'data-testid': 'link'
					}}
				/>
			</CardContent>
		)
	}

	getRules() {
		return {
			restaurantId: {
				presence: { allowEmpty: false },
			},
			...super.getRules()
		}
	}
}

decorate(OpentableAction, {
	Title: observable,
	Link: observable,
	ButtonText: observable,
	isValid: observable,
	restaurantId: observable,
})

export default OpentableAction
