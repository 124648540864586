import { useModels } from "./useModels";
import { useObserver } from 'mobx-react'


export function useCardModel() {
    const { model } = useModels();

    return useObserver(() => ({
        Title: model.Title,
        Subtitle: model.Subtitle,
        ShareText: model.ShareText,
        showShareText: model.showShareText,
        showBranding: model.showBranding,
        autoTranslate: model.autoTranslate,
        autoOptimized: model.autoOptimized,
        autoPlayYoutube: model.autoPlayYoutube,
        openNewTab: model.openNewTab,
        autoRedirect: model.autoRedirect,
        country: model.country,
        facebookPixel: model.facebookPixel,
        tagManagerPixel: model.tagManagerPixel,
        fallbackUrl: model.fallbackUrl,
        Image: model.Image,
        YoutubeID: model.YoutubeID,
        CardUrl: model.CardUrl,
        LinksList: model.LinksList,
        InactiveLinksList: model.InactiveLinksList,
        InvalidActions: model.InvalidActions,
        Card_ID: model.Card_ID,
        Twitter: model.Twitter,
        loading: model.loading,
        TriedBuildingCard: model.TriedBuildingCard,
        ActiveCard: model.ActiveCard,
        AllData: model.AllData,
        isArchived: model.isArchived,
        isAnEdit: model.isAnEdit,
        totalClicks: model.totalClicks,
        totalViews: model.totalViews,
        tags: model.tags,
        customId: model.customId,
        customDomain: model.customDomain,
        theme: model.theme,
        touchedMap: model.touchedMap,
        warningRequirement: model.warningRequirement,
        warningRequirementByCard: model.warningRequirementByCard,
        disclaimerPosition : model.disclaimerPosition,
        disclaimerText : model.disclaimerText,
        disclaimerUrl: model.disclaimerUrl,
        uniqueSelected: model.uniqueSelected,
        options: model.options,
        optionsSelected: model.optionsSelected,
        firstDate: model.firstDate,
        endDate: model.endDate,
        query: model.query,
        presetRange: model.presetRange,
        analytics: model.analytics,
        timeSeriesData: model.timeSeriesData,
        integrations: model.integrations,
        fetchIntegrations: model.fetchIntegrations,
        removeIntegration: model.removeIntegration,
        createIntegration: model.createIntegration,
        validateIntegration: model.validateIntegration,
        setIntegrations: model.setIntegrations,
        setYoutubeByUrl: model.setYoutubeByUrl,
        addLink: model.addLink,
        deleteLink: model.deleteLink,
        reset: model.reset,
        setFromObject: model.setFromObject,
        getCompleteURL: model.getCompleteURL,
        getCardInfo: model.getCardInfo,
        setKey: model.setKey,
        buildActionModel: model.buildActionModel,
        invalidLinkIndex: model.invalidLinkIndex,
        standAloneLinks: model.standAloneLinks,
        removeLink: model.removeLink,
        removeLinks: model.removeLinks,
        isStandAloneLinksEmpty: model.isStandAloneLinksEmpty,
        allActionsDict: model.allActionsDict,
        servicesInactiveLinkList: model.servicesInactiveLinkList,
        setField: model.setField,
        headerCustomHtml: model.headerCustomHtml,
        footerCustomHtml: model.footerCustomHtml,
        languageCode: model.languageCode
    }))
}
