import { useModels } from "./useModels";
import { useObserver } from 'mobx-react';

export function useOnboardingModel() {
    const { onboardingModel } = useModels();

    return useObserver(() => ({
        open: onboardingModel.open,
        currentStep: onboardingModel.currentStep,
        steps: onboardingModel.steps,
        navigationBlocked: onboardingModel.navigationBlocked,
        nextStep: onboardingModel.nextStep,
        prevStep: onboardingModel.prevStep,
        goToStep: onboardingModel.goTo,
        setSteps: onboardingModel.setSteps,
        blockStep: onboardingModel.blockStep,
        setOpen: onboardingModel.setOpen
    }))
}