
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import { I18n } from 'aws-amplify';
import Paper from '@material-ui/core/Paper';
import { styles } from './styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as Sentry from '@sentry/browser'
import { withStyles } from "@material-ui/core/styles";

class ErrorBoundary extends Component {

    state = {
        error: '',
        errorInfo: '',
        hasError: false,
        eventId: ''
    };

    static getDerivedStateFromError = error => {
        return { hasError: true };
    };

    componentDidCatch(error, errorInfo) {
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({ eventId, errorInfo, error });
        })
    }

    handleFeelBack = () => {
        Sentry.showReportDialog({ eventId: this.state.eventId })
    }

    handleTryAgain = () => {
        window.location.reload();
    }

    render() {
        const { hasError, errorInfo } = this.state;
        const { classes } = this.props

        if (hasError) {
            return (
                <Paper className={classes.root}>
                    <Typography color="textPrimary" variant="h6">{I18n.get("_error_boundary_title", "Oops! This is embarrassing")}</Typography>
                    <Typography color="textPrimary" variant="subtitle1">{I18n.get("_error_boundary_message", "Something went wrong loading this component")}</Typography>
                    <Accordion elevation={0}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{I18n.get("_details", "Details")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{errorInfo.componentStack}</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <div className={classes.buttonsContainer}>
                        <Button
                            color="primary"
                            variant="outlined"
                            className={classes.sendFeedbackBttn}
                            onClick={this.handleFeelBack}>{I18n.get("_send_feedback", "Send Feedback")}</Button>
                        <Button
                            style={{ marginRigth: '8px', color: 'white' }}
                            variant="contained"
                            color="primary"
                            onClick={this.handleTryAgain}>
                            {I18n.get("_try_again", "Try Again")}
                        </Button>
                    </div>
                </Paper>
            )
        }

        // next code block goes here
        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default withStyles(styles)(ErrorBoundary);
