import React, { Component } from 'react';
import { API, Hub, I18n } from "aws-amplify";
import Dialog from '@material-ui/core/Dialog';
import lodashGet from 'lodash/get';
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import lodashSnakeCase from 'lodash/snakeCase';
import {withRouter} from "react-router";
import {parseURLParameters} from "../utils/functions";
import Typography from "@material-ui/core/Typography";
import {inject, observer} from "mobx-react";
import {inviteQuery} from "../constants/data-fetching";
const inviteListener = "fcInvite"

class InviteDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            canAccept: false,
            invite_ID:"",
            name:"",
            description:"",
            email:""
        }

        Hub.listen(inviteListener, this.hubListener, `${inviteListener}_listener`);
    }



    hubListener = ({ payload: { event, data } }) => {

        switch (event) {
            case "WorkSpaceInvitation":

                let { WorkSpacesModel } = this.props;
                const queryItems = parseURLParameters(data)
                let invite_ID = lodashGet(queryItems, inviteQuery)

                if(!invite_ID){ return;}

                WorkSpacesModel.getInviteData({invite_ID}, (data)=>{
                    if(!data){ return;}

                    let canAccept = lodashGet(data,'canAccept', false)
                    let email = lodashGet(data, 'inviteEmail')
                    let name = lodashGet(data, 'workspaceInfo.name')
                    let description = lodashGet(data, 'workspaceInfo.description')
                    this.setState({name,description, invite_ID, canAccept,email})
                    this.openDialog();
                })
                break;
            default: break;
        }

    }

    acceptInvitation = ()=>{

        let { WorkSpacesModel } = this.props;

        let {invite_ID} = this.state;

        WorkSpacesModel.acceptInvite({invite_ID},(successful)=>{

            if (successful){
                WorkSpacesModel.fetchWorkSpaces();
                this.closeDialog();
            }else{


            }
        })

    }

    componentWillUnmount() {
        Hub.remove(inviteListener, this.hubListener)
    }

    openDialog = () => {
        this.setState({ open: true })
    }
    closeDialog = () => {
        this.setState({ open: false })
    }

    signOut = () => {
        let { UserModel } = this.props;
        this.closeDialog();
        UserModel.signOut();
    }

    canAcceptRender = () => {

        let { WorkSpacesModel } = this.props;

        return (
            <>
                <DialogTitle>
                    {I18n.get("_workspace_invite_can_accept_title")}
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle2">
                        {this.state.name} - "{this.state.description}".
                    </Typography>
                    <DialogContentText>
                        {I18n.get("_workspace_invite_can_accept")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={WorkSpacesModel.loading}
                        onClick={() => { this.closeDialog() }}
                        color="primary">
                        {I18n.get('_cancel')}
                    </Button>
                    <Button
                        disabled={WorkSpacesModel.loading}
                        onClick={()=>{ this.acceptInvitation()}}
                        color="primary">
                        {I18n.get('_join_workspace')}
                    </Button>
                </DialogActions>
            </>
        )
    }

    cantAcceptRender = () => {

        let { WorkSpacesModel } = this.props;

        return (
        <>
            <DialogTitle>
                {I18n.get("_workspace_invite_cant_accept_title")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {I18n.get("_workspace_invite_cant_accept")} { this.state.email}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={WorkSpacesModel.loading}
                    onClick={() => { this.closeDialog() }}
                    color="primary">
                    {I18n.get('_cancel')}
                </Button>
                <Button
                    disabled={WorkSpacesModel.loading}
                    onClick={()=>{ this.signOut();}}
                    color="primary">
                    {I18n.get('_sign_out')}
                </Button>
            </DialogActions>
        </>
        )
    }




    render() {

        let { canAccept } = this.state;

        return (
            <div>
                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={this.state.open}>
                    { canAccept ? this.canAcceptRender():this.cantAcceptRender()}
                </Dialog>
            </div>
        );
    }
}

export default withRouter(inject("UserModel","WorkSpacesModel")(observer(InviteDialog)));
