import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const Bar = styled.div`
    background-color: ${props => props.color};
    width: ${props => `${Math.floor(props.value * 200)}px`};
    height: 32px;
    display: flex;
    border-radius: 4px;
    align-items: center;
    justify-content: flex-end;
`;

export const styles = theme => ({
    skeleton: {
        backgroundColor: '#424242',
        marginRight: '40px',
        marginBottom: '4px',
        content: 'none !important'
    },
    barContainer: {
        width: 200,
        padding: 2,
        height: 36,
        backgroundColor: '#424242',
        display: 'flex',
        borderRadius: 4,
        alignItems: 'center',
        cursor: 'pointer'
    }
});
