import React, { Component } from 'react';
import Form from "rjsf-material-ui";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from "@material-ui/core/CircularProgress";
import { I18n } from "aws-amplify";

class FastcardAuthForm extends Component {

    state = {
        loading: false,
        formData: null
    }

    handleSubmit = () => {
        let { formSubmit, provider, providerIntegration } = this.props;

        let { authHandler } = providerIntegration

        if (formSubmit) { formSubmit({ providerData: this.state.formData, provider, authHandler }) }
    }

    handleOnChange = (formData) => {
        this.setState({ formData: formData })
    }


    render() {
        let { open, schema, handleClose } = this.props;
        let { loading } = this.state;
        return (
            <div>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            To subscribe to this website, please enter your email address here. We will send updates
                            occasionally.
                        </DialogContentText>

                        <Form
                            // disabled={true}
                            formData={this.state.formData}
                            onChange={e => this.handleOnChange(e.formData)}
                            schema={schema}
                        >
                            <DialogActions>
                                <Button variant={'outlined'} onClick={handleClose} color="primary">
                                    {I18n.get("_cancel")}
                                </Button>
                                <Button onClick={this.handleSubmit} variant="contained" color="primary" style={{ color: 'white' }}>
                                    {loading ? <CircularProgress size="1.5rem" style={{ color: 'white' }} /> : I18n.get("_submit")}
                                </Button>
                            </DialogActions>
                        </Form>
                    </DialogContent>
                    {/*<DialogActions>*/}
                    {/*    <Button*/}
                    {/*        // onClick={handleClose}*/}
                    {/*        color="primary">*/}
                    {/*        Cancel*/}
                    {/*    </Button>*/}
                    {/*    <Button*/}
                    {/*        // onClick={handleClose}*/}
                    {/*        color="primary">*/}
                    {/*        Subscribe*/}
                    {/*    </Button>*/}
                    {/*</DialogActions>*/}
                </Dialog>

            </div>
        );
    }
}

FastcardAuthForm.propTypes = {};

export default FastcardAuthForm;
