
import { observable, decorate, action } from 'mobx'

export class OnboardingModel {

    open = false;
    currentStep = 0;
    steps = [];
    navigationBlocked = false;

    setOpen = (open) => {
        this.open = open
    }

    blockStep = block => {
        this.navigationBlocked = block;
    }

    setSteps = (steps) => {
        this.steps = steps
    }

    nextStep = () => {
        if (this.navigationBlocked) {
            return
        }
        this.currentStep = this.currentStep < this.steps.length - 1 ? this.currentStep + 1 : this.currentStep;
    }

    prevStep = () => {
        if (this.navigationBlocked) {
            return
        }
        this.currentStep = this.currentStep > 0 ? this.currentStep - 1 : this.currentStep;
    }

    goTo = (step) => {
        this.currentStep = step;
    }
}

decorate(OnboardingModel, {
    open: observable,
    currentStep: observable,
    steps: observable,
    navigationBlocked: observable,
    nextStep: action,
    prevStep: action,
    goTo: action,
    blockStep: action,
    setOpen: action
})
