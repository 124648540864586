import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import {API, I18n} from "aws-amplify";
import lodashGet from 'lodash/get'
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Switch from "@material-ui/core/Switch";
import {inject, observer} from "mobx-react";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Button,DialogActions,DialogContentText,DialogTitle,DialogContent, Dialog} from '@material-ui/core/';

class FastmindDataSource extends Component {

    constructor(props){
        super(props);

        let { actionModel } = this.props;

        this.state = {
            isActive : lodashGet(actionModel, 'dataStores.fastmind.active',false),
            hasData:false,
            isDeleting:false,
            openDelete:false,
        }
    }

    componentDidMount() {
        this.FetchData();
    }

    FetchData = ()=>{

        let { actionModel, model } = this.props;

        let path = `/GetFastFormInfo`;
        let apiName = 'fastShare';

        let myInit = {
            body: {
                "Card_ID": model.Card_ID,
                "actionID": actionModel.actionID
            }
        };

        API.post(apiName, path, myInit)
            .then(result => {
                let hasData = lodashGet(result,'data.hasData',false);
                this.setState({hasData});
            })
            .catch(error => {
            });
    }

    deleteFormData = ()=>{
        let { actionModel, model } = this.props;

        let cardID= model.Card_ID;
        let actionID= actionModel.actionID;
        let path = `/RemoveFormData`;
        let apiName = 'fastShare';

        let myInit = {
            body:{
                "Card_ID": cardID,
                "actionID": actionID
            }
        };

        this.setState({isDeleting:true});

        API.post(apiName, path, myInit)
            .then(result => {

            })
            .catch(error => {
            })
            .finally(()=>{
                // let's delay it for 5 seconds
                setTimeout(() => {
                    this.setState({isDeleting:false});
                    this.FetchData();
                },5000)
            });
    }

    fetchDownloadLink =()=>{

        let { actionModel, model } = this.props;

        let cardID= model.Card_ID;
        let actionID= actionModel.actionID;
        let path = `/DownloadFastFormData/${cardID}/${actionID}`;
        let apiName = 'fastShare';

        let myInit = {
            body:{}
        };

        this.setState({loadingDownload:true});

        API.post(apiName, path, myInit)
            .then(result => {

                let url = lodashGet(result,'url');
                if(url){
                    window.open(url)
                }
            })
            .catch(error => {
            })
            .finally(()=>{
                this.setState({loadingDownload:false});
            });
    }

    onChange = (event)=>{

        let { actionModel } = this.props;
        let isActive = !lodashGet(actionModel, 'dataStores.fastmind.active',false)
        actionModel.dataStores.fastmind.active = isActive;
        this.setState({isActive})
    }
    handleClose = ()=>{
        this.setState({openDelete:false});
    }

    handleOpen = ()=>{
        this.setState({openDelete:true});
    }
    handleDelete = ()=>{
        this.handleClose();
        this.deleteFormData();
    }
    render() {

        return (
            <div>

                <Dialog
                    open={this.state.openDelete}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{I18n.get("_are_you_sure")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {I18n.get("_delete_formData_title")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            {I18n.get('_cancel')}
                        </Button>
                        <Button onClick={this.handleDelete} color="primary" autoFocus>
                            {I18n.get('_accept')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <ListItem>
                    <ListItemAvatar>
                        <Avatar
                            alt={`FastCard Logo`}
                            src={"/img/content/apple-icon.png"} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={"FastCard"}
                        // secondary={"Secondary"}
                    />
                    <ListItemSecondaryAction>

                        <IconButton
                            disabled={!this.state.hasData || this.state.loadingDownload}
                            onClick={this.fetchDownloadLink} aria-label="delete">

                            {this.state.loadingDownload?
                            <CircularProgress size="1rem" color={'primary'} />
                            :<DownloadIcon fontSize="small" />}
                        </IconButton>

                        <IconButton
                            disabled={this.state.isActive || this.state.isDeleting || !this.state.hasData}
                            onClick={this.handleOpen} aria-label="delete">

                            {this.state.isDeleting?
                                <CircularProgress size="1rem" color={'primary'} />
                                :<DeleteIcon fontSize="small" />}
                        </IconButton>

                        <Switch
                            edge="end"
                            color="primary"
                            size="small"
                            onChange={this.onChange}
                            checked={this.state.isActive}
                            inputProps={{ 'aria-labelledby': `switch-list-label-Fastcard` }}
                        />

                    </ListItemSecondaryAction>
                </ListItem>
            </div>
        );
    }
}

FastmindDataSource.propTypes = {};

export default inject("model")(observer(FastmindDataSource));
