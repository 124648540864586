import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    picker: {
        width: 120
    },
    flexContainer: {
        display: 'flex',
    },
    formGroup: {
        justifyContent: 'flex-start'
    },
    repeatSelect: {
        marginRight: 8,
        backgroundColor: theme.palette.grey[100],
        padding: "2px 4px"
    },
    timezonesSelect: {
        padding: "2px 4px",
        backgroundColor: theme.palette.background.default
    },
    select: {
        "&:focus": {
            backgroundColor: 'transparent !important'
        }
    },
    alarmSelect: {
        width: '100%'
    },
    alarmBodyCnt: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    alarmCnt: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 20
    },
    alarmBtnCnt: {
        display: "flex",
        width: "60px",
        justifyContent: "center",
        itemsAlign: 'center',
        alignItems: 'center'
    },
    alarmBtn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10
    }
}))
