import makeStyles from "@material-ui/styles/makeStyles";

export const useStyles = makeStyles(theme => ({
    btnAdd: {
        color: 'white'
    },
    fieldContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    valueField: {
        marginRight: '15px',
    },
    selectType: {
        paddingLeft: '10px',
        width: '200px'
    },
    iconDelete: {
        marginLeft: '10px',
        marginTop: '15px'
    },
    componentContainer: {
        marginTop: '15px'
    },
    btnRowGroupContainer: {
        display: "flex",
        width: "80px",
        justifyContent: "flex-start"
    },
    btn: {
        width: "40px"
    },
    rowContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    }
}));
