import { API } from 'aws-amplify';
import { decorate, action, observable } from 'mobx';
import CardModel from '../CardModel/CardModel';
import lodashPick from 'lodash/pick';
import lodashGet from 'lodash/get';
import * as Sentry from "@sentry/react";
import {adminProtocolStorage} from "../../constants/data-fetching";

class WorkSpacesModel {

    items = [];
    itemsDict = {};
    itemsSlugDict = {};
    dataTable = [];
    loading = false;
    loadingWorkSpaces = false;
    workSpaces = {};
    selectedWorkSpace = {};

    apiName = 'fastShare';

    constructor() {
        this.fetchWorkSpaces()
    }

    resetCampaignData(){
        this.workSpaces = {};
    }



    fetchWorkSpaces(callback) {
        this.loadingWorkSpaces = true;

        const path = `/workspace/list`;

        API.post(this.apiName, path)
            .then(result => {
                this.handleData(result)
                if(callback){callback(true)}
            })
            .catch(error => {
                this.items = [];
                this.itemsDict = {};
                if(callback){callback(false)}
                Sentry.captureException(error);
            }).finally(()=>{
            this.loadingWorkSpaces = false;
        })

    }

    handleData = result => {
            this.workSpaces = result.data;
            this.items = lodashGet(result, 'data.workspaces',[]);
            this.items.forEach(el => {
                this.itemsDict[el.workspace_ID] = el
                this.itemsSlugDict[el.slug] = el
            });

            this.dataTable = this.items.map(el => {
                return {
                    name: el.name,
                    role: el.role,
                    colorHex: el.colorHex,
                    status: el.status,
                    slug: el.slug,
                    description: el.description,
                    workspace_ID: el.workspace_ID,
                }
            })

            let overWatchUser = window.sessionStorage && window.sessionStorage.getItem(adminProtocolStorage) ? window.sessionStorage.getItem(adminProtocolStorage) : undefined;

            if(overWatchUser){
                this.switchWorkSpace(overWatchUser)
            }else{
                this.selectedWorkSpace = this.itemsDict[this.workSpaces.userID] || {}
            }
    };


    switchWorkSpace = (id)=>{

        // if they are going back to their personal one remove it
        if(id===this.workSpaces.userID){  window.sessionStorage.removeItem(adminProtocolStorage); return;}

        this.selectedWorkSpace = this.itemsDict[id] || {}
        if (window.sessionStorage) {
            window.sessionStorage.setItem(adminProtocolStorage, id);
        }
    };

    createWorkSpace(params, callback) {
        this.loading = true;

        let path = '/workspace/create';

        API.post(this.apiName, path, { body: { ...params }})
            .then(result => {
                this.loading = false;
                if (callback) callback(result)
            })
            .catch(error => {
                this.loading = false;
                if (callback) callback(undefined)
                Sentry.captureException(error);
            })
    }

    updateWorkSpace = (workspace, callback) => {
        this.loading = true;

        workspace = lodashPick(workspace, ["name", "description", "workspace_ID"]);

        let path = '/workspace/update';

        API.post(this.apiName, path,{ body: {...workspace} })
            .then(result => {
                if (callback) callback(result)
            })
            .catch(error => {
                Sentry.captureException(error);
                if (callback) callback(undefined)
            })
            .finally(() => {this.loading = false;})
    }



    updateWorkSpaceRole = (data, callback) => {

        this.loading = true;
        let path = '/workspace/team/updateRole';
        API.post(this.apiName, path,{ body: {...data} })
            .then(result => {
                if (callback) callback(result)
            })
            .catch(error => {
                Sentry.captureException(error);
                if (callback) callback(undefined)
            })
            .finally(() => {this.loading = false;})
    }

    deleteWorkSpace = (index, callback) => {
        this.loading = true;

        let path = '/workspace/delete';
        let myInit = {
            body: {
                workspace_ID: this.items[index].workspace_ID
            }
        };

        API.post(this.apiName, path, myInit)
            .then(result => {
                this.handleData(result);
                if (callback) callback(true)
            })
            .catch(error => {
                Sentry.captureException(error);
                if (callback) callback(false)
            })
            .finally(() => {this.loading = false;})

    };

    inviteToWorkSpace = (data, callback) => {

        this.loading = true;

        let {workspace_ID, email, role} = data
        let path = '/workspace/invite/create';
        let myInit = {
            body: {
                workspace_ID,
                email,
                role
            }
        };

        API.post(this.apiName, path, myInit)
            .then(result => {
                if (callback) callback(true)
            })
            .catch(error => {
                Sentry.captureException(error);
                if (callback) callback(false)
            })
            .finally(() => {this.loading = false;})

    };

    deleteInviteToWorkSpace = (data, callback) => {

        this.loading = true;

        let {invite_ID} = data
        let path = '/workspace/invite/remove';
        let myInit = {
            body: {
                invite_ID
            }
        };

        API.post(this.apiName, path, myInit)
            .then(result => {
                if (callback) callback(true)
            })
            .catch(error => {
                Sentry.captureException(error);
                if (callback) callback(false)
            })
            .finally(() => {this.loading = false;})

    };

    getInviteData = (data, callback) => {

        this.loading = true;

        let {invite_ID} = data

        let path = `/workspace/invite/${invite_ID}`;

        API.get(this.apiName, path)
            .then(result => {
                if (callback) callback(result.data)
            })
            .catch(error => {
                Sentry.captureException(error);
                if (callback) callback(undefined)
            })
            .finally(() => {this.loading = false;})

    };

    acceptInvite = (data, callback) => {

        this.loading = true;

        let {invite_ID} = data

        let path = `/workspace/invite/accept`;

        API.post(this.apiName, path, {body: {invite_ID}})
            .then(result => {
                if (callback) callback(true)
            })
            .catch(error => {
                Sentry.captureException(error);
                if (callback) callback(false)
            })
            .finally(() => {this.loading = false;})
    };
}

decorate(WorkSpacesModel, {
    items: observable,
    loadingWorkSpaces: observable,
    itemsDict: observable,
    itemsSlugDict: observable,
    dataTable: observable,
    selectedWorkSpace: observable,

    workSpaces: observable,
    loading: observable,

    acceptInvite: action,
    inviteToWorkSpace: action,
    deleteInviteToWorkSpace: action,
    getInviteData: action,

    updateWorkSpace: action,
    updateWorkSpaceRole: action,
    createWorkSpace: action,
    fetchWorkSpaces: action,
    resetWorkSpacesData: action,
    deleteWorkSpaces: action,
    switchWorkSpace: action,
});

export default WorkSpacesModel;