import { action, computed, decorate, observable } from "mobx";
import { I18n } from 'aws-amplify';
import React from "react";
import { rules } from './rules';
import { randomString } from '../../utils/functions'
import {
    ListItem,
    ServiceActionButton,
    ServiceActionButtonText,
    ServiceSubtitle,
    ServiceThumbnail,
    ServiceTitle,
    TextContainer
} from './styles'
import ValidationModel from "../ValidationModel/ValidationModel";
import moment from "moment";


class ActionModel extends ValidationModel {
    static metadata = {
        id: "default",
        name: "Link",
        icon: "https://l.fast.cm/content/icons/default.png",
        description: "A simple configurable link"
    };

    Title = this.constructor.metadata.name;
    Subtitle = "";
    Image = this.constructor.metadata.icon;
    Link = "";
    ButtonText = "GO";
    crawledLink = false;
    highlightInvalid = false;
    Animate = false;
    paywallData = {active:false,amount:0,everyTime:false, successMessage:"",successTitle:"", purchaseTtl:365}
    scheduleLinkData = {active:false,startDateActive:false, endDateActive:false,startDateData:undefined, endDateData:undefined,startTimeZone:"America/New_York",endTimeZone:"America/New_York"}
    propNames = ["Title", "Image", "Link", "Subtitle", "ButtonText", "Animate","paywallData","scheduleLinkData"];

    constructor(dat) {
        super()
        this.ButtonText = I18n.get(`_action_${this.constructor.metadata.id}_actionButtonText`, I18n.get("_action_default_actionButtonText", "GO"))

        if (typeof dat === "object" && Object.keys(dat).length) {
            this.setFromObject(dat);
        }
    }

    resetActionButton() {
        this.Title = this.constructor.metadata.name;
        this.Subtitle = "";
        this.Image = this.constructor.metadata.icon;
        this.Link = "";
        this.ButtonText = "";
        this.paywallData = {};
        this.scheduleLinkData = {};
        this.crawledLink = false;
        this.Animate = false;
    }

    setFromObject(dat) {
        if (!dat) {
            return;
        }

        for (let i in dat) {
            if (this.propNames.indexOf(i) !== -1) {
                this[i] = dat[i];
            }
        }

        if (this.Image) {
            this.Image = this._checkImageUrl(this.Image)
        }

        this.actionID = dat.actionID || `${this.constructor.metadata.id}_${randomString(9, "#aA")}`;
        this.crawledLink = dat.crawledLink;

    }

    get isGroup() {
        return false
    }

    get groupable() {
        return true
    }

    get _ImageIsFromUs() {
        // if it's from us then I can do the source set
        if (!this.Image) return true; // if there's no image it's one of our icons
        return this.Image.indexOf("images.fast.cm/content") !== -1
    }


    RenderItem = (cardModel, index, theme, containerClassName = null) => {

        let isLast = cardModel.LinksList.length === 0 ? true : (cardModel.LinksList.length - 1 === index);
        let image = this.Image || this.constructor.metadata.icon;
        return (
            <ListItem
                failed={false}
                last={isLast}
                theme={theme}
                key={index}
                className={containerClassName}>
                {
                    this._ImageIsFromUs ? (
                        <ServiceThumbnail
                            theme={theme}
                            loading="lazy"
                            src={`${image}?width=80&height=80`}
                            srcSet={`${image}?width=50&height=50 1x,
							 		 ${image}?width=80&height=80 2x,
							 		 ${image}?width=100&height=100 3x`
                            }
                            alt={this.Title || this.constructor.metadata.name} />
                    ) : (
                        <ServiceThumbnail
                            theme={theme}
                            loading="lazy"
                            src={image}
                            alt={this.Title || this.constructor.metadata.name} />
                    )
                }

                <TextContainer>
                    <ServiceTitle theme={theme}>{this.Title || this.constructor.metadata.name}</ServiceTitle>
                    <ServiceSubtitle theme={theme}>{this.Subtitle || this.constructor.metadata.subtitle}</ServiceSubtitle>
                </TextContainer>

                <ServiceActionButton theme={theme} failed={false}>
                    <ServiceActionButtonText theme={theme}>
                        <div>{(this.ButtonText).toUpperCase()}</div>
                    </ServiceActionButtonText>
                </ServiceActionButton>
            </ListItem>
        );
    };

    _checkImageUrl(link) {
        if (typeof link !== "string") {
            return "";
        }

        if (link.indexOf("#noFastCache") !== -1) {
            link = link.replace("#noFastCache", "");
        }

        if (link.indexOf("//") === -1) {
            return "//" + link;
        }
        else {
            return link;
        }
    }

    get asObject() {
        let ret = {
            Title: "",
            Image: "",
            Link: "",
            ButtonText: "",
            Animate: false,
            paywallData:{},
            scheduleLinkData:{},
        };

        this.propNames.forEach(e => {
            ret[e] = this[e];
        });

        if (this.constructor.metadata.id && this.constructor.metadata.id !== "default") {
            ret.action = this.constructor.metadata.id;
        }

        if (this.Subtitle) {
            ret.Subtitle = this.Subtitle
        }

        ret.actionID = this.actionID || `${this.constructor.metadata.id}_${randomString(9, "#aA")}`

        return ret;
    }

    getConfigCardBody(cardModel, handleOnChangeIcon, index) {
        return null;
    }

    getRules() {
        const rules = {
            Title: {
                presence: { allowEmpty: false, message: I18n.get("_cant_be_blank") },
            },
            ButtonText: {
                presence: { allowEmpty: false, message: I18n.get("_cant_be_blank") },
            }
        }

        return rules
    }
}

decorate(ActionModel, {
    Animate: observable,
    paywallData: observable,
    scheduleLinkData: observable,
    Title: observable,
    Subtitle: observable,
    Image: observable,
    Link: observable,
    ButtonText: observable,
    crawledLink: observable,
    highlightInvalid: observable,
    resetActionButton: action,
    asObject: computed,
    setFromObject: action,
    _ImageIsFromUs: computed,
    isGroup: computed,
    groupable: computed
});

export default ActionModel
