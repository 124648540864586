import React, { Component } from 'react';
import { API } from 'aws-amplify';
import TextField from '@material-ui/core/TextField';
import {inject, observer} from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import { HooksContainer, ChipsContainer, FormContainer} from "./styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Chip from '@material-ui/core/Chip';
import lodashGet  from 'lodash/get';
import lodashIsEmpty  from 'lodash/isEmpty';
import lodashIncludes  from 'lodash/includes';
import CircularProgress from "@material-ui/core/CircularProgress";
import isUrl from 'is-url'
import FieldsManager from "./FieldsManager/FieldsManager";
import Tooltip from "@material-ui/core/Tooltip";
import { defaultValue } from "./constant/defaultValue";
import { formSchema } from "./constant/formSchema";
import { toJS } from "mobx";
import { I18n } from 'aws-amplify';
import * as Sentry from "@sentry/react";
import FastmindDataSource from "../FastmindDataSource/FastmindDataSrouce";
import {List} from "@material-ui/core";

class FastFormInput extends Component {

    state = {
        loading: false,
        isTyping: false,
        validId: false,
        testingHooks: false,
        message: "",
        openManageFields: false
    };

    apiName = 'fastShare';


    handleOnChange = event => {
        this.props.actionModel.destId = event.target.value
    };

    addHook = ()=>{
        let { actionModel } = this.props;
        actionModel.webhooks.push({})
    };

    deleteHook = (index)=>{
        let { actionModel } = this.props;
        actionModel.webhooks.splice(index, 1);
    };

    modifyHook = (event, index)=>{
        let { actionModel } = this.props;
        actionModel.webhooks[index]['webhook']=  event.target.value
    };

    canTestHooks = ()=>{
        let { actionModel } = this.props;

        let hooks = actionModel.webhooks;

        // so hooks so no need to check
        if(hooks.length===0){return false }

        for (let i = 0; i < hooks.length; i++) {
            if(lodashIsEmpty(hooks[i].webhook) || !isUrl(hooks[i].webhook)){
                return false
            }
        }

        return true;
    };
    testHooks= ()=> {

        let { actionModel } = this.props;
        let selectedProperties = actionModel.selectedProperties;
        let webHooks = toJS(actionModel.webhooks);

        let body = {};
        for (let i = 0; i < selectedProperties.length; i++) {
            let id = selectedProperties[i].id;
            if(defaultValue.hasOwnProperty(id)){
                body[id] = defaultValue[id];
            }
        }

        this.setState({testingHooks: true});

        let path = `/actions/test/${actionModel.constructor.metadata.id}/testHooks`;
        let myInit = {
            body: {
                hooks: webHooks.map(h=>h.webhook),
                data: body
            }
        };

        API.post(this.apiName, path, myInit)
            .then(result => {
                let data = lodashGet(result,'data',{});
                for (let i = 0; i < actionModel.webhooks.length; i++) {
                    let h = actionModel.webhooks[i];
                    h.success = data[h.webhook].success || false
                }
                this.setState({testingHooks: false});
            })
            .catch(error => {
                Sentry.captureException(error);
                this.setState({testingHooks: false});
            });
    };


    getSelectedFormFields = ()=>{

        let { actionModel } = this.props;

        //  the properties the user has selected
        let selectedProperties = toJS(actionModel.selectedProperties);

        // all the fields we support.. can be used to later on add one from this specific user... V2
        let allFieldsDict = lodashGet(formSchema, 'properties', {});

        return selectedProperties.filter(t=>allFieldsDict.hasOwnProperty(t.id)).map( c=>{
                // c.title = allFieldsDict[c.id].title;
                c.title = I18n.get(`_form_field_${c.id}`, allFieldsDict[c.id].title);
            return c
        });
    };

    getRemainingFormFields = ()=>{

        let { actionModel } = this.props;

        //  the properties the user has selected
        let selectedProperties = toJS(actionModel.selectedProperties).map( sel =>sel.id);

        // all the fields we support.. can be used to later on add one from this specific user... V2
        let allFieldsDict = lodashGet(formSchema, 'properties', {});

        return Object.keys(allFieldsDict)
            .filter(f=>!lodashIncludes(selectedProperties, f))
            .map( f=>{
            return {
                id: f,
                //title: allFieldsDict[f].title
                title : I18n.get(`_form_field_${f}`, allFieldsDict[f].title)
            }
        });
    };


    openManageFields = ()=>{
        this.setState({openManageFields:true});
    };

    closeManageFields = ()=>{
        this.setState({openManageFields:false});
    };

    formChanged = ({selected, schema, language, successMessage})=>{
        let { actionModel } = this.props;

        actionModel.formSchema = schema.formSchema;
        actionModel.uiSchema = schema.uiSchema;

        actionModel.language = language;
        actionModel.successMessage = successMessage;
        //  the properties the user has selected
        actionModel.selectedProperties = selected;
    };


    render() {
        let { cardModel, actionModel, UserModel, learnMore, hookPlaceholder } = this.props;
        let language = actionModel.language || UserModel.currentUser.attributes['locale']

        return (
            <div>
                <div style={{marginBottom:14}}>
                    <FormContainer>
                        <Typography variant='subtitle2' >{I18n.get("_form_fields", "Form Fields")} </Typography>
                        <Tooltip title={I18n.get("_field_from_your_form", "Fields from your form")}>
                            <Button
                                onClick={this.openManageFields}
                                color="primary"
                                style={{display:'flex'}} >
                                {I18n.get("_manage_form", "Manage Form")}
                            </Button>
                        </Tooltip>
                    </FormContainer>
                    <ChipsContainer>
                        {this.getSelectedFormFields().map((field, index)=>{
                            return (
                                <Chip
                                    key={index}
                                    style={{margin: 4}}
                                    label={`${field.title}${field.required?'*':''}`}
                                />
                            )
                        })}
                    </ChipsContainer>
                </div>



                <div>


                  <div>
                    <FormContainer>
                    <Typography variant='subtitle2' >{I18n.get("_dataStorages", "Data Storages")}</Typography>
                        {learnMore &&
                        <Tooltip title={I18n.get("_show_you_how_to_connect", "Show you how to connect")} >
                            <Button onClick={()=>{  window.open(learnMore, "_blank")  }} color="primary"> {I18n.get("_learn_more", "Lean More")}</Button>
                        </Tooltip>
                        }
                    </FormContainer>
                      <List dense={true}>
                            <FastmindDataSource actionModel={actionModel}/>
                      </List>
                </div>

                  <div>
                    <FormContainer>
                    <Typography variant='subtitle2' >{I18n.get("_web_hooks", "Webhooks")}</Typography>
                        {learnMore &&
                        <Tooltip title={I18n.get("_show_you_how_to_connect", "Show you how to connect")} >
                            <Button onClick={()=>{  window.open(learnMore, "_blank")  }} color="primary"> {I18n.get("_learn_more", "Lean More")}</Button>
                        </Tooltip>
                        }
                    </FormContainer>
                    {actionModel.webhooks.map((hook, index)=>{
                    return (
                        <HooksContainer key={index}>
                            <TextField
                                error={!lodashGet(hook, 'success', true)}
                                type="text"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                style={{ marginTop: '16px'}}
                                autoComplete="off"
                                // label={'hookies'}
                                placeholder={hookPlaceholder || "https://hooks.zapier.com/hooks/catch/abcde/1234567/"}
                                value={hook.webhook}
                                onChange={ event => this.modifyHook(event, index)}
                                disabled={cardModel.loading} />

                                <IconButton
                                    onClick={() => this.deleteHook(index)}
                                    disabled={cardModel.loading} >
                                    <DeleteIcon />
                                </IconButton>

                        </HooksContainer>
                        )
                    })}
                </div>

                <HooksContainer>
                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth={true}
                        onClick={this.addHook}
                        style={{ color: 'primary', margin:4}}
                        startIcon={<AddIcon />} >
                        {I18n.get('_add_new_hook', "Add new hook")}
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={cardModel.loading || this.state.testingHooks || !this.canTestHooks()}
                        onClick={this.testHooks}
                        style={{ color: 'white', margin:4}} >
                        {this.state.testingHooks?<CircularProgress size="1rem" style={{ color: 'white' }}/>: I18n.get('_send_data_to_hooks',"Send Data to hooks")}
                    </Button>
                </HooksContainer>
                </div>

                {/*Managing the from dialog*/}
                <FieldsManager
                    title={ actionModel.formSchema.title}
                    description={actionModel.formSchema.description}
                    successMessage={actionModel.successMessage}
                    language={language}
                    formChanged = {this.formChanged}
                    selected={this.getSelectedFormFields()}
                    choices={this.getRemainingFormFields()}
                    handleClose={this.closeManageFields}
                    open={this.state.openManageFields}/>

            </div>
        )
    }
}

FastFormInput.propTypes = {

}

export default inject("UserModel")(observer(FastFormInput));
