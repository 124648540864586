import { useModels } from "./useModels";
import { useObserver } from 'mobx-react'

export function useUserModel() {
    const { UserModel } = useModels();

    return useObserver(() => ({
        profilePicLink: UserModel.profilePicLink,
        username: UserModel.username,
        currentUser: UserModel.currentUser,
        userProperties: UserModel.userProperties,
        fcUserData: UserModel.fcUserData,
        stripeData: UserModel.stripeData,
        stripeDataSummary: UserModel.stripeDataSummary,
        creditData: UserModel.creditData,
        subscriptionPlans: UserModel.subscriptionPlans,
        subscriptionSelected: UserModel.subscriptionSelected,
        loadingSubscriptions: UserModel.loadingSubscriptions,
        visitPackages: UserModel.visitPackages,
        paymentMethods: UserModel.paymentMethods,
        loading: UserModel.loading,
        defaultPayment: UserModel.defaultPayment,
        pendingPlan: UserModel.pendingPlan,
        paymentsHistory: UserModel.paymentsHistory,
        fetchConnectedAccounts: UserModel.fetchConnectedAccounts,
        disconnectAccount: UserModel.disconnectAccount,
        fetchAdSets: UserModel.fetchAdSets,
        fetchAdCampaigns: UserModel.fetchAdCampaigns,
        fetchAds: UserModel.fetchAds,
        fetchAvailableIntegrations: UserModel.fetchAvailableIntegrations,
        // XKitClient: UserModel.XKitClient,
        // PizzlyClient: UserModel.PizzlyClient
    }))
}