export const styles = theme => ({
    flexContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    timezonesSelect: {
        padding: "4px",
        marginLeft: '4px',
    },
    select: {
        "&:focus": {
            backgroundColor: 'transparent !important'
        }
    },
})
