import styled from 'styled-components';

export const Main = styled.main`
    position: relative;
    height: 100%;
    margin-top: 56px;

    @media (min-width: 600px) {
        margin-top: 64px;
    }
`;
