import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import Card from "@material-ui/core/Card/Card";
import Spacer from "../../../../../../components/UI/Spacer/Spacer";
import CardActions from "@material-ui/core/CardActions/CardActions";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import IconButton from '@material-ui/core/IconButton';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import Popover from "@material-ui/core/Popover/Popover";
import CardContent from "@material-ui/core/CardContent";
import ListItemAvatar from "@material-ui/core/ListItemAvatar/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Button from "@material-ui/core/Button";
import { I18n } from 'aws-amplify';
import { useStyles } from './styles'
import Divider from "@material-ui/core/Divider";
import DeleteIcon from "@material-ui/icons/Delete";
import HelpIcon from "@material-ui/icons/Help";
import CheckboxInput from "../CheckboxInput/CheckboxInput";
import { Tooltip } from "@material-ui/core";

function MobilityInput(props) {

    const classes = useStyles();
    const { actionModel, onChangeIcon } = props
    const [open, setOpen] = useState(false)
    const childSelectorRef = useRef()

    const handleToggleChildSelector = () => {
        setOpen(!open)
    }

    const moveUp = (index) => {

        if (index !== 0) {
            [actionModel.childActions[index - 1], actionModel.childActions[index]] = [actionModel.childActions[index], actionModel.childActions[index - 1]];
        }
    }

    const moveDown = (index) => {

        if (index !== (actionModel.childActions.length - 1)) {
            [actionModel.childActions[index + 1], actionModel.childActions[index]] = [actionModel.childActions[index], actionModel.childActions[index + 1]];
        }
    }

    return (
        <div>
            <Divider style={{ margin: '24px 0 16px 0' }} />
            <div className={classes.autoSelectContainer}>
                <CheckboxInput store={actionModel} field={'autoSelect'} label={I18n.get('_action_mobility_auto_select')} />
                <Tooltip title={I18n.get('_action_mobility_auto_select_help_text')} >
                    <HelpIcon fontSize="small" color='action' />
                </Tooltip>
            </div>
            <div>
                <List>
                    <ListItem
                        disabled={actionModel.autoSelect}
                        ref={childSelectorRef}
                        button
                        onClick={handleToggleChildSelector}
                        classes={{ root: classes.listItemSelector }}>
                        <ListItemText primary={I18n.get('_add_new_link', 'Add new link')} />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Popover
                        open={open}
                        classes={{
                            paper: classes.popOverListItemSelectorPaper
                        }}
                        anchorEl={childSelectorRef.current}
                        onClose={handleToggleChildSelector}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Card>
                            <CardContent style={{ padding: '0' }}>
                                <List component="div" disablePadding>
                                    {actionModel.availableChildActionEmpty
                                        ? <ListItem>
                                            <ListItemText primary={I18n.get("_no_link_card_available_add_it", "There aren't available actions")} />
                                        </ListItem>
                                        : actionModel.availableActions.map((link, index) => {
                                            const childAction = actionModel.childActions.find(action => action.constructor.metadata.id === link.constructor.metadata.id) !== undefined
                                                ? actionModel.childActions.find(action => action.constructor.metadata.id === link.constructor.metadata.id)
                                                : link

                                            const labelId = `checkbox-list-secondary-label-${index}`;
                                            return (
                                                <ListItem button key={index}>
                                                    <ListItemAvatar>
                                                        <Avatar variant="rounded" src={childAction.Image || childAction.constructor.metadata.icon} />
                                                    </ListItemAvatar>
                                                    <ListItemText id={labelId} primary={childAction.Title} />
                                                    <ListItemSecondaryAction>
                                                        <Checkbox
                                                            edge="end"
                                                            color="primary"
                                                            disabled={actionModel.autoSelect}
                                                            onChange={() => actionModel.toggleChecked(childAction)}
                                                            checked={actionModel.childActions.find(checked => checked.constructor.metadata.id === childAction.constructor.metadata.id) !== undefined}
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </ListItemSecondaryAction>
                                                </ListItem>)
                                        })}
                                </List>
                            </CardContent>
                            {!actionModel.availableChildActionEmpty ?
                                <CardActions classes={{ root: classes.cardActions }}>
                                    <Spacer />
                                    <Button variant="contained" size="small" color="primary" classes={{ root: classes.btnAdd }} onClick={handleToggleChildSelector}>
                                        {I18n.get('_accept', 'Accept')}
                                    </Button>
                                </CardActions> : null}
                        </Card>
                    </Popover>
                </List>
            </div>
            <Divider style={{ margin: '24px 0 16px 0' }} />

            <List>
                {actionModel.childActions.map((childAction, index) => {
                    return (
                        <ListItem
                            dense
                            key={index}
                            disabled={actionModel.autoSelect}
                            className={classes.listItemCollapsed}>
                            <div className={classes.listItemCollapsedIconContainer}>
                                <img
                                    className={classes.listItemCollapsedIcon}
                                    src={childAction.Image || childAction.constructor.metadata.icon}
                                    alt='action icon'
                                    onClick={() => actionModel.autoSelect ? undefined : onChangeIcon("Icons", true, false, actionModel, childAction)} />
                                <div>{childAction.Title}</div>
                            </div>
                            <div>
                                {(index !== 0) &&
                                    <IconButton
                                        size="small"
                                        disabled={actionModel.autoSelect}
                                        onClick={() => moveUp(index)}>
                                        <ArrowUpwardIcon />
                                    </IconButton>
                                }
                                {index !== (actionModel.childActions.length - 1) &&
                                    <IconButton
                                        size="small"
                                        disabled={actionModel.autoSelect}
                                        onClick={() => moveDown(index)}>
                                        <ArrowDownwardIcon />
                                    </IconButton>
                                }

                                <IconButton
                                    size="small"
                                    disabled={actionModel.autoSelect}
                                    onClick={() => actionModel.toggleChecked(childAction)}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </ListItem>
                    )
                })}
            </List>
        </div>
    )
}

export default observer(MobilityInput);
