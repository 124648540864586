export const signUpReasons ={
    "business": "Business",
    "influencer": "Influencer",
    "gov/non_profit": "Gov/Non Profit",
    "personal": "Personal",
    "other": "Other"
};

export const Languages = {
    "en":" English",
    "es": "Español"
}