import React, {Component} from 'react';
import {
    ButtonsContainer,
    SearchBarContainer, SearchBar
} from './styles';
import {inject, observer} from 'mobx-react';
// import ScrollArea from 'xico2k-react-scroll-area';
import FilterItem from './FilterItem/FilterItem';
import Button from '@material-ui/core/Button';
import {I18n} from 'aws-amplify';
import List from '@material-ui/core/List';
import ScrollArea from "../../../../../components/ScrollArea/ScrollArea";

class FilterDropdown extends Component {

    state = {
        search: ''
    }

    handleTagClicked = event => {

        let filteredTags = new Set([...this.props.CardsDataModel.FilterTags])

        if (event.target.checked) {
            filteredTags.add(event.target.name);
        } else {
            filteredTags.delete(event.target.name);
        }

        this.props.CardsDataModel.FilterTags = [...filteredTags.values()]
    };

    handleOnSearch = event => {
        this.setState({search: event.target.value.toLowerCase()})
    };

    handleReset = () => {
        this.props.CardsDataModel.FilterTags = [];
    };

    render() {

        const {TagsList} = this.props.CardsDataModel;

        return (
            <div style={{width: '210px'}}>
                <SearchBarContainer>
                    <SearchBar
                        autoComplete="off"
                        id="outlined-search"
                        label={I18n.get('_search_placeholder')}
                        type="search"
                        margin="dense"
                        variant="outlined"
                        value={this.state.search}
                        onChange={this.handleOnSearch}
                    />
                </SearchBarContainer>
                <div style={{position: 'relative'}}>
                    <ScrollArea>
                        <List dense style={{height: '180px', paddingBottom: '8px'}}>
                            {TagsList && TagsList
                                .filter(tag => tag.text
                                    .includes(this.state.search))
                                .map(tag => {
                                    return <FilterItem
                                        key={tag.text}
                                        color={tag.color}
                                        tagName={tag.text}
                                        selected={this.props.CardsDataModel.FilterTags.find(value => value === tag.text) !== undefined}
                                        handleOnChange={this.handleTagClicked}/>
                                })}
                        </List>
                    </ScrollArea>
                </div>
                <ButtonsContainer>
                    <Button variant="outlined" color="primary"
                            onClick={this.handleReset}>{I18n.get('_reset', "Reset")}</Button>
                    <Button variant="contained" color="primary" style={{width: '80px', color: 'white'}}
                            onClick={this.props.handleFilter}>{I18n.get("_filter", "Filter")}</Button>
                </ButtonsContainer>
            </div>
        );
    }
}

export default inject('CardsDataModel')(observer(FilterDropdown));
