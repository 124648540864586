
import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
    root: {
        marginTop: 64,
        backgroundColor: theme.palette.background.default,
        '&:focus': {
            outline: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 56
        }
    }, 
    toolbar: {
        padding: '0px 32px 4px 32px',
        minHeight: 56
    },
    progress: {
        flexGrow: 1
    }

}));