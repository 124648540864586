import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const Icon = styled.img`
    height: 48px;
    width: 48px;
    object-fit: cover;
    margin-right: 8px;
`;