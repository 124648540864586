import cloneDeep from "lodash/cloneDeep";

export const randomString = (length, chars) => {
    let mask = '';
    if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
    if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (chars.indexOf('#') > -1) mask += '0123456789';
    if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
    let result = '';
    for (let i = length; i > 0; --i) result += mask[Math.floor(Math.random() * mask.length)];
    return result;
};

export function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}


export const getImageCompleteURL =(link)=>{
    if (typeof link !== "string") {
        return "";
    }

    if (link.indexOf("#noFastCache") !== -1) {
        link = link.replace("#noFastCache", "");
    }

    if (link.indexOf("//") === -1) {
        return "https://" + link;
    } else {
        return link;
    }
}

export const parseURLParameters = (search) => {
    const hashes = search.slice(search.indexOf('?') + 1).split('&')
    const params = {}

    if (hashes.length === 0 || (hashes.length === 1 && hashes[0] === "")) {
        return undefined
    }

    hashes.forEach(hash => {
        const [key, val] = hash.split('=')
        params[key] = decodeURIComponent(val)
    })

    return params
};

export const countFilters = function (items) {
    let count = 0;

    Object.keys(items)
        .forEach(key => {
            if (key === 'referer' || key === 'fcFLRef'|| key === 'fcFLRefSeg') {
                count = count + Object.keys(items[key]).length
            } else {
                if (Object.keys(items[key]).length > 0) {
                    Object.keys(items[key]).forEach(option => {
                        count = count + Object.keys(items[key][option]).length
                    })
                }
            }
        })

    return count
}

export const handleOptionChange = function (unique, key, data, optionsSelected) {

    let _optionsSelected = cloneDeep(optionsSelected)

    if (data !== null) {
        if (key !== null) {
            if (data[Object.keys(data)[0]] === true) {
                _optionsSelected = {
                    ..._optionsSelected,
                    [unique]: {
                        ..._optionsSelected[unique],
                        [key]: {
                            ..._optionsSelected[unique][key],
                            ...data
                        }
                    }
                }
            } else {
                const _key = Object.keys(data)[0]
                delete _optionsSelected[unique][key][_key]
            }
        } else {
            if (data[Object.keys(data)[0]] === true) {
                _optionsSelected = {
                    ..._optionsSelected,
                    [unique]: {
                        ..._optionsSelected[unique],
                        ...data
                    }
                }
            } else {
                const _key = Object.keys(data)[0]
                delete _optionsSelected[unique][_key]
            }
        }
    }

    return _optionsSelected
}



export const isfUserFirstTimeAfterSignUp = () => {
    let signUpDateTime = localStorage.getItem("fastcard_sign_up_datetime");
    if (signUpDateTime !== null) {
        signUpDateTime = new Date(signUpDateTime)
        signUpDateTime.setSeconds(signUpDateTime.getSeconds() + 30)
        return signUpDateTime > new Date()
    }
    return false;
}

export const removeFirstTimeAfterSignUp = () => {
    localStorage.removeItem('fastcard_sign_up_datetime')
}

export const addFirstTimeAfterSignUp = () => {
    localStorage.setItem("fastcard_sign_up_datetime", new Date())
}
