import React from 'react';
import { withRouter } from 'react-router-dom';

import TagsFilter from "./TagsFilter/TagsFilter";
import NewCardButton from './NewCardButton/NewCardButton';
import Subheader from '../../../components/Subheader/Subheader';
import Hidden from "@material-ui/core/Hidden";
import { Button } from "@material-ui/core";
import ImportFastCard from "./ImportFastCard/ImportFastCard";

function SectionHeader(props) {

    function handleTemplates() {
        props.history.push('/create');
    };

    function handleBlankCard() {
        props.model.reset();
        props.history.push('/create/card');
    };

    return (
        <Hidden xsDown>
            <Subheader isLoading={props.isLoading}>
                {props.tagsButton ? <TagsFilter /> : null}
                <div style={{ flex: 1 }} />
                {/* <ExportButton variant="text" color="primary"
                    style={{ marginRight: '8px' }}
                    onClick={props.handleExportExcel}
                    disabled={props.isLoading}>
                    <InsertChartOutlinedTwoToneIcon style={{ marginRight: '4px' }} />
                    Export
                        </ExportButton> */}
                <Hidden mdDown>
                    <ImportFastCard />
                </Hidden>


                <NewCardButton
                    onBlankCard={handleBlankCard}
                    onTemplates={handleTemplates} />
            </Subheader>
        </Hidden>
    );

}

export default withRouter(SectionHeader);
