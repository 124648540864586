/*jshint esversion: 6 */
/*jshint undef:true*/
/*jshint node: true */

import { chartColors } from '../../constants/theme';
import { convertHex } from '../../utils/colors';
import isEmpty from "lodash/isEmpty";
import keyBy from 'lodash/keyBy'
import clone from "lodash/clone";
import get from "lodash/get";
import moment from "moment";
import * as Sentry from "@sentry/react";
const { getName } = require('country-list');

export default class CardAnalytics {

    totalViews = 0;
    totalClicks = 0;
    pageClicksData;
    pageViewsData;
    adSpendData;
    totalSpentClicks = 0
    totalAmountSpent = 0

    cardData;
    data = {};

    LifetimeViews = 0;
    LifetimeClicks = 0;

    PieClicksChart = {
        labels: [
            'None',
        ],
        datasets: [{
            data: [100],
            backgroundColor: [
                '#FF6384'
            ],
            hoverBackgroundColor: [
                '#FF6384'
            ]
        }]
    };

    ClicksChart = {
        labels: [' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' '],
        datasets: [
            {
                label: '',
                backgroundColor: convertHex("#63c2de", 10),
                borderColor: "#63c2de",
                pointHoverBackgroundColor: '#fff',
                borderWidth: 2,
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
        ]
    };

    ClicksChartOpts = {
        maintainAspectRatio: false,
        legend: {
            // display: !mobileCheck()
            display: false
        },
        scales: {
            xAxes: [{
                gridLines: {
                    drawOnChartArea: false,
                }
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 5,
                    stepSize: Math.ceil(250 / 5),
                    max: 250
                }
            }]
        },
        elements: {
            line: {
                fill: false
            },
            point: {
                radius: 0,
                hitRadius: 10,
                hoverRadius: 4,
                hoverBorderWidth: 3,
            }
        }
    };

    ViewsChart = {
        labels: [' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' '],
        datasets: [
            {
                label: 'Test Visitors',
                backgroundColor: convertHex("#63c2de", 10),
                borderColor: "#63c2de",
                pointHoverBackgroundColor: '#fff',
                borderWidth: 2,
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
        ]
    };

    ViewsChartOpts = {
        maintainAspectRatio: false,
        legend: {
            // display: !mobileCheck()
            display: false
        },
        scales: {
            xAxes: [{
                gridLines: {
                    drawOnChartArea: false,
                }
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 5,
                    stepSize: Math.ceil(250 / 5),
                    max: 250
                }
            }]
        },
        elements: {
            line: {
                fill: false
            },
            point: {
                radius: 0,
                hitRadius: 10,
                hoverRadius: 4,
                hoverBorderWidth: 3,
            }
        }
    };

    uniqueVisitsChart = {
        labels: [' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' '],
        datasets: [
            {
                label: 'Unique Users',
                backgroundColor: convertHex("#830db7", 10),
                borderColor: "#830db7",
                pointHoverBackgroundColor: '#fff',
                borderWidth: 2,
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
        ]
    };

    uniqueVisitsChartOpts = {
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        scales: {
            xAxes: [{
                gridLines: {
                    drawOnChartArea: false,
                }
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 5,
                    stepSize: Math.ceil(250 / 5),
                    max: 250
                }
            }]
        },
        elements: {
            line: {
                fill: false
            },
            point: {
                radius: 0,
                hitRadius: 10,
                hoverRadius: 4,
                hoverBorderWidth: 3,
            }
        }
    };

    ConversionChart = {
        labels: [' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' '],
        datasets: [
            {
                label: 'Conversion',
                backgroundColor: convertHex("#63c2de", 10),
                borderColor: "#63c2de",
                pointHoverBackgroundColor: '#fff',
                borderWidth: 2,
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
        ]
    };

    ConversionChartOpts = {
        maintainAspectRatio: false,
        legend: {
            // display: !mobileCheck()
            display: false

        },
        scales: {
            xAxes: [{
                gridLines: {
                    drawOnChartArea: false,
                }
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 5,
                    stepSize: Math.ceil(100 / 5),
                    max: 100
                }
            }]
        },
        elements: {
            line: {
                fill: false
            },
            point: {
                radius: 0,
                hitRadius: 10,
                hoverRadius: 4,
                hoverBorderWidth: 3,
            }
        }
    };

    costPerActionChart = {
        labels: [' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' '],
        datasets: [
            {
                label: 'Cost per Action',
                backgroundColor: convertHex("#e5710b", 10),
                borderColor: "#e5710b",
                pointHoverBackgroundColor: '#fff',
                borderWidth: 2,
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
        ]
    };

    costPerActionChartOpts = {
        maintainAspectRatio: false,
        legend: {
            // display: !mobileCheck()
            display: false

        },
        scales: {
            xAxes: [{
                gridLines: {
                    drawOnChartArea: false,
                }
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 5,
                    stepSize: Math.ceil(100 / 5),
                    max: 100
                }
            }]
        },
        elements: {
            line: {
                fill: false
            },
            point: {
                radius: 0,
                hitRadius: 10,
                hoverRadius: 4,
                hoverBorderWidth: 3,
            }
        }
    };

    constructor(cardData, data) {
        if (!data || !cardData) {
            return;
        }
        this.cardData = cardData;
        this.data = data;

        if (!this.data['CardAnalytics'] || isEmpty(this.data['CardAnalytics'])) { return; }

        try {
            // getting the total view and clicks for the card
            this.CardAnalytics = this.data['CardAnalytics'];
            this.totalViews = get(this.data, 'CardAnalytics.dataSummary.totalAmountVisits', 0);
            this.totalClicks = get(this.data, 'CardAnalytics.dataSummary.totalAmountClicks', 0);

            this.totalUniqueViews = get(this.data, 'CardAnalytics.dataSummary.totalUniqueUserVisits', 0);
            this.totalUniqueClicks = get(this.data, 'CardAnalytics.dataSummary.totalUniqueUserClicks', 0);


            this.lastUpdated = moment.unix(get(this.data, 'CardAnalytics.dataSummary.lastUpdated', 0)).utc().format("YYYY/MM/DD @ hh:mm a");
            this.lastUpdatedTimezone = get(this.data, 'CardAnalytics.dataSummary.lastUpdatedTimezone');
            this.fromTimeZone = get(this.data, 'CardAnalytics.dataSummary.fromTimeZone');

            this.adSpendData = this.data['Spending'];
            this.totalSpentClicks = get(this.CardAnalytics, 'dataSummary.fcaidsAmountByRange', []).reduce((a, b) => a + b, 0);
            this.totalAmountSpent = get(this.adSpendData, 'dataSummary.totalSpent', 0);
            this.timeRangeChanges = get(this.CardAnalytics, 'dataSummary.timeRangeChanges',[])

            this.parseIncomingData();
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    isDataAvailable() {
        return (!isEmpty(this.data['CardAnalytics']))
    }

    getCountryData() {
        return this.showTableData("countries").map(c => {
            try {
                c[0] = (getName(c[0]) || c[0]);
                return c
            } catch (error) {
                Sentry.captureException(error);
                return c
            }
        });
    }

    showTableData(event) {
        if (!this.isDataAvailable()) { return [] }

        return get(this.CardAnalytics,`dataSummary.${event}`,[]).map(event=>{
            return [event.name, event.visits, event.users, event.clicks, event.engagementRate]
        })
    }

    showSpendingTableData(event) {
        let path = ''
        let finalList = [];
        let fcaidDict = {};

        if (!this.isDataAvailable()) { return finalList }

        try {
            // in some cases we may not have things like the device information
            switch (event) {
                case "cpa_by_providers":
                    fcaidDict = get(this.adSpendData, 'dataSummary.fcaidsByProviders', {})
                    path = 'Provider'

                    break;
                case "cpa_by_campaigns":
                    fcaidDict = get(this.adSpendData, 'dataSummary.fcaidsByCampaigns', {})
                    path = 'Campaign'

                    break;
                case "cpa_by_adsets":
                    fcaidDict = get(this.adSpendData, 'dataSummary.fcaidsByAdSets', {})
                    path = 'Adsets'

                    break;
                case "cpa_by_ads":
                    let f = get(this.adSpendData, 'dataSummary.fcaids', []) // in  the case of the ads it's already an array

                    for (let i = 0; i < f.length; i++) {
                        let id = f[i];
                        fcaidDict[id] = [id]
                    }
                    path = 'Fcaids'

                    break;
                default:
                    break;
            }

        } catch (error) {
            Sentry.captureException(error);
            fcaidDict = {};
        }

        let keys = Object.keys(fcaidDict);

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let fcaids = fcaidDict[key]
            let visits = get(this.adSpendData, `dataSummary.impressionsBy${path}.${key}`, []).reduce((a, b) => a + b, 0);
            let clicks = fcaids.map(i => get(this.pageClicksData, `dataSummary.occurrences.fcaid.${i}`, 0)).reduce((a, b) => a + b, 0);
            let engagement = (visits === 0 || clicks === 0) ? 0 : (clicks / visits) * 100;
            let spent = get(this.adSpendData, `dataSummary.amountBy${path}.${key}`, []).reduce((a, b) => a + b, 0)
            let cpa = (spent === 0 || clicks === 0) ? 0 : (spent / clicks)
            finalList.push([key, visits, clicks, engagement, spent.toFixed(2), cpa.toFixed(2)])
        }

        return finalList
    }

    dataToRange(property) {
        return get(this.CardAnalytics, `dataSummary.${property}`)
    }

    getDataDateRange() {
        return get(this.CardAnalytics, "dataSummary.timeRange", []).map(i => moment(i).format('M/D'))
    }

    parseIncomingData() {

        let daysOut = this.getDataDateRange();
        let dailyData = this.dataToRange("visits");

        let maxVal = Math.max.apply(Math, dailyData);
        maxVal = Math.ceil(4 * maxVal / 3);

        this.ViewsChart = {
            labels: daysOut,
            datasets: [
                {
                    label: 'Visitors',
                    backgroundColor: chartColors[0].fillColor,
                    borderColor: "#007aff",
                    pointHoverBackgroundColor: chartColors[0].pointStrokeColor,
                    borderWidth: 3,
                    data: dailyData
                },
                {
                    type: 'bar',
                    label: 'Release',
                    backgroundColor: "#ff0044",
                    data: this.timeRangeChanges.map((i)=>i?Math.max.apply(Math, dailyData):0),
                    barThickness: 1,
                    order:2,
                },
            ]
        };

        this.ViewsChartOpts = {
            maintainAspectRatio: false,
            tooltips: {
                yAlign: 'bottom'
            },
            legend: {
                // display: !mobileCheck()
                display: false

            },
            scales: {
                xAxes: [{
                    gridLines: {
                        drawOnChartArea: false,
                    }
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        maxTicksLimit: 5,
                        stepSize: Math.ceil(maxVal / 5),
                        max: maxVal
                    }
                }]
            },
            elements: {
                line: {
                    fill: false
                },
                point: {
                    radius: 0,
                    hitRadius: 10,
                    hoverRadius: 4,
                    hoverBorderWidth: 3,
                }
            }
        };

        let dailyClickData = this.dataToRange("clicks");

        let actionsDict = get(this,'cardData.allActionsDict',{}); // in instances such as campaigns we don't have an action dict even though we could pull it from the data

        let DataSetArr = [];
        DataSetArr.push({
            label: 'Total',
            backgroundColor: convertHex("#F3467C", 10),
            borderColor: "#F3467C",
            pointHoverBackgroundColor: chartColors[0].pointStrokeColor,
            borderWidth: 3,
            data: dailyClickData
        });
        let colorNum = 0;
        let clickData = get(this.CardAnalytics,'dataSummary.actions').map(a=>a.id);

        if (clickData) {
            for (let i = 0; i < clickData.length; i++) {
                let actionId = clickData[i];

                colorNum = (colorNum + 1) % chartColors.length;
                colorNum++;
                if (!chartColors[colorNum]) { // if we ran out of colors then start over again
                    colorNum = 0
                }

                let actionDailyClickData = get(this.CardAnalytics, 'dataSummary.clicksByRange', []).map((d) => get(d, actionId, 0));

                let title = actionsDict[decodeURIComponent(actionId)] ? actionsDict[decodeURIComponent(actionId)].Title : decodeURIComponent(actionId);
                DataSetArr.push({
                    label: title,
                    backgroundColor: chartColors[colorNum].fillColor,
                    borderColor: chartColors[colorNum].strokeColor,
                    pointHoverBackgroundColor: chartColors[colorNum].pointStrokeColor,
                    borderWidth: 2,
                    data: actionDailyClickData
                });

            }
        }

        let daysOutClick = this.getDataDateRange();

        maxVal = Math.max.apply(Math, dailyClickData);
        maxVal = Math.ceil(4 * maxVal / 3);

        DataSetArr.push({
            type: 'bar',
            label: 'Release',
            backgroundColor: "#ff0044",
            data: this.timeRangeChanges.map((i)=>i?Math.max.apply(Math, dailyClickData):0),
            barThickness: 1,
            order:2,
        },)

        this.ClicksChart = {
            labels: daysOutClick,
            datasets: DataSetArr
        };

        this.ClicksChartOpts = {
            maintainAspectRatio: false,
            tooltips: {
                yAlign: 'bottom'
            },
            legend: {
                // display: !mobileCheck()
                display: false,
                // labels:{
                //   defaultFontFamily: 'lato',
                //   defaultFontStyle:'bold'
                // }
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        drawOnChartArea: false,
                    }
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        maxTicksLimit: 5,
                        stepSize: Math.ceil(maxVal / 5),
                        max: maxVal
                    }
                }]
            },
            elements: {
                line: {
                    fill: false
                },
                point: {
                    radius: 0,
                    hitRadius: 10,
                    hoverRadius: 4,
                    hoverBorderWidth: 3,
                }
            }
        };

        // conversion data

        let ConversionData = [];
        maxVal = 0;
        for (let i = 0; i < dailyData.length; i++) {
            // making sure to never divide by zero
            let convertion = dailyData[i] === 0 ? 0 : (dailyClickData[i] / dailyData[i]) * 100;
            if (isNaN(convertion) || !isFinite(convertion)) {
                convertion = 0;
            }

            if (convertion > maxVal) {
                maxVal = convertion;
            }
            ConversionData.push(Math.ceil(convertion));
        }

        maxVal = Math.ceil(4 * maxVal / 3);
        maxVal = Math.max(100, maxVal )
        
        this.ConversionChart = {
            labels: daysOut,
            datasets: [
                {
                    label: 'Conversion',
                    backgroundColor: convertHex("#00C8D3", 10),
                    borderColor: "#00C8D3",
                    pointHoverBackgroundColor: chartColors[0].pointStrokeColor,
                    borderWidth: 3,
                    data: ConversionData
                },
                {
                    type: 'bar',
                    label: 'Release',
                    backgroundColor: "#ff0044",
                    data: this.timeRangeChanges.map((i)=>i?Math.ceil(4 * maxVal / 3):0),
                    barThickness: 1,
                    order:2,
                },
            ]
        };

        this.ConversionChartOpts = {
            maintainAspectRatio: false,
            tooltips: {
                yAlign: 'bottom'
            },
            legend: {
                // display: !mobileCheck()
                display: false
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        drawOnChartArea: false,
                    }
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        maxTicksLimit: 5,
                        stepSize: Math.ceil(maxVal / 5),
                        max: maxVal
                    }
                }]
            },
            elements: {
                line: {
                    fill: false
                },
                point: {
                    radius: 0,
                    hitRadius: 10,
                    hoverRadius: 4,
                    hoverBorderWidth: 3,
                }
            }
        }

        // Unique Visits data

        let uniqueVisitsData = this.dataToRange("uniqueVisits");

        maxVal = Math.max.apply(Math, uniqueVisitsData);
        maxVal = Math.ceil(4 * maxVal / 3);

        this.uniqueVisitsChart = {
            labels: daysOut,
            datasets: [
                {
                    label: 'Unique User',
                    backgroundColor: convertHex("#830db7", 10),
                    borderColor: "#830db7",
                    pointHoverBackgroundColor: chartColors[0].pointStrokeColor,
                    borderWidth: 3,
                    data: uniqueVisitsData
                },
                {
                    type: 'bar',
                    label: 'Release',
                    backgroundColor: "#ff0044",
                    data: this.timeRangeChanges.map((i)=>i?Math.max.apply(Math, uniqueVisitsData):0),
                    barThickness: 1,
                    order:2,
                },
            ]
        };

        this.uniqueVisitsChartOpts = {
            maintainAspectRatio: false,
            tooltips: {
                yAlign: 'bottom'
            },
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        drawOnChartArea: false,
                    }
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        maxTicksLimit: 5,
                        stepSize: Math.ceil(maxVal / 5),
                        max: maxVal
                    }
                }]
            },
            elements: {
                line: {
                    fill: false
                },
                point: {
                    radius: 0,
                    hitRadius: 10,
                    hoverRadius: 4,
                    hoverBorderWidth: 3,
                }
            }
        }

        // Cost per action data

        let costPerActionData = [];
        let dailySpendData = get(this.adSpendData, 'dataSummary.amountByRange', [])
        let dailySpendClickData = get(this.pageClicksData, 'dataSummary.fcaidsAmountByRange', [])
        maxVal = 0;
        for (let i = 0; i < dailyData.length; i++) {
            // making sure to never divide by zero
            let costPerAction = dailySpendData[i] === 0 ? 0 : (dailySpendData[i] / dailySpendClickData[i]);
            if (isNaN(costPerAction) || !isFinite(costPerAction)) {
                costPerAction = 0;
            }

            if (costPerAction > maxVal) {
                maxVal = costPerAction;
            }
            costPerActionData.push(Math.ceil(costPerAction));
        }

        maxVal = Math.ceil(4 * maxVal / 3);
        maxVal = Math.max(100, maxVal )


        this.costPerActionChart = {
            labels: daysOut,
            datasets: [
                {
                    label: 'Cost per Action',
                    backgroundColor: convertHex("#e5710b", 10),
                    borderColor: "#e5710b",
                    pointHoverBackgroundColor: chartColors[0].pointStrokeColor,
                    borderWidth: 3,
                    data: costPerActionData
                },
                {
                    type: 'bar',
                    label: 'Release',
                    backgroundColor: "#ff0044",
                    data: this.timeRangeChanges.map((i)=>i?Math.ceil(4 * maxVal / 3):0),
                    barThickness: 1,
                    order:2,
                },
            ]
        };

        this.costPerActionChartOpts = {
            maintainAspectRatio: false,
            tooltips: {
                yAlign: 'bottom'
            },
            legend: {
                // display: !mobileCheck()
                display: false
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        drawOnChartArea: false,
                    }
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        maxTicksLimit: 5,
                        stepSize: Math.ceil(maxVal / 5),
                        max: maxVal
                    }
                }]
            },
            elements: {
                line: {
                    fill: false
                },
                point: {
                    radius: 0,
                    hitRadius: 10,
                    hoverRadius: 4,
                    hoverBorderWidth: 3,
                }
            }
        }
    }


    GetClicksByService(active) {

        if (!this.cardData || isEmpty(this.data['CardAnalytics'])) {
            return {
                services: [],
                totalClicks: []
            }
        }

        let cardLinks = clone(active ? this.cardData.servicesLinkList : this.cardData.servicesInactiveLinkList);
        let actionsClick = keyBy(get(this.CardAnalytics, 'dataSummary.actions', []),'id');

        for (let i = 0; i < cardLinks.length; i++) {
            let action = cardLinks[i];

            action.percentage = get(actionsClick,`${action.actionID}.clickedPercentage`,0);

            action.clicks = get(actionsClick,`${action.actionID}.clicks`,0);

            cardLinks.splice(i, 1, action);
        }

        let services = cardLinks.slice().sort((a, b) => b.clicks - a.clicks, 10);
        return {
            services,
            totalClicks: this.totalClicks
        }
    }

    showDeviceTypeData() {

        let finalData = {
            labels: ['Desktop', 'Tablet', 'Mobile'],
            datasets: [
                {
                    data: [33.33, 33.33, 33.33],
                    backgroundColor: [
                        '#b3e5fc',
                        '#29b6f6',
                        '#bcaaa4'
                    ],
                    hoverBackgroundColor: [
                        '#b3e5fc',
                        '#29b6f6',
                        '#bcaaa4'
                    ]
                }]
        };


        if (!this.isDataAvailable()) { return finalData; }

        let categories = keyBy(get(this.CardAnalytics,'dataSummary.deviceCategory',[]),'name')

        let data = [];
        for (let i = 0; i < finalData.labels.length; i++) {
            let label = finalData.labels[i].toLowerCase();
            data.push(get(categories, `${label}.visits`, 0))
        }


        if (data.length && !(data.length === 1 && data[0] === 0)) {
            finalData.datasets[0].data = data;
            finalData.datasets[0].backgroundColor = data.map((item, index) => chartColors[index % chartColors.length].strokeColor);
            finalData.datasets[0].hoverBackgroundColor = data.map((item, index) => chartColors[index % chartColors.length].pointHighlightStroke);
        }

        return finalData
    }

    showPieData() {

        let pieData = {
            labels: ['', '', ''],
            datasets: [{
                data: [33.33, 33.33, 33.33],
                backgroundColor: [
                    '#66bb6a',
                    '#c8e6c9',
                    '#bdbdbd'
                ],
                hoverBackgroundColor: [
                    '#66bb6a',
                    '#c8e6c9',
                    '#bdbdbd'
                ]
            }]

        };

        if (!this.isDataAvailable()) { return pieData; }

        let labels = get(this.CardAnalytics, 'dataSummary.operatingSystems', []).map(l=>l.name);
        let data = get(this.CardAnalytics, 'dataSummary.operatingSystems', []).map(l=>l.visits);

        if (data.length && !(data.length === 1 && data[0] === 0)) {
            pieData.labels = labels;
            pieData.datasets[0].data = data;
            pieData.datasets[0].backgroundColor = data.map((item, index) => chartColors[index % chartColors.length].strokeColor);
            pieData.datasets[0].hoverBackgroundColor = data.map((item, index) => chartColors[index % chartColors.length].pointHighlightStroke);
        }

        return pieData;

    }
}