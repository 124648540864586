import React, { Fragment, Component } from 'react';
import { observer, inject } from "mobx-react";
import { withRouter } from 'react-router-dom';
import AspectRatio from 'react-aspect-ratio';
import Numeral from "numeral";
import {Hub, I18n} from 'aws-amplify';
import ShareModal from '../ShareModal/ShareModal';
import 'date-format-lite';
import 'react-aspect-ratio/aspect-ratio.css';
import Tag from '../../../components/Tag/Tag';
import {
    CardHeader,
    TagsContainer,
    TagInnerContainer,
    HeaderTopContent,
    styles
} from './styles';

import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import BarChartOutlinedIcon from '@material-ui/icons/BarChartOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import RightMenu from './RightMenu/RightMenu';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import AlertDialog from '../../../components/AlertDialog/AlertDialog';
import Hidden from "@material-ui/core/Hidden";
import EditIcon from "@material-ui/icons/Edit";
import { withStyles } from '@material-ui/core/styles';

class MyLinksRow extends Component {

    state = {
        shareOptionsModal: false,
        copied: false,
        openArchiveDialog: false,
        openUnarchiveDialog: false,
        anchorEl: null
    }

    toggle = field => {

        const { isOverLimit} = this.props;
        if(isOverLimit && (field === 'shareOptionsModal')){ this.openPaywall(); return;}

        this.setState({
            [field]: !this.state[field]
        });
    }

    isArchivedPage = ()=>{
        const { location } = this.props;
        return location.pathname === "/settings/archived-fastcards"
    }
    openPaywall = ()=>{
            Hub.dispatch('fcPayWall', {
                data : { flag: ""},
                event: 'clicked'});
    }

    goToNewAnalytics = () => {
        const { isOverLimit} = this.props;
        if(isOverLimit){ this.openPaywall(); return;}

        this.props.history.push(`/analytics/${this.props.card.Card_ID}`);
    }

    goToEdit = () => {
        const { isOverLimit} = this.props;
        if(isOverLimit){ this.openPaywall(); return;}

        this.props.model._setEdit(this.props.card.AllData)
        this.props.history.push(`/create/card/${this.props.card.Card_ID}`);
    }

    gotToDuplicate = () => {
        this.props.model._setDuplicate(this.props.card.AllData)
        this.props.history.push("/create/card");
    }

    handleArchiveConfirmation = () => {

        const { card, CardsDataModel } = this.props
        card.isArchived = true
        card.isAnEdit = true // so that we don't create another card
        card._saveCardData((error, data) => {
            if (error) {
                card.isArchived = false
                return
            }

            const index = CardsDataModel.LinksList.findIndex(value => {
                return value.Card_ID === card.Card_ID
            })


            if (index >= 0) {
                CardsDataModel.LinksList.splice(index, 1)
            }

            delete CardsDataModel.LinksListDict[card.Card_ID]

            this.toggle('openArchiveDialog')
        })
    }

    handleUnarchive = () => {
        const { CardsDataModel, card } = this.props

        card.isArchived = false
        card.isAnEdit = true // so that we don't create another card
        card._saveCardData((error, data) => {
            if (error) {
                card.isArchived = true
                return
            }

            const index = CardsDataModel.LinksList.findIndex(value => {
                return value.Card_ID === card.Card_ID
            })


            if (index >= 0) {
                CardsDataModel.LinksList.splice(index, 1)
            }

            delete CardsDataModel.LinksListDict[card.Card_ID]

            this.toggle('openUnarchiveDialog')
        })
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    onAddToCampaign = props => {
        this.setState({
            anchorEl: null
        })

        this.props.onAddToCampaign(props)
    }

    handleArchive = () => {
        this.setState({
            anchorEl: null,
            openArchiveDialog: true
        });
    }

    render() {
        const { card, cardModel, CardsDataModel, classes } = this.props;
        let isDisabled = false;
        if (this.isArchivedPage()) {
            isDisabled = true
        }

        return (
            <Fragment>
                <Card>
                    <CardHeader>
                        <HeaderTopContent>
                            <div style={{ width: '80%' }}>
                                <Typography noWrap={true} variant="h6" tabIndex='0' aria-label={card.Title}>{card.Title}</Typography>
                                <Typography noWrap={true} variant="body2">{card.Subtitle}</Typography>
                            </div>
                            {card.isArchived ?
                                <Tooltip title={I18n.get("_activate", "Activate")}>
                                    <Switch
                                        checked={!card.isArchived || this.state.openUnarchiveDialog}
                                        onChange={() => this.toggle('openUnarchiveDialog')}
                                        name="checkedB"
                                        color="primary"
                                    />
                                </Tooltip> :
                                <IconButton
                                    aria-label="Card Settings"
                                    size="small"
                                    onClick={this.handleClick}>
                                    <MoreVertIcon />
                                </IconButton>}
                        </HeaderTopContent>
                        {CardsDataModel && CardsDataModel.TagsList.length > 0 &&
                            <TagsContainer>
                                <TagInnerContainer>
                                    {Object.keys(card.tags)
                                        .map(key => {
                                            return <Tag
                                                key={key}
                                                text={key}
                                                color={card.tags[key]} />
                                        })}
                                </TagInnerContainer>
                            </TagsContainer>}
                    </CardHeader>
                    <Divider />
                    <AspectRatio
                        ratio="16/9"
                        style={{
                            backgroundImage: `url(${card.Image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}
                    />

                    <Divider />
                    <div className={classes.metricsContainer}>
                        <div className={classes.metricItem}>
                            <Typography variant="h6">{Numeral(cardModel.totalViews).format("0[.]0a")}</Typography>
                            <Typography color="primary" variant="subtitle2" style={{ textTransform: 'uppercase' }}>{I18n.get("_visits")}</Typography>
                        </div>
                        <div className={classes.metricItem}>
                            <Typography variant="h6" >{Numeral(cardModel.totalClicks).format("0[.]0a")}</Typography>
                            <Typography color="primary" variant="subtitle2" style={{ textTransform: 'uppercase' }}>{I18n.get("_clicks")}</Typography>
                        </div>
                        <div className={classes.metricItem}>
                            <Typography variant="h6">{Numeral(cardModel.totalClicks / cardModel.totalViews).format("0[.]0%")}</Typography>
                            <Typography color="primary" variant="subtitle2" style={{ textTransform: 'uppercase' }}>{I18n.get("_er")}</Typography>
                        </div>
                    </div>

                    { !isDisabled &&
                        <Hidden mdUp>
                            <Divider/>
                            <div className={classes.optionsContainer}>
                                <div className={classes.option} onClick={this.goToEdit}>
                                    <EditIcon fontSize="small"/>
                                    <Typography variant="body2">{I18n.get("_edit")}</Typography>
                                </div>
                                <div className={classes.option} onClick={this.goToNewAnalytics}>
                                    <BarChartOutlinedIcon fontSize="small"/>
                                    <Typography variant="body1">{I18n.get("_analytics")}</Typography>
                                </div>
                                <div className={classes.option} onClick={() => this.toggle('shareOptionsModal')}>
                                    <ShareOutlinedIcon fontSize="small"/>
                                    <Typography variant="body1">{I18n.get("_share")}</Typography>
                                </div>
                                <div className={classes.option}>
                                    <VisibilityOutlinedIcon fontSize="small"/>
                                    <Button
                                        className={classes.previewBttn}
                                        disabled={isDisabled}
                                        variant="text"
                                        target='_blank'
                                        rel="noopener"
                                        href={card.CardUrl}>
                                        <Typography color={card.isArchived ? 'textSecondary' : 'textPrimary'}
                                                    variant="subtitle2">{I18n.get("_preview_button")}</Typography>
                                    </Button>
                                </div>
                            </div>
                        </Hidden>

                    }

                    { !isDisabled &&
                        <Hidden smDown>
                            <Divider/>
                            <List style={{padding: 0}}>
                                <ListItem
                                    disabled={isDisabled}
                                    onClick={this.goToNewAnalytics} role='none' button>
                                    <ListItemIcon style={{minWidth: 0, marginRight: '16px'}}>
                                        <BarChartOutlinedIcon/>
                                    </ListItemIcon>
                                    <Typography variant="subtitle2">{I18n.get("_view_analytics")}</Typography>
                                </ListItem>
                                <Divider/>
                                <ListItem
                                    disabled={isDisabled}
                                    onClick={this.goToEdit} button>
                                    <ListItemIcon style={{minWidth: 0, marginRight: '16px'}}>
                                        <EditOutlinedIcon/>
                                    </ListItemIcon>
                                    <Typography variant="subtitle2">{I18n.get("_edit")}</Typography>
                                </ListItem>
                                <Divider/>
                                <ListItem
                                    disabled={isDisabled}
                                    onClick={() => this.toggle('shareOptionsModal')} button>
                                    <ListItemIcon style={{minWidth: 0, marginRight: '16px'}}>
                                        <ShareOutlinedIcon/>
                                    </ListItemIcon>
                                    <Typography variant="subtitle2">{I18n.get("_share_link")}</Typography>
                                </ListItem>
                                <Divider/>
                                <ListItem
                                    disabled={isDisabled}
                                    style={{padding: 0}}>
                                    <Button
                                        className={classes.previewBttn}
                                        disabled={isDisabled}
                                        variant="text"
                                        target='_blank'
                                        rel="noopener"
                                        href={card.CardUrl}>
                                        <ListItemIcon style={{minWidth: 0, marginRight: '16px'}}>
                                            <VisibilityOutlinedIcon/>
                                        </ListItemIcon>
                                        <Typography color={card.isArchived ? 'textSecondary' : 'textPrimary'}
                                                    variant="subtitle2">{I18n.get("_go_to_page")}</Typography>
                                    </Button>
                                </ListItem>
                                <Divider/>
                                <ListItem
                                    style={{padding: '16px', textAlign: 'right'}}>
                                    <Typography
                                        variant="caption">{I18n.get("_last_updated") + ": " + card.TimeAgo.format("MMM D, YYYY")}</Typography>
                                </ListItem>
                            </List>
                        </Hidden>

                    }
                    <ShareModal
                        open={this.state.shareOptionsModal}
                        handleClose={() => this.toggle('shareOptionsModal')}
                        card={card} />
                </Card>
                <RightMenu
                    handleArchive={this.handleArchive}
                    gotToDuplicate={this.gotToDuplicate}
                    anchorEl={this.state.anchorEl}
                    isDisabled={isDisabled}
                    handleClose={this.handleClose}
                    onAddToCamapaign={this.onAddToCampaign}
                    cardId={card.Card_ID} />
                <AlertDialog
                    aria-labelledby="confirm archive dialog"
                    aria-describedby="dialog to confirm archive of fastcard"
                    open={this.state.openArchiveDialog}
                    title={I18n.get("_confirmation", "Confirmation")}
                    message={I18n.get("_archive_confirm_title", "Are you sure you want to archive this fastcard?")}
                    okLabel={I18n.get("_confirm", "Confirm")}
                    cancelLabel={I18n.get("_cancel", "Cancel")}
                    loading={card.loading}
                    handleOk={this.handleArchiveConfirmation}
                    handleClose={() => this.toggle('openArchiveDialog')} />
                <AlertDialog
                    aria-labelledby="confirm unarchive dialog"
                    aria-describedby="dialog to confirm unarchive a fastcard"
                    open={this.state.openUnarchiveDialog}
                    title={I18n.get("_confirmation", "Confirmation")}
                    message={I18n.get("_unarchive_confirm_title2", "Are you sure you want to unarchive this fastcard?")}
                    okLabel={I18n.get("_confirm", "Confirm")}
                    cancelLabel={I18n.get("_cancel", "Cancel")}
                    loading={card.loading}
                    handleOk={this.handleUnarchive}
                    handleClose={() => this.toggle('openUnarchiveDialog')} />
            </Fragment>
        );
    }
}

export default withStyles(styles)(withRouter(inject("model", "CardsDataModel")(observer(MyLinksRow))));
