import { Auth } from 'aws-amplify';
import {endpoint, endpointName} from '../constants/api-endpoint';
import { adminProtocolStorage } from '../constants/data-fetching';

export const amplifyConfig= {
    API: {
        endpoints: [
            {
                name: endpointName.base,
                endpoint: endpoint.base,
                custom_header: async () => {
                    let accessToken = (await Auth.currentSession()).idToken.jwtToken;
                    let overWatchProtocol = window.sessionStorage && window.sessionStorage.getItem(adminProtocolStorage) ? window.sessionStorage.getItem(adminProtocolStorage) : "";
                    return {
                        accessToken,
                        overWatchProtocol,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Methods": "*",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "*"
                    }
                }
            },
            {
                name: endpointName.payment,
                endpoint: endpoint.payment,
                custom_header: async () => {
                    let accessToken = (await Auth.currentSession()).idToken.jwtToken;
                    let overWatchProtocol = window.sessionStorage && window.sessionStorage.getItem(adminProtocolStorage) ? window.sessionStorage.getItem(adminProtocolStorage) : "";
                    return {
                        accessToken,
                        overWatchProtocol,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Methods": "*",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "*"
                    }
                }
            },
            {
                name: endpointName.auth,
                endpoint: endpoint.auth,
                custom_header: async () => {
                    let accessToken = (await Auth.currentSession()).idToken.jwtToken;
                    let overWatchProtocol = window.sessionStorage && window.sessionStorage.getItem(adminProtocolStorage) ? window.sessionStorage.getItem(adminProtocolStorage) : "";
                    return {
                        accessToken,
                        overWatchProtocol,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Methods": "*",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "*"
                    }
                }
            }
        ]
    },
    Auth: {

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: 'us-east-1:c401d363-3cd9-482c-b730-c3d508bff142',

        // REQUIRED - Amazon Cognito Region
        region: 'us-east-1',

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        // identityPoolRegion: 'XX-XXXX-X',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-east-1_Qd2eViPuw',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: 'rnbfts8pqdvmqg3o6deovff14',

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        // authenticationFlowType: 'USER_PASSWORD_AUTH',

        // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        // clientMetadata: { myCustomKey: 'myCustomValue' },

        // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: "auth.fast.cm",
            scope: [
                "phone",
                "email",
                "profile",
                "openid",
                "aws.cognito.signin.user.admin",
            ],
            redirectSignIn: process.env.REACT_APP_API_BASE,
            redirectSignOut: process.env.REACT_APP_API_BASE,
            responseType: "code"
        }
    },
    Storage: {
        AWSS3: {
            bucket: 'fastlinkuploads', //REQUIRED -  Amazon S3 bucket
            region: 'us-east-1', //OPTIONAL -  Amazon service region
        }
    }
};



