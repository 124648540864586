import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import axios from 'axios';
import Select from '@material-ui/core/Select';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';
import { I18n } from 'aws-amplify';
import TextInput from "../TextInput/TextInput";
import YoutubeItem from "./YoutubeItem/YoutubeItem";

const styles = {
    paper: {
        width: '370px',
    },
};

export const YoutubeTypes = {
    video: 'video',
    channel: 'channel',
    videoAndchannel: 'videoAndChannel'
}

class YoutubeSearchInput extends Component {

    state = {
        dropdownOpen: false,
        typeSelected: "video",
        suggestions: [],
        value: '',
        options: [],
        open: false,
        loading: false
    };

    inputRef = React.createRef()

    handleOnSearch = value => {

        this.setState({
            value: value,
            suggestions: []
        });

        clearTimeout(this.typingTimer);

        this.typingTimer = setTimeout(() => {
            if (value) {
                this.getSuggestions(value, this.props.itemType === YoutubeTypes.videoAndchannel ? this.state.typeSelected : this.props.itemType)
            }
        }, 400);
    };

    handleClose = () => {
        this.setState({
            open: false
        });
    };

    toggle = () => {
        this.setState(prevState => {
            return {
                dropdownOpen: !prevState.dropdownOpen
            }
        });
    }

    handleTypeChanged = event => {
        this.setState({
            typeSelected: event.target.value
        })
    }

    getSuggestions = (value, type) => {
        const baseUrl = "https://www.googleapis.com/youtube/v3/search?";

        axios.get(baseUrl + `q=${encodeURI(value)}&type=${type}&part=snippet&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`)
            .then(result => {
                this.handleSuggestions(result.data.items)
            })
            .catch(error => {
                this.setState({
                    open: false,
                    suggestions: []
                })
            })
    }

    handleSuggestions = suggestions => {
        const items = suggestions.map(el => {

            var url = ""

            if (this.props.itemType === YoutubeTypes.video) {

                url = "https://youtu.be/" + el.id.videoId

            } else if (this.props.itemType === YoutubeTypes.channel) {

                url = "https://youtube.com/channel/" + el.id.channelId;

            } else {
                switch (this.state.typeSelected) {
                    case "video":
                        url = "https://youtu.be/" + el.id.videoId;
                        break;
                    case "channel":
                        url = "https://youtube.com/channel/" + el.id.channelId;
                        break;
                    default:
                        break
                }
            }

            return {
                ...el.snippet,
                url: url,
                image: el.snippet.thumbnails.default.url
            }
        })

        this.setState({
            suggestions: items.slice(0, 4),
            open: items.length > 0 ? true : false
        })
    }

    componentWillUnmount() {
        clearTimeout(this.typingTimer);
    }

    getVideoID = (link) => {
        const regEx = "^(?:https?:)?//[^/]*(?:youtube(?:-nocookie)?\.com|youtu\.be).*[=/]([-\\w]{11})(?:\\?|=|&|$)";
        const videoID = link.match(regEx);
        return videoID[1]
    }

    getSuggestionValue = (suggestion) => {
        if (this.props.actionModel) {
            this.props.actionModel.Title = suggestion.title;
            this.props.actionModel.Link = suggestion.url;
        } else {
            this.props.cardModel.Image = suggestion.thumbnails.high.url
        }

        if (this.props.onChange) {
            this.props.onChange(suggestion.url)
        }

        this.setState({
            open: false
        })
    }

    onChange = (event, { newValue }) => {
        if (this.props.actionModel) {
            this.props.actionModel.Link = newValue;
        }

        if (this.props.onChange) {
            this.props.onChange(newValue)
        } else {
            this.setState({
                value: newValue
            });
        }
    };

    render() {

        const { classes } = this.props

        return (
            <div style={{ flex: 1 }}>
                <TextInput
                    type={'text'}
                    field={'Link'}
                    ref={this.inputRef}
                    label={this.props.label ? this.props.label : I18n.get("_youtube_search", "Search in Youtube")}
                    placeholder={'https://www.youtube.com/@UndecidedMF'}
                    store={this.props.actionModel}
                    disabled={this.props.cardModel.loading}
                    showRequiredHelperText={I18n.get("_required", "Required")}
                    InputProps={{
                        endAdornment: (
                            <React.Fragment>
                                {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                            </React.Fragment>
                        ),
                    }}
                    onChange={this.handleOnSearch}
                />
                <Popover
                    open={this.state.open}
                    classes={{
                        paper: classes.paper
                    }}
                    anchorEl={this.inputRef.current}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <List>
                        {this.state.suggestions.map((el, index) => {
                            return <YoutubeItem
                                key={`${el.title}-${index}`}
                                suggestion={el}
                                handleSelected={this.getSuggestionValue} />
                        })}
                    </List>
                </Popover>
                {this.props.itemType === YoutubeTypes.videoAndchannel &&
                    <Select
                        value={this.state.typeSelected}
                        onChange={this.handleTypeChanged}
                        margin="dense"
                        style={{ marginTop: '16px', width: '200px' }}
                        variant="outlined" >
                        <MenuItem value="video">{I18n.get('_video')}</MenuItem>
                        <MenuItem value="channel">{I18n.get('_channel')}</MenuItem>
                    </Select>}
            </div>
        );
    }
}

YoutubeSearchInput.propTypes = {
    actionModel: PropTypes.object,
    cardModel: PropTypes.object.isRequired,
    hideOptions: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
    itemType: PropTypes.string,

}

export default withStyles(styles)(observer(YoutubeSearchInput));
