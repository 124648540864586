import {observer} from "mobx-react";
import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const CheckboxInput = observer(({ store, field, label, onChange }) => {
    // const errors = store.errors && store.errors[field]
    // const hasError = !!errors
    // const dirty = store.validating || store.touchedMap.has(field);

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={store[field]}
                    onChange={event => {
                        store.setField(field, event.target.checked)
                        if (onChange){
                            onChange(event)
                        }
                    }}
                    color="primary"
                />
            }
            label={label}
        />

    );
});

export default CheckboxInput;
