import React, { Component } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core";
import clsx from 'clsx';


class ScrollArea extends Component {

    constructor(props) {
        super(props);
        this.ref = this.props.innerRef ? this.props.innerRef : React.createRef();

    }

    render() {
        const showScroll = this.ref.current && this.props.showBarOnOverFlow ? this.ref.current.clientHeight < this.ref.current.scrollHeight : false
        const { classes } = this.props

        return <div ref={this.ref} style={{ height: this.props.height !== undefined ? this.props.height : '100%' }}
            className={clsx(classes.root, { [classes.showBar]: showScroll, [classes.hideBar]: !showScroll })}>
            {this.props.children}
        </div>

    }
}


export default withStyles(styles, { withTheme: true })(React.forwardRef((props, ref) => (
    <ScrollArea {...props} innerRef={ref} />
)));
