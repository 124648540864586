import React from 'react';
import { observer } from 'mobx-react';
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { useStyles } from './styles';
import CustomAvatar from "../../../CustomAvatar/CustomAvatar";

const WorkSpaceListItem = ({ item, onWorkSpaceSelected, isPersonal, UserModel }) => {

    const classes = useStyles()

    return (
        <div style={{ cursor: "pointer" }}>
            <ListItem button onClick={() => { onWorkSpaceSelected(item.workspace_ID) }}>
                <ListItemAvatar style={{ minWidth: 44 }}>
                    <CustomAvatar
                        width={30}
                        height={30}
                        textColor={item.color}
                        backgroundColor={item.colorHex}
                        picture={item.picture}
                        initials={item.initials}
                        name={item.name}/>
                </ListItemAvatar>
                <ListItemText primary={item.name} />
            </ListItem>
        </div>
    );
}

export default observer(WorkSpaceListItem);
