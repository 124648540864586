import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { useUserModel } from '../../../../../../hooks';
import { I18n, API } from 'aws-amplify';
import { useStyles } from './styles';
import * as Sentry from "@sentry/react";
import CircularProgress  from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import AccountsDialog from '../../../../../../components/AccountsDialog/AccountsDialog';
import moment from 'moment';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import lodashCapitalize from 'lodash/capitalize'
import FastcardAuthForm from "../../../../../../components/FastcardAuthForm/FastcardAuthForm";
import { inject, observer } from "mobx-react";
import Skeleton from '@material-ui/lab/Skeleton';
import lodashEmpty from 'lodash/isEmpty'

/**
 * 
 * @param {string} provider
 */
const SocialInput = ({ provider, actionModel }) => {

    const [accountConnected, setAccountConnected] = useState(null)
    const [loading, setLoading] = useState(false)
    const [openAccountsDialog, setOpenAccountsDialog] = useState(false);
    const [openFastFormAuthDialog, setOpenFastFormAuthDialog] = useState(false);
    const [loadingSync, setLoadingSync] = useState(false)
    const [dataSourceId, setDataSourceId] = useState();
    const [providerIntegration, setAvailableIntegrations] = useState(null)
    const [serviceStatus, setServiceStatus] = useState({})
    const [socialSearchData, setSocialSearchData] = useState(actionModel.socialSearchData[provider])
    const userModel = useUserModel();

    const classes = useStyles();

    function fetchConnectedAccounts() {
        setLoading(true)
        userModel.fetchConnectedAccounts((err, data) => {
            setLoading(false);
            if (err) {
                return
            }

            let accounts = data.sources?.filter(source => source?.provider === provider)

            if (accounts.length && accounts.length > 0) {
                setAccountConnected(accounts[0])
            } else {
                setAccountConnected(null)
            }

        })
    }

    const handleConnect = async (provider, integration) => {

        // try {
        //     let { authHandler, params, authId } = integration;
        //
        //     if (authHandler === "fastmindForm") { setOpenFastFormAuthDialog(true); return; }
        //
        //     const providerConnection = (authHandler === 'xkit') ? await userModel.XKitClient.connect(provider) : await userModel.PizzlyClient.integration(provider).connect({
        //         authId,
        //         params
        //     })
        //     console.log('the provider connection--->', providerConnection)
        //
        //     upsertDataSource({ ...providerConnection, authHandler, provider })
        //
        // } catch (error) {
        //     Sentry.captureException(error);
        // }
    }

    const upsertDataSource = (data) => {

        let path = '/dataSources/upsert';
        let apiName = 'fastCardAuth';

        API.post(apiName, path, { body: data })
            .then(result => {
                setDataSourceId(result.dataSourceID)
                setOpenAccountsDialog(true)
            })
            .catch(error => {
                Sentry.captureException(error);
            })
    }


    function fetchAvailableIntegrations() {
        setLoading(true);
        userModel.fetchAvailableIntegrations((err, data) => {
            setLoading(false);
            if (err) {
                return
            }

            let integrations = data?.filter(integration => integration.provider === provider)

            if (integrations.length && integrations.length > 0) {
                setAvailableIntegrations(integrations[0])
            } else {
                setAvailableIntegrations(null)
            }
        })
    }

    const handleClose = (success) => {
        if (success) {
            setOpenAccountsDialog(false);
            fetchConnectedAccounts()
            handleSync()
        } else {
            setOpenAccountsDialog(false);
        }
    };

    useEffect(() => {

        if (!accountConnected) {
            fetchAvailableIntegrations();
        }
    }, [accountConnected])

    useEffect(() => {
        fetchConnectedAccounts()
        handleStatus()
    }, [])


    const handleSync = () => {
        let path = '/socialSearchSync';
        let apiName = 'fastShare';

        setLoadingSync(true)
        API.post(apiName, path, { body: { service: provider } })
            .then(result => {
                setSocialSearchData({service:true})
                actionModel.socialSearchData[provider] = {service:true}
            })
            .catch(error => {
                Sentry.captureException(error);
            }).finally(() => {
                // setLoadingSync(false)
                handleStatus()
            })
    }

    const handleStatus = (callback) => {
        let path = '/getSocialSearchStatus';
        let apiName = 'fastShare';
        setLoadingSync(true)
        setTimeout(() => {
            API.post(apiName, path, { body: { service: provider } })
                .then(result => {
                    // console.log(' sync result ---->', result)
                    setServiceStatus(result.data)
                })
                .catch(error => {
                    Sentry.captureException(error);
                }).finally(() => {
                    setLoadingSync(false)
                })
        }, 5000)
    }

    const onChange = (event) => {
        if (!accountConnected) { handleConnect(provider, providerIntegration); return; }

        // we already have an account connected so now we will sync it up
        if (accountConnected && !actionModel.socialSearchData[provider]) {
            // if we have a last time fetched then
            handleSync(); return;
        }
        // we already have an account connected so now we will sync it up
        if (accountConnected && actionModel.socialSearchData[provider]) {
            removeSync(); return;
        }
    }

    const removeSync = () => {
        delete actionModel.socialSearchData[provider];
        setSocialSearchData(undefined);
    };

    const shouldBeDisabled = () => {
        return loading || loadingSync
    }

    const closeFastCardAuth = () => {
        setOpenFastFormAuthDialog(false);
    }

    const handleFormSubmit = (data) => {
        upsertDataSource(data)
        closeFastCardAuth();
    }

    // Show Skeleton when originally loading
    if(!providerIntegration && loading){ return <Skeleton style={{ marginBottom: '8px' }} variant="rect" width={'100%'} height={34} />}

    // Don't show anything if the call fails
    if(!providerIntegration && !loading){ return null}

    return (
        <ListItem disabled={shouldBeDisabled()}>
            <AccountsDialog
                open={openAccountsDialog}
                dataSourceId={dataSourceId}
                handleClose={handleClose}
                provider={provider} />
            {providerIntegration && providerIntegration.schema &&
                <FastcardAuthForm
                    loading={loadingSync}
                    provider={provider}
                    providerIntegration={providerIntegration}
                    schema={providerIntegration.schema}
                    formSubmit={handleFormSubmit}
                    handleClose={closeFastCardAuth} open={openFastFormAuthDialog} />}
            <ListItemAvatar>
                <Avatar
                    alt={`${providerIntegration?.integrationName || provider} Logo`}
                    src={providerIntegration?.ImageUrl}
                />
            </ListItemAvatar>
            <ListItemText
                primary={lodashCapitalize(providerIntegration?.integrationName || provider)}
                secondary={(!accountConnected || !serviceStatus.lastFetched) ? "" : `${I18n.get("_last_updated", "Last Updated")} :${moment(serviceStatus.lastFetched).fromNow()} (${I18n.get(serviceStatus.status)})`}
            />
            <ListItemSecondaryAction>
                {
                    loadingSync ?
                        <CircularProgress size={24} /> :
                        <Switch
                            edge="end"
                            disabled={shouldBeDisabled()}
                            color="primary"
                            onChange={onChange}
                            checked={(!!serviceStatus.lastFetched && !lodashEmpty(accountConnected) && !lodashEmpty(socialSearchData))}
                            inputProps={{ 'aria-labelledby': `switch-list-label-${provider}` }}
                        />
                }
            </ListItemSecondaryAction>
        </ListItem>
    )
}

export default inject("model")(observer(SocialInput));