import React from 'react';
import { decorate, observable } from "mobx";
import CardContent from '@material-ui/core/CardContent';
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import NewActionHeader from '../../NewAction/NewActionHeader/NewActionHeader';
import SocialInput from '../../NewAction/inputs/SocialInput/SocialInput';
import SocialSearchFilterInput from "../../NewAction/inputs/SocialSearchFilterInput/SocialSearchFilterInput";
import Divider from "@material-ui/core/Divider";
import List from '@material-ui/core/List';
import {Typography} from "@material-ui/core";
import {I18n} from "aws-amplify";

class InstagramSearchAction extends ActionModel {
	static metadata = {
		id: "socialSearch",
		name: "Social Search",
		icon: "https://l.fast.cm/content/icons/socialSearch.png",
		categories: {
			social: true
		},
		// help:{
			// id: "articles/social-search-feature-for-instagram"
		// }
	};

	Title = "Social Search";
	Link = "https://www.fast.cm/"
	ButtonText = "GO";
	isValid = false;
	socialSearchData = {
		hashtags :[],
		mentions: [],
	};

	constructor(dat) {
		super();
		this.propNames.push("socialSearchData");
		this.setFromObject(dat);
	}


	getConfigCardBody(cardModel, handleOnChangeIcon) {
		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />

				<div>
					<Typography style={{ marginBottom: '8px' }}>{I18n.get('_social_search_text')}:</Typography>
					<List dense={true}>
						{
							['instagram','youtube-channel'].map(i => <SocialInput actionModel={this} key={i} provider={i} />)
						}
					</List>
				</div>

				<Divider style={{ margin: '24px 0 16px 0' }} />
				<SocialSearchFilterInput actionModel={this} />
			</CardContent>
		)
	}
}

decorate(InstagramSearchAction, {
	Link: observable,
	Title: observable,
	ButtonText: observable,
	isValid: observable,
	socialSearchData: observable,
})

export default InstagramSearchAction
