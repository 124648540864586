import FastForm from "./FastForm";
import CardContent from "@material-ui/core/CardContent";
import NewActionHeader from "../../NewAction/NewActionHeader/NewActionHeader";
import FormInput from "../../NewAction/inputs/FastFormInput/FastFormInput";
import React from "react";

class FastFormZapier extends FastForm {

    static metadata = {
        id: "FastFormZapier",
        name: "Fast Form Zapier",
        icon: "https://l.fast.cm/content/icons/zapier.png",
        categories: {
            integration: true
        }
    };


    // Title = "Fast Form Zapier";
    Link = "https://www.zapier.com";


    getConfigCardBody(cardModel, handleOnChangeIcon) {
        return (
            <CardContent>
                <NewActionHeader
                    onChangeIcon={handleOnChangeIcon}
                    actionModel={this} />
                <FormInput
                    learnMore={"https://zapier.com/help/create/code-webhooks/trigger-zaps-from-webhooks"}
                    hookPlaceholder={"https://hooks.zapier.com/hooks/catch/abcde/1234567/"}
                    cardModel={cardModel}
                    actionModel={this} />
            </CardContent>
        )
    }
}

export default FastFormZapier;