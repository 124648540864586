import React from 'react';
import { observable, decorate } from "mobx";
import CardContent from '@material-ui/core/CardContent';
import { I18n } from 'aws-amplify';
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import NewActionHeader from '../../NewAction/NewActionHeader/NewActionHeader';
import UsernameInput from '../../NewAction/inputs/UsernameInput/UsernameInput';

class SnapchatAction extends ActionModel {
	static metadata = {
		id: "snapchat",
		name: "Snapchat",
		icon: "https://l.fast.cm/content/icons/snapchat.png",
		categories: {
			// social: true
		}
	};

	constructor(dat) {
		super();

		// this.propNames = ["Title", "Image", "Subtitle", "ButtonText", "destId"];
		this.propNames.push("destId");
		this.setFromObject(dat);
	}

	Title = "Snapchat";
	Link = "https://www.snapchat.com/add/";
	ButtonText = "GO";
	isValid = false;

	getConfigCardBody(cardModel, handleOnChangeIcon) {
		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />
				<UsernameInput
					placeHolderText={I18n.get("_username")}
					cardModel={cardModel}
					actionModel={this} />
			</CardContent>
		)
	}

	getRules() {
		return {
			destId: {
				presence: { allowEmpty: false }
			},
			...super.getRules()
		}
	}
}

decorate(SnapchatAction, {
	Title: observable,
	Link: observable,
	isValid: observable,
	ButtonText: observable,
	destId: observable
})

export default SnapchatAction
