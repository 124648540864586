import React from "react";
import { decorate } from "mobx";
import CardContent from '@material-ui/core/CardContent';
import { I18n } from 'aws-amplify';
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import NewActionHeader from "../../NewAction/NewActionHeader/NewActionHeader";

class createOwnFastCardAction extends ActionModel {
    static metadata = {
        id: "createOwnFastCard",
        name: "Create Your Own FastCard",
        icon: "https://l.fast.cm/content/icons/createOwnFastCard.png",
        categories: {
            general: true
        },
        // help: {
        //     // id: 'articles/about-general-links-text-message',
        //     helperText: I18n.get('_createOwnFastCard_help_text', "Click to learn more about the Text action")
        // }
    };




    getConfigCardBody(cardModel, handleOnChangeIcon) {
        return (
            <CardContent>
                <NewActionHeader
                    onChangeIcon={handleOnChangeIcon}
                    actionModel={this} />
            </CardContent>
        )
    }


}

decorate(createOwnFastCardAction, {
})

export default createOwnFastCardAction
