import { useModels } from "./useModels";
import { useObserver } from 'mobx-react';

export function useTheme() {
    const { theme } = useModels();

    return useObserver(() => ({
        current: theme.current,
        type: theme.type,
        toggleTheme: theme.toggleTheme,
    }))
}
