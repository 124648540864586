import React, {  useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {useFormik} from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, InputLabel, MenuItem,
    TextField,
    Select
} from "@material-ui/core";
import {API, I18n} from 'aws-amplify';
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import lodashOmit from "lodash/omit";
import * as Sentry from "@sentry/react";
import {endpointName} from "../../../../../../constants/api-endpoint";
import lodashGet from "lodash/get";
import {baseUrl} from "../../../../../../constants/help-endpoint";


AddRetailerDialog.propTypes = {

};

function AddRetailerDialog(props) {
    const { open, handleClose, onRetailerAdded }= props;
    const [loading , setLoading] = useState(false);
    const [retailer , setRetailer] = useState('walmart');
    const [availableRetailers, setAvailableRetailers] = useState([]);
    const [availableRetailersMap, setAvailableRetailersMap] = useState({});


    useEffect(() => {
        // on component did mount
        setLoading(true);
        API.post(endpointName.base, "/actions/FastProduct/available-retailers", {body:{}})
            .then((result) => {
                let retailers = lodashGet(result, 'data',[]);
                let d = {}
                for (let i = 0; i < retailers.length; i++) {
                    let r = retailers[i];
                    d[r.source] = r
                }

                setAvailableRetailers(retailers);
                setAvailableRetailersMap(d);
            })
            .catch((error) => {
                Sentry.captureException(error);
            })
            .finally(()=>{
                setLoading(false);
            });

    }, []);


    const formikAddRetailer = useFormik({
        initialValues: {
            source: 'walmart',
            source_type: 'online',
            sku: '',
        },
        validationSchema: Yup.object({
            source: Yup.string().max(25).required(I18n.get('_field_required')),
            source_type: Yup.string().max(25).required(I18n.get('_field_required')),
            sku: Yup.string().max(25).required(I18n.get('_field_required')),
        }),
        onSubmit: async (values, helpers) => {
            try {
                let init={
                    body: {...values, productId:values.sku,fromBuilder:true}
                }

                let data = await API.post(endpointName.base,`/actions/FastProduct/product-details`,init)
                toast.success(I18n.get("_retailer_added"))
                helpers.setStatus({ success: true });
                helpers.setSubmitting(false);
                if(onRetailerAdded){ onRetailerAdded(lodashOmit({...values,...data},['fromBuilder']))}

            } catch (err) {
                toast.error(I18n.get('_request_failed'));
                helpers.setStatus({ success: false });
                helpers.setErrors({...err.response.data});
                helpers.setSubmitting(false);
            }
        }
    });


    const showForm = ()=>{
        return (
            <form
            autoComplete={'off'}
            onSubmit={formikAddRetailer.handleSubmit}>
            <DialogContent>
                <DialogContentText>
                    {I18n.get('_add_retailer_msg')}
                </DialogContentText>

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <FormControl
                            margin="dense"
                            required
                            error={Boolean(formikAddRetailer.touched.source && formikAddRetailer.errors.source)}
                            disabled={formikAddRetailer.isSubmitting}
                            variant="outlined"
                            fullWidth>
                            <InputLabel id="retailer-select-outlined-label">{I18n.get('_retailer')}</InputLabel>
                            <Select
                                labelId="retailer-select-outlined-label"
                                name={'source'}
                                onBlur={formikAddRetailer.handleBlur}
                                value={formikAddRetailer.values.source}
                                onChange={formikAddRetailer.handleChange}
                                label={I18n.get('_retailer')}>
                                {availableRetailers.map(i=><MenuItem key={i.source} value={i.source}>{i.name}</MenuItem>)}
                            </Select>
                            <FormHelperText>{formikAddRetailer.errors.source}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            margin="dense"
                            required
                            error={Boolean(formikAddRetailer.touched.source_type && formikAddRetailer.errors.source_type)}
                            disabled={formikAddRetailer.isSubmitting}
                            variant="outlined"
                            fullWidth>
                            <InputLabel id="retailer_type-select-outlined-label">{I18n.get('_retailer_type')}</InputLabel>
                            <Select
                                labelId="retailer_type-select-outlined-label"
                                name={'source_type'}
                                onBlur={formikAddRetailer.handleBlur}
                                value={formikAddRetailer.values.source_type}
                                onChange={formikAddRetailer.handleChange}
                                label={I18n.get('_retailer_type')}>
                                {lodashGet(availableRetailersMap[formikAddRetailer.values.source],'source_types',[]).map(i=><MenuItem key={i} value={i}>{I18n.get(`_retailer_type_${i}`)}</MenuItem>)}
                            </Select>
                            <FormHelperText>{formikAddRetailer.errors.source_type}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                <TextField
                    disabled={formikAddRetailer.isSubmitting}
                    error={Boolean(formikAddRetailer.touched.sku && formikAddRetailer.errors.sku)}
                    fullWidth
                    helperText={formikAddRetailer.touched.sku && formikAddRetailer.errors.sku}
                    label={I18n.get("_productID")}
                    name="sku"
                    onBlur={formikAddRetailer.handleBlur}
                    onChange={formikAddRetailer.handleChange}
                    required
                    value={formikAddRetailer.values.code}
                    margin="dense"
                    variant="outlined"
                />
                <Button target="_blank"  href={`${baseUrl}${lodashGet(availableRetailersMap[formikAddRetailer.values.source],'helpCenter','')}`} color="primary">
                    {I18n.get("_learn_more_retailer_product_id")}
                </Button>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={formikAddRetailer.isSubmitting}
                    color={'primary'}
                    variant={'outlined'} onClick={handleClose}>{I18n.get('_cancel')}</Button>
                <Button
                    color={'primary'}
                    type="submit"
                    style={{color:"white"}}
                    disabled={formikAddRetailer.isSubmitting}
                    variant={'contained'}>
                    {formikAddRetailer.isSubmitting ? <CircularProgress size="1rem" style={{ color: 'white' }} /> : I18n.get("_add_retailer")}
                </Button>
            </DialogActions>
        </form>)
    }

    const showLoader = ()=>{
        return  (<DialogContent><div style={{textAlign:'center'}}><CircularProgress size='4rem' /></div></DialogContent>)
    }
    return (
        <Card>
            <Dialog
                fullWidth
                open={open}
                TransitionProps={{
                    onEnter:()=>{
                        formikAddRetailer.resetForm();
                    },
                }}
                onClose={handleClose}>
                <DialogTitle>{I18n.get('_add_retailer')}</DialogTitle>
                {loading?showLoader():showForm()}
            </Dialog>
        </Card>
    );
}

export default AddRetailerDialog;