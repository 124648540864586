import { Flex, Image, useTheme } from "@aws-amplify/ui-react";
import logoImage from "../../../assets/images/auth-logo.png";
import React from "react";

const CustomAuthHeader = (props)=> {
    const { tokens } = useTheme();
    return (
        <Flex justifyContent="center">
            <Image
                alt="logo"
                src={logoImage}
                padding={tokens.space.xxl}
            />
        </Flex>
    );
}

export default CustomAuthHeader;
