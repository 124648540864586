import React from 'react';
import { observable, decorate } from "mobx";
import CardContent from '@material-ui/core/CardContent';
import { I18n } from 'aws-amplify';
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import UserName from '../../NewAction/inputs/UsernameInput/UsernameInput'
import NewActionHeader from '../../NewAction/NewActionHeader/NewActionHeader';

class TwitterAction extends ActionModel {

	static metadata = {
		id: "twitter",
		name: "Twitter",
		icon: "https://l.fast.cm/content/icons/twitter.png",
		categories: {
			// social: true
		}
	};

	Title = "Twitter";
	Link = "https://www.Twitter.com/"
	ButtonText = "GO";
	isValid = false
	destId = "";

	constructor(dat) {
		super();

		// this.propNames = ["Title", "Image", "Subtitle", "ButtonText", "destId"];
		this.propNames.push("destId");
		// This completely overwrites the existing validatorMap making these the only required properties
		this.validatorMap = {
			Title: "string",
			destId: "string"
		};

		this.setFromObject(dat);
	}

	onValidateUrl = () => {
		this.isValid = true
	}

	getConfigCardBody(cardModel, handleOnChangeIcon) {
		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />
				<UserName
					placeHolderText={I18n.get("_username")}
					cardModel={cardModel}
					actionModel={this} />
			</CardContent>
		)
	}
}

decorate(TwitterAction, {
	Link: observable,
	isValid: observable,
	Title: observable,
	ButtonText: observable,
	destId: observable
})

export default TwitterAction