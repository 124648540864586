import React from "react";
import { observable, decorate } from "mobx";
import CardContent from '@material-ui/core/CardContent';
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import NewActionHeader from "../../NewAction/NewActionHeader/NewActionHeader";
import { I18n } from "aws-amplify";

class PollAction extends ActionModel {
	static metadata = {
		id: "poll",
		name: "Poll",
		icon: "https://l.fast.cm/content/icons/poll.png",
		categories: {
			general: true
		},
		help: {
			id: 'articles/about-general-links-poll-how-does-it-work',
			helperText: I18n.get('_poll_help_text', "Click to learn more about the Poll action")
		}
	};

	Link = "https://fast.cm";

	constructor(dat) {
		super();
		this.setFromObject(dat);
	}


	getConfigCardBody(cardModel, handleOnChangeIcon) {

		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />
			</CardContent>
		)
	}

}

decorate(PollAction, {
	Image: observable
})

export default PollAction;
