import { observer } from "mobx-react";
import React, { useEffect, useRef } from "react";
import { I18n } from 'aws-amplify';
import TextField from "@material-ui/core/TextField";
import { autorun } from "mobx";

const TextInput = observer(React.forwardRef(({ store, field, label, type, showRequiredHelperText, onChange, ...otherProps }, ref) => {
    const errors = store.errors && store.errors[field]
    const hasError = !!errors
    const dirty = store.validating || store.touchedMap.has(field);
    const inputRef = useRef()

    useEffect(() => {
        return autorun(() => {
            if (store.isFirstInvalid && !!(store.errors && store.errors[field])) {
                inputRef.current.focus()
            }
        })
    }, [])

    return (
        <TextField
            variant={"outlined"}
            fullWidth
            type={type}
            value={store[field]}
            label={label}
            error={hasError && dirty}
            inputRef={ref => inputRef.current = ref}
            onChange={event => {
                store.setField(field, event.target.value)
                if (onChange) {
                    onChange(event.target.value)
                }
            }}
            margin={'dense'}
            helperText={(dirty && errors) ? errors[0] : showRequiredHelperText ? I18n.get("_required", "Required") : null}
            {...otherProps}
        />
    );
}));

export default TextInput;
