import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Bar, Container, styles } from './styles';
import numeral from 'numeral';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { withRouter } from 'react-router-dom';
import asyncRetry from 'async/retry';
import { withStyles } from "@material-ui/core";
// import Tooltip from '@material-ui/core/Tooltip';
import CreditProgressPopover from './CreditProgressPopover/CreditProgressPopover';
import { withLDConsumer } from "launchdarkly-react-client-sdk";
class CreditProgress extends Component {

    state = { open: false, ref:undefined }

    childSelectorRef = React.createRef()

    getColor = (progress) => {
        const temp = parseInt(progress * 100);
        return temp < 70 ? '#007aff' : (temp >= 90) ? "#F50057" : "#FFB300"
    };

    getLeftLabel = (progress) => {
        if (progress < 0 || progress > 1) return null;
        const temp = parseInt(progress * 100);
        if (temp >= 10) {
            return <Typography variant="caption" style={{ color: 'white', marginRight: '4px !important', fontWeight: 'bold' }}>{temp}&#37;</Typography>
        } else {
            return null
        }
    };

    getRightLabel = (progress) => {
        if (progress < 0 || progress > 1) return null;
        const temp = parseInt(progress * 100);
        if (temp < 10) {
            return <Typography variant="caption" style={{ color: 'white', marginLeft: '4px' }}>{temp}&#37;</Typography>
        } else {
            return null
        }
    };

    handleClick = (event) => {
        this.setState({ open: true, ref:event.currentTarget })
    };

    handleClosePopOver = () => {
        this.setState({ open: false,ref: null })
    }

    handleUpgrade = () => {
        this.handleClosePopOver()
        this.props.history.push('/settings/my-plan')
    }


    fetchConsumed = (callback) => {
        if (!this.props.UserModel.stripeData) {
            // we have not received the fetch Stripe data so don't try and fetch the consumed yet
            callback({}, undefined)
        }

        this.props.UserModel.fetchCreditProgress((err, result) => {
            callback(undefined, {})
        });
    };
    componentDidMount() {
        // first time the user comes on this runs                                                    indefinitely as the user is created for the first time in the system
        asyncRetry({ times: 6, interval: 1000 }, this.fetchConsumed, function (err, result) {});
    }

    render() {

        const { classes, flags, UserModel } = this.props

        // const { creditData } = this.props.UserModel;
        // const credits = creditData.dataSummary ? creditData.dataSummary.credits : 1000;
        // const creditsConsumed = creditData.dataSummary ? creditData.dataSummary.creditsConsumed : 0;
        // const percentConsumed = creditsConsumed === 0 ? 0 : creditsConsumed / credits;

        const percentConsumed = UserModel.fcUserData.totalActiveCards===0? 0: UserModel.fcUserData.totalActiveCards / flags.maxFastcard;

        const color = this.getColor(percentConsumed);

        return (<>
            <Container
                aria-owns={this.state.open ? 'credit-progress-mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={this.handleClick}
                onMouseLeave={this.handleClosePopOver}
                ref={this.childSelectorRef}
                 >
                {UserModel?.fcUserData?.totalActiveCards ?
                    <>
                        {/*<Tooltip title={`Total visits consumed by your Fastcards`} aria-label="visits consumed">*/}
                        <div className={classes.barContainer}>
                            <Bar value={percentConsumed} color={color}>
                                {this.getLeftLabel(percentConsumed)}
                            </Bar>
                            {this.getRightLabel(percentConsumed)}
                        </div>
                        {/*</Tooltip>*/}
                        <Typography variant="caption" style={{ margin: '0 4px', width: '40px' }}>{numeral(flags.maxFastcard).format('0.[0]a')}</Typography>
                    </> :
                    <Skeleton
                        className={classes.skeleton}
                        animation="wave"
                        height={48}
                        width={200}
                    />}
            </Container>
            {this.props.showPopover &&
                <CreditProgressPopover
                    ref={this.state.ref}
                    handleUpgrade={this.handleUpgrade}
                    handleClose={this.handleClosePopOver} />
            }

        </>
        )
    }
}

export default withLDConsumer()(withRouter(inject("UserModel")(withStyles(styles, { withTheme: true })(observer(CreditProgress)))));
