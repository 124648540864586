import React from "react";
import { observable, decorate } from "mobx";
import CardContent from '@material-ui/core/CardContent';
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import AddressInput from "../../NewAction/inputs/AddressInput/AddressInput";
import NewActionHeader from "../../NewAction/NewActionHeader/NewActionHeader";

class LyftAction extends ActionModel {

	static metadata = {
		id: "lyft",
		name: "Lyft",
		subtitle: "4 min wait - $14-18",
		icon: "https://l.fast.cm/content/icons/lyft.png",
		categories: {
			// mobility: true
		}
	};

	address = "";
	lat_lng = [0, 0];
	lat = 0;
	lng = 0;

	constructor(dat) {
		super();
		this.propNames.push("address", "lat_lng", "dynamicLocationData");
		this.setFromObject(dat);
		this.lat = this.lat_lng[0];
		this.lng = this.lat_lng[1];
	}

	getConfigCardBody(cardModel, handleOnChangeIcon) {
		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this}
					subtitle="disabled" />
				<AddressInput cardModel={cardModel} actionModel={this} />
			</CardContent>
		)
	}

	getRules() {
		return {
			address: {
				presence: { allowEmpty: false },
			},
			lat_lng: {
				type: "array"
			},
			lat: {
				presence: { allowEmpty: false },
				numericality: {
					greaterThanOrEqualTo: -90,
					lessThanOrEqualTo: 90,
				}
			},
			lng: {
				presence: { allowEmpty: false },
				numericality: {
					greaterThanOrEqualTo: -180,
					lessThanOrEqualTo: 180,
				}
			},
			...super.getRules()
		}
	}
};

decorate(LyftAction, {
	address: observable,
	lat_lng: observable,
	lat: observable,
	lng: observable,
})

export default LyftAction
