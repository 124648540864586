import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
    root: {
        flexGrow: 1
    },
    navItems: {
        display: 'none',

        [theme.breakpoints.up("md")]: {
            display: 'flex',
            padding: 0,
            margin: 0,
            alignItems: 'center'
        }
    },
    navItem: {
        margin: '8px 0',
        padding: '0 16px',
        border: 'none',
        background: 'transparent',
        cursor: 'pointer',
        '&:focus': {
            outline: 'none'
        },
        [theme.breakpoints.up("md")]: {
            margin: '0 1.5rem'
        }
    },
    navLink: {
        textDecoration: 'none',
        color: "#8f8f8f",
        '&:focus': {
            outline: 'none'
        },
        '&:active, &:hover': {
            textDecoration: 'none',
            color: 'white !important'
        }
    },
    anchor: {
        color: '#8f8f8f',
        textDecoration: 'none',
        '&:focus': {
            outline: 'none'
        }
    },
    active: {
        color: 'white'
    },
    canny: {
        minWidth: '40px !important',
        margin: '20px !important'
    },
    cannyText: {
        padding: '0px !important'
    },
    icon: {
        color: 'white !important'
    },
    backdrop: {
        zIndex: 1150
    },
    saveBttn: {
        color: 'white !important',
        width: 120
    },
    analyticsBtn: {
        color: 'white !important'
    },
    resourcesButton: {
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 16px',
        height: '100%',
        color: "#8f8f8f",
        '&:focus': {
            outline: 'none'
        },
        '&:active, &:hover': {
            textDecoration: 'none',
            color: 'white !important'
        }
    },
    popover: {
        marginTop: 16
    },
    upgradeBtton: {
        background: 'linear-gradient(45deg, rgb(139,58,248) 30%, rgb(19,189,212) 90%)',
        border: 0,
        borderRadius: theme.shape.borderRadius,
        color: 'white !important',
        padding: '0 30px',
        marginRight: '16px !important'
    },
    progresContainer: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'block'
        }
    },
    menuItem: {
        cursor: 'pointer',
        height: '100%',
        width: '100%',
        textDecoration: 'none',
        '&:focus': {
            outline: 'none'
        }
    },
    menuItemBttn: {
        padding: 0,
        margin: 0,
        fontSize: 16,
        textTransform: 'capitalize'
    }
}))
