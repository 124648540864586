import React, { useState, useEffect } from "react";
import { useLocation, useHistory, NavLink, withRouter } from "react-router-dom";
import { Hub, I18n } from "aws-amplify";
import { useStyles } from "./styles";
import { useCardModel, useUserModel } from "../../hooks";
import clsx from "clsx";
import Logo from "./Logo/Logo";
import MenuButton from "./MenuButton/MenuButton";
import SideDrawer from "./SideDrawer/SideDrawer";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import NotificationsIcon from "@material-ui/icons/Notifications";
// import ThemeButton from './ThemeButton/ThemeButton';
import CreditProgress from "./CreditProgressBar/CreditProgressBar";
import WorkSpaceButton from "./WorkSpaceButton/WorkSpaceButton";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import lodashGet from "lodash/get";
import { useFlags } from "launchdarkly-react-client-sdk";
import BackButton from "./BackButton/BackButton";
import { inject, observer } from "mobx-react";
import { baseUrl } from "../../constants/help-endpoint";
import useEventDispatch from "../../hooks/useEventDispatch";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import EditIcon from "@material-ui/icons/Edit";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";

function Header(props) {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const dispatchEvent = useEventDispatch();
  const classes = useStyles();
  const userModel = useUserModel();
  const location = useLocation();
  const history = useHistory();
  const cardModel = useCardModel();
  const flags = useFlags();
  const [anchorEl, setAnchorEl] = React.useState(null);
  let locationItems = location.pathname.split("/");

  function handleLogoClicked() {
    history.replace("/");
  }

  function toggleButtonClicked() {
    setSideMenuOpen((sideMenuOpen) => !sideMenuOpen);
  }

  function handleFeedbackClicked() {
    window.Canny("identify", {
      appID: "5d14ff21c9b59a5cea880342",
      user: {
        email: userModel.currentUser.attributes.email,
        name: userModel.currentUser.attributes["given_name"],
        id: userModel.currentUser.attributes.sub,
        avatarURL: userModel.profilePicLink,
      },
    });
  }

  function handleNewFastCard() {
    if (
      flags.maxFastcard <=
      lodashGet(userModel, "fcUserData.totalActiveCards", 0)
    ) {
      Hub.dispatch("fcPayWall", {
        data: { flag: `maxFastcard` },
        event: "clicked",
      });
      return;
    }
    props.history.push("/create/card");
  }

  function handlePublishBtn() {
    dispatchEvent("publish", "publicando....");
  }

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleUpgrade = () => {
    history.push("/settings/my-plan");
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    window.Canny("initChangelog", {
      appID: "5d14ff21c9b59a5cea880342",
      position: "bottom",
      align: "right",
    });
  }, []);

  return (
    <>
      <div className={classes.root}>
        <AppBar position="fixed" elevation={0} color="secondary">
          <Toolbar>
            {locationItems[1] === "create" ? (
              <BackButton goBack={() => props.history.push("")} />
            ) : (
              <MenuButton handleToggle={toggleButtonClicked} />
            )}
            <Hidden xsDown>
              <Logo onClick={handleLogoClicked} alt="" />
            </Hidden>
            <nav
              className={classes.navItems}
              aria-label="Navigation Menu with 6 Links"
              tabIndex="5"
            >
              <div className={classes.navItem}>
                <NavLink
                  className={clsx(
                    classes.navLink,
                    locationItems[1] === "" && classes.active
                  )}
                  aria-label="Fastcards"
                  tabIndex="0"
                  to="/"
                >
                  <Typography variant="body1">
                    {I18n.get("_fastcards", "Fastcards")}
                  </Typography>
                </NavLink>
              </div>
              <div className={classes.navItem}>
                <NavLink
                  className={clsx(
                    classes.navLink,
                    locationItems[1] === "campaigns" && classes.active
                  )}
                  aria-label="Campaigns"
                  tabIndex="0"
                  to="/campaigns"
                >
                  <Typography variant="body1">
                    {I18n.get("_campaings")}
                  </Typography>
                </NavLink>
              </div>
              <div className={classes.navItem}>
                <NavLink
                  className={clsx(
                    classes.navLink,
                    locationItems[1] === "fastlinks" && classes.active
                  )}
                  aria-label="FastLinks"
                  tabIndex="0"
                  to="/fastlinks"
                >
                  <Typography variant="body1">
                    {I18n.get("_fastlinks")}
                  </Typography>
                </NavLink>
              </div>
              <Box onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                <Typography className={classes.resourcesButton}>
                  {I18n.get("_resources", "Resources")}
                  <ExpandMoreIcon style={{ marginLeft: 4 }} />
                </Typography>
                <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  // style={{pointerEvents:'none' }}
                  onClose={handlePopoverClose}
                  className={classes.popover}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <List>
                    <ListItem
                      button
                      onClick={() => {
                        handlePopoverClose();
                        history.push("/showcase");
                      }}
                    >
                      <Typography className={classes.menuItem} variant="body1">
                        {I18n.get("_showcase")}
                      </Typography>
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        handlePopoverClose();
                        openInNewTab(baseUrl);
                      }}
                    >
                      <Typography className={classes.menuItem} variant="body1">
                        {I18n.get("_faq")}
                      </Typography>
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        handlePopoverClose();
                        openInNewTab(
                          "https://feedback.fastmind.io/feature-requests"
                        );
                      }}
                    >
                      <Typography variant="body1">
                        {I18n.get("_feedback")}
                      </Typography>
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        handlePopoverClose();
                        openInNewTab("https://affiliate.fast.cm");
                      }}
                    >
                      <Typography variant="body1">
                        {I18n.get("_affiliate")}
                      </Typography>
                    </ListItem>
                  </List>
                </Popover>
              </Box>
            </nav>
            <Hidden smUp>
              {locationItems[1] !== "create" ? (
                <Typography variant="body1">
                  {I18n.get("_fastcards", "Fastcards")}
                </Typography>
              ) : null}
            </Hidden>
            <div style={{ flex: 1 }} />
            <Button
              classes={{ root: classes.upgradeBtton }}
              onClick={handleUpgrade}
            >
              {I18n.get("_upgrade")}
            </Button>
            <div className={classes.progresContainer}>
              <CreditProgress showPopover={true} />
            </div>
            {/* <ThemeButton /> */}
            <Hidden xsDown>
              <Button
                classes={{
                  root: classes.canny,
                  text: classes.cannyText,
                }}
                aria-label="Latest Updates"
                data-canny-changelog
              >
                <NotificationsIcon className={classes.icon} />
              </Button>
            </Hidden>

            <Hidden xsDown>
              <WorkSpaceButton />
            </Hidden>

            {locationItems[1] === "" && (
              <Hidden smUp>
                <IconButton onClick={handleNewFastCard}>
                  <AddIcon style={{ color: "white" }} />
                </IconButton>
              </Hidden>
            )}

            {locationItems[1] === "create" && (
              <>
                <Hidden xsDown smUp>
                  <Button
                    data-testid={"_analytics_btn"}
                    className={classes.saveBttn}
                    disabled={!cardModel.isAnEdit}
                    onClick={() =>
                      history.push(`/analytics/${cardModel.Card_ID}`)
                    }
                    startIcon={<AssessmentOutlinedIcon />}
                  >
                    {I18n.get("_analytics", "Analytics")}
                  </Button>
                </Hidden>

                <Hidden smUp>
                  <Button
                    className={classes.saveBttn}
                    variant="contained"
                    color="primary"
                    type="submit"
                    data-testid="save-btn"
                    onClick={() => handlePublishBtn()}
                  >
                    {I18n.get(
                      cardModel.isAnEdit
                        ? "_update_existing_link"
                        : "_save_new_link"
                    )}
                  </Button>
                </Hidden>
              </>
            )}

            {locationItems[1] === "analytics" && (
              <Hidden mdUp>
                <Button
                  className={classes.saveBttn}
                  onClick={() =>
                    history.push(`/create/card/${cardModel.Card_ID}`)
                  }
                  startIcon={<EditIcon style={{ color: "white" }} />}
                >
                  {I18n.get("_edit", "Edit")}
                </Button>
              </Hidden>
            )}

            {/*<AccountButton aria-label='Account' />*/}
          </Toolbar>
        </AppBar>
      </div>
      <SideDrawer
        handleFeedbackClicked={handleFeedbackClicked}
        isOpen={sideMenuOpen}
        toggleButtonClicked={toggleButtonClicked}
      />
      <Backdrop
        className={classes.backdrop}
        open={sideMenuOpen}
        onClick={toggleButtonClicked}
        color="primary"
      />
    </>
  );
}

export default withRouter(inject("model")(observer(Header)));
