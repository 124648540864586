import React from 'react';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import LinearProgress from '@material-ui/core/LinearProgress';
import ToolBar from '@material-ui/core/Toolbar';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import AppBar from '@material-ui/core/AppBar';

function Subheader(props) {

    const classes = useStyles();

    function ElevationScroll(props) {
        const { children, window } = props;
       
        const trigger = useScrollTrigger({
            disableHysteresis: true,
            threshold: 0,
            target: window ? window() : undefined,
        });

        return React.cloneElement(children, {
            elevation: trigger ? 2 : 0,
        });
    }

    return (
        <ElevationScroll {...props}>
            <AppBar className={classes.root} position="fixed" tabIndex='-1'>
                {props.isLoading ? <LinearProgress color="primary" className={classes.progress} /> : <div style={{ height: '4px' }} />}
                <ToolBar 
                    className={classes.toolbar}>
                    {props.children}
                </ToolBar>
            </AppBar>
        </ElevationScroll>
    )
}

Subheader.propTypes = {
    isLoading: PropTypes.bool
}

export default Subheader;