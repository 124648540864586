import React, { Fragment } from 'react';
import { I18n } from 'aws-amplify';
import { observer } from "mobx-react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useStyles } from './styles';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { countries, countryToFlag } from '../../../../../../utils/countries';
import { useCardModel } from '../../../../../../hooks';
import TextInput from "../TextInput/TextInput";

function PhoneNumberInput(props) {

    const classes = useStyles()
    const cardModel = useCardModel()

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: option => option.label + option.code,
    });

    return (
        <div className={classes.root}>
            {props.requiresCountryCode ?
                <Autocomplete
                    options={countries}
                    freeSolo
                    disableClearable
                    inputValue={props.actionModel.countryCode}
                    onInputChange={(event, newInputValue) => {
                        props.actionModel.countryCode = newInputValue
                    }}
                    filterOptions={filterOptions}
                    classes={{
                        root: classes.autoComplete,
                        option: classes.option,
                        clearIndicator: classes.clearIndicator,
                        paper: classes.paper,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option.phone}
                    renderOption={(option) => (
                        < Fragment >
                            <span>{countryToFlag(option.code)}</span>
                            {`(${option.code}) ${option.label} +${option.phone}`}
                        </Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={I18n.get("_code", "Code")}
                            margin="dense"
                            variant="outlined"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                /> : null}
            <TextInput
                fullWidth
                type="tel"
                field={'phoneNumber'}
                label={I18n.get("_phone_number", "Phone Number")}
                placeholder={"2025550171"}
                store={props.actionModel}
                disabled={cardModel.loading}
                inputProps={{
                    'data-testid': 'phone-input-testid'
                }}
            />
        </div>
    );
}

export default observer(PhoneNumberInput);
