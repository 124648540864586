import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

export const SearchBarContainer = styled.div`
    position: relative;
    background-color: rgba(f,f,f,0.15);
    padding: 8px 16px;
    border-bottom: 1px solid #f0f3f5;

    &:hover {
        background-color: rgba(f,f,f,0.25),
    };
    
    width: 100%;

    @media screen and (max-width: 600px ) {
        margin-left: 4px;
        width: auto;   
    }
`;

export const SearchBar = withStyles( theme => ({
    root: {
      '& label.Mui-focused': {
        color: theme.palette.secondary.main,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.secondary.main,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.grey[200],
        },
        '&:hover fieldset': {
          borderColor: theme.palette.secondary.light,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.secondary.main,
        },
      },
    },
  }))(TextField);


export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-right: 8px;
    padding: 16px;
    border-top: 1px solid #f0f3f5;
`