import { formSchema } from "../constant/formSchema";
import { uiSchema } from "../constant/uiSchema";
import lodashPick from 'lodash/pick'
export const createSchemaJson= (selectedProperties, data)=>{

    let selectedListIds = selectedProperties.map(p=>p.id);

    let formSchemaClone = {...formSchema};
    let uiSchemaClone = {...uiSchema};

    formSchemaClone.title = data.title;
    formSchemaClone.description = data.description;

    formSchemaClone.properties = lodashPick(formSchema.properties, selectedListIds);
    formSchemaClone.required = selectedProperties.filter(f=>f.required).map(p=>p.id);
    uiSchemaClone["ui:order"] = selectedListIds;

    return{
        formSchema: formSchemaClone,
        uiSchema: uiSchemaClone
    }
};