import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Form from 'rjsf-material-ui';
import { createSchemaJson } from "../utils/utils";
import { formSchema } from "../constant/formSchema";
import lodashGet from "lodash/get";
import lodashUniq from "lodash/uniq";
import { DragableContainer, PreviewContainer, ContentContainer, FormBackground, FormContainer } from './styles'
import FieldItem from "./FieldItem";
import { I18n } from 'aws-amplify';
import {FormControl, InputLabel, MenuItem, Select, Typography, TextField} from "@material-ui/core";
import {Languages} from "../../../../../../../constants/reasons";
import es from '../../../../../../../additions/languages/es.json';
import en from '../../../../../../../additions/languages/en.json';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const langDict = {
    es,
    en
}

const grid = 8;
const listHeight = 500;


const getListStyle = isDraggingOver => ({
    borderStyle: "solid",
    borderWidth: .5,
    borderColor: previewBackground,
    borderRadius: 5,
    background: 'white',
    overflow: "scroll",
    // background: isDraggingOver ? 'lightblue' : listColor,
    color: "white",
    fontSize: '1.2em',
    padding: grid,
    width: 250,
    height: listHeight
});

const backgroundColor = "#f0f0f6";
const previewBackground = "#E2E2E9";

const colorChart = [
    "#7B40E4",
    "#2866EB",
    "#51BFFF",
    "#F3467C",
    "#F36E2E",
    "#F9B314",
    "#4FD9D2",
    "#4FD94F",
    "#9D9D9D",
    // added from palette
    '#3b5072',
    '#9ca6b9',
    '#000',
    '#007aff',
    '#6610f2',
    '#6f42c1',
    '#e83e8c',
    '#f86c6b',
    '#f8cb00',
    '#ffc107',
    '#4dbd74',
    '#50e3c2',
    '#63c2de',
];


// const ColorLuminance =(hex, lum)=> {

//     // validate hex string
//     hex = String(hex).replace(/[^0-9a-f]/gi, '');
//     if (hex.length < 6) {
//         hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
//     }
//     lum = lum || 0;

//     // convert to decimal and change luminosity
//     let rgb = "#", c, i;
//     for (i = 0; i < 3; i++) {
//         c = parseInt(hex.substr(i*2,2), 16);
//         c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
//         rgb += ("00"+c).substr(c.length);
//     }

//     return rgb;
// };

const assignColors = (list) => {

    list = lodashUniq(list.map(i => i.id));
    let colors = {};
    let colorNum = 0;

    for (let i = 0; i < list.length; i++) {
        let id = list[i];
        colorNum = (colorNum) % colorChart.length;
        colorNum++;
        if (!colorChart[colorNum]) { // if we ran out of colors then start over again
            colorNum = 0
        }
        colors[id] = colorChart[colorNum]
    }

    return colors
};


class FieldsManager extends Component {


    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            description: this.props.description,
            language: this.props.language || 'en',
            successMessage: this.props.successMessage,
            items: this.props.choices.map(i => { i.required = i.required || false; return i; }),
            selected: this.props.selected.map(i => { i.required = i.required || false; return i; }),
            allFieldsDict: lodashGet(formSchema, 'properties', {}),
            colorAssignment: assignColors([...this.props.choices, ...this.props.selected])
        }
    }


    /**
     * A semi-generic way to handle multiple lists. Matches
     * the IDs of the droppable container to the names of the
     * source arrays stored in the state.
     */
    id2List = {
        droppable: 'items',
        droppable2: 'selected'
    };

    getList = id => this.state[this.id2List[id]];

    onDragEnd = result => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );

            let state = { items };

            if (source.droppableId === 'droppable2') {
                state = { selected: items };
            }

            this.setState(state);
        } else {
            const result = move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
            );

            this.setState({
                items: result.droppable,
                selected: result.droppable2
            });
        }
    };

    handleApply = () => {

        let data = { title: this.state.title, description: this.state.description };
        let schema = createSchemaJson(this.state.selected, data);
        this.props.formChanged({
            language: this.state.language,
            successMessage: this.state.successMessage,
            selected:this.state.selected,
            schema});

        this.props.handleClose()
    };

    onChangeValue = (valueChange, event) => {
        let dat = {};
        dat[valueChange] = event.target.value;
        this.setState(dat)
    };

    handleRequired = (event, index) => {
        let selected = this.state.selected;
        for (let i = 0; i < selected.length; i++) {
            let item = selected[i];
            if (item.id === event.target.name) {
                selected[i].required = event.target.checked;
                break;
            }
        }
        this.setState({ selected })

    };

    translateSchema = (schemaJson) => {
        let i18nSchema = { ...schemaJson }
        Object.keys(i18nSchema.formSchema.properties).forEach((key => {
            // i18nSchema.formSchema.properties[key].title = I18n.get(`_form_field_${key}`, i18nSchema.formSchema.properties[key].title);
            // i18nSchema.formSchema.properties[key].title = lodashGet(langDict, `${this.state.language}._form_field_${key}`,i18nSchema.formSchema.properties[key].title);
            i18nSchema.formSchema.properties[key].title = lodashGet(langDict, `${this.state.language}._form_field_${key}`);
        }))

        // i18nSchema.uiSchema.phone["ui:description"] = I18n.get("_form_phone_ui_description")
        i18nSchema.uiSchema.phone["ui:description"] = lodashGet(langDict, `${this.state.language}._form_phone_ui_description`);

        return i18nSchema;
    }


    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {

        let data = { title: this.state.title, description: this.state.description };
        let schema = this.translateSchema(createSchemaJson(this.state.selected, data));

        let cancel = lodashGet(langDict, `${this.state.language}._cancel`);
        let submit = lodashGet(langDict, `${this.state.language}._submit`);

        return (
            <div>
                <Dialog
                    fullWidth={true}
                    maxWidth={'md'}
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description" >

                    <DialogContent style={{ backgroundColor: backgroundColor, padding: 0, height: '70vh' }}>

                        <ContentContainer>

                            <div style={{ padding: 20, width: '60%' }}  >

                                <Typography variant="h6">{I18n.get("_drag_drop", "DRAG AND DROP")}</Typography>

                                <TextField
                                    type="text"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    style={{ marginTop: '16px' }}
                                    autoComplete="off"
                                    label={I18n.get("_form_title", "Form Title")}
                                    placeholder={I18n.get("_mailing_list", "Mailing List")}
                                    value={this.state.title}
                                    onChange={event => this.onChangeValue("title", event)}
                                />

                                <TextField
                                    type="text"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    style={{ marginTop: '16px' }}
                                    autoComplete="off"
                                    label={I18n.get("_form_description", "Form Description")}
                                    placeholder={I18n.get("_please_signup_mail_list", "Please sign up and join our mailing list")}
                                    value={this.state.description}
                                    onChange={event => this.onChangeValue("description", event)}
                                />

                                {/*<FormControl*/}
                                {/*    variant="outlined"*/}
                                {/*    style={{ marginTop: '16px' }}*/}
                                {/*    fullWidth*/}
                                {/*    margin="dense">*/}
                                {/*    <InputLabel id="demo-simple-select-outlined-label">{I18n.get("_form_language")}</InputLabel>*/}
                                {/*    <Select*/}
                                {/*        labelId="demo-simple-select-outlined-label"*/}
                                {/*        id="demo-simple-select-outlined"*/}
                                {/*        value={this.state.language}*/}
                                {/*        onChange={event => this.onChangeValue("language", event)}*/}
                                {/*        label={I18n.get("_language")}>*/}
                                {/*        {*/}
                                {/*            Object.keys(Languages).map(i=><MenuItem value={i}>{Languages[i]}</MenuItem>)*/}
                                {/*        }*/}
                                {/*    </Select>*/}
                                {/*</FormControl>*/}

                                <TextField
                                    type="text"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    style={{ marginTop: '16px' }}
                                    autoComplete="off"
                                    label={I18n.get("_form_success_message", "Success Message")}
                                    // placeholder={I18n.get("_please_signup_mail_list", "Please sign up and join our mailing list")}
                                    value={this.state.successMessage}
                                    onChange={event => this.onChangeValue("successMessage", event)}
                                />

                                <DragDropContext onDragEnd={this.onDragEnd}>
                                    <DragableContainer>
                                        <div>
                                            <Typography variant='body1' style={{ marginBottom: 6 }} >{I18n.get("_available_options", "Available options")} </Typography>
                                            <Droppable droppableId="droppable">
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        style={getListStyle(snapshot.isDraggingOver)}>
                                                        {this.state.items.map((item, index) => (
                                                            <Draggable
                                                                key={item.id}
                                                                draggableId={item.id}
                                                                index={index}>
                                                                {(provided, snapshot) => (
                                                                    <FieldItem
                                                                        disableControls={true}
                                                                        handledRequired={this.handleRequired}
                                                                        title={lodashGet(langDict, `${this.state.language}._form_field_${item.id}`)}
                                                                        provided={provided}
                                                                        item={item}
                                                                        snapshot={snapshot}
                                                                        index={index}
                                                                        colorAssignment={this.state.colorAssignment} />
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </div>
                                        <div>
                                            <Typography variant='body1' style={{ marginBottom: 6 }} > {I18n.get("_selected_options", "Selected options")}</Typography>
                                            <Droppable droppableId="droppable2">
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        style={getListStyle(snapshot.isDraggingOver)}>
                                                        {this.state.selected.map((item, index) => (
                                                            <Draggable
                                                                key={item.id}
                                                                draggableId={item.id}
                                                                index={index}>
                                                                {(provided, snapshot) => (
                                                                    <FieldItem
                                                                        disableControls={false}
                                                                        handledRequired={this.handleRequired}
                                                                        title={lodashGet(langDict, `${this.state.language}._form_field_${item.id}`)}
                                                                        provided={provided}
                                                                        item={item}
                                                                        snapshot={snapshot}
                                                                        index={index}
                                                                        colorAssignment={this.state.colorAssignment} />
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </div>
                                    </DragableContainer>
                                </DragDropContext>
                            </div>


                            <PreviewContainer>
                                <Typography variant="h6" style={{ textTransform: 'uppercase' }}>{I18n.get("_preview", "PREVIEW")}</Typography>
                                <FormContainer>
                                    <FormBackground>
                                        <Form
                                            disabled={true}
                                            schema={schema.formSchema}
                                            uiSchema={schema.uiSchema}>
                                            <DialogActions>
                                                <Button variant={'outlined'} disabled={true} color="primary">
                                                    {cancel}
                                                </Button>
                                                <Button variant={'contained'} disabled={true} type={"submit"} color="primary">
                                                    {submit}
                                                </Button>
                                            </DialogActions>
                                        </Form>
                                    </FormBackground>

                                </FormContainer>

                            </PreviewContainer>

                        </ContentContainer>


                    </DialogContent>
                    <DialogActions style={{ backgroundColor: backgroundColor }}>
                        <Button onClick={this.props.handleClose} color="primary">
                            {I18n.get("_cancel")}
                        </Button>
                        <Button onClick={this.handleApply} color="primary" autoFocus>
                            {I18n.get("_apply")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        );
    }
}

export default FieldsManager
