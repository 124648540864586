import React from 'react'; 
import Paper from '@material-ui/core/Paper';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

export const Text = styled(props => <Typography {...props}/>)({
    color: 'white',
    width: 'max-content'
});

export const Container = styled(({ color, ...other }) => <Paper {...other} />)({
    padding: '0 6px',
    position: 'relative',
    backgroundColor: props => props.color,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '8px',
    whiteSpace: 'normal',
    height: '24px'
});
