import React, {Component} from 'react';
import { Auth, I18n } from 'aws-amplify';
import axios from 'axios';
// import { SignUp } from 'aws-amplify-react';
import ReCAPTCHA from "react-google-recaptcha";
import { styles } from './styles';
import logoImage from '../../../assets/images/auth-logo.png';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { sendEvent } from "../../../utils/eventLog";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExternalAuth from "../ExternalAuth/ExternalAuth";
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { withStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import {signUpReasons} from "../../../constants/reasons";
import {parseURLParameters} from "../../../utils/functions";
import { withRouter } from 'react-router-dom';
import ls from 'local-storage'
import {rewardfulReferral} from "../../../constants/data-fetching";



const reasons = signUpReasons

class CustomSignUp extends Component {

    state = {
        email: {
            value: '',
            error: false,
            message: null
        },
        password: {
            value: '',
            error: false,
            message: null
        },
        passwordMatch: {
            value: "",
            error: false,
            message: null
        },
        firstName: {
            value: '',
            error: false
        },
        lastName: {
            value: '',
            error: false
        },
        loading: false,
        reason: "",
        reasonKey: "",
        recaptcha: null
    };

    componentDidMount() {
        const urlParams = parseURLParameters(this.props.location.search)
        const isInvited = urlParams && urlParams.hasOwnProperty('invtEmail');

        // setTimeout(() => sendEvent("sign_up_pageView"),750);
        if (isInvited) {
            this.setState({
                email: {
                    value: urlParams['invtEmail'],
                    error: false,
                    disabled:true,
                    message: null
                }
            })
        }


        window.rewardful('ready', () => {
            if (window.Rewardful.referral) {
                // set it here, to be used later to be stored if the user is being created in stripe
                ls.set(rewardfulReferral,window.Rewardful.referral)
            }
        })
    }


    validateFields = () => {
        if (this.state.email.error) return true;
        if (this.state.password.value.trim().length === 0 || this.state.password.error) return true
        if (this.state.passwordMatch.value.trim().length === 0 || this.state.passwordMatch.error) return true
        if (this.state.firstName.value.trim().length === 0) return true
        if (this.state.lastName.value.trim().length === 0) return true
        if (this.state.reason === "") return true;
        if (!this.state.recaptcha) return true;

        return false
    };

    onChange = value => {
        let self = this;
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/recaptchaVerification`, {
            response: value
        })
            .then(function (response) {
                let { data } = response;
                self.setState({ recaptcha: data.hasOwnProperty('success') ? data.success : false });
            })
            .catch(function (error) {
                self.setState({ recaptcha: false });
            });
    };

    handleSubmit = event => {
        event.preventDefault();

        const params = {
            username: this.state.email.value,
            password: this.state.password.value,
            attributes: {
                "email": this.state.email.value,
                "given_name": this.state.firstName.value,
                "family_name": this.state.lastName.value,
                "custom:type": this.state.reasonKey,
                "custom:createdAt": new Date()
            }
        }
        this.setState({ loading: true });
        Auth.signUp(params)
            .then(result => {
                const user = { password: this.state.password.value, ...result.user };
                this.props.onStateChange('confirmSignUp', user);
            })
            .catch(error => {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        email: {
                            ...prevState.email,
                            error: true,
                            message: error.message
                        }
                    }
                })
            }).finally(() => {
                this.setState({ loading: false });
            })
    }

    emailOnBlur = event => {
        // eslint-disable-next-line
        const exp = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")

        if ((exp.test(event.target.value)) || (this.state.email.value.trim() === "")) {
            this.setState({
                ...this.state,
                email: { ...this.state.email, error: false }
            })
        } else {
            this.setState({
                ...this.state,
                email: { ...this.state.email, error: true }
            })
        }
    }

    handleReasonChanged = event => {
        this.setState({
            reasonKey: event.target.value,
            reason: reasons[event.target.value]
        })
    }

    handleOnBlur = event => {
        if (this.state[event.target.name].value.trim() === "") {
            this.setState({
                ...this.state,
                [event.target.name]: { ...this.state[event.target.name], error: true }
            })
        } else {
            this.setState({
                ...this.state,
                [event.target.name]: { ...this.state[event.target.name], error: false }
            })
        }
    }

    passwordOnBlur = (event) => {
        const expCounter = new RegExp("^(?=.{8,})")
        const expLowercase = new RegExp("^(?=.*[a-z])")
        const expNumbercase = new RegExp("^(?=.*[0-9])")

        if ((expCounter.test(event.target.value)) || (this.state.password.value.trim() === "")) {
            this.setState({ password: { ...this.state.password, error: false, message: null } })
        } else {
            this.setState({ password: { ...this.state.password, error: true, message: I18n.get("_auth_password_length", "The password must be eight characters or longer") } })
            return;
        }

        if (expLowercase.test(event.target.value)) {
            this.setState({ password: { ...this.state.password, error: false, message: null } })
        } else {
            this.setState({ password: { ...this.state.password, error: true, message: I18n.get("_auth_password_lowercase", "The password must contain at least 1 lowercase alphabetical character") } })
            return;
        }

        if (expNumbercase.test(event.target.value)) {
            this.setState({ password: { ...this.state.password, error: false, message: null } })
        } else {
            this.setState({ password: { ...this.state.password, error: true, message: I18n.get("_auth_password_numeric", "The password must contain at least 1 numeric character") } })
        }
    }

    confirmPasswordOnBlur = () => {
        if (this.state.password.value === this.state.passwordMatch.value) {
            this.setState({
                passwordMatch: {
                    ...this.state.passwordMatch,
                    error: false,
                    message: null,
                }
            })
        }
        else {
            this.setState({ passwordMatch: { ...this.state.passwordMatch, error: true, message: I18n.get("_auth_password_match", "The passwords don't match") } })
        }
    }

    render() {

        let { classes, authState } = this.props;

        return authState !== 'signUp' ?
            <div className={classes.container}>
                <img className={classes.logo} src={logoImage} alt='logo' />
                <Paper className={classes.wrapper}>
                    <Typography variant="h5">{I18n.get("_create_account", "Create your free Account")}</Typography>

                    <ExternalAuth buttonLocation='sign_up' loading={this.state.loading} />

                    <Typography align="center" style={{ marginTop: 8, fontWeight: 500 }} >{I18n.get('_or', "Or")}</Typography>

                    <form onSubmit={this.handleSubmit} autoComplete='off'>
                        <div style={{ display: 'flex' }}>
                            <TextField
                                id="sign-up-firstname-id"
                                error={this.state.firstName.error}
                                type="text"
                                margin="normal"
                                name="firstName"
                                style={{ marginRight: '4px' }}
                                variant="outlined"
                                required
                                label={I18n.get("_first_name", "First Name")}
                                value={this.state.firstName.value}
                                autoComplete='off'
                                onBlur={this.handleOnBlur}
                                onChange={event => this.setState({
                                    ...this.state,
                                    firstName: {
                                        ...this.state.firstName,
                                        error: false,
                                        value: event.target.value
                                    }
                                })} />
                            <TextField
                                id="sign-up-lastname-id"
                                error={this.state.lastName.error}
                                type="text"
                                style={{ marginLeft: '4px' }}
                                name="lastName"
                                margin="normal"
                                variant="outlined"
                                required
                                label={I18n.get("_last_name", "Last Name")}
                                value={this.state.lastName.value}
                                autoComplete='off'
                                onBlur={this.handleOnBlur}
                                onChange={event => this.setState({
                                    ...this.state,
                                    lastName: {
                                        ...this.state.lastName,
                                        error: false,
                                        value: event.target.value
                                    }
                                })} />
                        </div>
                        <TextField
                            id="signUpEmailInput"
                            error={this.state.email.error}
                            type="email"
                            variant="outlined"
                            required
                            fullWidth
                            margin="normal"
                            label="Email"
                            disabled={this.state.email.disabled}
                            helperText={this.state.email.message}
                            value={this.state.email.value}
                            autoComplete='off'
                            onBlur={this.emailOnBlur}
                            onChange={event => this.setState({
                                ...this.state,
                                email: {
                                    ...this.state.email,
                                    error: false,
                                    value: event.target.value.toLowerCase()
                                }
                            })} />
                        <TextField
                            id="signUpPasswordInputId"
                            type="password"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label={I18n.get("_password", "Password")}
                            required
                            value={this.state.password.value}
                            error={this.state.password.error}
                            onBlur={this.passwordOnBlur}
                            autoComplete='off'
                            helperText={this.state.password.message}
                            inputProps={{
                                'data-testid': 'password'
                            }}
                            onChange={event => this.setState({
                                ...this.state,
                                password: {
                                    ...this.state.password,
                                    value: event.target.value
                                }
                            })} />
                        <TextField
                            id="signUpConfirmPasswordInputId"
                            type="password"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            label={I18n.get("_confirm_password", "Confirm Password")}
                            required
                            inputProps={{
                                'data-testid': 'confirm-password'
                            }}
                            onChange={event => this.setState({
                                ...this.state,
                                passwordMatch: {
                                    ...this.state.passwordMatch,
                                    value: event.target.value
                                }
                            })}
                            helperText={this.state.passwordMatch.message}
                            error={this.state.passwordMatch.error}
                            placeholder={I18n.get("_confirm_password", "Confirm Password")}
                            autoComplete='off'
                            onBlur={this.confirmPasswordOnBlur} />
                        <Typography style={{ marginTop: '8px', marginBottom: '8px' }}>{I18n.get("_signup_reason", "How will you be using the Fastcards?")}</Typography>
                        <FormControl
                            fullWidth
                            margin="normal"
                            variant="outlined">
                            <Select
                                required
                                value={this.state.reasonKey}
                                onChange={this.handleReasonChanged}
                                inputProps={{
                                    name: 'age',
                                    id: 'outlined-type-simple',
                                    'data-testid': 'reason_key'
                                }}
                                native={true}
                            >
                                {Object.keys(reasons).map(key => <option key={key} value={key}>{reasons[key]}</option>)}
                                {/*{Object.keys(reasons).map(key => <MenuItem key={key} value={key}>{reasons[key]}</MenuItem>)}*/}
                            </Select>
                        </FormControl>

                        <ReCAPTCHA
                            style={{ margin: '16px 0 8px' }}
                            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                            onChange={this.onChange}
                        />
                        <Button
                            data-testid='signupSubmit'
                            type='submit'
                            fullWidth
                            className={classes.containedGradientButton}
                            variant="contained"
                            disabled={this.state.loading}>
                            {this.state.loading ? <CircularProgress size="1rem" style={{ color: 'white' }} /> : I18n.get("_sign_up", "Sign Up")}
                        </Button>

                        <Typography variant="caption">{I18n.get("_policy1", "By creating a free account, you acknowledge that you have read and understand our")} <Link href="https://fastmind.io/cookie-policy"> {I18n.get("_policy2", "Cookie Policy")}</Link>, <Link href="https://fastmind.io/privacy">{I18n.get("_privacy_policy", "Privacy Policy")}</Link>, {I18n.get("_policy3", "and our")} <Link href="https://fastmind.io/terms">{I18n.get("_term_of_services", "Terms of Service")}</Link>.</Typography>
                    </form>
                    <div className={classes.signInMovilContainer}>
                        <Typography variant="h6">{I18n.get("_ask_login", "Already have a Login?")}</Typography>
                        <Button
                            data-testid='signinSubmit'
                            disabled={this.state.loading}
                            className={classes.outlinedGradientButton}
                            fullWidth
                            onClick={() => {
                                sendEvent('signIn_button_click');
                                super.changeState("signIn");
                            }}>
                            {I18n.get("_sign_in", "Sign in")}
                        </Button>
                    </div>
                </Paper>
                <div className={classes.footer}>
                    <Typography variant="caption" color="textPrimary"><span dangerouslySetInnerHTML={{ "__html": "&copy;" }} />{new Date().getFullYear()} Fastmind Inc</Typography>
                </div>
            </div> : null
    }
}

export default withStyles(styles)(withRouter(CustomSignUp));
