import React, { useRef, useState } from 'react';
import { observer } from "mobx-react";
import { useStyles } from "./styles";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import CancelIcon from "@material-ui/icons/Cancel";
import Dropzone from "react-dropzone";
import Snackbar from "@material-ui/core/Snackbar";
import { I18n } from 'aws-amplify';
import Toast from "../../../../../../components/Toast/Toast";
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const PhotoContactInput = (props) => {

    const classes = useStyles()
    let { cardModel, actionModel } = props;
    const [dropError, setDropError] = useState(null)
    const [uploading, setUploading] = useState(false)
    const dropZone = useRef()

    const onDrop = files => {

        files.map(file => Object.assign(file, { preview: URL.createObjectURL(file) }));

        let file = files[0];

        if (!file) {
            return
        }
        setUploading(true)
        actionModel.saveImage(cardModel, file, () => setUploading(false))
    };

    const onRejectedFiles = (files) => {
        const codeError = files[0].errors[0].code;
        switch (codeError) {
            case 'file-invalid-type':
                setDropError(I18n.get('_image_contact_invalid_type'))
                break;
            case 'file-too-large':
                setDropError(I18n.get('_image_exceed_contact_image_size_limits'))
                break
            default:
                setDropError(I18n.get('_image_invalid'))
        }

    }

    return <>
        <div className={classes.avatarContainer}>
            <Dropzone
                accept="image/jpeg, image/png, image/jpg"
                maxSize={1100000} // 1.1MB but we'll say it's 1
                onDropRejected={onRejectedFiles}
                multiple={false}
                ref={dropZone}
                onDrop={onDrop}>
                {({ getRootProps, getInputProps }) => (
                    <div className={classes.dropcontent} {...getRootProps()}>
                        <input {...getInputProps()} />
                        <img className={classes.avatar} src={actionModel.photo ? actionModel.photo : 'https://ssl.gstatic.com/s2/oz/images/sge/grey_silhouette.png'} alt="profile" />
                        {uploading
                            ? <CircularProgress classes={{ root: classes.loader }} size="1.2rem" />
                            : <PhotoCameraIcon classes={{ root: classes.cameraIcon }} />}
                    </div>
                )}
            </Dropzone>
            {actionModel.photo && <CancelIcon classes={{ root: classes.cancelIcon }} onClick={() => actionModel.removePhoto()} />}
            <Typography variant="caption" align="left" className={classes.textImgSize}>{I18n.get('_image_contact_size_limit')}</Typography>
        </div>
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={!!dropError}
            autoHideDuration={6000}
            onClose={() => setDropError(null)}>
            <Toast onClose={() => setDropError(null)} severity="error">{dropError}</Toast>
        </Snackbar>
    </>

}

export default observer(PhotoContactInput);
