import React, {Component} from 'react';
import ListItemText from "@material-ui/core/ListItemText";
import {I18n} from "aws-amplify";
import {Divider, IconButton, ListItemSecondaryAction, Switch, Typography} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ListItem from "@material-ui/core/ListItem";
import {inject, observer} from "mobx-react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import FormControl from '@material-ui/core/FormControl';
import {withStyles} from "@material-ui/core/styles";
import {styles} from './styles'
import { MuiPickersUtilsProvider, DateTimePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Select from "@material-ui/core/Select";
import {timezonesList} from "../../../../../../../constants/timezones";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { toJS } from "mobx";
import moment from 'moment-timezone'

class ScheduleLinkConfigInput extends Component {

    constructor(props){
        super(props);

        this.state = {
            openDialog:false,
            error:false,
            scheduleLinkData:{}
        }
    }

    componentDidMount() {
        this.setScheduleLinkData();
    }

    handleOpen= ()=>{
        if(!this.props.hasFeature(this.props.LDKey)){return;}
        this.setState({openDialog:true})
    }
    handleClose= ()=>{
        this.setState({openDialog:false})
    }
    handleSave = ()=>{

        let { scheduleLinkData } = this.state;
        let {
            startDateData, startTimeZone, startDateActive,
            endDateData, endTimeZone, endDateActive
        } = scheduleLinkData;

        // check if it's a number
        if( this.isValid()){


            if(startDateActive){
                scheduleLinkData['startUTC'] = moment(startDateData).tz(startTimeZone).unix()
            }

            if(endDateActive){
                scheduleLinkData['endUTC'] = moment(endDateData).tz(endTimeZone).unix()
            }

            // console.log(scheduleLinkData)

            this.props.actionModel.scheduleLinkData = scheduleLinkData;
            this.handleClose();
        }
    }
    setScheduleLinkData = ()=>{

        let d = toJS(this.props.actionModel.scheduleLinkData)
        // console.log("ScheduleLinkData props ---->", d)
        this.setState({scheduleLinkData:{...d}})
        // console.log("ScheduleLinkData ---->", this.state.scheduleLinkData)

    }

    formatTimeZone=(timeZone)=>{
        return  (timeZone||"").replace("_"," ")
    }


    isValid = ()=>{


        let { scheduleLinkData } = this.state;
        let {  startDateActive, endDateActive  } = scheduleLinkData;

        return startDateActive || endDateActive;
    }

    handleChange =(key, value)=> {
        // console.log(key, value)
        let { scheduleLinkData } = this.state;
        scheduleLinkData[key] = value;
        this.setState({scheduleLinkData})
        // console.log("ScheduleLinkData change---->", this.state.scheduleLinkData)
    }

    handleDateChange =(key, value)=> {
        // console.log(key, value)
        // console.log(key, moment(value).format())
        let { scheduleLinkData } = this.state;
        scheduleLinkData[key] = moment(value).format();
        this.setState({scheduleLinkData})
        // console.log("ScheduleLinkData change---->", this.state.scheduleLinkData)
    }


    render() {
        let { scheduleLinkData } = this.state;
        let { active } = this.props.actionModel.scheduleLinkData;
        let  startDateDataMain = this.props.actionModel.scheduleLinkData.startDateData;
        let  startDateDataTimeZone = this.props.actionModel.scheduleLinkData.startTimeZone;
        let  endDateDataMain = this.props.actionModel.scheduleLinkData.endDateData;
        let  startDateActiveMain = this.props.actionModel.scheduleLinkData.startDateActive;
        let  endDateActiveMain = this.props.actionModel.scheduleLinkData.endDateActive;
        let  endDateDataTimeZone = this.props.actionModel.scheduleLinkData.endTimeZone;

        let {
            startDateData, startTimeZone, startDateActive,
            endDateData, endTimeZone, endDateActive
        } = scheduleLinkData;
        let { theme, classes }= this.props;

        return (
            <div>
                <ListItem>
                    <ListItemText
                        id="switch-list-label-schedule_link"
                        primary={I18n.get('_schedule_link')}
                        secondary={
                            <React.Fragment>
                                <Typography style={{fontSize:10}} display={'block'} component="span">{I18n.get('_start_date')}: { startDateActiveMain?`${moment(startDateDataMain).format('YYYY/MM/DD, h:mm a')} ( ${this.formatTimeZone(startDateDataTimeZone)} )`:"---"}</Typography>
                                <Typography style={{fontSize:10}} display={'block'} component="span">{I18n.get('_end_date')}  : { endDateActiveMain?`${moment(endDateDataMain).format('YYYY/MM/DD, h:mm a')} ( ${this.formatTimeZone(endDateDataTimeZone)} )`:"---"}</Typography>
                            </React.Fragment>
                        }
                    />

                    <ListItemSecondaryAction>
                        <IconButton
                            disabled={!active}
                            edge="end"
                            onClick={this.handleOpen}
                            aria-label={I18n.get("_edit")}>
                            <EditIcon/>
                        </IconButton>
                        <Switch
                            color="primary"
                            edge="end"
                            inputProps={{
                                'aria-labelledby': 'switch-list-label-schedule_link',
                            }}
                            checked={active}
                            onChange={()=>{
                                if(!this.props.hasFeature(this.props.LDKey)){return;}
                                this.props.actionModel.scheduleLinkData.active = !active
                            }}
                        />
                    </ListItemSecondaryAction>

                </ListItem>


                <Dialog
                    TransitionProps={{
                        onEnter:this.setScheduleLinkData,
                    }}
                    open={this.state.openDialog}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{I18n.get('_schedule_link')}</DialogTitle>
                    <DialogContent>
                        <>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className={classes.flexContainer}>
                                    <DateTimePicker
                                        variant="inline"
                                        inputVariant="outlined"
                                        margin="dense"
                                        fullWidth
                                        disabled={!startDateActive}
                                        format="yyyy/MM/dd h:mm a"
                                        name="startDate"
                                        label={I18n.get("_start_date", "Start Date")}
                                        value={moment(startDateData)}
                                        disablePast={true}
                                        onChange={date => this.handleDateChange("startDateData", date)}
                                    />


                                    <FormControl
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        disabled={!startDateActive}
                                        className={classes.timezonesSelect}>
                                        <InputLabel htmlFor="category-simple">{I18n.get("_timezone")}</InputLabel>

                                        <Select
                                            classes={{ select: classes.select }}
                                            value={startTimeZone || "America/New_York"}
                                            onChange={event => this.handleChange("startTimeZone", event.target.value)}
                                            label={I18n.get("_timezone")}>
                                            {timezonesList.map((el, index) => {
                                                return (
                                                    <MenuItem key={`${el.name}-${index}`} value={el.name}>{this.formatTimeZone(el.name)}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>

                                    <Switch
                                        color={'primary'}
                                        size="small"
                                        checked={startDateActive}
                                        onChange={()=>{
                                            this.handleChange("startDateActive",!startDateActive)
                                        }}  />

                                </div>

                                <Divider style={{marginTop:12,marginBottom:12}}  />
                                <div className={classes.flexContainer}>
                                    <DateTimePicker
                                        variant="inline"
                                        inputVariant="outlined"
                                        margin="dense"
                                        format="yyyy/MM/dd h:mm a"
                                        fullWidth
                                        disabled={!endDateActive}
                                        name="endDate"
                                        label={I18n.get("_end_date", "End Date")}
                                        value={moment(endDateData)}
                                        disablePast={true}
                                        minDate={endDateActive?startDateData:undefined}
                                        onChange={date => this.handleDateChange("endDateData", date)}
                                    />

                                    <FormControl
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        disabled={!endDateActive}
                                        className={classes.timezonesSelect}>

                                        <InputLabel htmlFor="category-simple">{I18n.get("_timezone")}</InputLabel>

                                        <Select
                                            classes={{ select: classes.select }}
                                            value={endTimeZone || "America/New_York"}
                                            onChange={event => this.handleChange("endTimeZone", event.target.value)}
                                            label={I18n.get("_timezone")}>
                                            {timezonesList.map((el, index) => {
                                                return (
                                                    <MenuItem key={`${el.name}-${index}`} value={el.name}>{this.formatTimeZone(el.name)}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>


                                    <Switch
                                        color={'primary'}
                                        size="small"
                                        checked={endDateActive}
                                        onChange={()=>{
                                            this.handleChange("endDateActive",!endDateActive)
                                        }}  />
                                </div>
                            </MuiPickersUtilsProvider>
                        </>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            {I18n.get('_cancel')}
                        </Button>
                        <Button onClick={this.handleSave} color="primary">
                            {I18n.get('_save')}
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        );
    }
}

ScheduleLinkConfigInput.propTypes = {};

export default  withStyles(styles,{withTheme:true})(inject("model")(observer(ScheduleLinkConfigInput)));
