import React from "react";
import CardContent from '@material-ui/core/CardContent';
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import AppSearch from "../../NewAction/inputs/AppsInput/appSearch"
import NewActionHeader from "../../NewAction/NewActionHeader/NewActionHeader";

export default class extends ActionModel {
	static metadata = {
		id: "ituneappstore",
		name: "App Store",
		icon: "https://l.fast.cm/content/icons/ituneappstore.png",
		categories: {
			// apps: true,
		}
	};

	constructor(dat) {
		super();
		this.setFromObject(dat);
	}

	getConfigCardBody(cardModel, handleOnChangeIcon) {
		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />
				<AppSearch
					appPlatform={'Apple'}
					cardModel={cardModel}
					actionModel={this} />
			</CardContent>
		)
	}
};
