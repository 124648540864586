import ActionModel from "../../../../../models/ActionModel/ActionModel";
import { observable, decorate } from "mobx";

class ShareAction extends ActionModel {
    static metadata = {
        id: "share",
        name: "Share",
        icon: "https://l.fast.cm/content/icons/share.png",
        visibleWhenZero: false,
        categories: {
            events: true
        }
    };

    Title = "Share";
}

decorate(ShareAction, {
    Title: observable
});

export default ShareAction;

