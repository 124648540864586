import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
    root: {
        marginTop: 0,
        marginBottom: 10
    },
    facebook: {
        backgroundColor: "#1877F2",
        marginTop: 16,
        height: 36,
        color: theme.palette.getContrastText("#1877F2"),
        '&:hover': {
            backgroundColor: "#1453a5"
        },
    },
    google: {
        marginTop: 16,
        height: 36,
        '&:hover': {
            backgroundColor: theme.palette.grey[200]
        },
    },
    progress: {
        color: 'white'
    },
    apple: {
        color: "white",
        backgroundColor: "#000",
        marginTop: 16,
        height: 36,
        '&:hover': {
            backgroundColor: theme.palette.grey[900]
        },
    },
    icon: {
        height: 18,
        width: 18,
        objectFit: 'contain'
    }
}))
