import React, { Component } from 'react';
import { I18n } from "aws-amplify";
import Button from '@material-ui/core/Button';
import FilterDropdown from './FilterDropdown/FiterDropdown';
import { inject, observer } from 'mobx-react';
import Popover from '@material-ui/core/Popover';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import Filter1OutlinedIcon from '@material-ui/icons/Filter1Outlined';
import Filter2OutlinedIcon from '@material-ui/icons/Filter2Outlined';
import Filter3OutlinedIcon from '@material-ui/icons/Filter3Outlined';
import Filter4OutlinedIcon from '@material-ui/icons/Filter4Outlined';
import Filter5OutlinedIcon from '@material-ui/icons/Filter5Outlined';
import Filter6OutlinedIcon from '@material-ui/icons/Filter6Outlined';
import Filter7OutlinedIcon from '@material-ui/icons/Filter7Outlined';
import Filter8OutlinedIcon from '@material-ui/icons/Filter8Outlined';
import Filter9OutlinedIcon from '@material-ui/icons/Filter9Outlined';
import Filter9PlusOutlinedIcon from '@material-ui/icons/Filter9PlusOutlined';

const icons = [
    <FilterListOutlinedIcon style={{ marginRight: '4px' }} />,
    <Filter1OutlinedIcon style={{ marginRight: '4px' }} />,
    <Filter2OutlinedIcon style={{ marginRight: '4px' }} />,
    <Filter3OutlinedIcon style={{ marginRight: '4px' }} />,
    <Filter4OutlinedIcon style={{ marginRight: '4px' }} />,
    <Filter5OutlinedIcon style={{ marginRight: '4px' }} />,
    <Filter6OutlinedIcon style={{ marginRight: '4px' }} />,
    <Filter7OutlinedIcon style={{ marginRight: '4px' }} />,
    <Filter8OutlinedIcon style={{ marginRight: '4px' }} />,
    <Filter9OutlinedIcon style={{ marginRight: '4px' }} />,
    <Filter9PlusOutlinedIcon style={{ marginRight: '4px' }} />
];

class TagsFilter extends Component {

    state = {
        anchorEl: null,
        activeTags:this.props.CardsDataModel.FilterTags
    };


    handleClick = event => {
        let tagsChanged = this.state.activeTags.sort().join(';') !== this.props.CardsDataModel.FilterTags.sort().join(';');

        if (Boolean(this.state.anchorEl)  && tagsChanged ) {
            this.props.CardsDataModel.PullToRefresh();
        }
        if (event) {
            this.setState({ anchorEl: event.currentTarget });
        } else {
            this.setState({ anchorEl: null });
        }
    };

    getIcon = () => {
        const length = this.props.CardsDataModel.FilterTags.length;
        const index = (length < 10) ? length : 10;

        return icons[index];
    };

    render() {

        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (
            <div>
                <Button aria-describedby={id} variant="text" color="primary" onClick={this.handleClick}>
                    {this.getIcon()}
                    {I18n.get('_home_filter_by_tags')}
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={this.state.anchorEl}
                    onClose={() => this.handleClick()}
                    TransitionProps={{
                        onEnter:()=>{this.setState({activeTags:this.props.CardsDataModel.FilterTags})},
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <FilterDropdown
                        handleFilter={this.handleClick} />
                </Popover>
            </div>
        );
    }
}

export default inject('CardsDataModel')(observer(TagsFilter));