
import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
    paper: {
        padding: 10,
        width: 340,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.text.primary,
        color: 'white'

    },
    title: {
        marginBottom: 16,
        width: '100%'
    },
    bar: {
        height: 20,
        backgroundColor: '#424242'
    },
    barLabelCnt: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    barCnt: {
        width: '100%',
        marginBottom: 24,
        display: 'flex',
        flexDirection: 'column'
    },
    btn: {
        background: 'linear-gradient(45deg, rgb(139,58,248) 30%, rgb(19,189,212) 90%)',
        color: 'white',
        padding: '0 30px'
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    }
}));