import React from 'react';
import Checkbox from '../../../../../../components/UI/Checkbox/Checkbox';
import Tag from '../../../../../../components/Tag/Tag';
import ListItem from '@material-ui/core/ListItem';

const FilterItem = props => {

    return(
        <ListItem style={{ justifyContent: 'space-between'}}>
            <Tag text={props.tagName} color={props.color}/>
            <Checkbox 
                name={props.tagName} 
                checked={props.selected} 
                handleChange={props.handleOnChange} />
        </ListItem>
    );
}

export default FilterItem;