import { API } from 'aws-amplify';
import { decorate, action, observable } from 'mobx';
import CardModel from '../CardModel/CardModel';
import CardAnalytics from "../CardAnalytics/CardAnalytics";
import lodashPick from 'lodash/pick';
import lodashGet from 'lodash/get';
import * as Sentry from "@sentry/react";
class CampaignsModel {

    items = [];
    itemsDict = {};
    dataTable = [];
    loading = false;
    loadingCampaigns = false;
    campaign = {};
    timeSeriesData = new CardAnalytics();

    apiName = 'fastShare';

    constructor() {
        this.fetchCampaigns()
    }

    resetCampaignData(){
        this.campaign = {};
        this.timeSeriesData = new CardAnalytics();
    }

    fetchCampaign(campaignId, callback) {
        this.loading = true;
        const path = `/campaigns/${campaignId}`;
        API.get(this.apiName, path)
            .then(result => {
                this.loading = false;

                result.data.Cards = lodashGet(result, 'data.Cards',[]).map(cardData => new CardModel(cardData));
                this.campaign = result.data;

                if (callback) callback(true)
            })
            .catch(error => {
                this.loading = false;
                this.campaign = {};
                Sentry.captureException(error);
                if (callback) callback(false)
            })
    }

    fetchDetails(campaignId, startDate, endDate, callback) {
        this.loading = true;
        let self = this;

        const path = `/analytics/campaigns/${campaignId}?start_date=${startDate}&end_date=${endDate}`;

        API.get(this.apiName, path)
            .then(result => {
                this.loading = false;

                self.timeSeriesData = new CardAnalytics(this, {CardAnalytics: result});

                if (callback) callback(true)
            })
            .catch(error => {
                this.loading = false;
                Sentry.captureException(error);
                if (callback) callback(false)
            })

    }

    fetchCampaigns() {
        this.loadingCampaigns = true;

        const path = `/campaigns`;

        API.get(this.apiName, path)
            .then(result => {
                this.loadingCampaigns = false;
                this.handleData(result)
            })
            .catch(error => {
                this.loadingCampaigns = false;
                this.items = [];
                this.itemsDict = {};
                Sentry.captureException(error);
            })

    }

    handleData = result => {

        if (result.data.Items && result.data.Items.length > 0) {
            this.items = result.data.Items;
            this.items.forEach(el => {
                this.itemsDict[el.Campaign_ID] = el
            });

            this.dataTable = result.data.Items.map(el => {
                return {
                    name: el.Name,
                    startDate: new Date(el.StartDate).toLocaleDateString(),
                    endDate: new Date(el.EndDate).toLocaleDateString(),
                    brand: el.Brand,
                    advertiser: el.Advertiser,
                    clicks: 0,
                    ctr: 0,
                    fastcards: el.Cards_ID ? el.Cards_ID.length : 0
                }
            })
        }
    };

    deleteCampaign = (index, callback) => {
        let path = '/campaigns';
        let myInit = {
            body: {
                Campaign_ID: this.items[index].Campaign_ID
            }
        };

        API.del(this.apiName, path, myInit)
            .then(result => {
                this.handleData(result);
                if (callback) callback(true)
            })
            .catch(error => {
                Sentry.captureException(error);
                if (callback) callback(false)
            })

    };

    createCampaign(params, callback) {
        this.loading = true;

        let path = '/campaigns';
        let myInit = {
            body: { ...params }
        };

        API.post(this.apiName, path, myInit)
            .then(result => {
                this.loading = false;
                if (callback) callback(result)
            })
            .catch(error => {
                this.loading = false;
                Sentry.captureException(error);
                if (callback) callback(false)
            })
    }

    updateCampaign = (campaign, callback) => {

        campaign = lodashPick(campaign, ["Advertiser", "Brand", "Budget", "ctr", "cpm", "Campaign_ID", "Category",
            "createdAt", "EndDate", "Name", "StartDate", "User_ID", "Cards_ID"]);

        let path = '/campaigns';
        let myInit = {
            body: campaign
        };

        API.put(this.apiName, path, myInit)
            .then(result => {
                if (result.error && callback) {
                    callback(false);
                    return
                }

                if (result.data.Cards) {

                    let cards = result.data.Cards.slice();
                    let temp = cards.map(cardData => {
                        return new CardModel(cardData)
                    });

                    result.data.Cards = temp
                }

                this.campaign = result.data;
                let index = this.items.findIndex(el => {
                    return el.Campaign_ID = this.campaign.Campaign_ID
                })
                this.items[index] = this.campaign
                this.itemsDict[this.campaign.Campaign_ID] = this.campaign
                if (callback) callback(true)
            })
            .catch(error => {
                Sentry.captureException(error);
                this.loading = false;
                if (callback) callback(false)
            })
    }

    saveUtm = (cardId, campaignId, utms) => {

        let path = "/campaigns/utms"
        let myInit = {
            body: {
                Card_ID: cardId,
                Campaign_ID: campaignId,
                utm: utms
            }
        }

        API.post(this.apiName, path, myInit)
            .then(result => {
                
            })
            .catch(error => {
                Sentry.captureException(error);
            })

    }
}

decorate(CampaignsModel, {
    items: observable,
    loadingCampaigns: observable,
    itemsDict: observable,
    dataTable: observable,
    campaign: observable,
    timeSeriesData: observable,
    loading: observable,
    createCampaign: action,
    fetchCampaigns: action,
    fetchDetails: action,
    fetchCampaign: action,
    resetCampaignData: action,
    deleteCampaign: action,
    saveUtm: action
});

export default CampaignsModel;