import React, { useRef, useState } from 'react';
import { observer } from "mobx-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dropzone from "react-dropzone";
import { I18n } from "aws-amplify";
import { useStyles } from "./styles";
import Icon from "@material-ui/core/Icon";
import BackupIcon from "@material-ui/icons/Backup";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Delete } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Toast from "../../../../../../components/Toast/Toast";
import Snackbar from "@material-ui/core/Snackbar";
import { useUserModel } from "../../../../../../hooks";
import AlertDialog from "../../../../../../components/AlertDialog/AlertDialog";
import uniqID from 'uniqid'

const FileInput = (props) => {

    const classes = useStyles()
    const userModel = useUserModel()
    const { cardModel, actionModel } = props
    const [dropError, setDropError] = useState(null)
    const [uploading, setUploading] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const dropZone = useRef()

    const onDrop = files => {

        let file = files[0];

        if (!file) {
            return
        }

        setUploading(true)
        let path = `${userModel.fcUserData.userID}/${cardModel.Card_ID}/${uniqID()}`
        actionModel.uploadFile(file, path, () => setUploading(false))
        actionModel.size = file.size;
    };

    const removeFile = () => {
        closeDeletingModal()
        setUploading(true)
        actionModel.removeFile(() => setUploading(false))
    }

    const onRejectedFiles = (files) => {
        const codeError = files[0].errors[0].code;
        switch (codeError) {
            case 'file-too-large':
                setDropError(I18n.get('_file_exceed_5m_size_limits'))
                break
            default:
                setDropError(I18n.get('_file_invalid'))
        }
    }

    function closeDeletingModal() {
        setIsDeleting(false)
    }

    if (uploading) {
        return <div style={{ textAlign: 'center', marginTop: 20 }}>
            <CircularProgress />
        </div>
    }

    return <div>
        {actionModel.Link
            ? (<div className={classes.fileContainer} >
                <div className={classes.fileName}>{actionModel.name}</div>
                <IconButton onClick={() => setIsDeleting(true)}><Delete /></IconButton>
            </div>)
            : <Dropzone
                maxSize={5100000} // 5.1MB but we'll say it's 5
                onDropRejected={onRejectedFiles}
                multiple={false}
                ref={dropZone}
                onDrop={onDrop}>
                {({ getRootProps, getInputProps }) => (
                    <section>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className={classes.content}>
                                <Icon style={{ fontSize: '56px' }}>
                                    <BackupIcon color="primary" style={{ fontSize: '56px' }} />
                                </Icon>
                                <div style={{ textAlign: 'center', marginBottom: 16 }}>
                                    <Typography variant="subtitle2">{I18n.get("_click_drag_file")}</Typography>
                                    <Typography variant="caption">{I18n.get("_account_avatar_helper_text")}5MB</Typography>
                                </div>
                                <Button size="small" variant="contained" color="primary"
                                    style={{ color: 'white' }}>{I18n.get("_upload", "Upload")}</Button>
                            </div>
                        </div>
                    </section>
                )}
            </Dropzone>}
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={!!dropError}
            autoHideDuration={6000}
            onClose={() => setDropError(null)}>
            <Toast onClose={() => setDropError(null)} severity="error">{dropError}</Toast>
        </Snackbar>
        <AlertDialog
            open={isDeleting}
            handleClose={closeDeletingModal}
            title={I18n.get("_confirmation", "Confirmation")}
            message={I18n.get("_confirm_remove_file", "Are you sure you want to remove this file?")}
            okLabel={I18n.get("_confirm", "Confirm")}
            handleOk={removeFile}
            cancelLabel={I18n.get("_cancel", "Cancel")}
        />
    </div>
}

export default observer(FileInput);
