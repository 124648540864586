import React from "react";
import { decorate, observable } from "mobx";
import CardContent from "@material-ui/core/CardContent";
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import NewActionHeader from "../../NewAction/NewActionHeader/NewActionHeader";
import TextInput from "../../NewAction/inputs/TextInput/TextInput";
import { I18n } from 'aws-amplify';
import Typography from "@material-ui/core/Typography";

class LinkAction extends ActionModel {
    static metadata = {
        id: "calendly",
        name: "Calendly",
        icon: "https://l.fast.cm/content/icons/calendly.png",
        categories: {
            general: true
        }
    };

    getConfigCardBody(cardModel, handleOnChangeIcon) {

        return (
            <CardContent>
                <NewActionHeader
                    onChangeIcon={handleOnChangeIcon}
                    actionModel={this} />
                <Typography variant={'subtitle2'}>{I18n.get('_ask_user')} <Typography component={'a'} href={'https://calendly.grsm.io/eg2moc2slgt6'} target="_blank" color={'primary'} variant={'subtitle1'}>{I18n.get('_click_here')}</Typography></Typography>
                <TextInput
                    data-testid='calendly-action-link'
                    type={'text'}
                    field={'Link'}
                    label={I18n.get("_calendly_url")}
                    store={this}
                    disabled={cardModel.loading}
                    showRequiredHelperText={I18n.get("_required", "Required")}
                    inputProps={{
                        'data-testid': 'calendly_link'
                    }}
                />
            </CardContent>
        )
    }

    getRules() {
        return {
            Link: {
                presence: { allowEmpty: false },
                url: { message: I18n.get("_action_wrong_link", "Wrong link") }
            },
            ...super.getRules()
        }
    }
}

decorate(LinkAction, {
    Image: observable
})

export default LinkAction;
