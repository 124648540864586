import React, { Fragment } from "react";
import { decorate, observable, action, computed } from "mobx";
import CardContent from "@material-ui/core/CardContent";
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import NewActionHeader from "../../NewAction/NewActionHeader/NewActionHeader";
import CalendarInput from "../../NewAction/inputs/EventInput/EventInput";
import moment from 'moment';
import CalendarItem from '../../../../../components/FastCard/CalendarItem/CalendarItem';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
    ExpandableCell,
    ListItem,
    ServiceActionButton,
    ServiceActionButtonText,
    ServiceSubtitle,
    ServiceThumbnail,
    ServiceTitle,
    TextContainer
} from "../../../../../models/ActionModel/styles";
import { actionsMap } from "../index";
import keyBy from 'lodash/keyBy'
import lodashCapitalize from 'lodash/capitalize'
import { I18n } from "aws-amplify";
import EventAlarm from "../Common/EventAlarm";
import { randomString } from "../../../../../utils/functions";

const links = [
    {
        id: "google",
        iconUrl: "https://images.fast.cm/content/icons/google-calendar.png?width=50&height=50"
    }, {
        id: "ical",
        iconUrl: "https://images.fast.cm/content/icons/ical.png?width=50&height=50"
    }, {
        id: "default",
        iconUrl: "https://images.fast.cm/content/icons/calendar.png?width=50&height=50"
    }, {
        id: "yahoo",
        iconUrl: "https://images.fast.cm/content/icons/yahoo-calendar.png?width=50&height=50"
    }, {
        id: "outlook",
        iconUrl: "https://images.fast.cm/content/icons/outlook-calendar.png?width=50&height=50"
    }
]

class EventAction extends ActionModel {

    static metadata = {
        id: "event",
        name: "Event",
        icon: "https://l.fast.cm/content/icons/calendar.png",
        categories: {
            general: true
        },
        help: {
            id: 'articles/about-general-links-event-how-does-it-work',
            helperText: I18n.get('_event_help_text', "Click to learn more about the Event action")
        }
    };

    constructor(dat) {
        super();

        this.propNames.push("startDate", "startTime", "endDate", "endTime", "location", "locationObject", "message", "description", "repeat", "organizer", "organizerEmail", "allDay", "timezone");
        this.setFromObject(dat);
    }

    isValid = false
    startDate = moment();
    startTime = moment();
    endDate = moment();
    endTime = moment();
    allDay = false;
    location = "";
    locationObject = null;
    message = "";
    description = "";
    repeat = 0;
    organizer = "";
    organizerEmail = "";
    timezone = "America/New_York";
    expand = false
    ButtonText = I18n.get("_view", "VIEW");
    alarms = []




    setFromObject(dat) {
        if (!dat) {
            return;
        }

        for (let i in dat) {
            if (this.propNames.indexOf(i) !== -1) {
                this[i] = dat[i];
            }
        }

        if (this.Image) {
            this.Image = this._checkImageUrl(this.Image)
        }

        this.actionID = dat.actionID || `${this.constructor.metadata.id}_${randomString(9, "#aA")}`;
        this.crawledLink = dat.crawledLink;

        if (dat.alarms && dat.alarms.length > 0) {

            this.alarms = dat.alarms.map(alarm => new EventAlarm(alarm))

        }

    }

    get asObject() {
        let ret = {
            Title: "",
            Image: "",
            Link: "",
            ButtonText: ""
        };

        this.propNames.forEach(e => {
            ret[e] = this[e];
        });

        if (this.constructor.metadata.id && this.constructor.metadata.id !== "default") {
            ret.action = this.constructor.metadata.id;
        }

        if (this.Subtitle) {
            ret.Subtitle = this.Subtitle
        }

        if (this.alarms) {
            ret['alarms'] = this.alarms.map(data => {
                const { type, trigger, description } = data;
                return { type, trigger, description }
            })
        }

        ret.actionID = this.actionID || `${this.constructor.metadata.id}_${randomString(9, "#aA")}`

        return ret;
    }

    validate() {
        let valid = super.validate();

        this.alarms.forEach(value => {
            if (!value.validate()) {
                valid = false
            }
        })

        return valid
    }

    get childActions() {
        let id = this.actionID.split('_')[1];

        let optionsDict = keyBy(links, 'id')

        let options = links.map(i => lodashCapitalize(i.id))

        return options.map(i => `event${i}`).map(action => {
            let eventSource = action.replace('event', '')
            return new actionsMap['default']({ actionID: `${action}_${id}`, Title: eventSource, Subtitle: this.Title, Image: optionsDict[eventSource.toLowerCase()].iconUrl })
        })
    }

    get hasAlarms() {
        return this.alarms.length > 0
    }

    getConfigCardBody(cardModel, handleOnChangeIcon) {
        return (
            <CardContent>
                <NewActionHeader
                    onChangeIcon={handleOnChangeIcon}
                    actionModel={this} />
                <CalendarInput
                    cardModel={cardModel}
                    actionModel={this} />
            </CardContent>
        )
    }

    toggleOpen = () => {
        this.expand = !this.expand
    }

    handleClick(item, cardModel) {

    }

    RenderItem = (cardModel, index, theme, containerClassName) => {

        let isLast = cardModel.LinksList.length === 0 ? true : (cardModel.LinksList.length - 1 === index);
        let image = this.Image || this.constructor.metadata.icon;
        return (
            <Fragment>
                <ListItem
                    failed={false}
                    last={isLast}
                    theme={theme}
                    onClick={this.toggleOpen}
                    key={index}
                    className={containerClassName}>
                    {
                        this._ImageIsFromUs ? (
                            <ServiceThumbnail
                                theme={theme}
                                loading="lazy"
                                src={`${image}?width=80&height=80`}
                                srcSet={`${image}?width=50&height=50 1x,
							 		 ${image}?width=80&height=80 2x,
							 		 ${image}?width=100&height=100 3x`
                                }
                                alt={this.Title || this.constructor.metadata.name} />
                        ) : (
                            <ServiceThumbnail
                                theme={theme}
                                loading="lazy"
                                src={image}
                                alt={this.Title || this.constructor.metadata.name} />
                        )
                    }

                    <TextContainer>
                        <ServiceTitle theme={theme}>{this.Title || this.constructor.metadata.name}</ServiceTitle>
                        <ServiceSubtitle theme={theme}>{this.Subtitle || this.constructor.metadata.subtitle}</ServiceSubtitle>
                    </TextContainer>

                    <ServiceActionButton theme={theme} failed={false}>
                        {this.expand ?
                            <UpIcon style={{ color: theme.cardActions }} />
                            :
                            <ServiceActionButtonText theme={theme}>
                                <div>{(this.ButtonText).toUpperCase()}</div>
                            </ServiceActionButtonText>}
                    </ServiceActionButton>
                </ListItem>
                <ExpandableCell open={this.expand} theme={theme} last={isLast}>
                    {links.map(el => {
                        return (
                            <Fragment key={el.id}>
                                <CalendarItem
                                    theme={theme}
                                    item={el}
                                    clicked={(item) => this.handleClick(item, cardModel)} />
                            </Fragment>
                        )
                    })}
                </ExpandableCell>
            </Fragment>
        );
    };

    addAlarm() {
        this.alarms.push(new EventAlarm())
    }

    removeAlarm(index) {
        this.alarms.splice(index, 1);
    }

    getRules() {
        return {
            ...super.getRules()
        }
    }
}

decorate(EventAction, {
    Image: observable,
    isValid: observable,
    startDate: observable,
    endDate: observable,
    location: observable,
    description: observable,
    repeat: observable,
    allDay: observable,
    endTime: observable,
    startTime: observable,
    organizer: observable,
    organizerEmail: observable,
    locationObject: observable,
    timezone: observable,
    expand: observable,
    alarms: observable,
    addAlarm: action,
    removeAlarm: action,
    setFromObject: action,
    asObject: computed,
    hasAlarms: computed
})

export default EventAction;
