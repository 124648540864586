export const palette = {
  white: '#fff',
  lightGrey: '#fbfbfe',
  mediumGray: '#f0f0f6',
  darkGray: '#3b5072',
  typoMediumGray: '#9ca6b9',
  black: '#000',
  blue: '#007aff',
  indigo: '#6610f2',
  purple: '#6f42c1',
  pink: '#e83e8c',
  red: '#f86c6b',
  orange: '#f8cb00',
  yellow: '#ffc107',
  green: '#4dbd74',
  teal: '#50e3c2',
  cyan: '#63c2de',
};

export const theme = {
  name: 'light',
  pageBackgroundStart: "#e6e6f1",
  pageBackgroundEnd: "#e6e6f1",
  pageBackgroundText: "#000",

  cardActions: "#136cff", // eg: share and the click to action such as go, visit, buy
  divider: "#f0f0f0",

  Title: "#333",
  Subtitle: "#636363",
  cardBackground: "#fff",
  actionButton: "#f0f1f6",

  // show an actual picture on desktop
  backgroundImage: undefined,
  desktopPositionIsRight: true,

  socialSearch: {
    headerBackground: '#FAFCFD',
    headerActionBackground: '#B0B2B3',
    headerActionColor: '#FAFCFD',
    titleColor: '#000',
    searchContainerBackground: '#E6E8E9',
    searchColor: '#26252A',
    searchPlaceholder: '#B0B2B3',
    contentBackground: '#F1F2F6'
}
};

export const chartColors = [
  { // blue
    fillColor: "#007aff",
    strokeColor: "#007aff",
    pointColor: "#007aff",
    pointStrokeColor: "#fff",
    pointHighlightFill: "#fff",
    pointHighlightStroke: "#42a5f5"
  },
  { // pink
    fillColor: "#e91e63",
    strokeColor: "#e91e63",
    pointColor: "#e91e63",
    pointStrokeColor: "#fff",
    pointHighlightFill: "#fff",
    pointHighlightStroke: "#f06292"
  },
  { // purple
    fillColor: "#673ab7",
    strokeColor: "#673ab7",
    pointColor: "#673ab7",
    pointStrokeColor: "#fff",
    pointHighlightFill: "#fff",
    pointHighlightStroke: "#9575cd"
  },
  { // orange
    fillColor: "#ff9800",
    strokeColor: "#ff9800",
    pointColor: "#ff9800",
    pointStrokeColor: "#fff",
    pointHighlightFill: "#fff",
    pointHighlightStroke: "#ffb74d"
  },
  { // blueGrey
    fillColor: "#607d8b",
    strokeColor: "#607d8b",
    pointColor: "#607d8b",
    pointStrokeColor: "#fff",
    pointHighlightFill: "#fff",
    pointHighlightStroke: "#90a4ae"
  },
  { // teal
    fillColor: "#009688",
    strokeColor: "#009688",
    pointColor: "#009688",
    pointStrokeColor: "#fff",
    pointHighlightFill: "#fff",
    pointHighlightStroke: "#4db6ac"
  },
  // { // deepPurple
  //   fillColor: "#673ab7",
  //   strokeColor: "#673ab7",
  //   pointColor: "#673ab7",
  //   pointStrokeColor: "#fff",
  //   pointHighlightFill: "#fff",
  //   pointHighlightStroke: "#9575cd"
  // }
];
