import React from 'react';
import { I18n } from 'aws-amplify';
import { decorate, observable } from "mobx";
import CardContent from "@material-ui/core/CardContent";
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import NewActionHeader from '../../NewAction/NewActionHeader/NewActionHeader';
import TextInput from "../../NewAction/inputs/TextInput/TextInput";


class AmazonAction extends ActionModel {

	static metadata = {
		id: "amazon",
		name: "Amazon",
		icon: "https://l.fast.cm/content/icons/amazon.png",
		categories: {
			// ecommerce: true
		}
	};

	Title = "Amazon";
	Link = "https://www.amazon.com/"
	ButtonText = "GO";
	isValid = false

	onValidateUrl = () => {
		this.isValid = true
	}

	getConfigCardBody(cardModel, handleOnChangeIcon) {
		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />
				<TextInput
					type={'text'}
					field={'Link'}
					label={I18n.get("_type_url", "Type here the url")}
					store={this}
					disabled={cardModel.loading}
					autoComplete="off"
					showRequiredHelperText={I18n.get("_required", "Required")}
				/>
			</CardContent>
		)
	}

	getRules() {
		return {
			Link: {
				presence: { allowEmpty: false },
				url: { message: I18n.get("_action_wrong_link", "Wrong link") }
			},
			...super.getRules()
		}
	}
}

decorate(AmazonAction, {
	Title: observable,
	Link: observable,
	ButtonText: observable,
	isValid: observable
})

export default AmazonAction;
