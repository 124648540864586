import React, { useEffect, useMemo, useState } from 'react';
import { observer } from "mobx-react";
import { I18n } from 'aws-amplify';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useStyles } from './styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { FormGroup } from '@material-ui/core';
import DateFnsUtils from "@date-io/date-fns";
import { toJS } from 'mobx';
import { timezonesList } from '../../../../../../constants/timezones';
import SelectInput from "../SelectInput/SelectInput";
import TextInput from "../TextInput/TextInput";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import DeleteIcon from "@material-ui/icons/Delete";

const autocompleteService = { current: null };

function EventInput(props) {

    let { actionModel, cardModel } = props;
    const classes = useStyles()
    const [options, setOptions] = useState([]);


    const fetch = useMemo(() =>
        throttle((request, callback) => {
            autocompleteService.current.getPlacePredictions(request, callback);
        }, 200),
        [],
    );

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (actionModel.location === '') {
            setOptions(actionModel.locationObject ? [actionModel.locationObject] : []);
            return undefined;
        }

        fetch({ input: actionModel.location }, (results) => {
            if (active) {
                let newOptions = [];

                if (actionModel.locationObject) {
                    newOptions = [actionModel.locationObject];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [actionModel.locationObject, actionModel.location, fetch]);


    function handleChange(key, value) {
        actionModel[key] = value
    }

    function handleOptionSelected(option, value) {
        return option.place_id === toJS(value.place_id);
    }

    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className={classes.flexContainer}>
                    <DatePicker
                        variant="inline"
                        inputVariant="outlined"
                        margin="dense"
                        fullWidth
                        format="MMM dd, yyyy"
                        name="startDate"
                        label={I18n.get("_start_date", "Start Date")}
                        value={actionModel.startDate}
                        onChange={date => handleChange("startDate", date)}
                    />
                    {!actionModel.allDay ?
                        <KeyboardTimePicker
                            variant="inline"
                            style={{ marginLeft: 8 }}
                            margin="dense"
                            fullWidth
                            label={I18n.get("_time", "Time")}
                            inputVariant="outlined"
                            value={actionModel.startTime}
                            onChange={time => handleChange("startTime", time)}
                        /> : null}
                </div>

                <div className={classes.flexContainer}>
                    <DatePicker
                        variant="inline"
                        inputVariant="outlined"
                        margin="dense"
                        format="MMM dd, yyyy"
                        fullWidth
                        name="endDate"
                        minDate={actionModel.startDate}
                        label={I18n.get("_end_date", "End Date")}
                        value={actionModel.endDate}
                        onChange={date => handleChange("endDate", date)}
                    />
                    {!actionModel.allDay ?
                        <KeyboardTimePicker
                            margin="dense"
                            variant="inline"
                            style={{ marginLeft: 8 }}
                            fullWidth
                            inputVariant="outlined"
                            label={I18n.get("_time", "Time")}
                            value={actionModel.endTime}
                            onChange={time => handleChange("endTime", time)}
                        /> : null}
                </div>
            </MuiPickersUtilsProvider>
            <FormGroup row className={classes.formGroup}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={actionModel.allDay}
                            onChange={event => handleChange("allDay", event.target.checked)}
                            color="primary"
                        />
                    }
                    label={I18n.get("_all_day", "All day")}
                />
                {!actionModel.allDay ?
                    <FormControl
                        margin="dense"
                        variant="standard"
                        className={classes.timezonesSelect}>
                        <Select
                            disableUnderline
                            classes={{ select: classes.select }}
                            value={actionModel.timezone}
                            onChange={event => handleChange("timezone", event.target.value)}
                            label="Timezone"
                        >
                            {timezonesList.map((el, index) => {
                                return (
                                    <MenuItem key={`${el.name}-${index}`} value={el.name}>{el.offset + ' ' + el.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    : null}
            </FormGroup>

            <Autocomplete
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                filterOptions={(x) => x}
                options={options}
                autoComplete
                forcePopupIcon={false}
                getOptionSelected={handleOptionSelected}
                includeInputInList
                filterSelectedOptions
                inputValue={toJS(actionModel.location)}
                value={actionModel.locationObject}
                onChange={(event, newValue) => {
                    setOptions(newValue ? [newValue, ...options] : options);
                    handleChange("locationObject", newValue)
                }}
                onInputChange={(event, newInputValue) => {
                    handleChange("location", newInputValue)
                }}
                renderInput={(params) => (
                    <TextField {...params}
                        label={I18n.get("_location", "Location")}
                        variant="outlined"
                        margin="dense"
                        fullWidth />
                )}
                renderOption={(option) => {
                    const matches = option.structured_formatting.main_text_matched_substrings;
                    const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match) => [match.offset, match.offset + match.length]),
                    );

                    return (
                        <Grid container alignItems="center">
                            <Grid item>
                                <LocationOnIcon className={classes.icon} />
                            </Grid>
                            <Grid item xs>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                        {part.text}
                                    </span>
                                ))}

                                <Typography variant="body2" color="textSecondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                }}
            />
            <TextField
                label={I18n.get("_description", "Description")}
                multiline
                margin="dense"
                fullWidth
                value={actionModel.description}
                rows={4}
                onChange={event => handleChange('description', event.target.value)}
                variant="outlined"
            />

            {actionModel.hasAlarms ? <>
                <Divider style={{ margin: '24px 0 16px 0' }} />
                <Typography variant="h6">{I18n.get("_alarms", 'Alarms')}</Typography>
            </> : null}

            {actionModel.alarms.map((alarm, index) => {
                return (<div key={index} className={classes.alarmCnt}>
                    <div className={classes.alarmBodyCnt}>
                        <div className={classes.flexContainer}>
                            <TextInput
                                type={'text'}
                                field={'description'}
                                label={I18n.get("_alarm_description", "Alarm description")}
                                store={alarm}
                                disabled={cardModel.loading}
                                autoComplete="off"
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className={classes.flexContainer}>
                            <div style={{ width: '100%' }}>
                                <SelectInput
                                    label={I18n.get("_alarmType", "Alarm Type")}
                                    store={alarm}
                                    field={'type'}
                                    style={{ width: '100%' }}
                                    options={[{ value: 'display', label: I18n.get('_alarm_type_display', 'Display') }]}
                                />
                            </div>
                            <div style={{ width: '100%', marginLeft: 8 }}>
                                <SelectInput
                                    label={I18n.get("_event_trigger", "Trigger")}
                                    store={alarm}
                                    field={'trigger'}
                                    style={{ width: '100%' }}
                                    options={[
                                        { value: '10m', label: I18n.get('_event_alarm_10_min', '10 minutes') },
                                        { value: '30m', label: I18n.get('_event_alarm_30_min', '30 minutes') },
                                        { value: '1h', label: I18n.get('_event_alarm_1_hour', '1 hour') },
                                        { value: '5h', label: I18n.get('_event_alarm_5_hour', '5 hour') },
                                        { value: '1d', label: I18n.get('_event_alarm_1_day', '1 day') },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.alarmBtnCnt}>
                        <div>
                            <IconButton
                                size="small"
                                onClick={() => actionModel.removeAlarm(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </div>
                </div>)
            })}
            <div className={classes.alarmBtn}>
                <Button variant="contained" color="primary" style={{ color: 'white', marginBottom: '4px' }} onClick={() => actionModel.addAlarm()}>{I18n.get('_add_alarm')}</Button>
                <Typography variant="caption">{I18n.get("_just_for_apple_devices", "Available just in Apple's devices")}</Typography>
            </div>
        </>
    )
}


export default observer(EventInput);
