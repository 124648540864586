import {API, Auth} from 'aws-amplify';
import {decorate, action, observable, computed} from 'mobx';
import lodashGet from 'lodash/get';
import * as Sentry from "@sentry/react";
import {amountOfcardsFetched} from "../../constants/data-fetching";
import numeral  from 'numeral';


class FastLinksModel {

    loading = false;
    loadingSegments = false;
    loadingFastLinks = false;
    fastLink = {};// the currently active fastLink
    fastLinksList = [];
    fastLinksListDict = {};
    apiName = 'fastShare';
    creatingFastLink = false;
    creatingSegment = false;

    constructor() {
        // this.fetchFastLinks()
    }

    resetSegmentData(){
        this.segment = {};
    }

    fetchFastLinks(callback){
        console.log("fetching fastLinks data---->")
        this.loadingFastLinks = true;
        this.loading = true;

        let path = '/GetUserLinks';
        let myInit = {
            body: {
                limit: 40,
                // limit: amountOfcardsFetched,
                onlyArchived: false, // set true to only see archived cards
                // filterTags: this.FilterTags
                type:'fastLink'
            }
        };

        if (this.StartKey) {
            // myInit.body.startKey = this.StartKey
        }

        API.post(this.apiName, path, myInit)
            .then(response => {
                this.StartKey = response.next;
                let list = lodashGet(response,'cards',[])
                for (let i = 0; i < list.length; i++) {
                    let f = list[i];
                    this.fastLinksListDict[f.Card_ID] = f
                }
                this.fastLinksList = list;
                if (callback) { callback(null, list) }
            })
            .catch(error => {
                Sentry.captureException(error);
                if (callback) { callback({}, []) }
            })
            .finally(()=>{
                this.loadingFastLinks = false;
                this.loading = false;
            });
    }

    createFastLink (data, callback){

        this.creatingFastLink = true;

        let path = '/saveFastLink';
        let myInit = {
            body: {...data}
        };

        API.post(this.apiName, path, myInit)
            .then(response => {
                let { cardData } = response;
                if (callback) { callback(null, cardData) }
            })
            .catch(error => {
                Sentry.captureException(error);
                if (callback) { callback(error, undefined) }
            })
            .finally(()=>{
                this.creatingFastLink = false;
            });
    }

    updateFastLink (data, callback){

        this.creatingFastLinks = true;

        let path = '/updateFastLink';
        let myInit = {
            body: {...data}
        };

        API.post(this.apiName, path, myInit)
            .then(response => {
                let { cardData } = response;
                if (callback) { callback(null, cardData) }
            })
            .catch(error => {
                Sentry.captureException(error);
                if (callback) { callback(error, undefined) }
            })
            .finally(()=>{
                this.creatingFastLinks = false;
            });
    }

    getRandomInt = (max)=> {
        return Math.floor(Math.random() * Math.floor(max));
    }

    fetchSegments(data, callback){
        let { cardId } = data;

        const path = `/GetUserCardInfo/${cardId}`;
        this.loading = this.loadingSegments = true;

        API.get(this.apiName, path)
            .then((result) => {
                let d = result.cards[0];
                this.fastLink = d;
                callback(null, d)
            })
            .catch((error) => {
            })
            .finally(()=>{
                this.loading = this.loadingSegments = false;
            });
    }

    createSegment(data, callback){
        this.creatingSegment = true;

        let path = '/createSegment';
        let myInit = {
            body: {...data}
        };

        API.post(this.apiName, path, myInit)
            .then(response => {
                let { cardData } = response;
                if (callback) { callback(null, cardData) }
            })
            .catch(error => {
                Sentry.captureException(error);
                if (callback) { callback(error, undefined) }
            })
            .finally(()=>{
                this.creatingSegment = false;
            });
    }

    makeDefaultSegment(data, callback){
        this.loading = true;
        let path = '/defaultSegment';
        let myInit = {
            body: {...data}
        };

        API.post(this.apiName, path, myInit)
            .then(response => {
                let { cardData } = response;
                if (callback) { callback(null, cardData) }
            })
            .catch(error => {
                Sentry.captureException(error);
                if (callback) { callback(error, undefined) }
            })
            .finally(()=>{
                this.loading = false;
            });
    }

    archiveStatusFastLink(data, callback){
        this.loading = true;
        let path = '/archiveStatusFastLink';
        let myInit = {
            body: {...data}
        };

        API.post(this.apiName, path, myInit)
            .then(response => {
                let { cardData } = response;
                if (callback) { callback(null, cardData) }
            })
            .catch(error => {
                Sentry.captureException(error);
                if (callback) { callback(error, undefined) }
            })
            .finally(()=>{
                this.loading = false;
            });
    }

    toggleActive(data, callback){

        this.loading = true;
        let path = '/toggleActiveSegment';
        let myInit = {
            body: {...data}
        };

        API.post(this.apiName, path, myInit)
            .then(response => {
                let { cardData } = response;
                if (callback) { callback(null, cardData) }
            })
            .catch(error => {
                Sentry.captureException(error);
                if (callback) { callback(error, undefined) }
            })
            .finally(()=>{
                this.loading = false;
            });
    }

    setOrder(data, callback){
        this.loading = true;

        let path = '/reOrderSegments';

        API.post(this.apiName, path, {  body: {...data} })
            .then(response => {
                let { cardData } = response;
                if (callback) { callback(null, cardData) }
            })
            .catch(error => {
                Sentry.captureException(error);
                if (callback) { callback(error, undefined) }
            })
            .finally(()=>{
                this.loading = false;
            });
    }

    get dataTable(){
        return this.fastLinksList.map((seg)=>{

            let clicks = lodashGet(seg,'lifetime.PageClick');
            let visits = lodashGet(seg,'lifetime.PageView');
            let er = numeral(clicks/visits).format('0.0%');
            let users = lodashGet(seg,'lifetime.UniqueUsers');
            return {
                name: seg.Title,
                id: seg.Card_ID,
                segments: seg.segments?.length || 0,
                clicks,
                visits,
                er,
                users,
                data:{
                    ...seg,
                    id:seg.Card_ID,
                    url:`https://fast.cm/${seg.customId||seg.Card_ID}`
                }
              }
        })
    }

    get dataTableSegments(){

        return lodashGet(this,'fastLink.segments',[]).map((seg)=>{
            let countries = seg?.countries?.toString() || "\t—"
            let languages = seg?.languages?.toString() || "\t—"
            let deviceTypes = seg?.deviceTypes?.toString() || "\t—"
            let tags = lodashGet(seg,'cardData.searchableTags',[]).toString()|| "\t—"
            let clicks = lodashGet(seg,'lifetime.PageView');
            let visits = lodashGet(seg,'lifetime.PageClick');
            let er = numeral(clicks/visits).format('0.0%');
            let users = lodashGet(seg,'lifetime.UniqueUsers');

            if(seg.default){ // don't show properties for the default
                countries = "\t—";
                languages = "\t—";
                deviceTypes = "\t—";
            }
            return {
                name: seg.Title,
                url: `https://fast.cm/${seg.customId||seg.Card_ID}`,
                countries,
                languages,
                deviceTypes,
                clicks,
                visits,
                er,
                users,
                fastcard:seg.cardData||{},
                tags,
                active: {active: seg.active,segmentID:seg.segmentID},
                default: {default:seg.default,segmentID:seg.segmentID},
              }
        })
    }

}

decorate(FastLinksModel, {
    loading: observable,
    loadingSegments: observable,
    loadingFastLinks: observable,
    fastLink: observable,
    fastLinksList: observable,
    fastLinksListDict: observable,
    makeDefaultSegment: action,
    toggleActive: action,
    setOrder: action,
    fetchFastLinks: action,
    fetchSegments: action,
    createFastLink: action,
    updateFastLink: action,
    archiveStatusFastLink: action,
    createSegment: action,
    dataTable: computed,
    dataTableSegments: computed,
    creatingFastLink: observable,
    creatingSegment: observable,
});

export default FastLinksModel;