
class ValidationRulePattern {

    static WEB_ADDRESS_OR_EMPTY = /^((?:https?:\/\/)?(?:www\.)?[-\w@:%.+~#=]{2,256}\.[a-z]{2,6}\b[-\w@:%+.~#?&\/=]*|)$/

    static EMAIL_OR_EMPTY = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6}$|^$/


}

export default  ValidationRulePattern;
