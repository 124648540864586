import React from 'react';
import { decorate, observable } from "mobx";
import CardContent from "@material-ui/core/CardContent";
import { I18n } from 'aws-amplify';
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import NewActionHeader from '../../NewAction/NewActionHeader/NewActionHeader';
import UsernameInput from '../../NewAction/inputs/UsernameInput/UsernameInput';
import TextInput from "../../NewAction/inputs/TextInput/TextInput";

class CashAction extends ActionModel {

	static metadata = {
		id: "cash",
		name: "Cash",
		icon: "https://l.fast.cm/content/icons/cash.png",
		categories: {
			// payments: true
		}
	};

	constructor(dat) {
		super();

		this.propNames.push("destId");
		this.setFromObject(dat);
	}

	Title = "Cash";
	Link = "https://www.cash.me/"
	ButtonText = "PAY";
	isValid = false;
	amount = "";

	onValidateUrl = () => {
		this.isValid = true
	}

	getConfigCardBody(cardModel, handleOnChangeIcon) {
		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />
				<UsernameInput
					placeHolderText={I18n.get("_username")}
					cardModel={cardModel}
					actionModel={this} />
				<TextInput
					fullWidth
					type="text"
					field={'amount'}
					label={I18n.get("_amount") + ":"}
					store={this}
					disabled={cardModel.loading}
					autoComplete="off"
					inputProps={{
						'data-testid': 'amount'
					}}
				/>
			</CardContent>
		)
	}

	//TODO check if amount must be numeric
	getRules() {
		return {
			destId: {
				presence: { allowEmpty: false }
			}
		}
	}
}

decorate(CashAction, {
	Link: observable,
	isValid: observable,
	Title: observable,
	destId: observable,
	ButtonText: observable,
	amount: observable
})

export default CashAction;
