
export const styles = theme => ({
    avatar: {
        height: 30,
        width: 30,
        borderRadius: 4
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    }
});
