import FastForm from "./FastForm";
import CardContent from "@material-ui/core/CardContent";
import NewActionHeader from "../../NewAction/NewActionHeader/NewActionHeader";
import FormInput from "../../NewAction/inputs/FastFormInput/FastFormInput";
import React from "react";

class FastFromIntegromat extends FastForm {

    static metadata = {
        id: "FastFormIntegromat",
        name: "Fast Form Integromat",
        icon: "https://l.fast.cm/content/icons/integromat.png",
        categories: {
            integration: true
        }
    };

    // Title = "Fast Form Integromat";
    Link = "https://www.integromat.com";


    getConfigCardBody(cardModel, handleOnChangeIcon) {
        return (
            <CardContent>
                <NewActionHeader
                    onChangeIcon={handleOnChangeIcon}
                    actionModel={this} />
                <FormInput
                    learnMore={"https://support.integromat.com/hc/en-us/articles/360006249313-Webhooks"}
                    hookPlaceholder={"https://hook.integromat.com/1p8inaach67lc4y9itwsnyfwgdbv7by8"}
                    cardModel={cardModel}
                    actionModel={this} />
            </CardContent>
        )
    }

}

export default FastFromIntegromat;