export const styles = theme => ({
    root: {
        maxWidth: 680,
        width: '90%',
        marginTop: 128,
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '16px 32px'
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 32
    },
    sendFeedbackBttn: {
        marginRight: 8
    }
})