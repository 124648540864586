import React from 'react';
import { Container, Text } from './styles';
import PropTypes from 'prop-types';

const Tag = props => {
    return(
        <Container color={props.color} onClick={props.handleOnClick}>
            <Text variant="subtitle2">{props.text}</Text>
        </Container>
    );
}

Tag.propTypes = {
    color: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    handleOnClick: PropTypes.func
}

export default Tag