import styled from 'styled-components';

export const Content = styled.div`
    padding: 32px 32px 8px 32px;
    display: grid;
    grid-gap: 16px;
    width: 500px;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));

    @media screen and (max-width: 481px) {
        width: 95%;
    }
`;

export const ShareItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 8px;
`;

export const Footer = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;