import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { withRouter } from 'react-router-dom';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import {Hub, I18n} from 'aws-amplify';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {useUserModel} from "../../../../hooks";
import lodashGet from 'lodash/get'
function NewCardButton(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const flags = useFlags();
    const userModel = useUserModel();


    const handleClick = event => {
        // setAnchorEl(event.currentTarget);
        if(flags.maxFastcard<=lodashGet(userModel,'fcUserData.totalActiveCards', 0)){
            Hub.dispatch('fcPayWall', {
                data : { flag: `maxFastcard`},
                event: 'clicked'});
            return;
        }
        props.history.push('/create/card');
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Button
                variant="contained"
                aria-describedby={id}
                color="primary"
                style={{ color: 'white' }}
                onClick={handleClick}>
                <AddIcon style={{ marginRight: '4px' }} />
                {I18n.get('_new_card')}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    <ListItem button onClick={props.onBlankCard}>
                        <ListItemIcon style={{ minWidth: '40px' }}>
                            <InsertDriveFileOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={I18n.get('_template_blank_card')} />
                    </ListItem>
                    <ListItem button onClick={props.onTemplates}>
                        <ListItemIcon style={{ minWidth: '40px' }}>
                            <DescriptionOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={I18n.get("_template_use_template", "Use a template")} />
                    </ListItem>
                </List>
            </Popover>
        </div>
    );
}

NewCardButton.propTypes = {
    onBlankCard: PropTypes.func.isRequired,
    onTemplates: PropTypes.func.isRequired
}

export default withRouter(NewCardButton);