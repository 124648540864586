import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useFormik} from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";

import {
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@material-ui/core";
import { I18n } from 'aws-amplify';


AddVariantDialog.propTypes = {

};

function AddVariantDialog(props) {
    const { open, handleClose, onVariantAdded }= props;

    const formikAddVariant = useFormik({
        initialValues: {
            name: '',
            submit: null
        },
        validationSchema: Yup.object({
            name: Yup.string().max(25).required(I18n.get('_field_required'))
        }),
        onSubmit: async (values, helpers) => {
            try {
                toast.success(I18n.get("_variant_added"))
                helpers.setStatus({ success: true });
                helpers.setSubmitting(false);
                if(onVariantAdded){ onVariantAdded(values)}

            } catch (err) {
                toast.error(I18n.get('_request_failed'));
                helpers.setStatus({ success: false });
                helpers.setErrors({...err.response.data});
                helpers.setSubmitting(false);
            }
        }
    });

    return (
        <Card>
            <Dialog
                fullWidth
                open={open}
                TransitionProps={{
                    onEnter:()=>{
                        formikAddVariant.resetForm();
                    },
                }}
                onClose={handleClose}>
                <DialogTitle>{I18n.get('_add_variant')}</DialogTitle>
                <form
                    autoComplete={'off'}
                    onSubmit={formikAddVariant.handleSubmit}>
                    <DialogContent>
                        <DialogContentText>
                            {I18n.get('_add_variant_msg')}
                        </DialogContentText>

                        <TextField
                            disabled={formikAddVariant.isSubmitting}
                            error={Boolean(formikAddVariant.touched.name && formikAddVariant.errors.name)}
                            fullWidth
                            helperText={formikAddVariant.touched.name && formikAddVariant.errors.name}
                            label={I18n.get("_variant")}
                            name="name"
                            onBlur={formikAddVariant.handleBlur}
                            onChange={formikAddVariant.handleChange}
                            required
                            value={formikAddVariant.values.name}
                            margin="dense"
                            variant="outlined"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button color={'primary'} variant={'outlined'} onClick={handleClose}>{I18n.get('_cancel')}</Button>
                        <Button
                            color={'primary'}
                            type="submit"
                            style={{color:"white"}}
                            disabled={formikAddVariant.isSubmitting}
                            variant={'contained'}>
                            {I18n.get('_add_variant')}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Card>
    );
}

export default AddVariantDialog;