import { Flex, Text, useTheme } from "@aws-amplify/ui-react";
import Typography from "@material-ui/core/Typography";
import React from "react";

export function CustomAuthFooter() {
    const { tokens } = useTheme();

    return (
        <Flex justifyContent="center" padding={tokens.space.medium}>
            {/*<Text>&copy; All Rights Reserved</Text>*/}
            {/*<div className={classes.footer}>*/}
                <Typography variant="caption" color="textPrimary"><span dangerouslySetInnerHTML={{ "__html": "&copy;" }} />{new Date().getFullYear()}Fastmind Inc</Typography>
            {/*</div>*/}
        </Flex>
    );
}
