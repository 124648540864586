import React from 'react';
import { LogoContainer } from './styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import { logoIcon } from '../../../assets/icons/logo';

const Logo = props => (
    <LogoContainer onClick={props.onClick}>
        <SvgIcon viewBox="0 0 877.81 257.18" style={{ width: '120px', height: '40px'}} >
            {logoIcon()}
        </SvgIcon>
    </LogoContainer>
);

export default Logo