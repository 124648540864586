
export const uiSchema = {
    "phone": {
        "ui:description": "Please provide country code"
    },
    "note": {
        "ui:widget": "textarea",
        "ui:options": {
            "rows": 4
        }
    },
    "ui:order": [
        "email",
        "first_name",
        "last_name",
        "*"
    ]
};