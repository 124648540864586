import styled from 'styled-components';
import { palette } from '../../../constants/theme';

export const Avatar = styled.img`
    background-color: ${palette.lightGrey};
    border-radius: 6px;
    height: 120px;
    width: 120px;
    object-fit: cover;
    margin-right: 16px;
`

export const AvatarContainer = styled.div`
    display: flex;
    align-items: flex-end;
    margin-bottom: 5px;
`;

export const ButtonsContainer = styled.div`
    margin-top: 32px;
    display: flex;
    justify-content: flex-start;
`;

export const TableButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const InviteContainer = styled.div`
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
`;