export const darkTheme = {
    themeName: 'Fastmind Dark Theme',
    palette: {
        type: 'dark',
        tonalOffset: 0.2,
        contrastThreshold: 3,
        primary: {
            light: '#69a8ff',
            main: '#007aff',
            dark: '#004fcb',
            contrastText: '#000',
        },
        secondary: {
            light: '#2c2c2c',
            main: '#000',
            dark: '#424242',
            contrastText: '#fff',
        },
        success: {
            main: '#4caf50',
        },
        info: {
            main: '#2196f3',
        },
        warning: {
            main: '#ff9800',
        },
        error: {
            main: '#f44336'
        },
        divider: "rgba(255, 255, 255, 0.4)",
        background: {
            paper: '#424242',
            default: '#303030',
        },
        text: {
            primary: "#fff",
            secondary: "rgba(255, 255, 255, 0.7)",
            disabled: "rgba(255, 255, 255, 0.5)",
            hint: "rgba(255, 255, 255, 0.5)",
            icon: "rgba(255, 255, 255, 0.7)"
        },
        action: {
            active: "#fff",
            hover: "rgba(255, 255, 255, 0.08)",
            hoverOpacity: 0.08,
            selected: "rgba(255, 255, 255, 0.16)",
            selectedOpacity: 0.16,
            disabled: "rgba(255, 255, 255, 0.3)",
            disabledBackground: "rgba(255, 255, 255, 0.12)",
            disabledOpacity: 0.38,
            focus: "rgba(255, 255, 255, 0.12)",
            focusOpacity: 0.12,
            activatedOpacity: 0.24
        }
    }
}

export const lightTheme = {
    themeName: 'Fastmind Light Theme',
    palette: {
        type: 'light',
        tonalOffset: 0.2,
        contrastThreshold: 3,
        primary: {
            light: '#69a8ff',
            main: '#007aff',
            dark: '#004fcb',
            contrastText: '#000',
        },
        secondary: {
            light: '#2c2c2c',
            main: '#000',
            dark: '#424242',
            contrastText: '#fff',
        },
        success: {
            main: '#4caf50',
        },
        info: {
            main: '#2196f3',
        },
        warning: {
            main: '#ff9800',
        },
        error: {
            main: '#f44336'
        },
        background: {
            paper: "#fff",
            default: "#f0f0f6",
        },
        text: {
            primary: "rgba(0, 0, 0, 0.87)",
            secondary: "#616161",
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(0, 0, 0, 0.38)",
            icon: "rgba(0, 0, 0, 0.70)"
        },
        divider: "rgba(0, 0, 0, 0.12)",
        action: {
            active: "rgba(0, 0, 0, 0.54)",
            hover: "rgba(0, 0, 0, 0.04)",
            hoverOpacity: 0.04,
            selected: "rgba(0, 0, 0, 0.08)",
            selectedOpacity: 0.08,
            disabled: "rgba(0, 0, 0, 0.26)",
            disabledBackground: "rgba(0, 0, 0, 0.12)",
            disabledOpacity: 0.38,
            focus: "rgba(0, 0, 0, 0.12)",
            focusOpacity: 0.12,
            activatedOpacity: 0.12
        }
    }
}
