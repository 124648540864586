import React from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import { Auth, I18n } from "aws-amplify";
import { useStyles } from './styles';
import lodashGet from 'lodash/get'
import googleIconUrl from '../../../assets/icons/google.png';
import facebookIconUrl from '../../../assets/icons/facebook.png';
import { sendEvent } from '../../../utils/eventLog';
import * as Sentry from "@sentry/react";
import {withRouter} from "react-router-dom";

function ExternalAuth(props) {

    const classes = useStyles();

    async function handleClick(provider) {

        sendEvent(`external_${props.buttonLocation}`, { provider: provider, location: props.location });

        try {
            await Auth.federatedSignIn({ provider: provider, customState: lodashGet(props,'location.search')})
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    return (
        <div className={classes.root}>
            <Button
                data-testid="google-button"
                onClick={() => handleClick('Google')}
                fullWidth
                className={classes.google}
                variant="contained"
                startIcon={<img className={classes.icon} alt="" src={googleIconUrl} />}
                disabled={props.loading}>
                {`${I18n.get(`_auth_${props.buttonLocation}_with`, props.buttonLocation === "sign_in" ? "Sign in with" : "Sign up with")} Google`}
            </Button>
            <Button
                data-testid="facebook-button"
                onClick={() => handleClick('Facebook')}
                fullWidth
                className={classes.facebook}
                variant="contained"
                startIcon={<img className={classes.icon} alt="" src={facebookIconUrl} />}
                disabled={props.loading}>
                {`${I18n.get(`_auth_${props.buttonLocation}_with`, props.buttonLocation === "sign_in" ? "Sign in with" : "Sign up with")} Facebook`}
            </Button>
            {/* <Button*/}
            {/*    onClick={() => handleClick('SignInWithApple')}*/}
            {/*    fullWidth*/}
            {/*    variant="contained"*/}
            {/*    startIcon={*/}
            {/*        <img className={classes.icon} src={appleIconUrl} alt="" />*/}
            {/*    }*/}
            {/*    className={classes.apple}*/}
            {/*    disabled={props.loading}>*/}
            {/*    {`${I18n.get('_auth_sign_with', "Sign in with")} Apple`}*/}
            {/*</Button>*/}
        </div>
    );
}

ExternalAuth.propTypes = {
    loading: PropTypes.bool,
    buttonLocation: PropTypes.string
};

export default withRouter(ExternalAuth);
