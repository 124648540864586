import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'aws-amplify';
import { observer } from 'mobx-react';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import CloudDownload from '@material-ui/icons/CloudDownload';
import {
    FacebookShareButton, LinkedinShareButton, TwitterShareButton,
    TelegramShareButton, WhatsappShareButton, PinterestShareButton, RedditShareButton,
    TumblrShareButton, EmailShareButton
} from 'react-share';
import Slide from '@material-ui/core/Slide';
import DialogTitle from '../../../components/UI/DialogTitle/DialogTitle'
import { Content, ShareItem } from './styles';
import { QRCode } from "react-qr-svg";
import { withTheme } from '@material-ui/core/styles';
import ShareIcon from "@material-ui/icons/Share";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ShareModal extends Component {

    state = {
        copied: false
    }

    copyToClipboard = (e) => {
        this.textArea.select();
        document.execCommand('copy');
        e.target.focus();
        this.setState({ copied: true });
    };

    downloadQR = (idBtn) => {
        const svgEl = document.getElementById(idBtn);
        svgEl.setAttribute("xmlns", "http://www.w3.org/2000/svg");
        let svgData = svgEl.outerHTML;
        let preface = '<?xml version="1.0" standalone="no"?>\r\n';
        let svgBlob = new Blob([preface, svgData], { type: "image/svg+xml;charset=utf-8" });
        let svgUrl = URL.createObjectURL(svgBlob);
        let downloadLink = document.createElement("a");
        downloadLink.href = svgUrl;
        downloadLink.download = "qr.svg";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    handleClose = () => {
        this.setState({
            copied: false
        }, () => {
            this.props.handleClose()
        })
    }

    nativeShare=({url,title, text})=>{

        if(!window.navigator.share){return;}

        window.navigator
            .share({
                title,
                text,
                url,
            })
            .then(() => { })
            .catch(err => { });

    }

    render() {
        const style = { margin: "auto", width: 50, height: 50 };
        const { card } = this.props;

        if(!card.Card_ID){ return null}

        const url = card.customId !== "" ?
            `${process.env.REACT_APP_FASTCARD_BASE}/${card.customId}` :
            `${process.env.REACT_APP_FASTCARD_BASE}/${card.Card_ID}`;

        return (
            <Dialog
                open={this.props.open}
                TransitionComponent={Transition}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >

                <DialogTitle style={{ borderBottom: "none" }} onClose={this.handleClose}>{I18n.get("_share")}</DialogTitle>
                <Content >
                    <ShareItem>
                        <div>
                            <a href={"sms:?&body=" + url} className="link-share-block">
                                <img style={style} src="https://l.fast.cm/content/icons/sms.png" alt='sms' />
                            </a>
                        </div>
                        <Typography variant="subtitle2">Messages</Typography>
                    </ShareItem>
                    <ShareItem>
                        <div>
                            <a href={"fb-messenger://share/?link=" + encodeURIComponent(url) + '&app_id=' + encodeURIComponent("123456789")} className="link-share-block">
                                <img style={style} src="https://l.fast.cm/content/icons/messenger.png" alt='messenger' />
                            </a>
                        </div>
                        <Typography variant="subtitle2">Messenger</Typography>
                    </ShareItem>
                    <ShareItem>
                        <WhatsappShareButton title={card.Title} separator=":: " url={url}>
                            <img style={style} src="https://l.fast.cm/content/icons/whatsapp.png" alt='whatsapp' />
                        </WhatsappShareButton>
                        <Typography variant="subtitle2">Whatsapp</Typography>
                    </ShareItem>
                    <ShareItem>
                        <FacebookShareButton quote={card.Title} url={url}>
                            <img style={style} src="https://l.fast.cm/content/icons/facebook.png" alt='facebook' />
                        </FacebookShareButton>
                        <Typography variant="subtitle2">Facebook</Typography>
                    </ShareItem>
                    <ShareItem>
                        <LinkedinShareButton title={card.Title} windowWidth={750} windowHeight={600} url={url}>
                            <img style={style} src="https://l.fast.cm/content/icons/linkedin.png" alt='linkedin' />
                        </LinkedinShareButton>
                        <Typography variant="subtitle2">Linkedin</Typography>
                    </ShareItem>
                    <ShareItem>
                        <TwitterShareButton title={card.Title} url={url}>
                            <img style={style} src="https://l.fast.cm/content/icons/twitter.png" alt='twitter' />
                        </TwitterShareButton>
                        <Typography variant="subtitle2">Twitter</Typography>
                    </ShareItem>
                    {card.Image &&
                        <ShareItem>
                            <PinterestShareButton media={card.Image} url={url}>
                                <img style={style} src="https://l.fast.cm/content/icons/pinterest.png" alt='pinterest' />
                            </PinterestShareButton>
                            <Typography variant="subtitle2">Pinterest</Typography>
                        </ShareItem>
                    }
                    <ShareItem>
                        <TelegramShareButton title={card.Title} url={url}>
                            <img style={style} src="https://l.fast.cm/content/icons/telegram.png" alt='telegram' />
                        </TelegramShareButton>
                        <Typography variant="subtitle2">Telegram</Typography>
                    </ShareItem>
                    <ShareItem>
                        <RedditShareButton title={card.Title} url={url}>
                            <img style={style} src="https://l.fast.cm/content/icons/reddit.png" alt='reddit' />
                        </RedditShareButton>
                        <Typography variant="subtitle2">Reddit</Typography>
                    </ShareItem>
                    <ShareItem>
                        <TumblrShareButton title={card.Title} url={url}>
                            <img style={style} src="https://l.fast.cm/content/icons/tumblr.png" alt='tumblr' />
                        </TumblrShareButton>
                        <Typography variant="subtitle2">Tumblr</Typography>
                    </ShareItem>
                    <ShareItem>
                        <EmailShareButton title={card.Title} url={url}>
                            <img style={style} src="https://l.fast.cm/content/icons/email.png" alt='email' />
                        </EmailShareButton>
                        <Typography variant="subtitle2">{I18n.get("_email")}</Typography>
                    </ShareItem>
                    {window.navigator.share &&
                        <ShareItem>
                            <Button onClick={()=>{this.nativeShare({url,title:card.Title,text:card.Subtitle})}} disableElevation={true} color={'primary'} variant="contained" style={style}>
                                <ShareIcon style={{color: 'white'}} size={'large'}/>
                            </Button>
                            <Typography variant="subtitle2">{I18n.get("_share")}</Typography>
                        </ShareItem>
                    }
                </Content>
                <div style={{ padding: '16px', margin: '0 auto', textAlign: 'center' }}>
                    <div>

                        <QRCode
                            bgColor="#FFFFFF"
                            fgColor="#000000"
                            level="Q"
                            style={{ width: 100 }}
                            value={url}
                            id={`id_${url}`}
                        />

                    </div>
                    <div>
                        <Button
                            onClick={() => this.downloadQR(`id_${url}`)}
                            startIcon={<CloudDownload />}
                        >
                            {I18n.get("_download")}
                        </Button>
                    </div>

                    <div>
                        <div>
                            {document.queryCommandSupported('copy') ?
                                <Button
                                    color="primary"
                                    variant="contained"
                                    style={{ color: 'white', marginBottom: '8px', textTransform: 'none' }}
                                    onClick={this.copyToClipboard}
                                    startIcon={<FileCopyOutlinedIcon />}>
                                    {url}
                                </Button> :
                                <Typography>{url}</Typography>}
                        </div>
                        {this.state.copied && <Typography align="center" variant="caption" color="primary">{I18n.get("_copied", "Copied")}!</Typography>}
                    </div>
                    <form style={{ height: 0, width: 0 }}>
                        <textarea
                            style={{ height: 0, width: 0, padding: 0, borderColor: 'transparent', backgroundColor: this.props.theme.palette.background.paper }}
                            readOnly
                            ref={(textarea) => this.textArea = textarea}
                            value={url}
                        />
                    </form>
                </div>
            </Dialog >
        );
    }
}

ShareModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    card: PropTypes.object.isRequired
}

export default observer(withTheme(ShareModal));
