import styled from 'styled-components';

export const ExternalContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const InnerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const FieldsContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
`;