import React, { Component } from 'react';
import { observer, inject } from "mobx-react";
import MyLinksRow from "./MyLinksRow/MyLinksRow";
import EmptyLinks from './EmptyLinks/EmptyLinks'
import { StyledInfiniteScroll, Container, Background, SkeletonContainer, styles } from './styles';
import SectionHeader from './SectionHeader/SectionHeader';
import ExportExcel from './ExportExcel/ExportExcel';
import AddToCampaign from './AddToCampaign/AddToCampaign';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Skeleton from '@material-ui/lab/Skeleton';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import lodashGet from "lodash/get";
import { Hub, I18n } from "aws-amplify";
import Alert from "@material-ui/lab/Alert";
import { baseUrl } from "../../constants/help-endpoint";
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import ls from 'local-storage'
import {sendEvent} from "../../utils/eventLog";
import {withRouter} from "react-router";

class Home extends Component {


  constructor(props) {
    super(props);

    this.state = {
      openExportExcel: false,
      openAddCardToCampaign: false,
      cardIdToAdd: null,
      loading: false,
      openSnack: false,
      variant: "message",
      message: "",
      showAffiliateBanner: ls.get('showAffiliateBanner') === null
    }
  }
  onSetExportExcel = (value) => {
    this.setState({ openExportExcel: value })
  };

  handleAddToCampaign = cardId => {
    this.setState({
      openAddCardToCampaign: true,
      cardIdToAdd: cardId
    })
  }

  onAddCardToCampaign = campaignId => {

    this.setState({
      loading: true
    })

    let campaign = this.props.CampaignsModel.items.find(el => el.Campaign_ID === campaignId)

    let merged = new Set([...campaign.Cards_ID || [], this.state.cardIdToAdd]);

    let temp = {
      ...campaign,
      Cards_ID: Array.from(merged)
    }

    delete temp["Cards"]

    this.props.CampaignsModel.updateCampaign(temp, success => {
      this.setState({
        loading: false,
        openSnack: true,
        cardIdToAdd: null,
        openAddCardToCampaign: false,
        message: success ? "Card added" : "Opss something went wrong"
      })
    })
  }

  handleClose = () => {
    this.setState({
      openAddCardToCampaign: false,
      cardIdToAdd: null
    })
  }

  handleCloseSnack = () => {
    this.setState({
      openSnack: false,
      message: ""
    })
  }

  getCardSkeleton = key => {
    let skeleton = <div key={key}>
      <Skeleton style={{ marginBottom: '8px' }} variant="rect" width={320} height={10} />
      <Skeleton style={{ marginBottom: '32px' }} variant="rect" width={320} height={10} />
      <Skeleton style={{ marginBottom: '16px' }} variant="rect" width={320} height={200} />
      <Skeleton style={{ marginBottom: '16px' }} variant="rect" width={320} heigth={40} />
      <Skeleton style={{ marginBottom: '16px' }} variant="rect" width={320} heigth={40} />
      <Skeleton style={{ marginBottom: '16px' }} variant="rect" width={320} heigth={40} />
    </div>
    return skeleton
  }

  componentWillUnmount() {
    this.props.CardsDataModel.resetCardsData()
  }

  componentDidMount() {
    this.props.CardsDataModel.fetchData()
  }

  upgradePlan = () => {
    Hub.dispatch('fcPayWall', { data: { flag: `maxFastcard` }, event: 'clicked' });
  }

  LearnMore = () => {
    window.open(baseUrl + 'articles/where-are-my-archived-fastcards-archived-unarchive-fastcards', "_blank")
  }

  LearnMoreAffiliate = () => {
    sendEvent("LearnMoreAffiliate")
    let { history} = this.props;
    history.push('settings/account?openAffiliate=true', "_blank");
    this.affiliateDismiss();
  }

  affiliateDismiss = () => {
    this.setState({showAffiliateBanner: false})
    ls.set('showAffiliateBanner',false)
  }


  render() {
    const { CardsDataModel } = this.props;

    let isOverLimit = lodashGet(this.props, 'flags.maxFastcard', 3) < lodashGet(this.props, 'UserModel.fcUserData.totalActiveCards', 0)

    return (
      <ErrorBoundary >
        <SectionHeader
          model={this.props.model}
          tagsButton={CardsDataModel.LinksList.length > 0}
          handleExportExcel={() => this.onSetExportExcel(true)}
          isLoading={CardsDataModel.isLoading && CardsDataModel.LinksList.length > 0} />

        <Background>

          {(CardsDataModel.isLoading && CardsDataModel.LinksList.length === 0) ?
            <SkeletonContainer>
              {[1, 2, 3, 4].map((el) => {
                return this.getCardSkeleton(el)
              })}
            </SkeletonContainer> :
            (CardsDataModel.LinksList.length > 0) ?
              <div className={this.props.classes.scrollContainer}>

                {isOverLimit &&
                  <div style={{ height: 40, marginBottom: 20, width: '100%' }}>
                    <Alert
                      style={{ backgroundColor: "#FF1744" }}
                      action={
                        <Button onClick={() => { this.LearnMore() }} color="inherit" size="small">
                          {I18n.get('_learn_more')}
                        </Button>
                      }
                      variant="filled"
                      severity="error">
                      {I18n.get("exceedMaxFC")} &#8212; <a style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => { this.upgradePlan() }} > {I18n.get('_upgrade')}</a>
                    </Alert>
                  </div>
                }
                { this.state.showAffiliateBanner &&
                  <div style={{ height: 40, marginBottom: 30, width: '100%' }}>
                    <Alert
                      action={<>
                        <Button style={{marginRight:5}} onClick={this.affiliateDismiss} color="inherit" size="small">
                          {I18n.get('_dismiss')}
                        </Button>

                        <Button onClick={this.LearnMoreAffiliate} color="inherit" size="small">
                          {I18n.get('_learn_more')}
                        </Button>
                      </>
                      }
                      variant="filled"
                      severity="success">
                      {I18n.get("affiliateLink_announcement")} $$
                    </Alert>
                  </div>
                }
                <StyledInfiniteScroll
                  dataLength={CardsDataModel.LinksList.length}
                  next={() => { CardsDataModel.fetchMoreUserLinks() }}
                  hasMore={CardsDataModel.hasMore}
                  refreshFunction={() => { CardsDataModel.PullToRefresh() }}
                  pullDownToRefresh={true}
                  pullDownToRefreshContent={
                    <span />
                  }
                  releaseToRefreshContent={
                    <span />
                  }
                >
                  <Container>
                    {CardsDataModel.LinksList.map((card, index) => {
                      return (
                        <MyLinksRow
                          card={card}
                          key={index}
                          isOverLimit={isOverLimit}
                          onAddToCampaign={() => this.handleAddToCampaign(card.Card_ID)}
                          cardModel={CardsDataModel.LinksListDict[card.Card_ID]}
                          cardsDataModel={CardsDataModel} />)
                    })}
                  </Container>
                  <AddToCampaign
                    handleConfirm={this.onAddCardToCampaign}
                    handleClose={this.handleClose}
                    open={this.state.openAddCardToCampaign} />

                </StyledInfiniteScroll>
              </div> :
              !CardsDataModel.isLoading ? <EmptyLinks /> : null}
        </Background>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          variant="success"
          open={this.state.openSnack}
          autoHideDuration={4000}
          onClose={this.handleCloseSnack}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.message}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={this.handleCloseSnack}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
        <ExportExcel
          isOpen={this.state.openExportExcel}
          handleClose={() => this.onSetExportExcel(false)}
          CardsDataModel={CardsDataModel} />
      </ErrorBoundary>
    );
  }
};

export default withRouter(withStyles(styles)(withLDConsumer()(inject("CardsDataModel", "model", "CampaignsModel", "UserModel")(observer(Home)))));
