import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { InnerContainer, FieldsContainer, ExternalContainer } from './styles';
import {Hub, I18n} from 'aws-amplify';
import IconInput from '../inputs/IconInput/IconInput';
import Divider from '@material-ui/core/Divider';
import TextInput from "../inputs/TextInput/TextInput";
import {
    Button,
    IconButton,
    List,
    ListItemIcon,
    ListItemSecondaryAction,
    ListSubheader,
    Switch,
    TextField
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PayWallConfigInput from "../inputs/config/PayWallConfingInput/PayWallConfigInput";


import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {withStyles} from "@material-ui/core/styles";
import ScheduleLinkConfigInput from "../inputs/config/ScheduleLinkConfigInput/ScheduleLinkConfigInput";
import lodashGet from "lodash/get";
import lodashCamelCase from "lodash/camelCase";
import PriorityLinkConfigInput from "../inputs/config/PriorityLinkConfigInput/PriorityLinkConfigInput";
import {withLDConsumer} from "launchdarkly-react-client-sdk";

class NewActionHeader extends Component {


    hasFeature = (LDKey)=>{
        let { flags } = this.props;
        let hasFeature =  lodashGet(flags, lodashCamelCase(LDKey), true)

        if(!hasFeature){
            Hub.dispatch('fcPayWall', {
                data : { flag: lodashCamelCase(LDKey)},
                event: 'clicked'});
        }

        return hasFeature;
    }

    render() {
        let { theme } = this.props;
        return (
            <>
                <ExternalContainer>
                    <IconInput
                        onChangeIconClicked={() => this.props.onChangeIcon("Icons", true, false, this.props.actionModel)}
                        actionModel={this.props.actionModel}
                        cardModel={this.props.model} />
                    <FieldsContainer>
                        <TextInput
                            type={'text'}
                            field={'Title'}
                            label={I18n.get("_title")}
                            store={this.props.actionModel}
                            disabled={this.props.model.loading}
                            showRequiredHelperText={I18n.get("_required", "Required")}
                            inputProps={{
                                'data-testid': 'title'
                            }}
                        />

                        <InnerContainer>

                            {!this.props.hideSubtitle && <TextInput
                                type={'text'}
                                field={'Subtitle'}
                                label={I18n.get("_subtitle")}
                                store={this.props.actionModel}
                                disabled={this.props.model.loading || this.props.subtitle === "disabled"}
                                style={{ marginRight: '16px' }}
                                inputProps={{
                                    'data-testid': 'subtitle'
                                }}
                            />}

                            {!this.props.hideBtnText && <TextInput
                                type={'text'}
                                field={'ButtonText'}
                                label={I18n.get("_button_text")}
                                store={this.props.actionModel}
                                placeholder={I18n.get("_button_text") + ":"}
                                disabled={this.props.model.loading || this.props.buttonText === "disabled"}
                                onChange={value => this.props.actionModel.ButtonText = value.toUpperCase()}
                                maxLength="9"
                                inputProps={{
                                    'data-testid': 'buttonText'
                                }}
                            />}
                        </InnerContainer>
                    </FieldsContainer>
                </ExternalContainer>

                <Divider style={{marginTop:8}} />
                <div style={{ marginTop:6}}>
                    <Accordion
                        elevation={0}
                        style={{ border: theme.palette.divider,borderWidth:0.1,borderStyle:'dashed'}}
                        defaultExpanded={this.props.actionModel.Animate || this.props.actionModel?.paywallData?.active || this.props.actionModel?.scheduleLinkData?.active}
                        // square
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <Typography variant={'subtitle2'}>{I18n.get('_link_config')}</Typography>
                        </AccordionSummary>

                        <AccordionDetails style={{ display: 'block'}}>
                            <List dense={true}>
                                <PayWallConfigInput LDKey={'paywall-link-config'} hasFeature={this.hasFeature} actionModel={this.props.actionModel}/>
                                <ScheduleLinkConfigInput LDKey={'schedule-link-config'} hasFeature={this.hasFeature} actionModel={this.props.actionModel}/>
                                <PriorityLinkConfigInput  LDKey={'priority-link-config'} hasFeature={this.hasFeature} actionModel={this.props.actionModel}/>
                            </List>
                        </AccordionDetails>
                    </Accordion>


                </div>
                {!this.props.hideDivider && <Divider style={{ margin: '24px 0 16px 0' }} />}
            </>
        );
    }

}

NewActionHeader.propTypes = {
    subtitle: PropTypes.string,
    buttonText: PropTypes.string,
    actionModel: PropTypes.object.isRequired,
    onChangeIcon: PropTypes.func,
    hideSubtitle: PropTypes.bool,
    hideBtnText: PropTypes.bool,
    hideDivider: PropTypes.bool,
}

export default  withStyles(undefined,{withTheme:true})(withLDConsumer()(inject("model")(observer(NewActionHeader))));
