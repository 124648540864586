import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const Checkbox = props => (
    <label className="checkbox-label">
        <input
            type='checkbox'
            name={props.name}
            checked={props.checked}
            onChange={props.handleChange} />
        <span className="checkbox-custom" />
    </label>
);

Checkbox.propTypes = {
    checked: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired
}

export default Checkbox;