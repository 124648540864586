import React from 'react';
import { observable, decorate } from "mobx";
import CardContent from '@material-ui/core/CardContent';
import { I18n } from 'aws-amplify';
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import NewActionHeader from '../../NewAction/NewActionHeader/NewActionHeader';
import UsernameInput from '../../NewAction/inputs/UsernameInput/UsernameInput';

class VenmoAction extends ActionModel {

	static metadata = {
		id: "venmo",
		name: "Venmo",
		icon: "https://l.fast.cm/content/icons/venmo.png",
		categories: {
			// payments: true
		}
	};

	constructor(dat) {
		super();
		// this.propNames = ["Title", "Image", "Subtitle", "ButtonText", "destId"];
		this.propNames.push("destId");
		this.setFromObject(dat);
	}

	Title = "Venmo";
	Link = "https://www.venmo.com/"
	ButtonText = "GO";
	isValid = false

	onValidateUrl = () => {
		this.isValid = true
	}

	getConfigCardBody(cardModel, handleOnChangeIcon) {
		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />
				<UsernameInput
					placeHolderText={I18n.get("_username")}
					cardModel={cardModel}
					actionModel={this} />
			</CardContent>
		)
	}
}

decorate(VenmoAction, {
	Link: observable,
	isValid: observable,
	Title: observable,
	ButtonText: observable
})

export default VenmoAction