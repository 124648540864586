import React from 'react';
import { I18n } from 'aws-amplify';
import { observer } from 'mobx-react';
import TextInput from "../TextInput/TextInput";

function UsernameInput(props) {

    let { cardModel, actionModel, placeHolderText } = props;

    return (
        <TextInput
            fullWidth
            type="text"
            field={'destId'}
            label={placeHolderText || I18n.get("_app_search_placeholder")}
            placeholder={placeHolderText || I18n.get("_app_search_placeholder")}
            store={actionModel}
            disabled={cardModel.loading}
            autoComplete="off"
            inputProps={{
                'data-testid': 'destId'
            }}
        />
    )
}

export default observer(UsernameInput);
