import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import { decorate, observable } from "mobx";
import ActionModel from '../../../../../models/ActionModel/ActionModel';
import NewActionHeader from '../../NewAction/NewActionHeader/NewActionHeader';
import YoutubeSearchInput, { YoutubeTypes } from "../../NewAction/inputs/YoutubeSeachInput/YoutubeSearchInput";
import { I18n } from 'aws-amplify';

class YoutubeSubscribeAction extends ActionModel {
	static metadata = {
		id: "youtube_subscribe",
		name: "YouTube",
		icon: "https://l.fast.cm/content/icons/youtube.png",
		categories: {
			social: true
		},
		help: {
			id: 'articles/how-to-youtube-subscription-button',
			helperText: I18n.get('_subscription_help_text', "Click to learn more about the Subscription action")
		}
	};

	constructor(dat) {
		super();
		this.ButtonText = "Subscribe"
		// this.propNames = ["Title", "Image", "Subtitle", "ButtonText", "Link"]
		this.setFromObject(dat)
	}

	Image = this.Image || this.constructor.metadata.icon;


	getConfigCardBody(cardModel, handleOnChangeIcon) {
		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />
				<YoutubeSearchInput
					actionModel={this}
					cardModel={cardModel}
					itemType={YoutubeTypes.channel}
					value={this.Link}
					label={I18n.get("_youtube_search_channel_copy_channel_link")}
				/>
			</CardContent>
		)
	}

	getRules() {
		return {
			Link: {
				presence: { allowEmpty: false },
				url: { message: I18n.get("_action_wrong_link", "Wrong link") }
			},
			...super.getRules()
		}
	}
}

decorate(YoutubeSubscribeAction, {
	Link: observable,
	isValid: observable,
	Title: observable,
	ButtonText: observable
})

export default YoutubeSubscribeAction
