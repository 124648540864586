
export const styles = theme => ({
    avatar: {
        marginRight: '8px'
    },
    icon: {
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
    },
    connectedAccount: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    accountName: {
        flex: 1
    },
    lastUpdatedText: {
        marginTop: 6,
        marginBottom: 2,
        fontWeight: 'bold'
    }
})