import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    avatar: {
        borderRadius: "20px",
        height: "40px",
        width: "40px",
        objectFit: "cover",
        marginRight: "16px",
        padding: "1px",
        border: `1px solid ${theme.palette.divider}`,
        '&:hover': {
            opacity: 0.5
        }
    },
    avatarContainer: {
        display: 'flex',
        marginTop: 8,
        position: 'relative',
        justifyContent: 'flex-end',
        flexDirection: 'column'
    },
    cameraIcon: {
        opacity: 0,
        position: 'absolute',
        left: '8px',
        top: '8px',
        '&:hover': {
            opacity: 0.5
        }
    },
    loader: {
        position: 'absolute',
        left: '10px',
        top: '10px',
        color: 'white'
    },
    cancelIcon: {
        position: 'absolute',
        right: '4px',
        top: '-4px',
        fontSize: '15px !important'
    },
    textImgSize: {
        fontSize: 10
    },
    dropcontent: {
        outlineStyle: 'none'
    }
}));
