import { observable, decorate, action } from 'mobx'
import { lightTheme, darkTheme } from './themeObjects';

class Theme {

    current = lightTheme;
    type = 'light';
    changedByUser = false;

    constructor() {
        // let themePreferenceSelected = localStorage.getItem("fastmint_theme_preference_selected");
        let themePreferenceSelected = 'light';
        if (themePreferenceSelected) {
            this.changedByUser = true;
            if (themePreferenceSelected === 'dark') {
                this.current = darkTheme;
                this.type = 'dark';
            } else {
                this.current = lightTheme;
                this.type = 'light';
            }
        } else {
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                this.current = darkTheme;
                this.type = 'dark';
            } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
                this.current = lightTheme;
                this.type = 'light';
            }
        }
    }

    toggleTheme = () => {
        const { palette: { type } } = this.current;
        let updateTheme = {};

        if (type === 'light') {
            updateTheme = darkTheme
        } else {
            updateTheme = lightTheme
        }

        this.current = updateTheme;
        this.type = updateTheme.palette.type;
        this.changedByUser = true;
        localStorage.setItem("fastmint_theme_preference_selected", this.type);
    }
}

decorate(Theme, {
    current: observable,
    toggleTheme: action,
    type: observable,
})

export default Theme;