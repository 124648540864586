import styled from 'styled-components';

export const styles = theme => ({
    previewBttn: {
        width: '100%',
        textTransform: 'none !important',
        textDecoration: 'none !important',
        justifyContent: 'flex-start !important',
        [theme.breakpoints.up('md')]: {
            padding: '0 16px !important',
            height: 40
        },

        '&:hover, &:active': {
            color: 'inherit',
            textDecoration: 'none'
        }
    },
    optionsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 24px'
    },
    option: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    metricsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 24px',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flexStart',
            padding: '8px 16px',
        }
    },
    metricItem: {
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            textAlign: 'left',
            minWidth: 100,
        }
    }
})

export const CardHeader = styled.div`
    padding: 8px 16px;
`;

export const HeaderTopContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const TagsContainer = styled.div`
    height: 26px;
    margin-top: 8px;
    overflow: hidden;
`;

export const TagInnerContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    padding-bottom: 17px;
    box-sizing: content-box;
`;


