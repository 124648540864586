
import makeStyles from "@material-ui/styles/makeStyles";

export const useStyles = makeStyles(theme => ({
    nameMarginRight: {
        marginRight: '15px',
    },
    divider: {
        margin: '24px 0 16px 0'
    },
    btnExpandCollapseContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginBottom: 8
    },
    btnExpandCollapse: {
        padding: 0,
        minWidth: 0,
        marginLeft: 5
    },
    mtop15: {
        marginTop: '15px'
    },
    headerForm: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center"
    },
    photoNameContainer: {
        display: 'flex',
        alignItems: "flex-start",
        marginTop: "20px"
    },
    fieldsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    /*Websites*/
    btnAdd: {
        color: 'white'
    },
    fieldContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    valueField: {
        marginRight: '15px',
    },
    selectType: {
        paddingLeft: '10px',
        width: '200px'
    },
    iconDelete: {
        marginLeft: '10px',
        marginTop: '15px'
    },
    componentContainer: {
        marginTop: '15px'
    },
    btnRowGroupContainer: {
        display: "flex",
        width: "80px",
        justifyContent: "flex-start"
    },
    btn: {
        width: "40px"
    },
    rowContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    }
}));
