import React from 'react';
import { observable, decorate } from "mobx";
import CardContent from '@material-ui/core/CardContent';
import ActionModel from '../../../../../models/ActionModel/ActionModel';
import NewActionHeader from '../../NewAction/NewActionHeader/NewActionHeader';
import SpotifyInput from '../../NewAction/inputs/SpotifyInput/SpotifyInput';
import { I18n } from 'aws-amplify';

class SpotifyAction extends ActionModel {
	static metadata = {
		id: "spotify",
		name: "Spotify",
		icon: "https://l.fast.cm/content/icons/spotify.png",
		categories: {
			// media: true
		}
	};

	// Title = "Spotify";
	// Image = this.Image || this.constructor.metadata.icon
	isValid = false;
	Link = "";

	getConfigCardBody(cardModel, handleOnChangeIcon) {
		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />
				<SpotifyInput
					hideOptions={true}
					actionModel={this}
					cardModel={cardModel} />
			</CardContent>
		)
	}

	getRules() {
		return {
			Link: {
				presence: { allowEmpty: false },
				url: { message: I18n.get("_action_wrong_link", "Wrong link") }
			},
			...super.getRules()
		}
	}

}

decorate(SpotifyAction, {
	Link: observable,
	isValid: observable,
	Title: observable,
	ButtonText: observable
})

export default SpotifyAction
