import React, { Fragment } from "react";
import { observable, decorate, computed, action, reaction } from "mobx";
import CardContent from '@material-ui/core/CardContent';
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import NewActionHeader from "../../NewAction/NewActionHeader/NewActionHeader";
import {
	ExpandableCell,
	ListItem, ServiceActionButton, ServiceActionButtonText,
	ServiceSubtitle,
	ServiceThumbnail,
	ServiceTitle,
	TextContainer
} from "../../../../../models/ActionModel/styles";
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import GoogleMapAction from "./googlemaps";
import UberAction from "./uber";
import LyftAction from "./lyft";
import AppleMapsAction from "./applemaps";
import { I18n } from "aws-amplify";
import WazeAction from "./waze";
import MobilityInput from "../../NewAction/inputs/MobilityInput/MobilityInput";
import MobilityItem from "../../../../../components/FastCard/MobilityItem/MobilityItem";
import { randomString } from "../../../../../utils/functions";
import { actionsMap } from "../index";
import AddressInput from "../../NewAction/inputs/AddressInput/AddressInput";

const getMobilityChildActions = () => [new GoogleMapAction(), new UberAction(), new LyftAction(), new AppleMapsAction(), new WazeAction()];

class MobilityAction extends ActionModel {
	static metadata = {
		id: "mobility",
		name: "Mobility",
		subtitle: "4 min wait - $14-18",
		icon: "https://l.fast.cm/content/icons/mobility.png",
		categories: {
			mobility: true
		},
		help: {
			id: 'articles/mobility-action-single-multiple-or-latlon',
			helperText: I18n.get('_mobility_help_text', "Click to learn more about the Mobility action")
		}
	};
	expand = false
	Image = this.Image || this.constructor.metadata.icon;
	childActions = getMobilityChildActions();
	availableActions = getMobilityChildActions();
	ButtonText = I18n.get("_view", "VIEW");
	address = "";
	lat_lng = [0,0];
	lat = "";
	lng = "";
	autoSelect = false
	disposeAddressFieldReaction

	constructor(dat) {
		super();
		this.propNames.push("address", "lat_lng", "dynamicLocationData", "autoSelect");
		this.setFromObject(dat)
		this.lat = this.lat_lng[0]
		this.lng = this.lat_lng[1]

		if (dat && dat.childActions) {
			this.childActions = dat.childActions.map((childAction) => new actionsMap[childAction.action](childAction))
		}

		this.setupAddressFieldReaction()
	}

	get asObject() {
		let ret = {
			Title: "",
			Subtitle: "",
			Image: "",
			Link: "",
			ButtonText: I18n.get("_view", "VIEW")
		};

		this.propNames.forEach(e => {

			ret[e] = this[e];
		});

		if (this.constructor.metadata.id && this.constructor.metadata.id !== "default") {
			ret.action = this.constructor.metadata.id;
		}

		if (this.Subtitle) {
			ret.Subtitle = this.Subtitle
		}

		ret.actionID = this.actionID || `${this.constructor.metadata.id}_${randomString(9, "#aA")}`

		ret["childActions"] = this.childActions.map(childAction => childAction.asObject)

		return ret;
	}

	get isChildActionsEmpty() {
		return this.childActions.length === 0
	}

	validate() {
		let isValid = super.validate();

		this.childActions.forEach(childAction => {
			if (!childAction.validate()) {
				isValid = false;
			}
		})

		return isValid
	}

	get groupable() {
		return false
	}

	toggleOpen = () => {
		this.expand = !this.expand
	}

	get availableChildActionEmpty() {
		return this.availableActions.length === 0;
	}

	toggleChecked(action) {
		const found = this.childActions.find(childAction => childAction.constructor.metadata.id === action.constructor.metadata.id)
		if (found !== undefined) {
			this.childActions = this.childActions.filter(childAction => found.constructor.metadata.id !== childAction.constructor.metadata.id)
		} else {
			action.address = this.address
			action.lat_lng = this.lat_lng
			action.lat = this.lat
			action.lng = this.lng
			this.childActions.push(action)
		}
	}

	getConfigCardBody(cardModel, handleOnChangeIcon) {
		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					subtitle={"disabled"}
					actionModel={this} />
				<AddressInput
					cardModel={cardModel}
					actionModel={this} />
				<MobilityInput actionModel={this} onChangeIcon={handleOnChangeIcon} cardModel={cardModel} />
			</CardContent>
		)
	}

	RenderItem = (cardModel, index, theme, containerClassName) => {

		let isLast = cardModel.LinksList.length === 0 ? true : (cardModel.LinksList.length - 1 === index);
		let image = this.Image || this.constructor.metadata.icon;
		return (
			<Fragment>
				<ListItem
					failed={false}
					last={isLast}
					theme={theme}
					onClick={this.toggleOpen}
					key={index}
					className={containerClassName}>
					{
						this._ImageIsFromUs ? (
							<ServiceThumbnail
								theme={theme}
								loading="lazy"
								src={`${image}?width=80&height=80`}
								srcSet={`${image}?width=50&height=50 1x,
							 		 ${image}?width=80&height=80 2x,
							 		 ${image}?width=100&height=100 3x`
								}
								alt={this.Title || this.constructor.metadata.name} />
						) : (
							<ServiceThumbnail
								theme={theme}
								loading="lazy"
								src={image}
								alt={this.Title || this.constructor.metadata.name} />
						)
					}

					<TextContainer>
						<ServiceTitle theme={theme}>{this.Title || this.constructor.metadata.name}</ServiceTitle>
						<ServiceSubtitle theme={theme}>{this.Subtitle || this.constructor.metadata.subtitle}</ServiceSubtitle>
					</TextContainer>

					<ServiceActionButton theme={theme} failed={false}>
						{this.expand ?
							<UpIcon style={{ color: theme.cardActions }} />
							:
							<ServiceActionButtonText theme={theme}>
								<div>{(this.ButtonText).toUpperCase()}</div>
							</ServiceActionButtonText>}
					</ServiceActionButton>
				</ListItem>
				<ExpandableCell open={this.expand} theme={theme} last={isLast}>
					{this.childActions.map(el => {
						return (
							<Fragment key={el.actionID}>
								<MobilityItem
									theme={theme}
									item={el}
									clicked={(item) => this.handleClick(item, cardModel)} />
							</Fragment>
						)
					})}
				</ExpandableCell>
			</Fragment>
		);
	};

	getRules() {
		return {
			address: {
				presence: { allowEmpty: false },
			},
			lat_lng: {
				type: "array"
			},
			lat: {
				presence: { allowEmpty: false },
				numericality: {
					greaterThanOrEqualTo: -90,
					lessThanOrEqualTo: 90,
				}
			},
			lng: {
				presence: { allowEmpty: false },
				numericality: {
					greaterThanOrEqualTo: -180,
					lessThanOrEqualTo: 180,
				}
			},
			...super.getRules()
		}
	}

	setupAddressFieldReaction() {
		this.disposeAddressFieldReaction = reaction(
			() => {
				const { address, lat_lng, lat, lng } = this
				return { address, lat_lng, lat, lng }
			},
			({ address, lat_lng, lat, lng }) => {
				this.childActions.forEach((action) => {
					action.address = address
					action.lat_lng = lat_lng
					action.lat = lat
					action.lng = lng
				})
			},
		);
	}
};

decorate(MobilityAction, {
	address: observable,
	lat_lng: observable,
	lat: observable,
	lng: observable,
	expand: observable,
	childActions: observable,
	availableChildActionEmpty: computed,
	availableActions: observable,
	asObject: computed,
	toggleChecked: action,
	groupable: computed,
	autoSelect: observable
})

export default MobilityAction
