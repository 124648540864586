// https://developers.facebook.com/docs/app-events/getting-started-app-events-web/
import { getAnalytics, logEvent, setCurrentScreen, setUserId, setUserProperties as setFireBaseUserProperties } from "firebase/analytics";
import lodashSnakeCase from 'lodash/snakeCase'
import {adminProtocolStorage, rewardfulReferral as referral} from "../constants/data-fetching";
import { API } from 'aws-amplify';
import mixpanel from "mixpanel-browser";
import ls from "local-storage";

export const sendEvent = (name, data) => {

    if(window.sessionStorage && window.sessionStorage.getItem(adminProtocolStorage)){return;}

    if (!name) { console.log('no event can be sent without a name');  return;  }

    name = lodashSnakeCase(name);

    if (window.FB) {
        window.FB.AppEvents.logEvent(name, data);
    }

    const analytics = getAnalytics();
    logEvent(analytics, name, data);
    // zoho page sense
    if(window.pagesense){
        window.pagesense.push(['trackEvent', name])
    }

    if(mixpanel){
        try{
            mixpanel.track(name, data);
        }catch(e){
            console.log('mixpanel failed', e)
        }
    }

};

export const pageView = (location)=>{

    if(window.sessionStorage && window.sessionStorage.getItem(adminProtocolStorage)){return;}

    if (window.FB) {  window.FB.AppEvents.logPageView();  }

    const analytics = getAnalytics();
    setCurrentScreen(analytics, location)

    // hubspot page tracking
    let _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', location]);
    _hsq.push(['trackPageView']);

    // zoho
    if(window.pagesense){
        // window.pagesense.push(['trackEvent','trackPageView']);
    }
};


export const setUserProperties = (data) => {
    // https://developers.facebook.com/docs/analytics/send_data/user_properties/

    if(!data){return;}

    // Do not go any further and send data if it's overWatch protocol
    if(window.sessionStorage && window.sessionStorage.getItem(adminProtocolStorage)){return;}

    let userID = data['fastcard_user'];
    let rewardfulReferral = ls.get(referral);

    if (window.FB) {
        window.FB.AppEvents.setUserID(userID);
        window.FB.AppEvents.updateUserProperties(data, (res) => {
        });
    }

    const analytics = getAnalytics();

    setUserId(analytics, userID);
    setFireBaseUserProperties(analytics, data);

    // zoho
    if(window.pagesense){
        window.pagesense.push(['identifyUser', data['email']]);
    }

    /// hubspot
    let _hsq = window._hsq = window._hsq || [];
    _hsq.push(["identify", data]);

    // Zoho
    let path = '/identifyUser';
    let apiName = 'fastShare';
    let apiNamePayment = 'fastCardPayment';

    let IdData = {
        "First_Name": data['firstname'],
        "Last_Name": data['lastname'],
        "Email": data['email'],
        "fastcard_user": userID,
        "Record_Image": data['avatar_url'],
        "Mailing_Country": data['country'],
        "login_provider": data['login_provider'],
        "purpose": data['purpose'],
        "active_fastcards": data['active_fastcards'],
        "inactive_fastcards": data['inactive_fastcards'],
        "language": data['hs_language'],
        "plan": data['plan'],
        rewardfulReferral
    }


    if(window.$zoho && window.$zoho.salesiq){
        window.$zoho.salesiq.visitor.email(data?.email);
        window.$zoho.salesiq.visitor.id(userID);
        window.$zoho.salesiq.visitor.info(data);
    }

    if(mixpanel){
        mixpanel.identify(userID);
        mixpanel.people.set({
            "$email": data['email'],
            "$avatar": data['avatar_url'],
            "USER_ID": userID,
            "$first_name": data['firstname'],
            "$last_name": data['lastname'],
            "login_provider": data['login_provider'],
            "purpose": data['purpose'],
            "active_fastcards": data['active_fastcards'],
            "inactive_fastcards": data['inactive_fastcards'],
            "plan": data['plan'],
            rewardfulReferral
        });
    }


    API.post(apiName, path, {body:IdData})
    .then(result => {})
    .catch(error => {});

    API.post(apiNamePayment, '/updateCustomer', {body:{rewardfulReferral}})
    .then(result => {})
    .catch(error => {});

};

export function createCard({tagsAmount, hasVideo, numberOfActions, desktopBackground, autoOptimize, shareButton, autoPlay, clickNewTab, fallbackURL, pixel, tagManager}) {
    let params = {};
    params['tagsAmount'] = tagsAmount;
    params['hasVideo'] = hasVideo;
    params['numberOfActions'] = numberOfActions;
    params['desktopBackground'] = desktopBackground;
    params['autoOptimize'] = autoOptimize;
    params['shareButton'] = shareButton;
    params['autoPlay'] = autoPlay;
    params['clickNewTab'] = clickNewTab;
    params['fallbackURL'] = fallbackURL;
    params['pixel'] = pixel;
    params['tagManager'] = tagManager;

   sendEvent('Card Created', params);
}

export function updateCard({tagsAmount, hasVideo, numberOfActions, desktopBackground, autoOptimize, shareButton, autoPlay, clickNewTab, fallbackURL, pixel, tagManager}) {
    let params = {};
    params['tagsAmount'] = tagsAmount;
    params['hasVideo'] = hasVideo;
    params['numberOfActions'] = numberOfActions;
    params['desktopBackground'] = desktopBackground;
    params['autoOptimize'] = autoOptimize;
    params['shareButton'] = shareButton;
    params['autoPlay'] = autoPlay;
    params['clickNewTab'] = clickNewTab;
    params['fallbackURL'] = fallbackURL;
    params['pixel'] = pixel;
    params['tagManager'] = tagManager;
    sendEvent('Card Updated', params);
}
