import {decorate, observable} from "mobx";
import ValidationModel from "../../../../../models/ValidationModel/ValidationModel";

class PhoneContact extends ValidationModel {
    value = ''
    type = 'home'

    constructor(data) {
        super();
        if (data){
            this.value = data.value;
            this.type = data.type;
        }
    }
}

decorate(PhoneContact, {
    value: observable,
    type: observable,
})

export default PhoneContact
