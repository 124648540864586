import React, {Component} from 'react';
import {Button} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {I18n, API} from "aws-amplify";


class ImportFastCard extends Component {

    constructor(props){
        super(props);
        this.state ={
            open:false,
            importingCard:false,
            cardUrl:"",
            errorImporting:false
        }
    }

    handleOpen = () =>{
        this.setState({open:true})

    }
    handleClose = ()=>{
        this.setState({open:false})
    }

    handleError= (error)=>{
        this.setState({errorImporting:error})
    }

    handleImport = ()=>{

        let path = '/importCard';
        let apiName = 'fastShare';
        let myInit = {
            body: {
                cardUrl: this.state.cardUrl
            },
        };
        this.handleError(false)
        API.post(apiName, path, myInit)
        .then(result => {
            console.log(result)
            this.handleClose();
            window.location.reload();
        })
        .catch(error => {
            console.log(error)
            this.handleError(true)
        })
    }

    render() {
        return (
            <div>
                <Button onClick={this.handleOpen} style={{marginRight:20}} variant="outlined" color={'primary'}>{I18n.get('_import_fastcard')}</Button>

                <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{I18n.get('_import_fastcard')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {I18n.get('_import_fastcard_description')}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            variant={'outlined'}
                            placeholder={"https://fast.cm/harry"}
                            margin="dense"
                            id="url"
                            label={I18n.get('_url')}
                            type="text"
                            fullWidth
                            onChange={(e)=>{this.setState({cardUrl: e.target.value})}}
                            value={this.state.cardUrl}
                            disabled={this.state.importingCard}
                            error={this.state.errorImporting}
                            helperText={this.state.errorImporting? I18n.get("save_failed"):""}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={this.state.importingCard}
                            onClick={this.handleClose} color="primary">
                            {I18n.get('_cancel')}
                        </Button>
                        <Button
                            disabled={this.state.importingCard}
                            onClick={this.handleImport} color="primary">
                            {I18n.get('_import_fastcard')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default ImportFastCard;