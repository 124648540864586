import React, { Component } from 'react';
import { saveAs } from 'file-saver';
import XLSX from 'xlsx';
import { I18n } from 'aws-amplify';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import { Container, ProgressContainer } from './styles';

class ExportExcel extends Component {

    state = {
        loading: true,
        progress: 0
    }

    handleSheet = (index, cardsData, wb, callback) => {
        if (index === cardsData.length) {
            return callback(wb)
        } else {

            const cardModel = cardsData[index]
            wb.SheetNames.push(cardModel.Card_ID)

            const columnsName = ["Title", "Subtitle", "Tags", "Date", "Time", "Visits"]

            const actionsId = cardModel.GetClicksByService.services
                .sort((a, b) => {
                    let titleA = a.Title.toUpperCase();
                    let titleB = b.Title.toUpperCase();
                    return (titleA < titleB) ? -1 : (titleA > titleB) ? 1 : 0
                })
                .map(item => {
                    let content = item.Title
                    if (item.Subtitle !== "") {
                        content = content + "/" + item.Subtitle
                    }
                    columnsName.push(content)
                    return item.actionID
                })

            const data = [[...columnsName]];

            let cardData = cardModel.analytics.data
            let cardClickData = cardModel.analytics.clickData

            let temp = [];

            for (let row = 0; row < cardData.length; row++) {

                temp[0] = cardModel.Title;
                temp[1] = cardModel.Subtitle;
                temp[2] = Object.keys(cardModel.tags).join();

                const tempDate = new Date(cardData[row].Time)

                const month = tempDate.getUTCMonth() + 1;
                const day = tempDate.getUTCDate();
                const year = tempDate.getUTCFullYear();

                const hours = tempDate.getUTCHours();

                temp[3] = month + "/" + day + "/" + year;
                temp[4] = hours;
                temp[5] = cardData[row].Visitors_Total || 0;
                temp.join();

                // eslint-disable-next-line
                const actionClicks = actionsId.map(actionId => {
                    if (cardClickData && cardClickData[actionId]) {
                        return cardClickData[actionId].data[row].Visitors_Exits
                    }

                    return 0
                })

                temp = temp.concat(actionClicks)
                data.push(temp)
                temp = []
                let ws = XLSX.utils.aoa_to_sheet(data);
                wb.Sheets[cardModel.Card_ID] = ws;
            }

            index += 1;
            const progress = Math.round((index + 1) / cardsData.length * 100);
            this.setState({ progress: progress }, () => {
                this.loopTimeout = setTimeout(() => {
                    return this.handleSheet(index, cardsData, wb, callback)
                }, 50)
            })
        }
    }

    handleExport = async () => {

        let wb = XLSX.utils.book_new();

        wb.Props = {
            Title: "Fastmind analytics",
            Subject: "Cards data",
            Author: "Fastmind",
            CreateDate: new Date()
        };

        const cardsData = this.props.CardsDataModel.LinksList;

        this.handleSheet(0, cardsData, wb, (wb_final) => {
            let wb_out = XLSX.write(wb_final, { bookType: 'xlsx', type: "binary" });

            let buffer = new ArrayBuffer(wb_out.length)
            let view = new Uint8Array(buffer)

            for (let i = 0; i < wb_out.length; i++) {
                view[i] = wb_out.charCodeAt(i) & 0xFF;
            }

            this.setState({ loading: false })
            saveAs(new Blob([buffer], { type: "application/octet-stream" }), "fastmind.xlsx");
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isOpen === true) {
            this.setState({ loading: true })
            this.Loadtimer = setTimeout(() => {
                this.handleExport();
            }, 1000)
        }
    }

    componentWillUnmount() {
        clearTimeout(this.Loadtimer);
        clearTimeout(this.loopTimeout);
    }

    render() {
        return (
            <Dialog
                fullWidth={true}
                maxWidth='sm'
                open={this.props.isOpen}>
                <DialogTitle>{I18n.get('_exportExcel_title')}</DialogTitle>
                <Container>
                    <ProgressContainer>
                        <Typography style={{ marginBottom: '16px' }}>{this.state.loading ? I18n.get("_exportExcel_processing") : I18n.get("_exportExcel_success")}</Typography>
                        <LinearProgress variant="determinate" value={this.state.progress} />
                    </ProgressContainer >
                </Container>
                <DialogActions style={{ margin: "8px 16px" }}>
                    <Button
                        variant="contained"
                        style={{ color: 'white' }}
                        disabled={this.state.loading}
                        color="primary"
                        onClick={this.props.handleClose}>Accept</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default observer(ExportExcel);