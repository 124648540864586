import React from "react";
import { observable, decorate } from "mobx";
import CardContent from '@material-ui/core/CardContent';
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import UserName from '../../NewAction/inputs/UsernameInput/UsernameInput'
import NewActionHeader from "../../NewAction/NewActionHeader/NewActionHeader";
import { I18n } from "aws-amplify";
import TextInput from "../../NewAction/inputs/TextInput/TextInput";


class MessengerAction extends ActionModel {
	static metadata = {
		id: "messenger",
		name: "Messenger",
		icon: "https://l.fast.cm/content/icons/messenger.png",
		categories: {
			social: true
		},
		help:{
			id:"articles/how-to-use-the-facebook-messenger-link"
		}
	};

	Title = "Messenger";
	destId = "";
	isValid = false

	constructor(dat) {
		super();

		this.propNames.push("destId", "message");
		this.setFromObject(dat);
	}

	// onHandleId = () => {
	// 	this.isValid = true
	// }

	getConfigCardBody(cardModel, handleOnChangeIcon) {
		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />
				<UserName
					baseUrl="https://www.facebook.com/"
					placeHolderText={I18n.get("_username")}
					cardModel={cardModel}
					actionModel={this} />

				<TextInput
					fullWidth
					type="textarea"
					field={'message'}
					label={I18n.get("_action_email_placeHolder_message")}
					store={this}
					disabled={cardModel.loading}
					autoComplete="off"
					style={{ marginTop: '16px' }}
				/>
			</CardContent>
		);
	}

	getRules() {
		return {
			destId: {
				presence: { allowEmpty: false }
			},
			...super.getRules()
		}
	}

};

decorate(MessengerAction, {
	Title: observable,
	destId: observable,
	message: observable,
	isValid: observable
})

export default MessengerAction
