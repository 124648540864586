import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { Icon } from './styles';

const SpotifyItem = props => {
    const { suggestion } = props

    return (
        <ListItem button onClick={() => props.handleSelected(suggestion)}>
            <Icon alt="icon" src={suggestion.image} />
            <div>
                <Typography>{suggestion.title}</Typography>
                <Typography variant="body2">{suggestion.subtitle}</Typography>
            </div>
        </ListItem>
    );
}

SpotifyItem.propTypes = {
    suggestion: PropTypes.object.isRequired
}

export default SpotifyItem