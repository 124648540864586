import React from 'react';
import { I18n } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import cardsWelcomeImage from '../../../assets/images/group.png';
import { useStyles } from './styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

function EmptyLinks(props) {

    const history = useHistory();
    const classes = useStyles();

    function handleClick() {
        history.push('/create/card')
    }

    return (
        <div className={classes.root}>
            <div className={classes.innerContainer}>
                <Typography  variant="h6">{I18n.get("_create_first_card_title") + " "}<span className={classes.title}>{I18n.get("_fastcard", "Fastcard")}</span></Typography>
                <Typography className={classes.subtitle}>{I18n.get("_create_first_card_subtext")}</Typography>
                <img className={classes.image} src={cardsWelcomeImage} alt="cards empty" />
                <Button
                    color="primary"
                    variant='contained'
                    className={classes.button}
                    onClick={handleClick}> {I18n.get("_startnow")}</Button>
            </div>
        </div>
    )
};

export default EmptyLinks;