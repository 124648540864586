import {Flex, View, useAuthenticator, useTheme, Authenticator, SelectField} from "@aws-amplify/ui-react";
import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import {withRouter} from "react-router-dom";
import {I18n} from "aws-amplify";
// import ReCAPTCHA from "react-google-recaptcha";
import {signUpReasons} from "../../../constants/reasons";

const CustomSignUpFields = (props)=> {
    const { validationErrors } = useAuthenticator();
    // const [recaptcha, setRecaptcha] = React.useState(false);
    const reasons = signUpReasons

    return (
        <>
            {/* Re-use default `Authenticator.SignUp.FormFields` */}
            <Authenticator.SignUp.FormFields />

            <SelectField
                errorMessage={validationErrors['custom:type']}
                hasError={!!validationErrors['custom:type']}
                name="custom:type"
                defaultValue={Object.keys(reasons)[0]}
                isRequired={true}
                label={I18n.get("_signup_reason")}>
                <option value="">{I18n.get("_select_reason", "Select a Type")}</option>
                {Object.keys(reasons).map(key => <option key={key} value={key}>{reasons[key]}</option>)}
            </SelectField>

                {/*TODO: Add recaptcha*/}
            {/*<ReCAPTCHA*/}
            {/*    style={{ margin: '16px 0 8px' }}*/}
            {/*    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}*/}
            {/*    onChange={(token)=>onChange(token)}*/}
            {/*    // onChange={(token)=>onChange}*/}
            {/*/>*/}
        </>
    );
}

export default  withStyles(styles)(withRouter(CustomSignUpFields));
