import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Avatar} from "../../Pages/Settings/Workspace/styles";
import Typography from "@material-ui/core/Typography";
import Skeleton from '@material-ui/lab/Skeleton';
import { withTheme } from '@material-ui/core/styles';

class CustomAvatar extends Component {

    render() {
         let primaryColor = this.props.theme.palette.primary.main
        let { width, height, picture, initials, backgroundColor, textColor} = this.props;
        let style = {width:width||40,height:height||40}

        backgroundColor = (backgroundColor||primaryColor).replace("#",'')
        textColor = (textColor||"#ffffff").replace("#",'')

        let url = `https://via.placeholder.com/${style.width}/${backgroundColor}/${textColor}?text=${initials}`

        if(!initials){
            return  <Skeleton onClick={this.props.onClick} style={{borderRadius: 4, backgroundColor:primaryColor, marginRight:16 }} variant="rect" width={width} height={height} />
        }
        return <Avatar onClick={this.props.onClick} variant="rounded" style={style} src={picture||url} />

        // return (<Avatar
        //             style={{ color: "#ffffff" , backgroundColor: backgroundColor||"red", ...style }}
        //             variant="rounded">
        //                 harry
        //             {/*<Typography style={{ fontWeight: 'bold', fontSize: 16 }} variant="caption" >kl</Typography>*/}
        //             {/*<Typography style={{ fontWeight: 'bold', fontSize: 16 }} variant="caption" >{initials|| "hd"}</Typography>*/}
        //         </Avatar>
        // );
    }
}

CustomAvatar.propTypes = {};

export default withTheme(CustomAvatar);
