import React from 'react';
import PropTypes from 'prop-types';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { ButtonContainer } from './styles';

const MenuButton = props => {

    return (
        <ButtonContainer >
            <IconButton onClick={props.handleToggle}>
                <MenuIcon style={{ color: 'white' }} />
            </IconButton>
        </ButtonContainer>
    );
}

MenuButton.propTypes = {
    handleToggle: PropTypes.func.isRequired
}

export default MenuButton