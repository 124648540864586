import React from "react";
import { decorate, observable } from "mobx";
import CardContent from '@material-ui/core/CardContent';
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import NewActionHeader from "../../NewAction/NewActionHeader/NewActionHeader";
import { I18n } from 'aws-amplify';
import TextInput from "../../NewAction/inputs/TextInput/TextInput";

class TelegramAction extends ActionModel {

	static metadata = {
		id: "telegram",
		name: "Telegram",
		icon: "https://l.fast.cm/content/icons/telegram.png",
		categories: {
			social: true
		},
		help: {
			id: 'articles/about-social-actions-telegram-integration',
			helperText: I18n.get('_telegram_help_text', "Click to learn more about the Telegram action")
		}
	};

	username = "";
	isValid = false;

	constructor(dat) {
		super();
		this.propNames.push("username");
		this.setFromObject(dat);
	}

	getConfigCardBody(cardModel, handleOnChangeIcon) {

		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />
				<TextInput
					fullWidth
					type="text"
					field={'username'}
					label={I18n.get("_username", "Username")}
					placeholder={I18n.get("_username", "Username")}
					store={this}
					disabled={cardModel.loading}
					autoComplete="off"
					style={{ marginTop: '16px' }}
					showRequiredHelperText={I18n.get("_required", "Required")}
					inputProps={{
						'data-testid': 'username'
					}}
				/>
			</CardContent>
		)
	}

	getRules() {
		return {
			username: {
				presence: { allowEmpty: false }
			},
			...super.getRules()
		}
	}

};

decorate(TelegramAction, {
	username: observable,
	isValid: observable
})

export default TelegramAction
