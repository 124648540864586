import React from 'react';
import { observable, decorate } from "mobx";
import { I18n } from 'aws-amplify';
import CardContent from "@material-ui/core/CardContent";
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import NewActionHeader from '../../NewAction/NewActionHeader/NewActionHeader';
import FormInput from "../../NewAction/inputs/FastFormInput/FastFormInput";

class FastForm extends ActionModel {

    static metadata = {
        id: "FastForm",
        name: "Fast Form",
        icon: "https://l.fast.cm/content/icons/fastform.png",
        categories: {
            general: true,
            integration: true
        }
    };

    constructor(dat) {
        super();

        this.propNames.push("formSchema", "uiSchema", "webhooks", "selectedProperties", "dataStores", "language","successMessage");
        this.setFromObject(dat);
    }

    Title = "Fast Form";
    Link = "https://www.thefastmind.com";
    ButtonText = "GO";
    isValid = false;
    language
    successMessage=""
    formSchema = {};
    uiSchema = {};

    webhooks = [];
    dataStores={
        fastmind:{active:true}
    }

    selectedProperties = [{ id: "email", required: true }, { id: "first_name", required: false }, { id: "last_name", required: false }];

    onValidateUrl = () => {
        this.isValid = true
    };

    getConfigCardBody(cardModel, handleOnChangeIcon) {
        return (
            <CardContent>
                <NewActionHeader
                    onChangeIcon={handleOnChangeIcon}
                    actionModel={this} />
                <FormInput
                    hookPlaceholder={I18n.get("_place_webhook", "Place webhook here")}
                    cardModel={cardModel}
                    actionModel={this} />
            </CardContent>
        )
    }

    getRules() {
        return {
            //TODO check if link is mandatory
            Link: {
                presence: { allowEmpty: false },
                url: { message: I18n.get("_action_wrong_link", "Wrong link") }
            },
            ...super.getRules()
        }
    }
}

decorate(FastForm, {
    Link: observable,
    isValid: observable,
    Title: observable,
    destId: observable,
    ButtonText: observable,
    selectedProperties: observable,
    webhooks: observable,
    uiSchema: observable,
    formSchema: observable,
    language: observable,
    successMessage: observable,
});

export default FastForm;
