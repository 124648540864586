import makeStyles from "@material-ui/styles/makeStyles";

export const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiSelect-icon': {
            left: 5
        },
        '& .MuiSelect-selectMenu': {
            paddingLeft: '32px'
        },
        '& .MuiInputLabel-root': {
            paddingLeft: '16px',
        },
        '& .MuiInputLabel-root.MuiInputLabel-shrink': {
            paddingLeft: 0,
        }
    },
    cardTitle: {
        marginBottom: 16
    },
    cardContent: {
        paddingBottom: 0
    },
    cardActions: {
        borderTop: `1px solid rgba(0, 0, 0, 0.12)`,
        paddingTop: '20px',
        paddingBottom: '20px',
    },
    checkBox: {
        '.MuiCheckbox-colorSecondary.Mui-checked': {
            color: theme.palette.primary.main
        }
    },
    btnAdd: {
        color: 'white',
        marginRight: 8
    },
    listItemCollapsed: {
        justifyContent: "space-between",
        alignItems: "center",
        padding: '6px 8px',
        marginBottom: 5,
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        borderRadius: theme.shape.borderRadius,
        border: `1px solid rgba(0, 0, 0, 0.23)`
    },
    listItemCollapsedIconContainer: {
        display: 'flex',
        justifyContent: "flex-start",
        alignItems: "center"
    },
    listItemCollapsedIcon: {
        height: 32,
        width: 32,
        marginRight: 8,
        borderRadius: theme.shape.borderRadius,
        border: 'none',
        flexGrow: 0,
        cursor: 'pointer'
    },
    listItemSelector: {
        backgroundColor: "rgba(0, 0, 0, 0.04)"
    },
    popOverListItemSelectorPaper: {
        width: '420px'
    },
    bttnExpandCollapseContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginBottom: 8
    },
    bttnExpandCollapse: {
        padding: 0,
        minWidth: 0,
        marginLeft: 5
    },
    checkBoxDlg: {
        marginRight: 5,
    },
    divider: {
        margin: '24px 0 16px 0'
    },
    listItemExpanded: {
        margin: 0,
        paddingBottom: 8,
        padding: 0,
        alignItems: 'flex-start',
        borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
        '&:last-child': {
            paddingBottom: 8,
            borderLeft: 0
        }
    },
    listItemExpandedInnerContainer: {
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: theme.shape.borderRadius,
        maxWidth: '400px'
    },
    childIndicatorContainer: {
        width: 20,
        height: '100%'
    },
    childIndicator: {
        width: 20,
        height: 34,
        marginRight: 2,
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
    },
    borderLeft: {
        borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
    },
    autoSelectContainer: {
        display: 'flex',
        alignItems: 'center'
    }
}));
