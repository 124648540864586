import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { useStyles, DialogTitle } from './styles';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Auth, API, I18n } from 'aws-amplify';
import * as Sentry from "@sentry/react";
import { Typography } from '@material-ui/core';

function AccountsDialog({ dataSourceId, ...props }) {

    const [accounts, setAccounts] = useState([])
    const [error, setError] = useState();
    const [accountsChecked, setAccountsChecked] = useState({})
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    const handleChange = (event) => {
        setAccountsChecked({
            ...accountsChecked,
            [event.target.name]: !accountsChecked[event.target.name]
        })
    }

    const getProviderName = (provider) => {
        let name = "";
        switch (provider) {
            case 'facebook':
                name = 'Facebook'
                break;
            case 'googleAds':
                name = 'Google'
                break;
            case 'instagram':
                name = 'Instagram'
                break;
            default:
                break;
        }

        return name
    }

    async function handleConnect() {
        setLoading(true)
        let _accountsData = Object.keys(accountsChecked).map(key => {
            return {
                name: accounts[key].name,
                accountID: key
            }
        })
        let currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true })
        if (currentUser) {
            let path = '/dataSources/addNewConnectedAccounts';
            let apiName = 'fastCardAuth';
            let data = {
                body: {
                    dataSourceID: dataSourceId,
                    accounts: _accountsData
                }
            }

            try {
                await API.post(apiName, path, data)
                const success = true;
                props.handleClose(success)
            } catch (error) {
                Sentry.captureException(error);
                setLoading(false)
            }

        }
    }

    async function fetchAccounts(sourceId) {
        setLoading(true)
        let currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true })

        if (currentUser) {
            let path = '/dataSources/getAllAdsAccounts';
            let apiName = 'fastCardAuth';
            let data = {
                body: {
                    dataSourceID: sourceId
                }
            }

            try {
                let result = await API.post(apiName, path, data)
                let _accounts = {}
                result.data.accounts.forEach(account => {
                    _accounts[account.id] = account
                })
                setAccounts(_accounts)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(error)
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        if (dataSourceId) {
            fetchAccounts(dataSourceId)
        }
    }, [dataSourceId])

    return (
        <Dialog
            open={props.open}
            fullWidth
            maxWidth="sm"
            onClose={props.handleClose}>
            <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                {I18n.get("_connect", "Connect") + ' ' + getProviderName(props.provider) + ' ' + I18n.get("_account", "Account") + 's'}
            </DialogTitle>
            <Divider />
            <DialogContent className={classes.content}>
                {loading ?
                    <div className={classes.progressContainer}>
                        <CircularProgress className={classes.progress} color="primary" thickness={3.4} />
                    </div> :
                    error ?
                        <div className={classes.errorWrapper}>
                            <Typography>Oops!</Typography>
                            <Typography>Something went wrong. Please try again later</Typography>
                        </div> :
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormGroup>
                                {Object.keys(accounts).map(key => {
                                    return (
                                        <FormControlLabel
                                            key={key}
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={accountsChecked[key] || false}
                                                    onChange={handleChange}
                                                    name={key} />
                                            }
                                            label={accounts[key].name}
                                        />
                                    )
                                })}
                            </FormGroup>
                        </FormControl>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} >{I18n.get("_cancel", "Cancel")}</Button>
                <Button disabled={Object.keys(accountsChecked).length === 0} onClick={handleConnect} color="primary" autoFocus>{I18n.get("_connect", "Connect")}</Button>
            </DialogActions>
        </Dialog>
    )
}

AccountsDialog.propTypes = {
    provider: PropTypes.string,
    handleClose: PropTypes.func.isRequired
}

export default AccountsDialog;