import styled from 'styled-components';

export const Container = styled.div`
    height: 12rem;
    display: flex;
    padding: 32px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const ProgressContainer = styled.div`
    width: 100%;
    padding: 16px 0;
`;