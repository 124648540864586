import React from "react";
import { decorate, observable } from "mobx";
import CardContent from "@material-ui/core/CardContent";
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import NewActionHeader from "../../NewAction/NewActionHeader/NewActionHeader";
import TextInput from "../../NewAction/inputs/TextInput/TextInput";
import { I18n } from 'aws-amplify';

class LinkAction extends ActionModel {
	static metadata = {
		id: "default",
		name: "Link",
		icon: "https://l.fast.cm/content/icons/default.png",
		categories: {
			general: true
		}
	};

	getConfigCardBody(cardModel, handleOnChangeIcon) {

		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />

				<TextInput
					data-testid='default-action-link'
					type={'text'}
					field={'Link'}
					label={I18n.get("_link")}
					store={this}
					disabled={cardModel.loading}
					showRequiredHelperText={I18n.get("_required", "Required")}
					inputProps={{
						'data-testid': 'default_link'
					}}
				/>
			</CardContent>
		)
	}

	getRules() {
		return {
			Link: {
				presence: { allowEmpty: false },
				url: { message: I18n.get("_action_wrong_link", "Wrong link") }
			},
			...super.getRules()
		}
	}
}

decorate(LinkAction, {
	Image: observable
})

export default LinkAction;
