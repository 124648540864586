import React, { Component } from 'react';
import { observer } from "mobx-react";
import { I18n } from 'aws-amplify';
import { API } from 'aws-amplify';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import AppsItem from './AppsItem/AppItem';
import { withStyles } from '@material-ui/core/styles';
import * as Sentry from "@sentry/react";

const styles = {
  paper: {
    width: '420px',
  }
};

class AppSearchInput extends Component {

  state = {
    value: this.props.actionModel.Link,
    suggestions: [],
    open: false
  };

  inputRef = React.createRef();

  handleClose = () => {
    this.props.actionModel.Link = ""

    this.setState({
      suggestions: [],
      open: false
    });
  };

  handleSelected = suggestion => {
    this.props.actionModel.Title = suggestion.title
    this.props.actionModel.Image = suggestion.icon
    this.props.actionModel.Link = suggestion.url

    this.setState({
      open: false,
      value: suggestion.url
    })
  }

  handleSearch = event => {

    let value = event.target.value;
    this.props.actionModel.Link = ""

    this.setState({
      value: value
    })

    clearTimeout(this.typingTimer);

    this.typingTimer = setTimeout(() => {
      if (value) {
        this.getSuggestions(value)
      }
    }, 400);
  }

  componentWillUnmount() {
    clearTimeout(this.typingTimer);
  }

  getSuggestions = value => {

    if (!this.props.appPlatform) {
      return
    }
    let path = `/Search${this.props.appPlatform}AppInfo`;

    let myInit = {
      body: {
        term: value,
        limit: 3
      }
    };

    let apiName = 'fastShare';
    API.post(apiName, path, myInit)
      .then(response => {
        this.setState({
          suggestions: response.slice(0, 4),
          open: response.length > 0 ? true : false
        })
      })
      .catch(error => {
        Sentry.captureException(error);
        this.setState({
          suggestions: []
        });
      });
  };

  render() {
    let { cardModel, classes } = this.props;

    return (
      <div style={{ flex: 1 }}>
        <TextField
          disabled={cardModel.loading}
          fullWidth
          ref={this.inputRef}
          placeholder={I18n.get("_app_search_placeholder")}
          label={I18n.get("_app_search_placeholder")}
          margin="dense"
          value={this.state.value}
          variant="outlined"
          onChange={this.handleSearch}
          InputProps={{
            endAdornment: (
              <React.Fragment>
                {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
              </React.Fragment>
            ),
          }}
        />
        <Popover
          open={this.state.open}
          classes={{
            paper: classes.paper
          }}
          anchorEl={this.inputRef.current}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <List>
            {this.state.suggestions.map(el => {
              return <AppsItem
                key={el.title}
                suggestion={el}
                handleSelected={this.handleSelected} />
            })}
          </List>
        </Popover>
      </div>
    )
  }
}

export default withStyles(styles)(observer(AppSearchInput));