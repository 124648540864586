import { observer } from "mobx-react";
import React, { useEffect } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const SelectInput = observer(({ store, field, label, showRequiredHelperText, onChange, options, style, ...otherProps }) => {

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);

    useEffect(() => {
        if (inputLabel.current)
            setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    return (
        <FormControl
            margin="dense"
            variant="outlined"
            style={style ? style : {}}
        >
            {label && (
                <InputLabel ref={inputLabel} >{label}</InputLabel>
            )}
            <Select
                name={field}
                margin="dense"
                value={store[field]}
                onChange={event => store.setField(field, event.target.value)}
                variant="outlined"
                labelWidth={labelWidth}
                {...otherProps}
            >
                {options.map(el => {
                    return (
                        <MenuItem key={el.value} value={el.value}>{el.label}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    );
});

export default SelectInput;
