import styled from "styled-components";

const previewBackground = "#E2E2E9";

export const DragableContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
`;


export const ContentContainer = styled.div`
    display: flex;
    justify-content: space-between;'
`;

export const PreviewContainer = styled.div`
    width:400px;
    max-height: 700px;
    padding:20px;
    align-items: center;
`;


export const FormContainer = styled.div`
    height: 100%;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-self: center;
    justify-content: center;
    align-content: center;
    background-color: ${previewBackground};
`;



export const FormBackground = styled.div`
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    min-width: 100%;
    min-height: 50%;
    max-height: 70%;
    overflow: scroll;
    align-self:center;
`;





