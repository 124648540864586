export const styles = (theme) => ({
    root: {
        overflowY: 'scroll',
        scrollBehavior: 'smooth',
        '&::-webkit-scrollbar-thumb': {
            background: '#bbb'
        },

        msOverflowStyle: "none",
        scrollbarColor: "#F5F5F5"
    },
    showBar: {
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#F5F5F5"
        },
        scrollbarWidth: "thin"
    },

    hideBar: {
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none"
    },
})
