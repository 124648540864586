import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'aws-amplify';

import {inject, observer} from "mobx-react";
import Button from "@material-ui/core/Button";
import lodashGet from "lodash/get";
import {
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    makeStyles,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText, ListItemSecondaryAction, Checkbox
} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import {ChipsContainer, FormContainer} from "../FastFormInput/styles";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import lodashReject from 'lodash/reject'
import AddRetailerDialog from "./AddRetailerDialog";
import AddVariantDialog from "./AddVariantDialog";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from '@material-ui/icons/MoreVert';

FastProductInput.propTypes = {

};

const useStyles = makeStyles((theme) => ({
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
    container: {
        padding: theme.spacing(1),
        border: theme.palette.divider,
        borderWidth:0.1,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        borderStyle:'dashed'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function RetailerChip(props){
    const { retailer, selectedSku, selectRetailer, removeRetailer} = props;
    const isSelected = selectedSku===retailer.sku
    return(<Chip
        avatar={<Avatar alt={retailer?.name} src={`https://logo.clearbit.com/${retailer.source}.com`} />}
        clickable={!isSelected}
        onClick={()=>selectRetailer(retailer)}
        onDelete={()=>removeRetailer(retailer)}
        color={!isSelected?'default':'primary'}
        variant={'outlined'}
        style={{margin: 4}}
        label={I18n.get(`_retailer_${retailer?.source}`)}
    />)
}

function FastProductInput(props) {
    const classes = useStyles();
    const { cardModel, actionModel, theme } = props;
    const [selectedVariant, setSelectedVariant] = useState(0);
    const [addingRetailer, setAddingRetailer] = useState(false);
    const [addingVariant, setAddingVariant] = useState(false);
    let variants = lodashGet(actionModel, 'productData.variants',[])
    let variant = variants[selectedVariant]
    const [selectedSku, setSelectedSku] = useState(lodashGet(variant,'retailers[0].sku',undefined));

    const getSelectedRetailer = ()=>{
        return lodashGet(variant, 'retailers',[]).filter(item =>item.sku===selectedSku)[0]||{}
    }
    let selectedRetailer = getSelectedRetailer()

    useEffect(() => {
        setSelectedSku(lodashGet(variant,'retailers[0].sku',''))
    }, [selectedVariant]);

    const addRetailer = (retailer)=>{
        variant.retailers.push(retailer)
        selectRetailer(retailer)
        setAddingRetailer(false)
    }
    const addVariant = (variant)=>{
        variants.push({retailers:[],...variant})
        setSelectedVariant(variants.length-1)
        setAddingVariant(false);
    }

    const selectRetailer = (retailer)=>{
        setSelectedSku(retailer.sku)
    }

    const removeRetailer = (retailer)=>{
        variant.retailers = lodashReject(variant.retailers, {sku:retailer.sku, source:retailer.source, source_type:retailer.source_type})
    }
    return (
        <div className={classes.container}>
            <div style={{textAlign:'end'}}>
                <Button
                    style={{color:'white'}}
                    color={'primary'}
                    size="small"
                    onClick={()=>setAddingVariant(true)}
                    variant={'contained'}>{I18n.get("_add_product")}</Button>
            </div>

       {variant &&
           <div>
            <div style={{textAlign:'start'}}>
                <FormControl
                    variant="outlined"
                    size={'small'}
                    className={classes.formControl}>
                    <InputLabel id="product-select-outlined-label">{I18n.get('_variants')}</InputLabel>
                    <Select
                        labelId="product-outlined-label"
                        id="product-select-outlined"
                        value={selectedVariant}
                        onChange={(event)=>setSelectedVariant(event.target.value)}
                        label={I18n.get('_variants')}
                    >
                        { variants.map((product, index) => <MenuItem value={index} key={index}>{product.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </div>

            <div>
                <List dense className={classes.root}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar
                                variant={'square'}
                                alt={selectedRetailer?.name}
                                src={selectedRetailer?.image}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            id={"labelId"}
                            primary={selectedRetailer?.name}
                            secondary={`${I18n.get('_productID')}# ${selectedRetailer?.sku||""}`}
                        />
                        <ListItemSecondaryAction>
                            <IconButton size="small" color="primary" aria-label="edit variant" component="span">
                                <MoreVertIcon/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
                <div>
                    <IconButton size="small" color="primary" aria-label="edit variant" component="span">
                        <EditIcon/>
                    </IconButton>
                    <IconButton size="small" color="primary" aria-label="upload picture" component="span">
                        <VisibilityIcon />
                    </IconButton>
                </div>
            </div>

            <Divider style={{marginTop:8, marginBottom:8}} />

               <div style={{textAlign:'end'}}>
                   <Button
                       size={"small"}
                       style={{color:"white"}}
                       variant={'contained'}
                       onClick={()=>setAddingRetailer(true)}
                       color="primary" >
                       {I18n.get("_add_retailer", )}
                   </Button>
               </div>

            {/*<FormContainer style={{marginTop:8}}>*/}
            {/*    <Typography variant='subtitle2' >{I18n.get("_retailers")} </Typography>*/}
            {/*    <Tooltip title={I18n.get("_field_from_your_form", "Fields from your form")}>*/}
            {/*        <Button*/}
            {/*            size={"small"}*/}
            {/*            style={{color:"white"}}*/}
            {/*            variant={'contained'}*/}
            {/*            onClick={()=>setAddingRetailer(true)}*/}
            {/*            color="primary" >*/}
            {/*            {I18n.get("_add_retailer", )}*/}
            {/*        </Button>*/}
            {/*    </Tooltip>*/}
            {/*</FormContainer>*/}

            <Typography variant='subtitle1' >{I18n.get("_retailer_type_in_store")}</Typography>
            <ChipsContainer style={{marginTop:2, minHeight:50}}>
                {lodashGet(variant, 'retailers',[]).filter(i=>i.source_type==="in_store").map((retailer, index)=><RetailerChip selectRetailer={selectRetailer} selectedSku={selectedSku} key={index} retailer={retailer} removeRetailer={removeRetailer} />)}
            </ChipsContainer>

            <Typography variant='subtitle1' >{I18n.get("_retailer_type_online")}</Typography>
            <ChipsContainer style={{marginTop:2, minHeight:50}}>
                {lodashGet(variant, 'retailers',[]).filter(i=>i.source_type==="online").map((retailer, index)=><RetailerChip selectRetailer={selectRetailer} selectedSku={selectedSku} key={index} retailer={retailer} removeRetailer={removeRetailer} />)}
            </ChipsContainer>
           </div>
       }
            <AddVariantDialog onVariantAdded={(variant)=>addVariant(variant)} handleClose={()=>setAddingVariant(false)} open={addingVariant}/>
            <AddRetailerDialog onRetailerAdded={(retailer)=>addRetailer(retailer)} handleClose={()=>setAddingRetailer(false)} open={addingRetailer}/>
        </div>
    );
}

export default withStyles(undefined,{withTheme:true})(inject("UserModel")(observer(FastProductInput)));