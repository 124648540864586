

export const defaultValue = {
    email: "john.wick@thefastmind.com",
    first_name: "John",
    last_name: "Wick",
    phone: "13151946020",
    age: 54,
    note: "It wasn't just a puppy.",
    postal_code: '13290',
    country: 'US',
    language: 'en'
};