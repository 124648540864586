import { createContext, useContext } from 'react';
import { MobXProviderContext } from 'mobx-react'
import { OnboardingModel } from '../models/OnboardingModel/OnboardingModel';
import Theme from '../models/Theme/Theme';

export const modelsContext = createContext({
    onboardingModel: new OnboardingModel(),
    theme: new Theme()
})

export function useModels() {
    return {
        ...useContext(MobXProviderContext),
        ...useContext(modelsContext)
    }
}