import styled from "styled-components";

export const HooksContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const FormContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const ChipsContainer = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 5px
`;


