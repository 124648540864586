import React from "react";
import { decorate, observable } from "mobx";
import { I18n } from 'aws-amplify';
import CardContent from '@material-ui/core/CardContent';
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import NewActionHeader from '../../NewAction/NewActionHeader/NewActionHeader';
import TextInput from "../../NewAction/inputs/TextInput/TextInput";

class EmailAction extends ActionModel {
	static metadata = {
		id: "email",
		name: "Email",
		icon: "https://l.fast.cm/content/icons/email.png",
		categories: {
			general: true
		},
		help: {
			id: 'articles/about-links-add-your-email',
			helperText: I18n.get('_email_help_text', "Click to learn more about the Email action")
		}
	};

	constructor(dat) {
		super();
		this.propNames.push("emailAddress", "subject", "message");
		this.setFromObject(dat);
	}

	emailAddress = "";
	isValid = false
	subject = "";
	message = "";

	getConfigCardBody(cardModel, handleOnChangeIcon) {
		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />

				<TextInput
					fullWidth
					type="email"
					field={'emailAddress'}
					label={I18n.get("_action_email_placeHolder_recipient")}
					store={this}
					disabled={cardModel.loading}
					inputProps={{
						'data-testid': 'emailAddress'
					}}
				/>

				<TextInput
					fullWidth
					type="text"
					field={'subject'}
					label={I18n.get("_action_email_placeHolder_subject")}
					store={this}
					disabled={cardModel.loading}
					inputProps={{
						'data-testid': 'subject'
					}}
				/>

				<TextInput
					fullWidth
					type="textarea"
					field={'message'}
					label={I18n.get("_action_email_placeHolder_message")}
					store={this}
					disabled={cardModel.loading}
					multiline
					inputProps={{
						'data-testid': 'message'
					}}
				/>
			</CardContent>
		)
	}

	getRules() {
		return {
			emailAddress: {
				presence: { allowEmpty: false },
				email: true
			},
			...super.getRules()
		}
	}
};

decorate(EmailAction, {
	emailAddress: observable,
	isValid: observable,
	subject: observable,
	message: observable
})

export default EmailAction
