import React from "react";
import { observable, decorate } from "mobx";
import CardContent from '@material-ui/core/CardContent';
import ActionModel from "../../../../../models/ActionModel/ActionModel";
import NewActionHeader from "../../NewAction/NewActionHeader/NewActionHeader";
import PhoneInput from "../../NewAction/inputs/PhoneInput/PhoneInput";
import { I18n } from "aws-amplify";

class CallAction extends ActionModel {
	static metadata = {
		id: "call",
		name: "Phone",
		icon: "https://l.fast.cm/content/icons/call.png",
		categories: {
			general: true
		},
		help: {
			id: 'articles/about-general-links-phone',
			helperText: I18n.get('_call_help_text', "Click to learn more about the Phone action")
		}
	};

	phoneNumber = "";
	isValid = false;

	constructor(dat) {
		super();
		this.propNames.push("phoneNumber");
		this.setFromObject(dat);
	}

	getConfigCardBody(cardModel, handleOnChangeIcon) {
		return (
			<CardContent>
				<NewActionHeader
					onChangeIcon={handleOnChangeIcon}
					actionModel={this} />
				<PhoneInput
					cardModel={cardModel}
					actionModel={this} />
			</CardContent>
		)
	}

	getRules() {
		return {
			phoneNumber: {
				presence: { allowEmpty: false }
			},
			...super.getRules()
		}
	}
};

decorate(CallAction, {
	phoneNumber: observable,
	isValid: observable
})

export default CallAction
